import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Data, Router } from '@angular/router';
import { DialogService } from '../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../utils/utils';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common'

@Component({
    selector: "kpidetailform",
    templateUrl: './kpidetail-form.component.html',
    styleUrls: ['../../../style/azdatafactory.component.less', '../../../style/custom-mat-dialog.component.less', './kpidetail-form.component.less',
        '../../../style/new-generic-styles.component.less']

})

export class KpiDetailFormComponent implements OnInit {

    selected = {} as ControllerTypeByTenant;

    controllerTypes: ControllerType[] = [];

    body: any;
    title: string;
    submitted = false;
    form: FormGroup = new FormGroup({});

    MersyId: string;
    KpiDefinitionContent: string;
    KpiDefinitionTechnical: string;
    KpiDescription: string;
    KpiName: string;
    KpiFormula: string;
    ReportingType: string;
    KpiUnit: string;
    KpiOwner: string;

    isForecastRequired: string;
    NoOplRequired: string;
    IsAccumulative: string;
    AvailabilityTime: string;

    BreakdownDescription1: string;
    BreakdownDescription2: string;
    ProductHierarchyDescription: string;
    MovementTypeDescription: string;

    selectedTabIndex: number;

    DataConceptSelected: string;
    MarketActorSelected: string;
    CrudeData: any;
    DataConcepts: string[];
    MarketActors: string[];

    kpiDescriptionDetails: any[] | undefined;

    TabToSee: string;

    kpiHistoryLogDialogTags: kpiHistoryLogDialogTags | undefined;

    columnsToDisplay = ['User', 'CreatedAtUtc', 'Value', 'DataConcept', 'ApprovalStatus'];

    public LogDetailDataSource = new MatTableDataSource<any>();


    constructor(private http: HttpClient
        , private confimDialog: DialogService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<KpiDetailFormComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService
        , private formBuilder: FormBuilder
        , private datepipe: DatePipe) {


        this.confimDialog.tthis = this;
        this.title = "";
        this.TabToSee = "";
        this.MersyId = "-";
        this.KpiDefinitionContent = "-";
        this.KpiDefinitionTechnical = "-";
        this.KpiDescription = "-";
        this.KpiName = "-";
        this.KpiFormula = "-";
        this.KpiUnit = "-";
        this.KpiOwner = "-";
        this.ReportingType = "-";
        this.BreakdownDescription1 = "";
        this.BreakdownDescription2 = "";
        this.ProductHierarchyDescription = "";
        this.MovementTypeDescription = "";
        this.selectedTabIndex = 0;

        this.isForecastRequired = "";
        this.IsAccumulative = "";
        this.NoOplRequired = "";
        this.NoOplRequired = "";
        this.AvailabilityTime = "";

        this.DataConcepts = [];
        this.MarketActors = [];
        this.DataConceptSelected = "";
        this.MarketActorSelected = "";
    }

    ngOnInit(): void {

        const tthis = this;

        var url = this.baseUrl + "api/KPIManagement/kpi_info/" + this.data.KpiDefinitionId + "/" + this.data.KpiId;

        if (this.data.DataType != undefined && this.data.DataType != '')
            url += "/" + this.data.DataType;

        this.kpiHistoryLogDialogTags = this.data.kpiHistoryLogDialogTags;

        this.http.get<any[]>(url).subscribe(
            {
                next: (r: any) => {
                    if (r.DataConcepts != undefined) {
                        if (r.DataConcepts[0] == 'ACTUAL') {
                            this.columnsToDisplay = ['User', 'CreatedAtUtc', 'Value', 'ApprovalStatus'];
                        }
                        else {
                            this.DataConcepts = r.DataConcepts;
                            this.columnsToDisplay = ['User', 'CreatedAtUtc', 'Value', 'DataConcept', 'ApprovalStatus'];

                            this.DataConcepts.unshift('ALL');
                            this.DataConceptSelected = 'ALL';

                            // MarketActor column is displayed only if it segment sheet
                            if (r.MarketActors) {
                                this.MarketActors = r.MarketActors;
                                this.columnsToDisplay.splice(this.columnsToDisplay.length - 1, 0, 'MarketActor');

                                this.MarketActors.unshift('ALL');
                                this.MarketActorSelected = 'ALL';
                            }
                        }
                    }


                    this.MersyId = r.KpiInfo.MersyId;
                    if (r.KpiInfo.KpiDefinitionContent != undefined)
                        this.KpiDefinitionContent = r.KpiInfo.KpiDefinitionContent;
                    if (r.KpiInfo.KpiDefinitionTechnical != undefined)
                        this.KpiDefinitionTechnical = r.KpiInfo.KpiDefinitionTechnical;
                    if (r.KpiInfo.KpiDescription != undefined)
                        this.KpiDescription = r.KpiInfo.KpiDescription;
                    if (r.KpiInfo.KpiName != undefined)
                        this.KpiName = r.KpiInfo.KpiName;
                    if (r.KpiInfo.KpiFormula != undefined)
                        this.KpiFormula = r.KpiInfo.KpiFormula;
                    if (r.BreakdownDescription1 != undefined)
                        this.BreakdownDescription1 = r.BreakdownDescription1;
                    if (r.BreakdownDescription2 != undefined)
                        this.BreakdownDescription2 = r.BreakdownDescription2;
                    if (r.ProductHierarchyDescription != undefined)
                        this.ProductHierarchyDescription = r.ProductHierarchyDescription;
                    if (r.MovementTypeDescription != undefined)
                        this.MovementTypeDescription = r.MovementTypeDescription;

                    if (r.KpiInfo.ReportingType != undefined)
                        this.ReportingType = r.KpiInfo.ReportingType;
                    if (r.KpiInfo.KpiUnit != undefined)
                        this.KpiUnit = r.KpiInfo.KpiUnit;
                    if (r.KpiInfo.KpiOwner != undefined)
                        this.KpiOwner = r.KpiInfo.KpiOwner;

                    if (r.KpiInfo.isForecastRequired != undefined)
                        if (r.KpiInfo.isForecastRequired)
                            this.isForecastRequired = "YES";
                        else
                            this.isForecastRequired = "NO";

                    if (r.KpiInfo.IsAccumulative != undefined)
                        if (r.KpiInfo.IsAccumulative)
                            this.IsAccumulative = "YES";
                        else
                            this.IsAccumulative = "NO";

                    if (r.KpiInfo.NoOplRequired != undefined)
                        if (r.KpiInfo.NoOplRequired)
                            this.NoOplRequired = "YES";
                        else
                            this.NoOplRequired = "NO";

                    if (r.KpiInfo.AvailabilityTime != undefined)
                        this.AvailabilityTime = r.KpiInfo.AvailabilityTime;

                    this.CrudeData = r.ChangeLog;
                    this.LogDetailDataSource.data = r.ChangeLog;
                    if (r.ChangeLog != undefined)
                        this.TabToSee = "2";
                    else
                        this.TabToSee = "1";

                    this.setKpiDescriptionDetailsOrder();
                }, error: (error) => {
                    Utils.writeLog(this.http, this.baseUrl, error);
                }
            }
        );
    }

    FilterData() {
        this.LogDetailDataSource.data = this.CrudeData.filter(item => (this.DataConceptSelected !== 'ALL' ? item.DataConcept === this.DataConceptSelected : true)
            && ((this.MarketActorSelected !== 'ALL' && this.MarketActorSelected !== '') ? item.MarketActor === this.MarketActorSelected : true));
    }

    formatCurrency(value): string {

        if (value == undefined)
            return '';
        else
            return parseFloat(value).toLocaleString('de', { maximumFractionDigits: 2 });
    }


    // convenience getter for easy access to form fields
    get f()
    {
        return this.form.controls;
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onSubmit() {
        this.submitted = true;
        this.dialogRef.close(false);

    }

    onBack() {

    }

    setKpiDescriptionDetailsOrder() {
        if (this.kpiHistoryLogDialogTags) {
            
            const kpiTagsArray = Object.keys(this.kpiHistoryLogDialogTags).map(key => ({
                key,
                ...this.kpiHistoryLogDialogTags![key]
            }));

            kpiTagsArray.sort((a, b) => a.position - b.position);

            this.kpiDescriptionDetails = kpiTagsArray.map(tag => ({
                description: this[`${tag.key}`],
                title: tag.title ?? this.getAuxTagTitleFormat(tag.auxTitle),
 
            }));
        }
    }

    getAuxTagTitleFormat(auxTag: string): string {
        switch (auxTag) {
            case 'Breakdown1':
                return 'Breakdown 1';
            case 'Breakdown2':
                return 'Breakdown 2';
            case 'MovementType':
                return 'Movement Type';
            case 'ProductHierarchy':
                return 'Product Hierarchy';
            default:
                return auxTag;
        }
    }
}


