import { Component, Inject, OnInit, Self } from '@angular/core';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { takeUntil } from 'rxjs';
import { DestroyService } from '../../services/destroyservice';
import { MatDialog } from '@angular/material/dialog';
import { EmtTdcDeploymentStatusPopup } from '../emttdcdeployment/emttdc-deploymentstatus-popup.component';

@Component({
  selector: 'emtcontrollersoftwarecomponent',
  templateUrl: './emtcontrollersoftware.component.html',
    styleUrls: ['./emtcontrollersoftware.component.less', '../../style/new-generic-styles.component.less'],
    providers: [DestroyService]
})
export class EmtcontrollersoftwareComponent implements OnInit {
    availableFamilyCodes: FamilyCode[] = [];
    availableProductUsages: EMTProductUsageItem[] = [];
    availableCenters: MfgCenter[] = [];
    availableCenterProducts: MFGCenterProductItem[] = [];
    availableControllerSoftwareReleases: EMTControllerSoftwareReleaseItem[] = [];
    subscriptionStage: any;
    constructor(private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , @Self() private readonly destroy$: DestroyService
        , private matDialog: MatDialog) { }

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getFamilyCodes() });
        this.getFamilyCodes();
        this.getMFGCenters();
    }

    getFamilyCodes() {
        Utils.httpGetComponentFamilyCodes(
            this.http,
            this.baseUrl,
            this,
            function (tthis: EmtcontrollersoftwareComponent, result: ComponentFamilyCodes) {
                tthis.availableFamilyCodes = result.familyCodeList;
            }
        );
    }

    getMFGCenters() {
        Utils.httpGetMfgcenters(this.http,
            this.baseUrl,
            this,
            function (tthis: EmtcontrollersoftwareComponent, result: MfgCenters) {
                tthis.availableCenters = result.centersList;
            }
        );
    }

    loadProductUsage(productUsages: EMTProductUsageItem[]) {
        this.availableProductUsages = productUsages;
    }

    loadCenterProduct(centerProduct: MFGCenterProductItem[]) {
        this.availableCenterProducts = centerProduct;
    }

    loadControllerSoftware(controllerSoftware: EMTControllerSoftwareReleaseItem[]) {
        this.availableControllerSoftwareReleases = controllerSoftware;
    }

    openDeploymentInformationPopup() {
        this.matDialog.open(EmtTdcDeploymentStatusPopup, {
            panelClass: 'custom-mat-dialog',
        });
    }
}
