<div class="custom-mat-dialog-form mTab">

    <mat-tab-group [selectedIndex]="0">
        <mat-tab id="Tab1" *ngIf="this.TabToSee == '1'">
            <ng-template mat-tab-label>
                KPI Detail
            </ng-template>
            <div>
                <p>&nbsp;</p>
                <div>
                    <span class="title">KPI Code</span>
                    <p class="kpidetailblock">
                        {{this.MersyId}}
                    </p>
                    <span class="title">Name</span>
                    <p class="kpidetailblock">
                        {{this.KpiName}}
                    </p>
                    <span class="title">Description</span>
                    <p class="kpidetailblock">
                        {{this.KpiDescription}}
                    </p>
                    <span class="title">KPI Definition (technical)</span>
                    <p class="kpidetailblock">
                        {{this.KpiDefinitionTechnical}}
                    </p>

                    <table border="0" cellspacing="10">
                        <tr>
                            <td colspan="2">
                                <span class="title">KPI Definition (content)</span>
                                <p class="kpidetailblock">
                                    {{this.KpiDefinitionContent}}
                                </p>
                            </td>
                            <td colspan="2">
                                <span class="title">Formula</span>
                                <p class="kpidetailblock">
                                    {{this.KpiFormula}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="title">Unit</span>
                                <p class="kpidetailblock">
                                    {{this.KpiUnit}}
                                </p>
                            </td>
                            <td>
                                <span class="title">Owner</span>
                                <p class="kpidetailblock">
                                    {{this.KpiOwner}}
                                </p>
                            </td>
                            <td>
                                <span class="title">Reporting Type</span>
                                <p class="kpidetailblock">
                                    {{this.ReportingType}}
                                </p>
                            </td>
                            <td>
                                <span class="title">Is Accumulative</span>
                                <p class="kpidetailblock">
                                    {{this.IsAccumulative}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="title">Availability Time</span>
                                <p class="kpidetailblock">
                                    {{this.AvailabilityTime}}
                                </p>
                            </td>
                        </tr>
                    </table>

                </div>



            </div>
        </mat-tab>
        <mat-tab id="Tab2" *ngIf="this.TabToSee == '2'">
            <ng-template mat-tab-label>
                Change log
            </ng-template>
            <p>
                &nbsp;
            </p>
            <span class="title">KPI</span>
            <p class="kpidetailblock">
                {{this.MersyId}} &nbsp; &nbsp; {{this.KpiName}}
            </p>
            <ng-container *ngFor="let detail of kpiDescriptionDetails">
                <span class="title" *ngIf="detail.description != ''">{{ detail.title }}</span>
                <p class="kpidetailblock">
                    {{ detail.description }}
                </p>
            </ng-container>

            <table mat-table
                   [dataSource]="LogDetailDataSource"
                   multiTemplateDataRows
                   matSort
                   class="mat-elevation-z8 mat-table-custom my-table" id="KpiLogDetail">

                <ng-container matColumnDef="User">
                    <th style="text-align: left !important;" mat-header-cell *matHeaderCellDef> User </th>
                    <td style="text-align: left !important;" mat-cell *matCellDef="let element">
                        <p style="width: 200px;" class="overflow-ellipsis" [innerHTML]="element.User"></p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="CreatedAtUtc">
                    <th mat-header-cell *matHeaderCellDef> Created At (UTC) </th>
                    <td mat-cell *matCellDef="let element">
                        <p [innerHTML]="element.CreatedOn | date:'yyyy-MM-dd HH:mm'"></p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="DataConcept">
                    <th mat-header-cell *matHeaderCellDef>
                        Data Concept <br />
                        <mat-select (selectionChange)="FilterData()"
                                    [(ngModel)]="DataConceptSelected" style="background-color:gray;font-size: x-small">
                            <mat-option *ngFor="let option of DataConcepts;" [value]="option"> {{ option }} </mat-option>
                        </mat-select>

                        <!--<ng-select (change)="DataConceptChange()"
                       [(ngModel)]="DataConceptSelected">
                <ng-option *ngFor="let option of DataConcepts;" [value]="option"> {{ option }} </ng-option>
            </ng-select>-->

                    </th>
                    <td mat-cell *matCellDef="let element">
                        <p [innerHTML]="element.DataConcept"></p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="MarketActor">
                    <th mat-header-cell *matHeaderCellDef>
                        Market Actor <br />
                        <mat-select (selectionChange)="FilterData()"
                                    [(ngModel)]="MarketActorSelected" style="background-color:gray;font-size: x-small">
                            <mat-option *ngFor="let option of MarketActors;" [value]="option"> {{ option }} </mat-option>
                        </mat-select>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <p [innerHTML]="element.MarketActor"></p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Value">
                    <th style="text-align: center !important; width:100px;" mat-header-cell *matHeaderCellDef> Value </th>
                    <td style="text-align: right !important; width: 100px; " mat-cell *matCellDef="let element">
                        <p [innerHTML]="formatCurrency(element.OldValue)"></p>
                    </td>
                </ng-container>
                <ng-container matColumnDef="ApprovalStatus">
                    <th mat-header-cell *matHeaderCellDef> Approval<br /> Status </th>
                    <td mat-cell *matCellDef="let element">
                        <p [innerHTML]="element.ApprovalStatus"></p>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row2; let i2 = index; columns: columnsToDisplay;"></tr>

                <!--<mat-paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>-->
            </table>

        </mat-tab>

    </mat-tab-group>

    <div style="margin-top:30px;">
    <p>&nbsp;</p>
    <p>
        <button type="button"
                class="btn btn-danger"
                (click)="onNoClick()">
            Close
        </button>
    </p>
    </div>

</div>
