import { Component, OnInit } from '@angular/core';
import { EquipmentManagenementService } from '../../services/equipmentmanagement.service';
import { MatTableDataSource } from '@angular/material/table';
import { APICallStatus } from '../../utils/utils';
import { CustomDialogService } from '../../services/customdialog.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'emttdc-deploymentstatus-popup',
    templateUrl: './emttdc-deploymentstatus-popup.component.html',
    styleUrls: ['./emttdc-deploymentstatus-popup.component.less', '../../style/new-generic-styles.component.less']
})
export class EmtTdcDeploymentStatusPopup implements OnInit {

    constructor(
        private emtConfigurationService: EquipmentManagenementService,
        private customDialog: CustomDialogService,
        public dialogRef: MatDialogRef<EmtTdcDeploymentStatusPopup>
    )
    {
        this.environments = [] as EnvironmentObject[];
    }

    public dataSource = new MatTableDataSource<EnvironmentObject>();
    buildRelease: BuildRelease | undefined;
    environments: EnvironmentObject[] | undefined;
    prodEnvironment: EnvironmentObject | undefined;
    devEnvironment: EnvironmentObject | undefined;

    columnsToDisplay = ['environment'];
    showTable: boolean = false;
    apiResponse: APICallStatus = APICallStatus.NotRequested;

    ngOnInit(): void {
        this.refreshInformation();
    }

    get isLoading(): boolean {
        return this.apiResponse === APICallStatus.Waiting4Response;
    }

    get buildExists(): boolean {
        return this.buildRelease ? this.buildRelease?.BuildId !== -1 : false;
    }

    refreshInformation() {
        this.apiResponse = APICallStatus.Waiting4Response;
        this.showTable = false;
        this.emtConfigurationService.getLastBuildId(
            this,
            (tthis: EmtTdcDeploymentStatusPopup, buildRelease: BuildRelease) => {               
                tthis.buildRelease = buildRelease;
                if (tthis.buildRelease && tthis.buildRelease.BuildId !== -1) {
                    
                    tthis.emtConfigurationService.getRelease(      // Get release in case of being generated by build id  
                        tthis,
                        (tthis: EmtTdcDeploymentStatusPopup, data: ReleaseInformation) => {
                            tthis.apiResponse = APICallStatus.ResponseDataOk;
                            if (data.Environments) {
                                tthis.showTable = true;
                                tthis.environments = data.Environments;
                                tthis.devEnvironment = data.Environments?.find(env => env.name === 'dev');
                                tthis.prodEnvironment = data.Environments?.find(env => env.name === 'ea');
                                if (tthis.buildRelease)
                                    tthis.buildRelease.ReleaseId = data.ReleaseId
                                tthis.columnsToDisplay = ['environment', 'environmentdeploymentStatus'];
                                tthis.dataSource.data = tthis.environments;
                            }
                        },
                        error => {
                            this.apiResponse = APICallStatus.ResponseError;
                        }
                    );
                }
                else {
                    tthis.showTable = false;
                    this.apiResponse = APICallStatus.ResponseEmptyOk;
                    tthis.columnsToDisplay = ['environment'];
                    tthis.dataSource.data = tthis.environments!
                }
            },
            error => {
                this.apiResponse = APICallStatus.ResponseError;
            }
        )
    }

    onClickDeploy() {
        this.apiResponse = APICallStatus.Waiting4Response;
        this.emtConfigurationService.creteBuild(
            (data: boolean) => {
                this.apiResponse = APICallStatus.ResponseDataOk;
                if (data === true) {
                    this.customDialog.openDisplayInfoDialog({ title: 'Build started', message: 'Please wait until build finishes for releasing it into desired environment. Use refresh button to check whether build is generated or not.' });
                    this.refreshInformation();
                }
                else
                    this.customDialog.openDisplayInfoDialog({ title: 'Error', message: 'Error executing deployment. Please, contact DevOps team.' });
            }
            , error => {
                this.apiResponse = APICallStatus.ResponseError;
            });
    }

    releaseEnvironment(envId: number) {
        this.emtConfigurationService.releaseEnvironment(
            this
            , envId
            , (tthis: EmtTdcDeploymentStatusPopup, data: boolean) => {
                if (data === true) {
                    tthis.customDialog.openDisplayInfoDialog({ title: 'Release started', message: 'Please wait until deployment finishes. Use refresh button to update the information.' });
                    tthis.refreshInformation();
                }
                else
                    tthis.customDialog.openDisplayInfoDialog({ title: 'Error', message: 'Error executing deployment. Please, contact DevOps team.' });

            }
        );
    }

    backClick(): void {
        this.dialogRef.close();
    }
}
