<div class="container-tab-lists new-table-style">
    <div style="display:flow-root">
        <div style="float:left; width:40vw">
            <h2>Focus Plan</h2>
        </div>
    </div>
    <mat-tab-group [(selectedIndex)]="selectedTabIndex" #tabGroup>
        <mat-tab label="Definition">
            <div class="inner-tcontainer-tab">
                <div style="display:flow-root">
                    <div style="text-align:right; float:left">
                        <h2>{{isMinMeasuresReached() ? null : minMeasuresWarning}}</h2>
                    </div>
                    <div style="text-align:right; float:right">

                        <a class='kpi-save-menu save-button' title="Save" *ngIf="showSaveButton() && (defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser)" (click)="onClickSave()">
                            <span class='glyphicon glyphicon-floppy-disk'></span> Save
                        </a>


                        <a class="kpi-button-menu"
                           [title]="canAddNewMeasure() ? 'Create measure' : 'Max Measure items limit reached'"
                           (click)="openCreateMeasurePopup()"
                           [class.disabled-link-looking]="!canAddNewMeasure()"
                           *ngIf="defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser">
                            <span class="glyphicon glyphicon-plus"></span> Create
                        </a>


                    </div>
                </div>
                    <br />
                    <focusplan-list #focusPlanList
                                    [measuresInfo]="measuresInfo"
                                    [waveId]="waveId"
                                    (refresh)="onRefresh()"></focusplan-list>
                </div>
        </mat-tab>
        <mat-tab label="Financial Impact">
            <div class="inner-tcontainer-tab">
                <div style="text-align:right; float:right">

                    <a class='kpi-save-menu save-button' title="Save" *ngIf="isEditModeEnabled && (defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser)" (click)="onClickSave()">
                        <span class='glyphicon glyphicon-floppy-disk'></span> Save
                    </a>

                    <a class='kpi-button-menu' title="Edit values" (click)="onClickEdit()" *ngIf="defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser">
                        <span class='glyphicon glyphicon-pencil'></span> {{isEditModeEnabled ? 'Undo' : 'Edit'}}
                    </a>

                    <h5 [style.visibility]="isEditModeEnabled === true ? 'visible' : 'hidden'">
                        Edit Mode is enabled. Please click on Save button to save all changes
                    </h5>
                </div>
                <br />
                <focusplanimpact-list #focusPlanListImpact
                                      [measureImpactsInfo]="measureImpactsInfo"
                                      [isEditModeEnabled]="isEditModeEnabled"
                                      [waveId]="waveId"
                                      (refresh)="onRefresh()"></focusplanimpact-list>
            </div>
        </mat-tab>
        <mat-tab label="Status">
            <div class="inner-tcontainer-tab">
                <div style="text-align:right; float:right">

                    <a class='kpi-save-menu save-button' title="Save" *ngIf="isEditModeEnabled" (click)="onClickSave()">
                        <span class='glyphicon glyphicon-floppy-disk'></span> Save
                    </a>

                    <a class='kpi-button-menu' title="Edit values" (click)="onClickEdit()" *ngIf="defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser">
                        <span class='glyphicon glyphicon-pencil'></span> {{isEditModeEnabled ? 'Undo' : 'Edit'}}
                    </a>

                    <h5 [style.visibility]="isEditModeEnabled === true ? 'visible' : 'hidden'">
                        Edit Mode is enabled. Please click on Save button to save all changes
                    </h5>
                </div>
                <br />
                <focusplanstatus-list #focusPlanListStatus
                                      [measuresInfo]="measuresInfo"
                                      [isEditModeEnabled]="isEditModeEnabled"
                                      [waveId]="waveId"
                                      (refresh)="onRefresh()"></focusplanstatus-list>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>
