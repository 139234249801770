import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../../../../../utils/utils';

@Component({
  selector: 'branchconfigdates-popup',
  templateUrl: './branchconfigdates-popup.component.html',
  styleUrls: ['./branchconfigdates-popup.component.less']
})
export class BranchconfigdatesPopup implements OnInit {

    @Output() datesChange = new EventEmitter<FieldBranchDates>();

    constructor(
        public dialogRef: MatDialogRef<BranchconfigdatesPopup>,
        @Inject(MAT_DIALOG_DATA) public data: FieldBranchDates,
        private formBuilder: FormBuilder,
    ) { }

    form!: FormGroup
    minDate: Date = new Date();

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            plannedAssessmentDateForm: [this.data?.PlannedAssessmentDate],
            actualAssessmentDateForm: [this.data?.ActualAssessmentDate],
            actualValidationCallDateForm: [this.data?.ActualValidationCallDate],
            plannedValidationCallDateForm: [this.data?.PlannedValidationCallDate],
            actualCheckInCall_1_DateForm: [this.data?.ActualCheckInCall_1_Date],
            actualCheckInCall_2_DateForm: [this.data?.ActualCheckInCall_2_Date],
            actualCheckInCall_3_DateForm: [this.data?.ActualCheckInCall_3_Date],
            actualCheckInCall_4_DateForm: [this.data?.ActualCheckInCall_4_Date],
        });
  }

    onSubmit() {
        const formValues = this.form.value;
        const branchDates: FieldBranchDates = {
            PlannedAssessmentDate: formValues.plannedAssessmentDateForm,
            ActualAssessmentDate: formValues.actualAssessmentDateForm,
            ActualValidationCallDate: formValues.actualValidationCallDateForm,
            PlannedValidationCallDate: formValues.plannedValidationCallDateForm,
            ActualCheckInCall_1_Date: formValues.actualCheckInCall_1_DateForm,
            ActualCheckInCall_2_Date: formValues.actualCheckInCall_2_DateForm,
            ActualCheckInCall_3_Date: formValues.actualCheckInCall_3_DateForm,
            ActualCheckInCall_4_Date: formValues.actualCheckInCall_4_DateForm
        };
        this.datesChange.emit(branchDates);
        this.dialogRef.close();
    }

    backButtonHandler(event: any) {
        event.preventDefault();
        this.dialogRef.close();
    }

    getDateUTCChange(event, formControlName) {
        let utcDate = Utils.dateUTCchange(event);
        this.form.get(formControlName)?.setValue(utcDate);
    }

    clearDate(controlName: string) {
        this.form.get(controlName)?.setValue(null);
    }
}
