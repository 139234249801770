<div class="custom-mat-dialog-form">
    <div>
        <h3>Assign Contact</h3>
    </div>
    <div>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-sm-4 form-group">
                    <br />
                    <mat-label> <span>Contact</span><span class="orange-asterisk"> *</span></mat-label>
                </div>
                <div class="col-sm-8 form-group">

                    <!--Primary Tenant Drop Down-->
                    <!--Primary Tenant Drop Down-->
                    <ng-select [items]="availableContacts"
                               [searchable]="true"
                               [clearable]="false"
                               bindLabel="ContactLabel"
                               bindValue="ContactId"
                               formControlName="contact"
                               required>
                        <ng-template ng-option-tmp let-item="item">
                            <span matTooltip="{{item.ContactLabel}}">{{item.ContactLabel}}</span>
                        </ng-template>
                    </ng-select>
                    <br>


                </div>
            </div>
            <div class="row">
                <div class="panel-body">
                    <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                        <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                        Back
                    </button>
                    <button mat-raised-button type="submit" class="btn button-color-fix pull-right" [disabled]="!form.valid">
                        Assign
                        <mat-icon class=" icon-color-fix ">person</mat-icon>
                    </button>

                </div>
            </div>
        </form>
    </div>
</div>
