import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogService } from "../../../services/dialog.service";
import { APICallStatus, UMPCategory, Utils } from "../../../utils/utils";
import { ComponentTool } from "../../../interfaces/componenttool";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { AdditionalUsersDialogue } from "./additionalusersdialogue/additionalusersdialogue.component";
import { SpinnerOverlayService } from "../../../services/spinner-overlay.service";
import { animate, state, style, transition, trigger } from "@angular/animations";

interface groupObject {
    Personae: string; Id: string; UmpCategory: UMPCategory
}

@Component({
    selector: "grouphome",
    templateUrl: 'grouphome.component.html',
    styleUrls: ['./grouphome.component.less',
        '../../../style/new-generic-styles.component.less',
        '../home/edituser/edituser.component.less'],
    animations: [
        trigger('shrinkOut', [
            state('in', style({})),
            transition('* => void', [
                style({ height: '!', opacity: 1 }),
                animate(200, style({ height: 0, opacity: 0 }))
            ]),
            transition('void => *', [
                style({ height: 0, opacity: 0 }),
                animate(400, style({ height: '*', opacity: 1 }))
            ])
        ])

    ],
    encapsulation: ViewEncapsulation.None
})

export class GroupHome implements OnInit {
    searchString: string = '';
    searchStringUser: string = '';
    subscriptionStage: any;
    componentToolEnum: typeof ComponentTool = ComponentTool;
    filteredGroupRoles: Array<groupObject> = [];
    filterUserList: any;
    users: UserSummary[] = [];
    count: number = 0;
    queryFilter = "";
    queryFilter_list = "";
    additionalUsers: any;
    respAssignUser = [];
    UMPCategory = UMPCategory;

    userList: { userPrincipalName: string; userId: string; hidden: boolean }[] = [];

    selection = new SelectionModel<UserSummary>(true, []);
    dialogRef: MatDialogRef<AdditionalUsersDialogue, any> | undefined

    public dataSource = new MatTableDataSource<UserSummary>();
    @ViewChild(MatPaginator, { static: true }) matPaginator!: MatPaginator;
    headerCheckbox = false;
    tableResponseState: APICallStatus = APICallStatus.NotRequested;

    get isLoading(): boolean {
        return this.tableResponseState === APICallStatus.Waiting4Response;
    }

    columnsToDisplay = ['select', 'upn', 'displayname'];
    ngOnInit() {
        if (this.defaults.umpFilters.tenants.length === 0)
            this.loadInformation();
    }
    constructor(
        private http: HttpClient
        , private dialogService: DialogService,
        private dialogForm: MatDialog,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService,
        private spinnerOverlayService: SpinnerOverlayService,


    ) {

        this.updateRolesResults();
        this.filteredGroupRoles = this.defaults.groupList;
        this.filterUserList = this.userList
        this.dialogService.showTypeIcon = false;

    }




    async updateRolesResults() {
        this.filteredGroupRoles = this.searchByValue(this.defaults.groupList, "Personae", this.searchString);
    }
    updateSelectedUser() {
        this.userList = this.filterAndShowResults(this.userList, "userPrincipalName", this.searchStringUser);
    }



    searchByValue(items: any, filterProperty: string, query: string) {

        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })
    }

    filterAndShowResults(list: any, filterProperty: string, searchString: string) {
        // If the search string is empty, show all items
        if (searchString === "") {
            return list.map(item => ({ ...item, hidden: false }));
        }

        // Filter and update hidden property based on search
        const filteredList = list.map(item => ({
            ...item,
            hidden: !item[filterProperty].toLowerCase().includes(searchString.toLowerCase())
        }));

        return filteredList;
    }

    loadUsersByFilter(Mode) {
        this.headerCheckbox = false;
        if (this.queryFilter.trim() === '' && Mode === "SearchString")
            return;
        let arr = this.queryFilter_list.split(",").map(function (item) {
            return item.trim();
        });
        if (arr.every(x => x === "") && Mode === "SearchList")
            return;
        else if (this.defaults.umpFilters.tenants.length > 0 || this.defaults.isDataAnalytStage()) {
            if (Mode === "SearchString") {
                this.searchstring();               
            } else if (Mode === "SearchList") {
                this.searchList();
            }
        }
        this.dataSource.paginator = this.matPaginator;
        this.selection.clear();
    }
    searchList() {

        this.users = [];
        this.count = 0;
        this.dataSource.data = [];
        this.tableResponseState = APICallStatus.Waiting4Response;
        Utils.httpGetUsersGroupListByFilter(
            this.http
            , this.baseUrl
            , {
                "Page": 0,
                "PageSize": 5,
                "UserPrincipalName": "",
                "GivenName": "",
                "CdpGroup": [""],
                "NodeId": [""],
                "Tenant": this.defaults.umpFilters.tenants.map(tenant => tenant.value),
                "MailO356Account": this.queryFilter_list.toLocaleLowerCase()
            }, this
            , function (tthis: GroupHome, data: UserList) {
                if (data) {
                    tthis.tableResponseState = APICallStatus.ResponseDataOk;
                    tthis.users = data.users;
                    tthis.count = data.totalResultsCount;
                    tthis.dataSource.data = tthis.users;
                    if (data.additionalUsers !== undefined && data.additionalUsers.length > 0) {
                        tthis.count = data.totalResultsCount;
                        tthis.additionalUsers = data.additionalUsers;
                        tthis.dialogService.tthis = tthis;
                        tthis.opnedialoguebox();
                    }
                }
                else
                    tthis.tableResponseState = APICallStatus.ResponseEmptyOk;
            }
            , this.defaults.isDataAnalytStage()
        )


    }



    searchstring() {
        this.users = [];
        this.count = 0;
        this.dataSource.data = [];
        this.tableResponseState = APICallStatus.Waiting4Response;
        Utils.httpGetUsersByFilter(
            this.http
            , this.baseUrl
            , {
                "Page": 0,
                "PageSize": 5,
                "UserPrincipalName": this.queryFilter.toLocaleLowerCase(),
                "GivenName": this.queryFilter.toLocaleLowerCase(),
                "CdpGroup": [""],
                "NodeId": [""],
                "Tenant": this.defaults.umpFilters.tenants.map(tenant => tenant.value),
                "MailO365Account": null
            }, this
            , function (tthis: GroupHome, data: UserList) {
                tthis.tableResponseState = APICallStatus.ResponseDataOk;
                tthis.users = data.users;
                tthis.count = data.totalResultsCount;
                tthis.dataSource.data = tthis.users
            }, this.defaults.isDataAnalytStage())



    }
    private loadInformation() {
        if (this.defaults.umpFilters.tenants.length === 0 && this.defaults.componentTool === this.componentToolEnum.Rulebook && !this.defaults.isDataAnalytStage()) {
            Utils.httpGetInitialFilters(
                this.http
                , this.baseUrl
                , this
                , function (tthis: GroupHome, data: UserFilter) {
                    tthis.users = [];
                    tthis.defaults.umpFilters = data
                    tthis.selectAllForDropdownItems(tthis.defaults.umpFilters.tenants);
                }
            )
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    selectAllTable() {
        this.dataSource.data.forEach(row => (this.itemSelected(true, row.userPrincipalName, row.userId, row)));
        this.headerCheckbox = true;
    }

    unSelectAllTable() {
        this.dataSource.data.forEach(row => (this.itemSelected(false, row.userPrincipalName, row.userId, row)));
        this.headerCheckbox = false;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(event) {
        event.checked ? this.selectAllTable() : this.unSelectAllTable();
    }

    unselectFromTable(id: string) {
        this.removeUserListItem(id);

        const index = this.dataSource.data.findIndex((i) => i.userId === id);
        if (index !== -1) {
            this.selection.deselect(this.dataSource.data[index]);
        }
    }



    removeUserListItem(id: string) {
        const index = this.userList.findIndex((i) => i.userId === id);
        if (index !== -1) {
            this.userList.splice(index, 1);
        }

    }

    addUserListItem(item: { userPrincipalName: string; userId: string; hidden: boolean }) {

        if (!this.userList.some((i) => i.userId === item.userId)) {
            this.userList.push(item);
        }
    }

    itemSelected(ischecked, name, id, row) {
        if (ischecked) {
            this.selection.select(row)
            this.onSelect(name, id);
        } else {
            this.selection.deselect(row)
            this.onDeselect(name, id);
        }
    }

    onSelect(name, selectedid) {
        this.addUserListItem({ userPrincipalName: name, userId: selectedid, hidden: false });

    }

    onDeselect(name, selectedid) {
        this.removeUserListItem(selectedid);

    }
    opnedialoguebox_assign() {
        this.dialogRef = this.dialogForm.open(AdditionalUsersDialogue, {
            width: '700px',
            data: {
                title: "Group Modification", statement: "Following requests could not be fulfilled", additionalUsers: this.respAssignUser
            }
        });
        this.dialogRef.afterClosed().subscribe(() => {
            this.clearResetAll();
        });
    }
    opnedialoguebox_assign_success() {
        this.dialogRef = this.dialogForm.open(AdditionalUsersDialogue, {
            width: '600px',
            data: {
                title: "Group Modification", statement: "All the requests have been successfully fulfilled"
            }
        });
        this.dialogRef.afterClosed().subscribe(() => {
            this.clearResetAll();
        });
    }

    opnedialoguebox() {
        //this.dialogRef = this.dialogForm.open(AdditionalUsersDialogue, {
        //    width: '550px',
        //    data: {
        //        title: "Additional Users", additionalUsers: this.additionalUsers, statement: "Following users do not exist in the system"
        //    }
        //});
        //this.dialogRef.afterClosed().subscribe(() => {
        //
        //});

        this.dialogService.showSuccessDialog("", `Following users do not exist in the system:`, this.additionalUsers);    
    }
    funcPostGroupModifyUser(isAdding: boolean) {
        this.respAssignUser = [];
        if (this.userList?.length > 0 && this.defaults.groupList?.length > 0) {

            var reqData = { Users: this.userList, Groups: this.defaults.groupList, SecondaryTenants: Array.from(this.defaults.secondaryTenList) }
            Utils.httpPostGroupModifyUser(
                this.http
                , this.baseUrl
                , isAdding
                , reqData
                , this
                , function (tthis: GroupHome, data: UserGrupsBulkModifyData<UMPCategory>) {                   
                    if (data?.errorsDic || data?.exceptionList) {
                        let textIconItemArray: TextIconItem[] = [];
                        if (data?.errorsDic) {
                            Object.keys(data.errorsDic).forEach(key => {
                                let item: TextIconItem = {
                                    text: key,
                                    matIcon: data.errorsDic ? (data.errorsDic[key] ? 'check' : 'cancel') : "",
                                    iconClass: data.errorsDic ? (data.errorsDic[key] ? 'green bottom' : 'red bottom') : ""
                                };
                                textIconItemArray.push(item);
                            });
                        }
                        if (data?.exceptionList) {
                            data.exceptionList.forEach(e => {
                                let item: TextIconItem = {
                                    text: e,
                                    matIcon: 'cancel',
                                    iconClass: 'red bottom'
                                };
                                textIconItemArray.push(item);
                            });
                        }
                        tthis.dialogService.text_icon_items = textIconItemArray;
                        tthis.dialogService.tthis = tthis;
                        tthis.dialogService.extraClasses = ['width_75vw'];
                        tthis.dialogService.showSuccessDialog("", `Group error list:`);
                    }
                    else {
                        tthis.opnedialoguebox_assign_success()
                    }

                })


        }
        else {
            return
        }

    }
    selectAllForDropdownItems(items) {

        let allSelect = items => {
            items.forEach(element => {
                element['selectedAllGroup'] = 'selectedAllGroup';
            });
        };

        allSelect(items);
    }

    ngOnDestroy() {
        this.defaults.clearGroupListItem();





    }

    onBack(ref: any) {
        this.dialogService.text_icon_items = [] as TextIconItem[];
        this.dialogService.extraClasses = undefined;
    }
    clearResetAll() {
        this.defaults.clearGroupListItem()
        this.filteredGroupRoles = this.defaults.groupList;
        this.dataSource = new MatTableDataSource<UserSummary>();
        this.userList = [];
        this.users = [];
        this.queryFilter = "";
        this.queryFilter_list = "";
    }
}