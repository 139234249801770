<div class="emtconfiguration_tree">
    <div class="EmtConfiguration">
        <div style="position:relative">
            <div class="deployment-info">
                <h2 class="flex-aligned-div" (click)="openDeploymentInformationPopup()">
                    Deployment information
                    <mat-icon data-html="true" class="info-icon">info</mat-icon>
                </h2>
            </div>
            <div class="panel-body row">
                <h4>Equipment Activation</h4>
                <div class="row">
                    <div class="edituser panel-body zoomIn col-md-3 col-sm-12">
                        <levelhierarchy (onLevelSelectedTrigger)="onNewLevelSelected($event)"></levelhierarchy>
                    </div>
                    <div class="panel-body zoomIn col-md-9 col-sm-12">
                        <emtconfiguration-list></emtconfiguration-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

