<div class="custom-mat-dialog-form">
    <div>
        <h3>TDC/EMT - Deployment Status</h3>
    </div>

    <div>
        <div style="display:flex; align-items: baseline">
            <button mat-icon-button (click)="refreshInformation()" matTooltip="Refresh Deployment Information">
                <mat-icon style="color:white">refresh</mat-icon>
            </button>
            Refresh
        </div>

        <table mat-table
               *ngIf="showTable && !isLoading"
               [dataSource]="dataSource"
               multiTemplateDataRows
               class="mat-elevation-z8 mat-table-custom"
               style="margin-top:15px">

            <ng-container matColumnDef="environment">
                <th mat-header-cell *matHeaderCellDef> Environment </th>
                <td mat-cell *matCellDef="let element">
                    <p [innerHTML]="element.name.toUpperCase()"></p>
                </td>
            </ng-container>

            <ng-container matColumnDef="environmentdeploymentStatus">
                <th mat-header-cell *matHeaderCellDef> Release Status </th>
                <td mat-cell *matCellDef="let element" pEditableColumn>
                    <p>{{element.status === 'notStarted' || element.status === '' || element.status === undefined ? 'Pending' : element.status === 'inProgress' || element.status === 'queued'  ? 'In Progress' : 'Done' }}</p>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; let i = dataIndex;columns: columnsToDisplay;" class="opmode-item"></tr>
        </table>
        <div *ngIf="!isLoading && !showTable" style="text-align:center">
            <div *ngIf="buildExists">
                <h4>
                    <span>Build {{this.buildRelease?.BuildId}} in progress </span>
                    <mat-spinner diameter="20" style="margin-left: 5px; display: inline-block;"></mat-spinner>
                </h4>
            </div>
            <div *ngIf="!buildExists">
                <h4>No build was found</h4>
            </div>
        </div>
        <div *ngIf='isLoading' class="whitebg card__desc" style="width: 100%; margin-left: 0; padding-top:15px">
            <div *ngFor="let _ of [].constructor(8)" class="card__desc-text"></div>
        </div>
        <div>
            <br />
            <h5>How to deploy latest changes?</h5>
            <ul>
                <li>1 - Create a new deployment pressing "Generate new Deployment" button to generate a build. It could take some minutes until it finishes.</li>
                <li>2 - Please, use "Refresh" button to know the status of the deployment.</li>
                <li>3 - When the new build is done, please press "Deploy to Stable" button or if you want to deploy it to production, please press "Deploy to Production" button.</li>
                <li>4 - If you need a new deployment, please click "Generate new Deployment" button.</li>
            </ul>
            <br />
            <div *ngIf="buildRelease">
                <h4>Current Build Id: {{buildRelease.BuildId}}</h4>
                <h4>Creation Date UTC: {{buildRelease.CreatedDateUtc | date:"yyyy-MM-dd HH:mm"}}</h4>
            </div>
        </div>
        <div class="mt-2 right">
            <button type="button"
                    class="btn btn-primary"
                    (click)="onClickDeploy()"
                    [disabled]="!isLoading && !showTable && buildExists">
                Generate new Deployment
            </button>
            <button type="button"
                    class="btn btn-primary"
                    (click)="releaseEnvironment(devEnvironment?.id!)" *ngIf="buildRelease?.ReleaseId !== -1"
                    [disabled]="devEnvironment?.status !== 'notStarted'">
                Deploy to Stable
            </button>
            <button type="button"
                    class="btn btn-primary"
                    (click)="releaseEnvironment(prodEnvironment?.id!)" *ngIf="buildRelease?.ReleaseId !== -1"
                    [disabled]="prodEnvironment?.status !== 'notStarted'">
                Deploy to Production
            </button>
            <button type="button"
                    class="btn btn-danger"
                    (click)="backClick()">
                Back
            </button>
        </div>
    </div>
</div>