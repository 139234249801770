<div class="custom-mat-dialog-form">
    <div>
        <h3>{{data.component === 'opmodes' ? 'Operation Modes' : 'Error Codes'}} - Deployment Status for Controller Type {{defaults.controllertype.Description}}</h3>

    </div>
    <div>
        <div>
            <div style="display:flex; align-items: baseline">
                <button mat-icon-button (click)="refreshInformation()" matTooltip="Refresh Deployment Information">
                    <mat-icon>refresh</mat-icon>
                </button>
                <p><b>Note:</b> Changes may take effect in 24 hours*</p>
            </div>
            <table mat-table
                   [dataSource]="dataSource"
                   multiTemplateDataRows
                   class="mat-elevation-z8 mat-table-custom">

                <ng-container matColumnDef="environment">
                    <th mat-header-cell *matHeaderCellDef> Environment </th>
                    <td mat-cell *matCellDef="let element">
                        <p [innerHTML]="element.Environment.toUpperCase()"></p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="element.OpModes && data.component === 'opmodes'">check_circle</mat-icon>
                        <mat-icon *ngIf="!element.OpModes && data.component === 'opmodes'">error</mat-icon>
                        <mat-icon *ngIf="element.ErrorCodes && element.ErrorCodesresol && data.component === 'errorcodes'">check_circle</mat-icon>
                        <mat-icon *ngIf="(!element.ErrorCodes || !element.ErrorCodesresol) && data.component === 'errorcodes'">error</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastdeploymentdate">
                    <th mat-header-cell *matHeaderCellDef> Last Deployment Date (UTC) </th>
                    <td mat-cell *matCellDef="let element">
                        <p *ngIf="element.ErrorCodesDate && data.component === 'errorcodes'">
                            ({{element.ErrorCodesDate | date:"yyyy-MM-dd HH:mm"}} - {{sinceDays(element.ErrorCodesDate)}} day/s ago)
                        </p>
                        <p *ngIf="element.OpModesDate && data.component === 'opmodes'">({{element.OpModesDate | date:"yyyy-MM-dd HH:mm"}} - {{sinceDays(element.OpModesDate)}} day/s ago)</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="infoupdateddate">
                    <th mat-header-cell *matHeaderCellDef> Last daily comparison (UTC) </th>
                    <td mat-cell *matCellDef="let element" pEditableColumn>
                        <p>{{element.UpdatedDate | date:"yyyy-MM-dd HH:mm"}} - {{sinceDays(element.UpdatedDate)}} day/s ago</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="environmentdeploymentStatus">
                    <th mat-header-cell *matHeaderCellDef> Deployment Status </th>
                    <td mat-cell *matCellDef="let element" pEditableColumn>
                        <p>{{element.EnvironmentDeploymentStatus === 'notStarted' || element.EnvironmentDeploymentStatus === '' || element.EnvironmentDeploymentStatus === undefined ? 'Pending' : element.EnvironmentDeploymentStatus === 'inProgress' || element.EnvironmentDeploymentStatus === 'queued'  ? 'In Progress' : 'Done' }}</p>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; let i = dataIndex;columns: columnsToDisplay;" class="opmode-item">
                </tr>
            </table>


            <div>
                <br />
                <h5>How to deploy latest changes?</h5>
                <ul>
                    <li>1 - Create a new deployment pressing "Generate new Deployment to Stable" button. It could take some minutes until it finishes.</li>
                    <li>2 - Please, use "Refresh" button to know status of the deployment.</li>
                    <li>3 - Wait until "Deployment Status" field shows a Succeeded message on stable stage.</li>
                    <li>4 - If you want to deploy it also on production, please press "Deploy to Production" button. If you need a new deployment, please click "Generate new Deployment to Stable" button.</li>
                </ul>
                <p *ngIf="buildRelease">Current Build Id: {{buildRelease.BuildId}}</p>
                <p *ngIf="buildRelease">Creation Date UTC: {{buildRelease.CreatedDateUtc | date:"yyyy-MM-dd HH:mm"}}</p>
            </div>
            <div class="mt-2 right">
                <button type="button"
                        class="btn btn-primary"
                        (click)="onClickDeploy()">
                    Generate new Deployment to Stable
                </button>

                <button type="button"
                        class="btn btn-primary"
                        (click)="releaseEnvironment(prodEnvironment?.ReleaseEnvironmentId!)" *ngIf="buildRelease?.ReleaseId !== -1 && prodEnvironment?.EnvironmentDeploymentStatus === 'notStarted'">
                    Deploy to Production
                </button>

                <button type="button"
                        class="btn btn-danger"
                        (click)="onNoClick()">
                    Back
                </button>
            </div>
        </div>
    </div>
</div>
