<div class="container-tab-lists">
    <div class="inner-tcontainer-tab">
        <div style="position:relative">
            <div class="deployment-info">
                <h2 class="flex-aligned-div" (click)="openDeploymentInformationPopup()">
                    Deployment information
                    <mat-icon data-html="true" class="info-icon">info</mat-icon>
                </h2>
            </div>
            <div class="tab-body">
                <div class="tab-content">
                    <mat-tab-group #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
                        <mat-tab label="Function Type">
                            <tdcfunctiontype #tdcfunctiontypeComponent (compFunctionsEvent)="loadCompFunctions($event)"></tdcfunctiontype>
                        </mat-tab>
                        <mat-tab label="Family Code">
                            <tdcfamilycode #tdcfamilycodeComponent [availableComponentFunctions]="availableComponentFunctions" (familyCodesEvent)="loadFamilyCodes($event)"></tdcfamilycode>
                        </mat-tab>
                        <mat-tab label="BOM Code Mapping">
                            <tdcbomcodemapping #tdcbomcodemappingComponent [availableFamilyCodes]="availableFamilyCodes"></tdcbomcodemapping>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>