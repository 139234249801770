import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DialogService } from '../../../services/dialog.service';
import { DatePipe } from '@angular/common';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'controllersoftwarerelease',
  templateUrl: './controllersoftwarerelease.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        '../emtcontrollersoftware.component.less',
        './controllersoftwarerelease.component.less'],
        providers: [DestroyService]
})
export class EMTControllerSoftwareRelease implements OnInit {
    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatTable) objTable!: MatTable<any>;
    CSRForm!: FormGroup;
    genType: number = 0;
    originalSoftwareReleases: EMTControllerSoftwareReleaseItem[] = [];
    modifiedSoftwareReleases: EMTControllerSoftwareReleaseItem[] = [];
    newSoftwareReleases: EMTControllerSoftwareReleaseItem[] = [];
    isCallSucceed: number = 0;
    subscriptionStage: any;
    forbiddenchars = ['/', '\\', ':', '*', '?', '<', '>', '|', '"'];
    minDate: Date = new Date()
    @Output() controllerSoftwareEvent = new EventEmitter<EMTControllerSoftwareReleaseItem[]>();

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private _formBuilder: FormBuilder,
        private toastrService: ToastrService,
        public defaults: DefaultValuesService,
        private confimDialog: DialogService,
        public datePipe: DatePipe,
        @Self() private readonly destroy$: DestroyService,
        private cdr: ChangeDetectorRef    ) {
        this.confimDialog.tthis = this;
    }
    @Input() availableFamilyCodes: FamilyCode[] = [];
    @Input() availableProductUsages: EMTProductUsageItem[] = [];

    columnsToDisplay = ["softwareversion", "controllersoftwareversion", "agentversion", "familycode", "productusage", "file", "releasedate", "comment", "action"];

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getSoftwareReleases() });
        this.genType = 0;
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array([])
        });
        this.getSoftwareReleases();
    }

    onCommentChange(value: string, index: number) {
        this.CSRForm.value.CSRRows[index].comment = value;
    }

    getSoftwareReleases() {
        Utils.httpGetEMTControllerSoftwareReleases(
            this.http,
            this.baseUrl,
            this,
            function (tthis: EMTControllerSoftwareRelease, result: EMTControllerSoftwareReleases) {
                tthis.originalSoftwareReleases = result.releasesList;
                tthis.mapDataToForm(tthis.originalSoftwareReleases);
                tthis.controllerSoftwareEvent.emit(tthis.originalSoftwareReleases);
            }
        );
    }



    mapDataToForm(data) {
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array([])
        });
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array(data.map((val, index) => this._formBuilder.group({
                controllerSoftwareVersionID: new FormControl(val.controllerSoftwareVersionID),
                softwareVersion: new FormControl(val.softwareVersion),
                familyCodeId: new FormControl(val.familyCodeId ?? -1),
                productUsageId: new FormControl(val.productUsageId ?? -1),
                tdcAgentVersion: new FormControl(val.tdcAgentVersion),
                controllerSoftwareVersion: new FormControl(val.controllerSoftwareVersion),
                comment: new FormControl(val.comment),
                softwareFileName: new FormControl(val.softwareFileName),
                softwareFile: new FormControl(val.softwareFile ?? null),
                releaseDate: new FormControl(val.releaseDate != null ? new Date(val.releaseDate) : ""),
                isOpenOverlay: new FormControl(false),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
                textAreaId: new FormControl(this.getTextAreaId(index)),
                maxCommentLength: new FormControl(2000),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.CSRForm.get('CSRRows') as FormArray).value;
    }

    initCSRForm(index: number): FormGroup {
        return this._formBuilder.group({
            softwareVersion: new FormControl(`0.0.${this.genType}`),
            tdcAgentVersion: new FormControl(`AG_0.0.${this.genType}`),
            controllerSoftwareVersion: new FormControl(`GC_0.0.${this.genType++}`),
            familyCodeId: new FormControl(-1),
            productUsageId: new FormControl(-1),
            comment: new FormControl(''),
            softwareFileName: new FormControl(''),
            releaseDate: new FormControl(),
            action: new FormControl('newRecord'),           
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
            isOpenOverlay: new FormControl(false),
            softwareFile: new FormControl(null),
            textAreaId: new FormControl(this.getTextAreaId(index)),
            maxCommentLength: new FormControl(2000),
        });
    }

    addNewRow() {
        const control = this.CSRForm.get('CSRRows') as FormArray;
        control.insert(0, this.initCSRForm(control.length));
        this.dataSource = new MatTableDataSource(control.controls);
        this.updateFileUploadControls();
    }

    getFamilyCodeText(familyCodeId: number) {
        let famcode = this.availableFamilyCodes.find(x => x.familyCodeId === familyCodeId);
        if (famcode)
            return `${famcode.familyCode} - ${famcode.familyCodeName}`;
        else
            return "---";
    }

    getProductUsageText(productusageId: number) {
        let prodUsage = this.availableProductUsages.find(x => x.productUsageID === productusageId);
        if (prodUsage)
            return prodUsage.name;
        else
            return "---";
    }

    resetRow(i) {
        if (this.CSRForm.value.CSRRows[i]?.isNewRow)
            this.deleteRow(i);
        else {            
            this.updateFormFeature(i);
            this.CSRForm.value.CSRRows[i].isEditable = true;
        }       
    }

    deleteRow(i) {
        const control = this.CSRForm.get('CSRRows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    updateFormFeature(index) {
        const formArray = this.CSRForm.get('CSRRows') as FormArray;
        const formGroup = formArray.at(index) as FormGroup;
        var updated = this.originalSoftwareReleases.find(s => s.controllerSoftwareVersionID == this.CSRForm.value.CSRRows[index]?.controllerSoftwareVersionID);
        formGroup.patchValue({ softwareVersion: updated?.softwareVersion });
        formGroup.patchValue({ tdcAgentVersion: updated?.tdcAgentVersion });
        formGroup.patchValue({ controllerSoftwareVersion: updated?.controllerSoftwareVersion });
        formGroup.patchValue({ familyCodeId: updated?.familyCodeId ?? -1 });
        formGroup.patchValue({ productUsageId: updated?.productUsageId ?? -1 });
        formGroup.patchValue({ comment: updated?.comment });
        formGroup.patchValue({ softwareFilename: updated?.softwareFileName });
        formGroup.patchValue({ softwareFile: null });
        formGroup.patchValue({ releaseDate: updated?.releaseDate ?? "" });
    }

    editRow(index) {
        const formArray = this.CSRForm.get('CSRRows') as FormArray;
        const formGroup = formArray.at(index) as FormGroup;
        formGroup.patchValue({ isEditable: !formGroup.value.isEditable });
    }

    refreshTable() {
        this.objTable.renderRows();
        this.updateFileUploadControls();
    }

    onSave() {
        if (this.anyChange()) {
            
            let formData: FormData = new FormData();
            this.modifiedSoftwareReleases.forEach((val) => {
                formData.append(val.fileUploadId, val.softwareFile);
            });
            this.newSoftwareReleases.forEach((val) => {
                formData.append(val.fileUploadId, val.softwareFile);
            });  
            let releasesChanges = { 'modifiedReleases': this.modifiedSoftwareReleases, 'newReleases': this.newSoftwareReleases }
            formData.append('releasesChanges', JSON.stringify(releasesChanges)); 

            Utils.httpUpdateControllerSoftwareReleases(
                this.http
                , this.baseUrl
                , formData
                , this
                , function (tthis: EMTControllerSoftwareRelease, result: any) {
                    if (result?.error && result.errorMssg) {
                        tthis.confimDialog.showErrorDialog({
                            'error':
                            {
                                'message': result.errorMssg
                            }
                        })
                    }
                    tthis.getSoftwareReleases();
                    tthis.funcNotify();
                }
            )
        }
        
    }

    anyChange() {
        this.mapFormToData();
        return this.modifiedSoftwareReleases?.length > 0 || this.newSoftwareReleases?.length > 0;
    }

    mapFormToData() {
        this.modifiedSoftwareReleases = this.CSRForm.get('CSRRows')?.value.filter(row => !row.isNewRow
            && !row.isEditable
            && row.familyCodeId !== -1
            && row.softwareVersion !== "" && row.softwareVersion.length <= 50
            && row.tdcAgentVersion !== "" && row.tdcAgentVersion.length <= 10
            && row.controllerSoftwareVersion !== "" && row.controllerSoftwareVersion.length <= 10
            && (row.comment != null ? row.comment.length <= 2000 : true)
        );
        this.newSoftwareReleases = this.CSRForm.get('CSRRows')?.value.filter(row => row.isNewRow
            && row.familyCodeId !== -1
            && row.softwareVersion !== "" && row.softwareVersion.length <= 50
            && row.tdcAgentVersion !== "" && row.tdcAgentVersion.length <= 10
            && row.controllerSoftwareVersion !== "" && row.controllerSoftwareVersion.length <= 10
            && (row.comment != null ? row.comment.length <= 2000 : true)
            && row.softwareFile != null
        );
    }

    getTextAreaId(index) {
        return `tArea_${index}`;
    }

    getFileUploadId(index) {
        return `fu_${index}`;
    }

    updateFileUploadControls() {
        setTimeout(() => {
            const control = this.CSRForm.value.CSRRows as FormArray;
            for (var i = 0; i < control.length; i++) {
                let fileInput = document.getElementById(this.getFileUploadId(i)) as HTMLInputElement;
                control[i].fileUploadId = this.getFileUploadId(i);
                if (fileInput && control[i].softwareFile != null) {
                    
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(control[i].softwareFile);
                    fileInput.files = dataTransfer.files;
                }
            }
        }, 25);
    }

    onFileChange(event: any, index) {
        event = (event?.target as HTMLInputElement)?.files;
        if (event && event.length) {
            //let ext = event[0].name.match(/\.([^\.]+)$/)[1];
            //if (ext === 'bin') {
                this.CSRForm.value.CSRRows[index].softwareFile = event[0];
                this.CSRForm.value.CSRRows[index].fileUploadId = this.getFileUploadId(index);
            //}
            //else {
            //    this.CSRForm.value.CSRRows[index].softwareFile = null;
            //    let fileInput = document.getElementById(this.getFileUploadId(index)) as HTMLInputElement;
            //    if (fileInput) {
            //        fileInput.value = "";
            //        fileInput.files = null;
            //    }
            //}
        }
        else {
            this.CSRForm.value.CSRRows[index].softwareFile = null;
        }
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }

    checkInput(event) {       
        if (this.forbiddenchars.some(x => x === event.key))
            event.preventDefault();        
    }

    getDateUTCChange(event, index) {
        let utcDate = Utils.dateUTCchange(event);
        this.CSRForm.value.CSRRows[index].releaseDate = utcDate;
    }
}
