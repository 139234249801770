import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../../utils/utils";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CustomDialogService } from '../../../../services/customdialog.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { KpiAdminGFFormComponent } from "./kpiadmingf-form.component";
import { KpiAdminNewKpiFormComponent } from "./kpiadminnewkpi-form.component";
import { MatDialog } from "@angular/material/dialog";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";



@Component({
    selector: "kpiadmin",
    templateUrl: './kpiadmin.component.html',
    styleUrls: ['../../../../style/azdatafactory.component.less',
        '../../../../style/custom-mat-dialog.component.less',
        '../../../../style/container-tab-list.component.less',
        './kpiadmin.component.less'],
    encapsulation: ViewEncapsulation.None
})



export class KpiAdminComponent implements OnInit {
    //@ViewChild('KpiForm')


    public CurrentKpiIndex: number = 0;
    public CurrentKpi: KpiAdminI;
    public CurrentKpiList: KpiAdminI[] = [];
    public AllKpiList: KpiAdminI[] = [];
    public CurrentKpiGroupFunction: string = "";

    public MessageList: string[] = [];

    filteredOptions: KpiAdminI[] = [];
    selectedOption: string = '';


    BreakDown1DimensionName: string = "";
    BreakDown2DimensionName: string = "";
    ProductDimensionName: string = "";
    MovementDimensionName: string = "";

    BreakDown1: Dimension[] = [];
    BreakDown2: Dimension[] = [];
    MovementType: Dimension[] = [];
    ProductHierarchy: Dimension[] = [];

    BreakDownList: Dimension[] = [];
    ProductList: Dimension[] = [];
    MovementList: Dimension[] = [];

    BreakDownDimensionsList: Dimension[] = [];
    ProductDimensionList: Dimension[] = [];
    MovementDimensionList: Dimension[] = [];

    GOTOKpi: string = "";


    showSaveButton = false;

    Dim1Desc: string = "-";
    Dim2Desc: string = "-";
    Dim3Desc: string = "-";
    Dim4Desc: string = "-";

    Dim1Order: number = 0;
    Dim2Order: number = 0;
    Dim3Order: number = 0;
    Dim4Order: number = 0;


    //References
    KpiDefinitionReferences: Reference[] = [];
    KpiUnits: Reference[] = [];
    GroupFunctions: Reference[] = [];
    KpiOwners: Reference[] = [];
    ReportingTypes: Reference[] = [];
    KpiLevels: Reference[] = [];
    FormatFactors: Reference[] = [];


    isCheckedMovementType: boolean = false;
    isCheckedProductHierarchy: boolean = false;
    isCheckedBreakDown1: boolean = false;
    isCheckedBreakDown2: boolean = false;


    Dim_List: ListItem[] = [
        { content: 'BreakDown1', selected: false },
        { content: 'BreakDown2', selected: false },
        { content: 'MovementType', selected: false },
        { content: 'ProductHierarchy', selected: false }
    ];

    KpiForm: FormGroup = new FormGroup({});

    constructor(
        public defaults: DefaultValuesService,
        private http: HttpClient,
        private formBuilder: FormBuilder,
        @Inject('BASE_URL') private baseUrl: string
        , private customDialog: CustomDialogService
        , private sanitizer: DomSanitizer
        , private dialogForm: MatDialog
        , private dialogPopUp: MatDialog
    ) {
        this.selectedOption = "";

        this.CurrentKpiIndex = 0;
        this.CurrentKpi =
        {
            KpiDefinitionId: 0,
            MersyId: "",
            GroupFunction: "",
            KpiPlan: "",
            KpiName: "",
            KpiUnit: "",
            KpiDefinitionContent: "",
            KpiDefinitionTechnical: "",
            KpiDescription: "",
            KpiFormula: "",
            KpiOwner: "",
            AvailabilityLevel_ACT: "",
            AvailabilityLevel_PLANNED: "",
            AvailabilityLevel_EST: "",
            AvailabilityLevel_ORG: "",
            AvailabilityTime: "",
            FormatFactor: 0,
            NegativeDiffIsGood: false,
            ReportingType: "",
            KpiLevel: "",
            ReportingAggregate: "",
            BreakDown1: [],
            BreakDown1Order: 0,
            BreakDown2: [],
            BreakDown2Order: 0,
            MovementType: [],
            MovementTypeOrder: 0,
            ProductHierarchy: [],
            ProductHierarchyOrder: 0,
            HideKpi: false,
            isForecastRequired: false,
            FullName: "",
            GroupFunctionSubItems: [],
            Visible: true,
            Action: "",
            NoOplRequired: false,
            NoActRequired: false,
            IsAccumulative: false
        };
        this.KpiForm = this.formBuilder.group({
            KpiDefinitionId: [""],
            MersyId: [""],
            GroupFunction: [""],
            KpiPlan: [""],
            KpiName: [""],
            KpiUnit: [""],
            KpiDefinitionContent: [""],
            KpiDefinitionTechnical: [""],
            KpiDescription: [""],
            KpiFormula: [""],
            KpiOwner: [""],
            AvailabilityLevel_ACT: [""],
            AvailabilityLevel_PLANNED: [""],
            AvailabilityLevel_EST: [""],
            AvailabilityLevel_ORG: [""],
            AvailabilityTime: [""],
            FormatFactor: [""],
            NegativeDiffIsGood: [""],
            ReportingType: [""],
            KpiLevel: [""],
            ReportingAggregate: [""],
            BreakDown1: [""],
            BreakDown1Order: [""],
            BreakDown2: [""],
            BreakDown2Order: [""],
            MovementType: [""],
            MovementTypeOrder: [""],
            ProductHierarchy: [""],
            ProductHierarchyOrder: [""],
            HideKpi: [false],
            isForecastRequired: [false],
            FullName: "",
            GroupFunctionSubItems: [],
            Visible: true,
            Action: "",
            NoOplRequired: false,
            NoActRequired: false,
            IsAccumulative: false
        });
    }

    sanitizeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    chk_Dimension(index: number, val: boolean) {

        this["isChecked" + this.Dim_List[index].content] = val;

        if (!val && index != this.Dim_List.length - 1) {
            /*Unselecting will move it after the last enabled*/
            for (let i = index; i < this.Dim_List.length - 1; i++) {
                if (this.Dim_List[i + 1].selected)
                    this.moveDown(i);
            }
        }

        if (val && index != 0) {
            /*Selecting will move it to the last enabled*/
            for (let i = index; i > 0; i--) {
                if (!this.Dim_List[i - 1].selected)
                    this.moveUp(i);
            }
        }
    }

    moveUp(index: number) {
        if (index > 0) {
            const temp = this.Dim_List[index - 1];
            this.Dim_List[index - 1] = this.Dim_List[index];
            this.Dim_List[index] = temp;
        }
    }

    moveDown(index: number) {

        if (index < this.Dim_List.length - 1) {
            if (this.Dim_List[index + 1].selected) {
                const temp = this.Dim_List[index + 1];
                this.Dim_List[index + 1] = this.Dim_List[index];
                this.Dim_List[index] = temp;
            }
        }
    }


    validate() {
        let result = true;


        if (result) {
            // Dimension configuration check

            let notmatch = false;

            const itemstocheck = this.AllKpiList.filter(x => x.Action != "");

            let NumDim = 0;
            let SumDim: number = Number(this.Dim1Order) + Number(this.Dim2Order) + Number(this.Dim3Order) + Number(this.Dim4Order);

            if (this.KpiForm.value.MovementType != undefined && this.KpiForm.value.MovementType.length > 0)
                NumDim++;
            if (this.KpiForm.value.ProductHierarchy != undefined && this.KpiForm.value.ProductHierarchy.length > 0)
                NumDim++;
            if (this.KpiForm.value.BreakDown1 != undefined && this.KpiForm.value.BreakDown1.length > 0)
                NumDim++;
            if (this.KpiForm.value.BreakDown2 != undefined && this.KpiForm.value.BreakDown2.length > 0)
                NumDim++;

            switch (NumDim) {
                case 1:
                    {
                        if (SumDim != 1) notmatch = true;
                        break;
                    }
                case 2:
                    {
                        if (SumDim != 3) notmatch = true;
                        break;
                    }
                case 3:
                    {
                        if (SumDim != 6) notmatch = true;
                        break;
                    }
                case 4:
                    {
                        if (SumDim != 10) notmatch = true;
                        break;
                    }
            }

            if (notmatch) {
                this.customDialog.openError({
                    message: 'Kpi Dimension Order invalid',
                    caller: this
                });

                result = false;

            }
        }

        return result;
    }



    save()
    {

        //const params = new HttpParams()
        //    .set('Kpi', this.KpiForm.value)
        //    .set('MovementType', this.KpiForm.value("MovementType"))
        //    .set('ProductHierarchy', this.KpiForm.value("ProductHierarchy"));

        if (this.validate())
        {
            // seting dimension order because there is no direct assignation form-currentkpi
            this.updateCurrentKpifromForm();

            const url = this.baseUrl + "api/KPIManagement/update_kpi_definition";
            this.http.post<any[]>(url, this.KpiForm.value).subscribe(() =>
            {
                this.customDialog.openDisplayInfoDialog({
                    message: 'Kpi definition sucessfully updated (Internal code ' + this.CurrentKpi.KpiDefinitionId + ' )',
                    caller: this
                });

                // Now, the rest of Kpis with same MERSYID must get same header values
                let SameMersy = this.AllKpiList.filter(item => item.MersyId === this.CurrentKpi.MersyId);

                if (SameMersy) {
                    for (let i = 0; i < SameMersy.length; i++) {
                        SameMersy[i].MersyId = this.KpiForm.value.MersyId; // Warning
                        SameMersy[i].KpiPlan = this.KpiForm.value.KpiPlan;
                        SameMersy[i].KpiName = this.KpiForm.value.KpiName;
                        SameMersy[i].KpiUnit = this.KpiForm.value.KpiUnit;
                        SameMersy[i].KpiDefinitionContent = this.KpiForm.value.KpiDefinitionContent;
                        SameMersy[i].KpiDefinitionTechnical = this.KpiForm.value.KpiDefinitionTechnical;
                        SameMersy[i].KpiDescription = this.KpiForm.value.KpiDescription;
                        SameMersy[i].KpiFormula = this.KpiForm.value.KpiFormula;
                        SameMersy[i].KpiOwner = this.KpiForm.value.KpiOwner;
                        SameMersy[i].AvailabilityLevel_ACT = this.KpiForm.value.AvailabilityLevel_ACT;
                        SameMersy[i].AvailabilityLevel_PLANNED = this.KpiForm.value.AvailabilityLevel_PLANNED;
                        SameMersy[i].AvailabilityLevel_EST = this.KpiForm.value.AvailabilityLevel_EST;
                        SameMersy[i].AvailabilityLevel_ORG = this.KpiForm.value.AvailabilityLevel_ORG;
                        SameMersy[i].FormatFactor = this.KpiForm.value.FormatFactor;
                        SameMersy[i].NegativeDiffIsGood = this.KpiForm.value.NegativeDiffIsGood;
                        SameMersy[i].ReportingType = this.KpiForm.value.ReportingType;
                        SameMersy[i].KpiLevel = this.KpiForm.value.KpiLevel;
                        SameMersy[i].ReportingAggregate = this.KpiForm.value.ReportingAggregate;
                        SameMersy[i].HideKpi = this.KpiForm.value.HideKpi;
                        SameMersy[i].isForecastRequired = this.KpiForm.value.isForecastRequired;
                        SameMersy[i].AvailabilityLevel_ACT = this.KpiForm.value.AvailabilityLevel_ACT;
                        SameMersy[i].AvailabilityLevel_PLANNED = this.KpiForm.value.AvailabilityLevel_PLANNED;
                        SameMersy[i].AvailabilityLevel_EST = this.KpiForm.value.AvailabilityLevel_EST;
                        SameMersy[i].AvailabilityLevel_ORG = this.KpiForm.value.AvailabilityLevel_ORG;
                        SameMersy[i].NoOplRequired = this.KpiForm.value.NoOplRequired;
                        SameMersy[i].NoActRequired = this.KpiForm.value.NoActRequired;
                        SameMersy[i].IsAccumulative = this.KpiForm.value.IsAccumulative;
                    }
                }

                // Updating KPI List item in case of modifying name
                this.CurrentKpiList.filter(item => item.MersyId === this.CurrentKpi.MersyId)[0].FullName = this.KpiForm.value.MersyId + " " + this.KpiForm.value.KpiName;

            }, error => {
                Utils.writeLog(this.http, this.baseUrl, error);
            });
        }
    }

    updateCurrentKpifromForm() {

        this.KpiForm.value.MovementTypeOrder = this.Dim1Order;
        this.KpiForm.value.ProductHierarchyOrder = this.Dim2Order;
        this.KpiForm.value.BreakDown1Order = this.Dim3Order;
        this.KpiForm.value.BreakDown2Order = this.Dim4Order;

        let itemIndex = this.AllKpiList.findIndex(item => item.GroupFunction === this.CurrentKpi.GroupFunction && item.MersyId === this.CurrentKpi.MersyId);

        if (itemIndex != -1)
        {
            this.AllKpiList[itemIndex] = Object.assign({}, this.KpiForm.value);
            this.CurrentKpi = Object.assign({}, this.AllKpiList[itemIndex]);
        }


    }


    deleteGF()
    {

        this.customDialog.openConfirm({
            title: "DELETE Group Function",
            message: 'Group Function "' + this.CurrentKpi.GroupFunction + '" will be DELETED for KPI "' + this.CurrentKpi.FullName +  '". Continue?' ,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res =>
        {
            if (res.confirmed === true)
            {

                const url = this.baseUrl + "api/KPIManagement/delete_kpi";
                this.http.post<any[]>(url, this.CurrentKpi.KpiDefinitionId).subscribe(kpideleted => {

                    let GroupFunctionTMP = this.CurrentKpi.GroupFunction;


                    // Updating GroupFunctions from all same MersyId kpis
                    let KpiGroupFunctions = this.CurrentKpi.GroupFunctionSubItems;
                    KpiGroupFunctions.splice(KpiGroupFunctions.findIndex(x => x === this.CurrentKpi.GroupFunction ),1)

                    let Sm_E = this.AllKpiList.filter(x => x.MersyId === this.CurrentKpi.MersyId);
                    for (let i = 0; i < Sm_E.length; i++) {
                        this.AllKpiList[i].GroupFunctionSubItems = KpiGroupFunctions;
                    }

                    // Removing item from "AllKpis" list
                    let kpiIndex = this.AllKpiList.findIndex(x => x.KpiDefinitionId === this.CurrentKpi.KpiDefinitionId);
                    if (kpiIndex!=-1)
                        this.AllKpiList.splice(kpiIndex, 1);

                    // Positioning on other Kpi with same MersyId
                    let Sm = this.AllKpiList.find(x => x.MersyId === this.CurrentKpi.MersyId);
                    if (Sm)
                        this.CurrentKpi = Sm;

                    //Refreshing
                    this.refresh();

                    this.customDialog.openDisplayInfoDialog({
                        message: 'Group Function "' + GroupFunctionTMP + '" sucessfully DELETED from KPI "' + this.CurrentKpi.FullName + '"',
                        caller: this
                    });
                }, error => {
                    Utils.writeLog(this.http, this.baseUrl, error);
                    this.customDialog.openDisplayInfoDialog({
                        message: 'KPI Group Function not deleted. ' + error,
                        caller: this
                    });
                });
            }
        });


    }


    NewKpi() {
        const Dialog = this.dialogForm.open(KpiAdminNewKpiFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: {
                "MersyId": this.CurrentKpi.MersyId,
                "KpiName": this.CurrentKpi.KpiName,
                "KpiDefinitionId": this.CurrentKpi.KpiDefinitionId,
                "SelectedGF": this.CurrentKpi.GroupFunction
            }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) {



                const PopUp = this.customDialog.openDisplayInfoDialog({
                    message: 'KPI "' + result.MersyId_NEW + '" "' + result.KpiName_NEW + '" sucessfully added (' + result.KpiAdded + ')',
                    caller: this
                })

                /*                PopUp.afterClosed().subscribe(result => { */

                this.GOTOKpi = result.MersyId_NEW;

                this.refreshFullList();

                //                });  */
            }
            else {
                let p = 0;
            }
        });

    }


    alterGF() {
        const Dialog = this.dialogForm.open(KpiAdminGFFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: {
                "GroupFunctions": this.GroupFunctions,
                "SelectedGF": this.CurrentKpi.GroupFunctionSubItems,
                "MersyId": this.CurrentKpi.MersyId,
                "KpiName": this.CurrentKpi.KpiName,
                "KpiDefinitionId": this.CurrentKpi.KpiDefinitionId
            }
        });
        Dialog.afterClosed().subscribe(result => {
            if (result) {
                /* new GR setup is in result */
                const GFDeleted: string[] = this.CurrentKpi.GroupFunctionSubItems.filter((element) => !result.includes(element));
                const GFAdded: string[] = result.filter((element) => !this.CurrentKpi.GroupFunctionSubItems.includes(element));



                //if (this.CurrentKpi.Action === "")
                //    this.CurrentKpi.Action = "UPDATED";

                //for (let i = 0; i < GFAdded.length; i++) {

                //    let kpiTMP = Object.assign({}, this.CurrentKpi); ;
                //    kpiTMP.KpiDefinitionId = 0;
                //    kpiTMP.Action = "NEW";
                //    kpiTMP.GroupFunction = GFAdded[i];
                //    this.AllKpiList.push(kpiTMP);
                //    //this.MessageList.push("NEW Group Function " + GFAdded[i] + " ADDED");
                //}
                for (let i = 0; i < result.length; i++)
                {
                    this.CurrentKpi.GroupFunctionSubItems.push(result[i]);
                }


                const KpiDefinitionId_reference = this.CurrentKpi.KpiDefinitionId;

                for (let i = 0; i < result.length; i++)
                {
                    const param = {
                        "KpiDefinitionId": KpiDefinitionId_reference,
                        "GroupFunction": result[i]
                    };

                    //                    this.CurrentKpi.GroupFunctionSubItems.push(result[i]);
                    const url = this.baseUrl + "api/KPIManagement/add_groupfunctions_to_kpi";
                    this.http.post<any[]>(url, param).subscribe( kpiadded => {

                        let kpiTMP = Object.assign({}, this.CurrentKpi);
                        kpiTMP.KpiDefinitionId = parseInt(kpiadded.toString());
                        kpiTMP.Action = "NEW";
                        kpiTMP.GroupFunction = result[i];
                        this.AllKpiList.push(kpiTMP);
                        this.CurrentKpi = Object.assign({}, kpiTMP);
                        this.CurrentKpiGroupFunction = result[i];
                        //Update GroupFunctionSubItems on all kpis with same MersyId

                        const SameMersy = this.AllKpiList.filter(x => x.MersyId === this.CurrentKpi.MersyId);
                        if (SameMersy)
                            for (let ix = 0; ix < SameMersy.length; ix++)
                            {
                                this.AllKpiList[ix].GroupFunctionSubItems = this.CurrentKpi.GroupFunctionSubItems;
                            }

                        this.customDialog.openDisplayInfoDialog({
                            message: 'Group Function "' + result[i] + '" sucessfully added to KPI "' + this.CurrentKpi.FullName + '"',
                            caller: this
                        });

                        console.log("End new GF SAVED");
                        this.loglastitem();

                        this.refresh(); // Update form from values of currentkpi

                    }, error => {
                        Utils.writeLog(this.http, this.baseUrl, error);
                    });
                }


                this.refresh();

            }
            else
            {
                let p = 0;
            }
        });
    }

    public loglastitem()
    {
        console.log(this.AllKpiList[this.AllKpiList.length - 1].GroupFunction);
        console.log(this.AllKpiList[this.AllKpiList.length - 1].KpiDefinitionId);

    }

    kpiSelected() {

        if (this.CurrentKpiList[this.CurrentKpiIndex].MersyId)
        {
            this.MessageList = [];

            let selItem = this.AllKpiList.find(item => item.MersyId === this.CurrentKpiList[this.CurrentKpiIndex].MersyId);

            if (selItem != undefined) {
                this.CurrentKpi = selItem;
                this.CurrentKpiGroupFunction = this.CurrentKpi.GroupFunctionSubItems[0];
            }

            this.refresh();
        }

    }


    updateDimCount() {
        this.Dim1Desc = "0";
        this.Dim2Desc = "0";
        this.Dim3Desc = "0";
        this.Dim4Desc = "0";


        if (this.KpiForm.get("MovementType")?.value != null)
            this.Dim1Desc = this.KpiForm.get("MovementType")?.value.length;

        if (this.KpiForm.get("ProductHierarchy")?.value != null)
            this.Dim2Desc = this.KpiForm.get("ProductHierarchy")?.value.length;

        if (this.KpiForm.get("BreakDown1")?.value != null)
            this.Dim3Desc = this.KpiForm.get("BreakDown1")?.value.length;

        if (this.KpiForm.get("BreakDown2")?.value != null)
            this.Dim4Desc = this.KpiForm.get("BreakDown2")?.value.length;
    }


    toggleItems(Ty: string = '') {
        switch (Ty) {
            case "BreakDown1":
                {
                    this.KpiForm.get("BreakDown1")?.reset();
                    break;
                }
            case "BreakDown2":
                {
                    this.KpiForm.get("BreakDown2")?.reset();
                    break;
                }
            
            case "MovementType":
                {
                    this.KpiForm.get('MovementType')?.reset();
                    break;
                }
            case "ProductHierarchy":
                {
                    this.KpiForm.get('ProductHierarchy')?.reset();
                    break;
                }
        }
        this.updateDimStatus();

        this.updateDimCount();
        this.showSaveButton = true;
    }


    updateDimStatus() {

        if (this.isCheckedMovementType) {
            this.KpiForm.get('MovementType')?.enable();
            if (this.MovementList !== undefined)
                this.MovementType = this.MovementList.filter(item => item.DimensionName === this.MovementDimensionName);
        }
        else {
            this.MovementDimensionName = "";
            this.MovementType = [];
            this.KpiForm.get('MovementType')?.disable();
            this.Dim1Order = 0;
        }

        if (this.isCheckedProductHierarchy) {
            this.KpiForm.get('ProductHierarchy')?.enable();
            if (this.ProductList !== undefined)
                this.ProductHierarchy = this.ProductList.filter(item => item.DimensionName === this.ProductDimensionName);
        }
        else {
            this.ProductDimensionName = "";
            this.ProductHierarchy = [];
            this.KpiForm.get('ProductHierarchy')?.disable();
            this.Dim2Order = 0;
        }


        if (this.isCheckedBreakDown1) {
            this.KpiForm.get('BreakDown1')?.enable();

            if (this.BreakDownList != undefined)
                this.BreakDown1 = this.BreakDownList.filter(item => item.DimensionName === this.BreakDown1DimensionName);
        }
        else {
            this.BreakDown1DimensionName = "";
            this.BreakDown1 = [];
            this.KpiForm.get('BreakDown1')?.disable();
            this.Dim3Order = 0;
        }

        if (this.isCheckedBreakDown2) {
            this.KpiForm.get('BreakDown2')?.enable();

            if (this.BreakDownList !== undefined)
                this.BreakDown2 = this.BreakDownList.filter(item => item.DimensionName === this.BreakDown2DimensionName);
        }
        else {
            this.BreakDown2DimensionName = "";
            this.BreakDown2 = [];
            this.KpiForm.get('BreakDown2')?.disable();
            this.Dim4Order = 0;
        }

        //this.changeOrder();
    }


    refresh() {
        //console.log(this.CurrentKpi.KpiDefinitionId);

        this.isCheckedMovementType = false;
        this.isCheckedProductHierarchy = false;
        this.isCheckedBreakDown1 = false;
        this.isCheckedBreakDown2 = false;

        this.Dim1Order = this.CurrentKpi.MovementTypeOrder;
        this.Dim2Order = this.CurrentKpi.ProductHierarchyOrder;
        this.Dim3Order = this.CurrentKpi.BreakDown1Order;
        this.Dim4Order = this.CurrentKpi.BreakDown2Order;


        this.KpiForm.reset();
        this.KpiForm.patchValue(this.CurrentKpi);

        if (this.CurrentKpi.MovementType != undefined && this.CurrentKpi.MovementType.length > 0) {
            this.isCheckedMovementType = true;

            if (this.MovementList != undefined) {
                const breakdown = this.MovementList.find(item => item.Code.toString() === this.CurrentKpi.MovementType[0])
                if (breakdown)
                    this.MovementDimensionName = breakdown.DimensionName;
            }
        }
        else {
            this.MovementDimensionName = "";
            this.Dim1Order = 0;
        }

        if (this.CurrentKpi.ProductHierarchy != undefined && this.CurrentKpi.ProductHierarchy.length > 0) {
            this.isCheckedProductHierarchy = true;

            if (this.ProductList != undefined) {
                const breakdown = this.ProductList.find(item => item.Code.toString() === this.CurrentKpi.ProductHierarchy[0])
                if (breakdown)
                    this.ProductDimensionName = breakdown.DimensionName;
            }
        }
        else {
            this.ProductDimensionName = "";
            this.Dim2Order = 0;
        }

        if (this.CurrentKpi.BreakDown1 != undefined && this.CurrentKpi.BreakDown1.length > 0) {
            this.isCheckedBreakDown1 = true;

            if (this.BreakDownList != undefined) {
                const breakdown = this.BreakDownList.find(item => item.Code.toString() === this.CurrentKpi.BreakDown1[0])
                if (breakdown)
                    this.BreakDown1DimensionName = breakdown.DimensionName;
            }
        }
        else {
            this.BreakDown1DimensionName = "";
            this.Dim3Order = 0;
        }

        if (this.CurrentKpi.BreakDown2 != undefined && this.CurrentKpi.BreakDown2.length > 0) {
            this.isCheckedBreakDown2 = true;

            if (this.BreakDownList != undefined) {
                const breakdown = this.BreakDownList.find(item => item.Code.toString() === this.CurrentKpi.BreakDown2[0])
                if (breakdown)
                    this.BreakDown2DimensionName = breakdown
                        .DimensionName;
            }
        }
        else {
            this.BreakDown2DimensionName = "";
            this.Dim4Order = 0;
        }


        this.updateDimStatus();
        this.updateDimCount();

    }


    changeOrder() {

        //while (this.Dim1Order > 1 &&
        //    this.Dim2Order > 1 &&
        //    this.Dim3Order > 1 &&
        //    this.Dim4Order > 1)
        //{
        //    if (this.Dim1Order != 0)
        //        this.Dim1Order--;
        //    if (this.Dim2Order != 0)
        //        this.Dim2Order--;
        //    if (this.Dim3Order != 0)
        //        this.Dim3Order--;
        //    if (this.Dim4Order != 0)
        //        this.Dim4Order--;
        //}
        this.showSaveButton = true;

    }

    SubGroupFunctionSelect() {

        console.log("GF Select BEGIN");
        this.loglastitem();


        //this.updateCurrentKpifromForm();
        // This is important. Before we leave current KPI (Group Function) we save status to the AllKpiList Array

        //let prevGFIndex = this.AllKpiList.findIndex(item => item.GroupFunction === this.CurrentKpi.GroupFunction && item.MersyId === this.CurrentKpi.MersyId)
        //this.AllKpiList[prevGFIndex] = Object.assign({}, this.CurrentKpi) ;

        // Now we move to the selected Group Function 
        console.log("GF Select Before Set Current Kpi");
        this.loglastitem();
        let selItem = this.AllKpiList.find(item => item.GroupFunction === this.CurrentKpiGroupFunction && item.MersyId === this.CurrentKpi.MersyId);

        console.log(this.CurrentKpiGroupFunction);
        console.log(selItem);

        if (selItem != undefined)
            this.CurrentKpi = Object.assign({}, selItem);

        console.log("GF Select Before Refresh");
        this.loglastitem();

        this.refresh();

        console.log("GF Select END");
        this.loglastitem();

    }



    refreshFullList()
    {

        let url = this.baseUrl + "api/KPIManagement/kpi_definition/FLD";
        this.http.get<any[]>(url).subscribe((r: KpiAdminI[]) => {
            this.CurrentKpiList = r.filter(item => item.Visible === true);

            let counter = 0;
            this.CurrentKpiList.forEach(x => {
                x.index = counter++;
            })

            console.log(this.CurrentKpiList)

            this.AllKpiList = r;

            if (this.GOTOKpi !== "")
            {
                let selItem = this.AllKpiList.find(item => item.MersyId === this.GOTOKpi);

                if (selItem != undefined) {
                    this.CurrentKpi = selItem;
                    if (selItem.index != undefined)
                        this.CurrentKpiIndex = selItem.index;
                }

                this.GOTOKpi = "";
            }
            else
            {
                this.CurrentKpi = this.CurrentKpiList[0];
                this.CurrentKpiIndex = 0;
            }

            this.CurrentKpiGroupFunction = this.CurrentKpi.GroupFunctionSubItems[0];
            this.refresh();
        }, error => {
            Utils.writeLog(this.http, this.baseUrl, error);
        });


    }


    ngOnInit(): void {

        const tthis = this;

        this.refreshFullList();

        let url = this.baseUrl + "api/KPIManagement/kpi_dimension/MovementType";
        this.http.get<any[]>(url).subscribe((r: any) => {
            this.MovementType = r;
            this.MovementList = r;
            this.MovementDimensionList = r.filter((elemento, index, array) => {
                return array.findIndex(item => item.DimensionName === elemento.DimensionName) === index;
            });
        }, error => {
            Utils.writeLog(this.http, this.baseUrl, error);
        });

        url = this.baseUrl + "api/KPIManagement/kpi_dimension/ProductHierarchy";
        this.http.get<any[]>(url).subscribe((r: any) => {
            this.ProductHierarchy = r;
            this.ProductList = r;
            this.ProductDimensionList = r.filter((elemento, index, array) => {
                return array.findIndex(item => item.DimensionName === elemento.DimensionName) === index;
            });
        }, error => {
            Utils.writeLog(this.http, this.baseUrl, error);
        });

        url = this.baseUrl + "api/KPIManagement/kpi_definition_reference/all";
        this.http.get<any[]>(url).subscribe((r: any) => {
            this.KpiDefinitionReferences = r;

            this.KpiUnits = r.filter(x => x.Key === "KpiUnit");
            this.GroupFunctions = r.filter(x => x.Key === "GroupFunction");
            this.KpiOwners = r.filter(x => x.Key === "KpiOwner");
            this.ReportingTypes = r.filter(x => x.Key === "ReportingType");
            this.KpiLevels = r.filter(x => x.Key === "KpiLevel");
            this.FormatFactors = r.filter(x => x.Key === "FormatFactor");

        }, error => {
            Utils.writeLog(this.http, this.baseUrl, error);
        });


        //url = this.baseUrl + "api/KPIManagement/kpi_definition_reference/KpiUnit";
        //this.http.get<any[]>(url).subscribe((r: any) => {
        //    this.KpiUnits = r;
        //}, error => {
        //    Utils.writeLog(this.http, this.baseUrl, error);
        //});

        //url = this.baseUrl + "api/KPIManagement/kpi_definition_reference/GroupFunction";
        //this.http.get<any[]>(url).subscribe((r: any) => {
        //    this.GroupFunctions = r;
        //}, error => {
        //    Utils.writeLog(this.http, this.baseUrl, error);
        //});

        //url = this.baseUrl + "api/KPIManagement/kpi_definition_reference/KpiOwner";
        //this.http.get<any[]>(url).subscribe((r: any) => {
        //    this.KpiOwners = r;
        //}, error => {
        //    Utils.writeLog(this.http, this.baseUrl, error);
        //});


        url = this.baseUrl + "api/KPIManagement/kpi_dimension/BreakDown";
        this.http.get<any[]>(url).subscribe((r: any) => {

            this.BreakDownList = r;
            this.BreakDownDimensionsList = r.filter((elemento, index, array) => {
                return array.findIndex(item => item.DimensionName === elemento.DimensionName) === index;
            });

            this.BreakDown1 = r;
            this.BreakDown2 = r;
        }, error => {
            Utils.writeLog(this.http, this.baseUrl, error);
        });


        this.KpiForm.valueChanges.subscribe(() => {
            this.showSaveButton = this.KpiForm.dirty;
        });

    }

}