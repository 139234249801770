import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
    name: 'search_bold_mismatch'
})
export class SearchBoldMismatch implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(value: any, args: string, isMismatch: boolean): any {
        if (isMismatch) {
            value = `<b style='color:red'>${value}</b>`
            return this.sanitizer.bypassSecurityTrustHtml(value);
        }

        let filters = args.split('+')
        if (!value) {
            return this.sanitizer.bypassSecurityTrustHtml(value);
        }
        value = value.toString();
        if (!args) {
            return this.sanitizer.bypassSecurityTrustHtml(value);
        }

        let sanitizeValue = value;
        // Match in a case insensitive manner
        filters.forEach((filter: string) => {
            const re = new RegExp(filter, 'gi');
            const match = value.match(re);
            // If there's no match, just return the original value.
            if (!match) {
            }
            else {
                sanitizeValue = sanitizeValue.replace(re, `<b style='color:#ff8708'>$&</b>`);
            }
        });
        return this.sanitizer.bypassSecurityTrustHtml(sanitizeValue);
    }
}
