 <!--The Heading sections (Heading + Serach )-->
<div class="row header-section mt-5">

    <div class="col-lg-6 col-md-9 col-sm-9 col-xs-12">
        <h4>
            Assessment Assignments
        </h4>
    </div>

    <div class="col-lg-6 col-md-3 col-sm-3 col-xs-12 flex-right">
        <div class="OrangeButton" style="margin-right: 50px; display:flex;" *ngIf="defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser">
            <button mat-raised-button (click)="openAssignBranchPopup()"
                    *ngIf="!isRemoveAssignmentEnabled"
                    title="Assign new branches" class="btn btn-sm button-color-fix">
                Add Branch <mat-icon class="add-button icon-color-fix">add_box</mat-icon>
            </button>
            <button mat-raised-button (click)="enableRemoveAssignmentMode()"
                    *ngIf="!isRemoveAssignmentEnabled"
                    title="Remove assigned branches" class="btn btn-sm button-color-fix">
                Unassign <mat-icon class="add-button icon-color-fix">remove_circle</mat-icon>
            </button>
            <button mat-raised-button (click)="enableRemoveAssignmentMode()"
                    *ngIf="isRemoveAssignmentEnabled"
                    title="Close desassignment mode" class="btn btn-sm button-color-fix">
                Close <mat-icon class="add-button icon-color-fix">close</mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="">
    <table mat-table #itemsList
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="wavesconfiguration">

        <ng-container matColumnDef="Checkbox">
            <th mat-header-cell *matHeaderCellDef> 
                <div class="defCheckbox">
                </div>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="">
                    <mat-checkbox (click)="$event.stopPropagation();" *ngIf="isRemoveAssignmentEnabled" (change)="controlAssignedItem($event.checked, element)">
                    </mat-checkbox>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="MgmtId">
            <th mat-header-cell *matHeaderCellDef> Branch Id </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.MgmtId"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="MgmtName">
            <th mat-header-cell *matHeaderCellDef> Branch Name </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.MgmtName"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="AssessmentDate">
            <th mat-header-cell *matHeaderCellDef> Assessment Date </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.AssessmentDate"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ChangedBy">
            <th mat-header-cell *matHeaderCellDef> Changed By </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedBy"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ChangedAtUtc">
            <th mat-header-cell *matHeaderCellDef> Changed At Utc </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedAtUtc | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item">
        </tr>
    </table>
</div>

<div *ngIf="isRemoveAssignmentEnabled && (defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser)" class="stickybottompanel">
    <div *ngIf="removeBranchesList.length > 0" class="col-lg-6" style="width:50%">
        Total selected branches : {{removeBranchesList.length}}
    </div>
    <div *ngIf="removeBranchesList.length <= 0" class="col-lg-6" style="width:50%">
        Please select at least one Branch
    </div>
    <button *ngIf="removeBranchesList.length > 0" class="btn btn-realease btn-sm pull-right button-margin" (click)="confirmRemoveAssignedBranches()">
        Confirm
    </button>
</div>
