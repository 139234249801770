<div id="kpi-admin">
    <!--class="container-tab-lists"-->


    <div style="float: right; margin-right: 50px;">
        <p>&nbsp;</p>
        <p>
            <button type="button"
                    class="btn btn-da   er"
                    (click)="save()" style="color:black;"
                    *ngIf="defaults.isMersyAdminUser && showSaveButton">
                SAVE
            </button>
        </p>
    </div>


    <div>
        <h2 style="padding-left:0px;">KPI List </h2>
        <div style="width:35%; font-size:larger;">
            <!--<ng-select style="background-color:white;" (change)="kpiSelected()" [(ngModel)]="CurrentKpiIndex" [searchable]="true">
                <ng-option *ngFor="let option of CurrentKpiList; index as i;" [value]="i">
                    {{option.FullName}}
                </ng-option>
            </ng-select>-->
            <!--<ng-select>
                <ng-option *ngFor="let option of CurrentKpiList"> {{ option.FullName }} </ng-option>
            </ng-select>-->


            <div style="display: grid; grid-template-columns: 1fr 1fr;">
                <div style="width:500px;">
                    <ng-select [(ngModel)]="CurrentKpiIndex"
                               [searchable]="true"
                               (change)="kpiSelected()" style="background-color:gray !important;">
                        <ng-option *ngFor="let option of CurrentKpiList; index as i;" [value]="i"> {{ option.FullName }} </ng-option>
                    </ng-select>
                </div>
                <div *ngIf="defaults.isMersyAdminUser">
                    <button mat-button color="warn" (click)="NewKpi()" title="Add new KPI from selected">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>



            <!--<ng-select style="background-color: white !important; color: black !important;" [items]="CurrentKpiList" bindValue="index" bindLabel="FullName"
                       [(ngModel)]="CurrentKpiIndex" [searchable]="true" (change)="kpiSelected()">
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                    <span [innerHTML]="item.FullName"></span>
                </ng-template>
            </ng-select>-->

        </div>

        <span style="font-size: smaller;">Internal code: {{ this.CurrentKpi.KpiDefinitionId }}</span>
        <!--<select [(ngModel)]="CurrentKpiIndex" (change)="kpiSelected()">
            <option *ngFor="let option of CurrentKpiList; index as i;" [value]="i">{{ option.MersyId }}&nbsp;{{ option.KpiName }}</option>
        </select>-->
        <form [formGroup]="KpiForm">
            <div>
                <h2 style="padding-left:0px;">KPI Definition master data</h2>
                <table border="0">
                    <tr>
                        <td class="grid_admin" colspan="4">
                            <table border="0">
                                <tr>
                                    <td class="grid_admin">
                                        <span class="title">Kpi Level</span>
                                        <p class="kpidetailblock">
                                            <select style="height: 100%;" class="list_detail" formControlName="KpiLevel" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null">
                                                <option *ngFor="let item of KpiLevels" [value]="item.Value">{{ item.Value }}</option>
                                            </select>
                                        </p>
                                    </td>
                                    <td class="grid_admin">
                                        <span class="title">KPI Visibility</span>
                                        <p class="title" style="color:white;">
                                            <input type="checkbox" formControlName="HideKpi" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" /> Hide KPI
                                        </p>
                                    </td>
                                    <td class="grid_admin">
                                        <span class="title">Forecast Required</span>
                                        <p class="title" style="color:white;">
                                            <input type="checkbox" formControlName="isForecastRequired" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" /> Forecast Required
                                        </p>
                                    </td>
                                    <td class="grid_admin">
                                        <span class="title">NO OPL Required</span>
                                        <p class="title" style="color:white;">
                                            <input type="checkbox" formControlName="NoOplRequired" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" /> NO OPL Required
                                        </p>
                                    </td>
                                    <td class="grid_admin">
                                        <span class="title">NO ACT Required</span>
                                        <p class="title" style="color:white;">
                                            <input type="checkbox" formControlName="NoActRequired" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" /> NO ACT Required
                                        </p>
                                    </td>
                                    <td class="grid_admin">
                                        <span class="title">Is Accumulative</span>
                                        <span class="glyphicon glyphicon-info-sign" data-toggle="tooltip" title="When a KPI is considered accumulative, suggested monthly split will be based on the forecast provided during OPL" aria-hidden="true"></span>
                                        <p class="title" style="color:white;">
                                            <input type="checkbox" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" formControlName="IsAccumulative" title="When a KPI is considered accumulative, suggested monthly split will be based on the forecast provided during OPL" /> Is Accumulative
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <!--<tr>
                    <td colspan="4">
                        <span class="title">Account (MersyID)</span>-->
                    <!--<p class="kpidetailblock">
                        <div *ngFor="let item of GroupFunctions">
                                <input type="checkbox" [value]="item.Value"  />
                                {{ item.Key }}
                        </div>
                    </p>-->
                    <!--</td>
                    </tr>-->
                    <tr>
                        <td class="grid_admin">
                            <span class="title">Account (MersyID)</span>
                            <p class="kpidetailblock">
                                <input class="text_input" formControlName="MersyId" [attr.disabled]="true" readonly type="text" />
                            </p>
                        </td>
                        <td class="grid_admin">
                            <span class="title">Kpi Name</span>
                            <p class="kpidetailblock">
                                <input class="text_input" formControlName="KpiName" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" type="text" />
                            </p>
                        </td>
                        <td class="grid_admin">
                            <span class="title">KPI Plan</span>
                            <p class="kpidetailblock">
                                <input class="text_input" formControlName="KpiPlan" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" type="text" />
                            </p>
                        </td>
                        <td class="grid_admin">
                            <span class="title">ReportingAggregate</span>
                            <p class="kpidetailblock">
                                <input class="text_input" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" formControlName="ReportingAggregate" type="text" />
                            </p>
                        </td>

                    </tr>
                    <tr>
                        <td colspan="3" class="grid_admin">
                            <span class="title">KPI Description</span>
                            <p class="kpidetailblock">
                                <input class="text_input" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" formControlName="KpiDescription" type="text" />
                            </p>
                        </td>
                        <td class="grid_admin">
                            <span class="title">KPI Formula</span>
                            <p class="kpidetailblock">
                                <input class="text_input" formControlName="KpiFormula" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" type="text" />
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="grid_admin">
                            <span class="title">KPI Definition Technical</span>
                            <p class="kpidetailblock">
                                <input class="text_input" formControlName="KpiDefinitionTechnical" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" type="text" />
                            </p>
                        </td>
                        <td class="grid_admin">
                            <span class="title">KPI Unit</span>
                            <p class="kpidetailblock">
                                <select style="height: 100%;" class="list_detail" formControlName="KpiUnit" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null">
                                    <option *ngFor="let item of KpiUnits" [value]="item.Value">{{ item.Value }}</option>
                                </select>

                            </p>
                        </td>
                        <td class="grid_admin">
                            <span class="title">KPI Definition Content</span>
                            <p class="kpidetailblock">
                                <input class="text_input" formControlName="KpiDefinitionContent" type="text" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" />
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td class="grid_admin">
                            <span class="title">KPI Owner</span>
                            <p class="kpidetailblock">
                                <select style="height: 100%;" class="list_detail" formControlName="KpiOwner" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null">
                                    <option *ngFor="let item of KpiOwners" [value]="item.Value">{{ item.Value }}</option>
                                </select>

                            </p>
                        </td>
                        <td class="grid_admin">
                            <span class="title">AvailabilityTime</span>
                            <p class="kpidetailblock">
                                <input class="text_input" formControlName="AvailabilityTime" type="text" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" />
                            </p>
                        </td>
                        <td class="grid_admin">
                            <span class="title">FormatFactor</span>
                            <p class="kpidetailblock">
                                <select style="height: 100%;" class="list_detail" formControlName="FormatFactor" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null">
                                    <option *ngFor="let item of FormatFactors" [value]="item.Value">{{ item.Value }}</option>
                                </select>
                            </p>
                        </td>
                        <td class="grid_admin">
                            <span class="title">Negative Diff.</span>
                            <p class="kpidetailblock" style="color:white;">
                                <input type="checkbox" formControlName="NegativeDiffIsGood" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" /> Is good
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td class="grid_admin">
                            <span class="title">ReportingType</span>
                            <p class="kpidetailblock">
                                <select style="height: 100%;" class="list_detail" formControlName="ReportingType" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null">
                                    <option *ngFor="let item of ReportingTypes" [value]="item.Value">{{ item.Value }}</option>
                                </select>

                            </p>
                        </td>
                        <td class="grid_admin" style="display: none;">
                            <span class="title">Group Function</span>
                            <p class="kpidetailblock">
                                <select style="height: 100%;" class="list_detail" formControlName="GroupFunction" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null">
                                    <option *ngFor="let item of GroupFunctions" [value]="item.Value">
                                        {{ item.Value }}
                                    </option>
                                </select>
                            </p>
                            &nbsp;
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                </table>
            </div>

            <div>
                <h2 style="padding-left:0px;">KPI Group Function</h2>
                <table border="0">
                    <tr>
                        <td style="width:15%;">
                            <p style="font-size:larger;">
                                <select style="width:100%;" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" (change)="SubGroupFunctionSelect()" [(ngModel)]="CurrentKpiGroupFunction" [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let item of CurrentKpi.GroupFunctionSubItems" [value]="item">{{ item }}</option>
                                </select>
                            </p>
                        </td>
                        <td style="width:15%;vertical-align: middle" *ngIf="defaults.isMersyAdminUser">


                            <button mat-button color="warn" (click)="alterGF()" title="Add new Group Function">
                                <mat-icon>add</mat-icon>
                            </button>

                            <button mat-button color="warn" (click)="deleteGF()">
                                <mat-icon>delete</mat-icon>
                            </button>

                        </td>
                        <td style="width:15%;">
                        </td>
                        <td style="width: 55%; color: gray; padding-left:20px; text-align: left; font-size: smaller; color:greenyellow">
                            <span *ngFor="let item of this.MessageList">{{item}}<br /></span>
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <h2 style="padding-left:0px;">KPI Dimension configuration</h2>

                <div>
                    <div class="dim_input_col">
                        <p>
                            <input type="checkbox" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" [(ngModel)]="isCheckedBreakDown2" (change)="toggleItems('BreakDown2')" [ngModelOptions]="{standalone: true}" /> Dimension 2 [Previous Breakdown2] ({{  this.Dim4Desc  }} items selected)
                            <br />
                            <span class="title">Dimension order position :&nbsp;</span>
                            <select class="list_order" [(ngModel)]="Dim4Order" [ngModelOptions]="{standalone: true}" (change)="changeOrder()" [attr.disabled]="!isCheckedBreakDown2 || defaults.isMersyAdminUser === false ? '' : null">
                                <option value="1">1st</option>
                                <option value="2">2nd</option>
                                <option value="3">3rd</option>
                                <option value="4">4th</option>
                            </select>
                        </p>
                        <p class="title">
                            <select [(ngModel)]="BreakDown2DimensionName" [attr.disabled]="!isCheckedBreakDown2 || defaults.isMersyAdminUser === false ? '' : null" [ngModelOptions]="{standalone: true}" (change)="toggleItems('BreakDown2')" style="width:90%;">
                                <option value="" disabled selected>- PLEASE SELECT -</option>
                                <option *ngFor="let item of BreakDownDimensionsList" [value]="item.DimensionName">{{ item.DimensionName }}</option>
                            </select>
                        </p>
                        <select style="height: 100%;" class="list_detail" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" (change)="updateDimCount()" multiple formControlName="BreakDown2">
                            <option *ngFor="let item of BreakDown2" [value]="item.Code">{{ item.Description }}</option>
                        </select>
                    </div>
                    <div class="dim_input_col">
                        <p>
                            <input type="checkbox" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" [(ngModel)]="isCheckedBreakDown1" (change)="toggleItems('BreakDown1')" [ngModelOptions]="{standalone: true}" /> Dimension 1 [Previous Breakdown1] ({{  this.Dim3Desc  }} items selected)
                            <br />
                            <span class="title">Dimension order position :&nbsp;</span>
                            <select class="list_order" [(ngModel)]="Dim3Order" [ngModelOptions]="{standalone: true}" (change)="changeOrder()" [attr.disabled]="!isCheckedBreakDown1 || defaults.isMersyAdminUser === false ? '' : null">
                                <option value="1">1st</option>
                                <option value="2">2nd</option>
                                <option value="3">3rd</option>
                                <option value="4">4th</option>
                            </select>
                        </p>
                        <p class="title">
                            <select [(ngModel)]="BreakDown1DimensionName" [attr.disabled]="!isCheckedBreakDown1 || defaults.isMersyAdminUser === false ? '' : null"
                                    [ngModelOptions]="{standalone: true}" (change)="toggleItems('BreakDown1')" style="width:90%;">
                                <option value="" disabled selected>- PLEASE SELECT -</option>
                                <option *ngFor="let item of BreakDownDimensionsList" [value]="item.DimensionName">{{ item.DimensionName }}</option>
                            </select>
                        </p>
                        <select style="height: 100%;" class="list_detail" (change)="updateDimCount()" multiple formControlName="BreakDown1" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null">
                            <option *ngFor="let item of BreakDown1" [value]="item.Code">{{ item.Description }}</option>
                        </select>
                    </div>
                    <!--ProductHierarchy-->
                    <div class="dim_input_col">
                        <p>
                            <input type="checkbox" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" [(ngModel)]="isCheckedProductHierarchy" (change)="toggleItems()" [ngModelOptions]="{standalone: true}" /> Dimension 3  ({{  this.Dim2Desc  }} items selected)
                            <br />
                            <span class="title">Dimension order position :&nbsp;</span>
                            <select class="list_order" [(ngModel)]="Dim2Order" [ngModelOptions]="{standalone: true}" (change)="changeOrder()"
                                    [attr.disabled]="!isCheckedProductHierarchy || defaults.isMersyAdminUser === false ? '' : null">
                                <option value="1">1st</option>
                                <option value="2">2nd</option>
                                <option value="3">3rd</option>
                                <option value="4">4th</option>
                            </select>
                        </p>
                        <!--<p>
                            &nbsp;
                        </p>-->
                        <p class="title">
                            <select [(ngModel)]="ProductDimensionName" [attr.disabled]="!isCheckedProductHierarchy || defaults.isMersyAdminUser === false ? '' : null" [ngModelOptions]="{standalone: true}" (change)="toggleItems('ProductHierarchy')" style="width:90%;">
                                <option value="" disabled selected>- PLEASE SELECT -</option>
                                <option *ngFor="let item of ProductDimensionList" [value]="item.DimensionName">{{ item.DimensionName }}</option>
                            </select>
                        </p>
                        <select style="height:100%;" class="list_detail" [attr.disabled]="!isCheckedProductHierarchy || defaults.isMersyAdminUser === false ? '' : null" 
                            (change)="updateDimCount()" multiple formControlName="ProductHierarchy">
                            <option *ngFor="let item of ProductHierarchy" [value]="item.Code" [innerHTML]="item.Description" [title]="item.Description"></option>
                        </select>
                    </div>
                    <!--MovementType-->
                    <div class="dim_input_col">
                        <p>
                            <input type="checkbox" [attr.disabled]="defaults.isMersyAdminUser !== true" [(ngModel)]="isCheckedMovementType" (change)="toggleItems()" [ngModelOptions]="{standalone: true}" /> Dimension 4  ({{  this.Dim1Desc  }} items selected)
                            <br />
                            <span class="title">Dimension order position :&nbsp;</span>
                            <select class="list_order" [(ngModel)]="Dim1Order" [ngModelOptions]="{standalone: true}" (change)="changeOrder()"
                                    [attr.disabled]="!isCheckedMovementType || defaults.isMersyAdminUser === false ? '' : null">
                                <option value="1">1st</option>
                                <option value="2">2nd</option>
                                <option value="3">3rd</option>
                                <option value="4">4th</option>
                            </select>
                        </p>
                        <!--<p>
                            &nbsp;
                        </p>-->
                        <p class="title">
                            <select [(ngModel)]="MovementDimensionName" [attr.disabled]="!isCheckedMovementType || defaults.isMersyAdminUser === false ? '' : null" [ngModelOptions]="{standalone: true}" (change)="toggleItems('MovementType')" style="width:90%;">
                                <option value="" disabled selected>- PLEASE SELECT -</option>
                                <option *ngFor="let item of MovementDimensionList" [value]="item.DimensionName">{{ item.DimensionName }}</option>
                            </select>
                        </p>
                        <select style="height: 100%;" [attr.disabled]="defaults.isMersyAdminUser === false ? '' : null" class="list_detail" (change)="updateDimCount()" multiple formControlName="MovementType">
                            <option *ngFor="let item of MovementType" [value]="item.Code">{{ item.Description }}</option>
                        </select>
                    </div>
                </div>
                <div>
                    &nbsp;
                </div>
            </div>
        </form>




    </div>
</div>