<div class="container-tab-lists new-table-style">
    <h2>{{title}} {{isAllLoaded === false && !hideSections() ? waitHeader : null}}</h2>

    <mat-tab-group [(selectedIndex)]="selectedTabIndex" #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
        <mat-tab *ngIf="(defaults.isYearlyOPLEnabled && router.url.includes('oplkpimanagement')) 
                 || (defaults.isYearlyOPLBranchReportingEnabled && router.url.includes('oplkpibranchreporting'))" label="Yearly planning">
            <div class="oplkpi-inner-tcontainer-tab" *ngIf="selectedTabIndex === 0">
                <p>&nbsp;</p>
                <div style="display:flow-root">
                    <div style="float:left; width:40vw" *ngIf="!hideSections()">
                        <mat-form-field class="search-input">
                            <input placeholder="Filter"
                                   matInput
                                   [matChipInputFor]="chipList"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   (matChipInputTokenEnd)="applyFilter($event)"
                                   [disabled]="isAllLoaded === false">
                            <mat-chip-list #chipList>
                            </mat-chip-list>
                        </mat-form-field>
                        <mat-icon style="color:#ddd !important;" (click)="openFilterPopup()">filter_alt</mat-icon>
                        <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                                  [removable]="removable" (removed)="remove(item)">
                            {{item.name}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                    </div>
                    <div style="text-align:right; float:right">

                        <a class='kpi-save-menu save-button' title="Save" *ngIf="isEditModeEnabled" (click)="onClickSave()">
                            <span class='glyphicon glyphicon-floppy-disk'></span> Save
                        </a>

                        <a style="display:none" class='kpi-edit-menu' title="Save" *ngIf="!isEditModeEnabled">
                            {{isApprovalModeEnabled === false ? 'Approval Mode' : 'Cancel'}}
                        </a>

                        <a class='kpi-button-menu' title="Release Mode" (click)="onClickReleaseMode(true)" *ngIf="(defaults.isMersyOwnerUser || defaults.isMersyReporterUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && hideSections()">
                            {{'Release All'}}
                        </a>
                        <a class='kpi-button-menu' title="Release Mode" (click)="toggleRelease();isRevokeActive ? toggleRevoke():null" *ngIf="!isEditModeEnabled && (defaults.isMersyOwnerUser || defaults.isMersyReporterUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && !hideSections()">
                            {{releaseButtonText}}
                        </a>

                        <a class='kpi-button-menu' title="Revoke all released values" (click)="onClickRevoke(true);isReleaseActive ? toggleRelease():null " *ngIf="(defaults.isMersyOwnerUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && hideSections()">
                            {{'Revoke All'}}
                        </a>
                        <!--<a class='kpi-button-menu' title="Revoke all released values" (click)="toggleRevoke();isReleaseActive ? toggleRelease():null " *ngIf=" !isEditModeEnabled && (showPrereleaseLabel || showReleaseLabel) && defaults.isMersyAdminUser && !hideSections()">
                            {{revokeButtonText}}
                        </a>--> 
                        <a class='kpi-button-menu' title="Revoke all released values" (click)="toggleRevoke();isReleaseActive ? toggleRelease():null " *ngIf=" !isEditModeEnabled && (showPrereleaseLabel) && (defaults.isMersyOwnerUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && !hideSections()">
                            {{revokeButtonText}}
                        </a>

                        <a class='kpi-button-menu' title="Excel" (click)="onClickExcelButton()" *ngIf="!isExcelModeEnabled && !isEditModeEnabled && !isReleaseActive">
                            Excel
                        </a>

                        <a class='kpi-button-menu' title="Download" *ngIf="isExcelModeEnabled" (click)="downloadExcelInformation(true)">
                            <span class='glyphicon glyphicon-download'></span> Download
                        </a>

                        <a class='kpi-button-menu' title="Upload" *ngIf="isExcelModeEnabled" (click)="goUploadFileView(true)">
                            <span class='glyphicon glyphicon-upload'></span> Upload
                        </a>

                        <a class='kpi-button-menu' title="Cancel" *ngIf="isExcelModeEnabled" (click)="onClickCancelButton()">
                            {{'Exit Excel mode'}}
                        </a>

                        <a class='kpi-button-menu' title="Edit values" (click)="onClickEdit()" *ngIf="!isReleaseActive && !isExcelModeEnabled && !hideSections()">
                            <span class='glyphicon glyphicon-pencil'></span> {{isEditModeEnabled ? 'Undo' : 'Edit'}}
                        </a>

                        <a class='kpi-button-menu' title="Show All" (click)="onClickShowAllButton()" *ngIf="isShowAllEnabled === false && !hideSections()">
                            <span class='glyphicon glyphicon-eye-open'></span> Show All
                        </a>

                        <a class='kpi-button-menu' title="Hide empty" (click)="onClickShowAllButton()" *ngIf="isShowAllEnabled === true&& !hideSections()">
                            <span class='glyphicon glyphicon-eye-close'></span> Hide empty
                        </a>

                        <h5 *ngIf="!hideSections()">
                            <span *ngIf="isShowAllEnabled === true">Showing all available entries </span>
                            <span *ngIf="isShowAllEnabled === false">Excluding empty values </span>
                            <span *ngIf="(showReleaseLabel === true || showPrereleaseLabel === true) || isEditModeEnabled === true"> / </span>
                            <span class="salmon-color" *ngIf="isEditModeEnabled === true"> Editable kpi values</span>
                            <span *ngIf="(showReleaseLabel === true || showPrereleaseLabel === true) && isEditModeEnabled === true"> / </span>
                            <span class="green-release-color" *ngIf="(showReleaseLabel === true || showPrereleaseLabel === true)">Released kpi values </span>
                        </h5>

                        <h5 [style.visibility]="isEditModeEnabled === true ? 'visible' : 'hidden'" *ngIf="!hideSections()">
                            Edit Mode is enabled. Please click on Save button to save all changes
                        </h5>
                    </div>
                </div>
                <br />

                <oplkpimanagement-list [list]="list"
                                       [isEditModeEnabled]="isEditModeEnabled"
                                       [filter]="filter"
                                       [selectorFilter]="selectorFilter"
                                       [inclusiveMode]="inclusiveMode"
                                       [isApprovalModeEnabled]="isApprovalModeEnabled"
                                       [auxFilterPredicate]="auxFilterPredicate"
                                       [isShowAllEnabled]="isShowAllEnabled"
                                       [isReleaseActive]="isReleaseActive && !isEditModeEnabled"
                                       [isRevokeActive]="isRevokeActive && !isEditModeEnabled"
                                       (onClickRevokeMode)="onClickRevoke($event)"
                                       (onClickReleaseMode)="onClickReleaseMode($event)"
                                       *ngIf="!hideSections()"></oplkpimanagement-list>
            </div>
        </mat-tab>
        <mat-tab *ngIf="(defaults.isMonthlyOPLEnabled && router.url.includes('oplkpimanagement')) 
                 || (defaults.isMonthlyOPLBranchReportingEnabled && router.url.includes('oplkpibranchreporting'))" label="Monthly planning">
            <div class="oplkpi-inner-tcontainer-tab" *ngIf="selectedTabIndex === 1">
                <p>&nbsp;</p>
                <div style="display:flow-root">
                    <div style="float:left; width:40vw" *ngIf="!hideSections()">
                        <mat-form-field class="search-input">
                            <input placeholder="Filter"
                                   matInput
                                   [matChipInputFor]="chipList"
                                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                   [matChipInputAddOnBlur]="addOnBlur"
                                   (matChipInputTokenEnd)="applyFilter($event)"
                                   [disabled]="isAllLoaded === false">
                            <mat-chip-list #chipList>
                            </mat-chip-list>
                        </mat-form-field>
                        <mat-icon style="color:#ddd !important;" (click)="openFilterPopup()">filter_alt</mat-icon>
                        <mat-chip *ngFor="let item of searchItems" [selectable]="selectable"
                                  [removable]="removable" (removed)="remove(item)">
                            {{item.name}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                    </div>
                    <div style="text-align:right; float:right">

                        <a class='kpi-save-menu save-button' title="Save" *ngIf="isEditModeEnabled" (click)="onClickSave()">
                            <span class='glyphicon glyphicon-floppy-disk'></span> Save
                        </a>

                        <a class='kpi-button-menu' title="Release Mode" (click)="toggleMonthlyRelease();isMonthlyRevokeActive ? toggleMonthlyRevoke():null" *ngIf="!isEditModeEnabled && (defaults.isMersyOwnerUser || defaults.isMersyReporterUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && !hideSections()">
                            {{releaseMonthlyButtonText}}
                        </a>

                        <a class='kpi-button-menu' title="Revoke all released values" (click)="toggleMonthlyRevoke();isMonthlyReleaseActive ? toggleMonthlyRelease():null " *ngIf="!isEditModeEnabled && showReleaseLabel && (defaults.isMersyOwnerUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && !hideSections()">
                            {{revokeMonthlyButtonText}}
                        </a>

                        <a class='kpi-button-menu' title="Release Mode" (click)="onClickReleaseMode(false)" *ngIf="(defaults.isMersyReporterUser || defaults.isMersyOwnerUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && hideSections()">
                            {{'Release All'}}
                        </a>

                        <a class='kpi-button-menu' title="Revoke all released values" (click)="onClickRevoke(false)" *ngIf="(defaults.isMersyOwnerUser || defaults.isMersyRevokerUser || defaults.isMersyRevokerBranchReportingUser || defaults.isMersyAdminUser) && hideSections()">
                            {{'Revoke All'}}
                        </a>

                        <a class='kpi-button-menu' title="Excel" (click)="onClickExcelButton()" *ngIf="!isExcelModeEnabled && !isEditModeEnabled && !isReleaseActive">
                            Excel
                        </a>

                        <a class='kpi-button-menu' title="Download" *ngIf="isExcelModeEnabled" (click)="downloadExcelInformation(false)">
                            <span class='glyphicon glyphicon-download'></span> Download
                        </a>

                        <a class='kpi-button-menu' title="Upload" *ngIf="isExcelModeEnabled" (click)="goUploadFileView(false)">
                            <span class='glyphicon glyphicon-upload'></span> Upload
                        </a>

                        <a class='kpi-button-menu' title="Cancel" *ngIf="isExcelModeEnabled" (click)="onClickCancelButton()">
                            {{'Exit Excel mode'}}
                        </a>

                        <a class='kpi-button-menu' title="Edit values" (click)="onClickEdit()" *ngIf="!isReleaseActive && !isExcelModeEnabled && !hideSections() ">
                            <span class='glyphicon glyphicon-pencil'></span> {{isEditModeEnabled ? 'Undo' : 'Edit'}}
                        </a>

                        <a class='kpi-button-menu' title="Show All" (click)="onClickShowAllButton()" *ngIf="isShowAllEnabled === false && !hideSections()">
                            <span class='glyphicon glyphicon-eye-open'></span> Show All
                        </a>

                        <a class='kpi-button-menu' title="Hide empty" (click)="onClickShowAllButton()" *ngIf="isShowAllEnabled === true && !hideSections()">
                            <span class='glyphicon glyphicon-eye-close'></span> Hide empty
                        </a>

                        <h5 *ngIf="!hideSections()">
                            <span *ngIf="isShowAllEnabled === true">Showing all available entries </span>
                            <span *ngIf="isShowAllEnabled === false">Excluding empty values </span>
                            <span *ngIf="showReleaseLabel === true || isEditModeEnabled === true"> / </span>
                            <span class="salmon-color" *ngIf="isEditModeEnabled === true"> Editable kpi values</span>
                            <span *ngIf="showReleaseLabel === true && isEditModeEnabled === true">/</span>
                            <span class="green-release-color" *ngIf="showReleaseLabel === true">Released kpi values </span>
                        </h5>

                        <h5 [style.visibility]="isEditModeEnabled === true ? 'visible' : 'hidden'" *ngIf="!hideSections()">
                            Edit Mode is enabled. Please click on Save button to save all changes
                        </h5>
                    </div>
                </div>
                <br />

                <oplmonthlykpimanagement-list [list]="list"
                                              [isEditModeEnabled]="isEditModeEnabled"
                                              [filter]="filter"
                                              [selectorFilter]="selectorFilter"
                                              [inclusiveMode]="inclusiveMode"
                                              [isApprovalModeEnabled]="isApprovalModeEnabled"
                                              [auxFilterPredicate]="auxFilterPredicate"
                                              [isShowAllEnabled]="isShowAllEnabled"
                                              [isMonthlyReleaseActive]="isMonthlyReleaseActive && !isEditModeEnabled"
                                              [isMonthlyRevokeActive]="isMonthlyRevokeActive && !isEditModeEnabled"
                                              (onClickRevokeMode)="onClickRevoke($event)"
                                              (onClickReleaseMode)="onClickReleaseMode($event)"
                                              *ngIf="!hideSections()">
                </oplmonthlykpimanagement-list>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>




