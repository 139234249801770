import { Component, Inject, Input, OnInit, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../utils/utils';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from '../../../services/dialog.service';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'peripheralsoftware',
    templateUrl: './peripheralsoftware.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        '../emtcontrollersoftware.component.less',
        './peripheralsoftware.component.less'],
    providers: [DestroyService]
})
export class EMTPeripheralSoftware implements OnInit {
    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatTable) objTable!: MatTable<any>;
    PSForm!: FormGroup;
    genType: number = 0;
    originalPeripheralSoftware: EMTPeripheralSoftwareItem[] = [];
    modifiedPeripheralSoftware: EMTPeripheralSoftwareItem[] = [];
    newPeripheralSoftware: EMTPeripheralSoftwareItem[] = [];
    isCallSucceed: number = 0;
    subscriptionStage: any;
    forbiddenchars = ['/', '\\', ':', '*', '?', '<', '>', '|', '"'];
    minDate: Date = new Date()

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private _formBuilder: FormBuilder,
        public defaults: DefaultValuesService,
        public datePipe: DatePipe,
        private toastrService: ToastrService,
        private confimDialog: DialogService,
        @Self() private readonly destroy$: DestroyService) {
        this.confimDialog.tthis = this;
    }

    @Input() availableFamilyCodes: FamilyCode[] = [];
    columnsToDisplay = ["version", "familycode", "file", "releasedate", "comment", "action"];


    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getPeripheralSoftwareVersions() });
        this.genType = 0;
        this.PSForm = this._formBuilder.group({
            PSRows: this._formBuilder.array([])
        });
        this.getPeripheralSoftwareVersions();
    }

    getPeripheralSoftwareVersions() {
        Utils.httpGetEMTPeripheralSoftwareVersions(
            this.http,
            this.baseUrl,
            this,
            function (tthis: EMTPeripheralSoftware, result: EMTPeripheralSoftwareVersions) {
                tthis.originalPeripheralSoftware = result.versionList;
                tthis.mapDataToForm(tthis.originalPeripheralSoftware);
            }
        );

    }

    mapDataToForm(data) {
        this.PSForm = this._formBuilder.group({
            PSRows: this._formBuilder.array([])
        });
        this.PSForm = this._formBuilder.group({
            PSRows: this._formBuilder.array(data.map((val, index) => this._formBuilder.group({
                peripheralSoftwareVersionID: new FormControl(val.peripheralSoftwareVersionID),
                peripheralSoftwareVersion: new FormControl(val.peripheralSoftwareVersion),
                familyCodeId: new FormControl(val.familyCodeId ?? -1),
                releaseDate: new FormControl(val.releaseDate != null ? new Date(val.releaseDate) : ""),
                softwareFileName: new FormControl(val.softwareFileName),
                softwareFile: new FormControl(val.softwareFile ?? null),
                comment: new FormControl(val.comment),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
                isOpenOverlay: new FormControl(false),
                textAreaId: new FormControl(this.getTextAreaId(index)),
                maxCommentLength: new FormControl(2000),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.PSForm.get('PSRows') as FormArray).value;
    }

    initPSForm(index: number): FormGroup {
        return this._formBuilder.group({
            peripheralSoftwareVersion: new FormControl(`0.0.${this.genType}`),
            familyCodeId: new FormControl(-1),
            releaseDate: new FormControl(),
            softwareFileName: new FormControl(new Date()),
            comment: new FormControl(''),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
            softwareFile: new FormControl(null),
            isOpenOverlay: new FormControl(false),
            textAreaId: new FormControl(this.getTextAreaId(index)),
            maxCommentLength: new FormControl(2000),
        });
    }

    editRow(index) {
        this.PSForm.value.PSRows[index].isEditable = false;
    }

    addNewRow() {
        const control = this.PSForm.get('PSRows') as FormArray;
        control.insert(0, this.initPSForm(control.length));
        this.dataSource = new MatTableDataSource(control.controls);
        this.updateFileUploadControls();
    }

    updateFileUploadControls() {
        setTimeout(() => {
            const control = this.PSForm.value.PSRows as FormArray;
            for (var i = 0; i < control.length; i++) {
                let fileInput = document.getElementById(this.getFileUploadId(i)) as HTMLInputElement;
                control[i].fileUploadId = this.getFileUploadId(i);
                if (fileInput && control[i].softwareFile != null) {

                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(control[i].softwareFile);
                    fileInput.files = dataTransfer.files;
                }
            }
        }, 25);
    }

    getFileUploadId(index) {
        return `psfu_${index}`;
    }

    getFamilyCodeText(familyCodeId: number) {
        let famcode = this.availableFamilyCodes.find(x => x.familyCodeId === familyCodeId);
        if (famcode)
            return `${famcode.familyCode} - ${famcode.familyCodeName}`;
        else
            return "---";
    }

    resetRow(i) {
        if (this.PSForm.value.PSRows[i]?.isNewRow)
            this.deleteRow(i);
        else {
            this.updateFormFeature(i);
            this.PSForm.value.PSRows[i].isEditable = true;
        }
    }

    deleteRow(i) {
        const control = this.PSForm.get('PSRows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    updateFormFeature(index) {
        var obj = this.PSForm.value.PSRows;
        var updated = this.originalPeripheralSoftware.find(s => s.peripheralSoftwareVersionID == this.PSForm.value.PSRows[index]?.peripheralSoftwareVersionID);
        obj[index].peripheralSoftwareVersion = updated?.peripheralSoftwareVersion;
        obj[index].releaseDate = updated?.releaseDate ?? "";
        obj[index].familyCodeId = updated?.familyCodeId ?? -1;
        obj[index].softwareFilename = updated?.softwareFileName;
        obj[index].softwareFile = null;
        obj[index].comment = updated?.comment;
    }

    refreshTable() {
        this.objTable.renderRows();
        this.updateFileUploadControls();
    }

    onFileChange(event: any, index) {
        event = (event?.target as HTMLInputElement)?.files;
        if (event && event.length) {
            this.PSForm.value.PSRows[index].softwareFile = event[0];
            this.PSForm.value.PSRows[index].fileUploadId = this.getFileUploadId(index);
        }
        else {
            this.PSForm.value.PSRows[index].softwareFile = null;
        }
    }

    onSave() {

        if (this.anyChange()) {

            let formData: FormData = new FormData();
            this.modifiedPeripheralSoftware.forEach((val) => {
                formData.append(val.fileUploadId, val.softwareFile);
            });
            this.newPeripheralSoftware.forEach((val) => {
                formData.append(val.fileUploadId, val.softwareFile);
            });
            let versionChanges = { 'modifiedVersions': this.modifiedPeripheralSoftware, 'newVersions': this.newPeripheralSoftware }
            formData.append('versionChanges', JSON.stringify(versionChanges));

            Utils.httpUpdatePeripheralSoftwareVersions(
                this.http
                , this.baseUrl
                , formData
                , this
                , function (tthis: EMTPeripheralSoftware, result: any) {
                    if (result?.error && result.errorMssg) {
                        tthis.confimDialog.showErrorDialog({
                            'error':
                            {
                                'message': result.errorMssg
                            }
                        })
                    }
                    tthis.getPeripheralSoftwareVersions();
                    tthis.funcNotify();
                }
            )
        }
    }

    anyChange() {
        this.mapFormToData();
        return this.modifiedPeripheralSoftware?.length > 0 || this.newPeripheralSoftware?.length > 0;
    }

    mapFormToData() {
        this.modifiedPeripheralSoftware = this.PSForm.get('PSRows')?.value.filter(row => !row.isNewRow
            && !row.isEditable
            && row.familyCodeId !== -1
            && row.peripheralSoftwareVersion !== "" && row.peripheralSoftwareVersion.length <= 50
            && (row.comment != null ? row.comment.length <= 2000 : true)
        );
        this.newPeripheralSoftware = this.PSForm.get('PSRows')?.value.filter(row => row.isNewRow
            && row.familyCodeId !== -1
            && row.peripheralSoftwareVersion !== "" && row.peripheralSoftwareVersion.length <= 50
            && (row.comment != null ? row.comment.length <= 2000 : true)
            && row.softwareFile != null
        );
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }

    checkInput(event) {
        if (this.forbiddenchars.some(x => x === event.key))
            event.preventDefault();
    }

    getDateUTCChange(event, index) {
        let utcDate = Utils.dateUTCchange(event);
        this.PSForm.value.PSRows[index].releaseDate = utcDate;
    }

    getCommentText(index) {
        if (this.PSForm.value.PSRows[index].isOpenOverlay) {
            if (!this.PSForm.value.PSRows[index].isEditable)
                return "Editing...";
            else
                return "Reading...";
        }
        else {
            if ((this.PSForm.value.PSRows[index].comment == null || this.PSForm.value.PSRows[index].comment === '') && !this.PSForm.value.PSRows[index].isEditable)
                return "Add a comment";
            else {
                return this.PSForm.value.PSRows[index].comment.length > 25 ? `${this.PSForm.value.PSRows[index].comment.substring(0, 25)}...` : this.PSForm.value.PSRows[index].comment;
            }
        }
    }

    getTextAreaId(index) {
        return `tArea_${index}`;
    }

    toggleOvl(index) {
        this.PSForm.value.PSRows[index].isOpenOverlay = !this.PSForm.value.PSRows[index].isOpenOverlay;
    }

    fitTextAreaToContent(index) {
        setTimeout(() => {
            let element = document.getElementById(this.getTextAreaId(index));
            if (element) {
                element.style.height = "";
                element.style.height = element.scrollHeight + 3 + "px";
                element.focus();
                if (this.PSForm.value.PSRows[index].isEditable)
                    element.setAttribute("readonly", "");
                else
                    element.removeAttribute("readonly");
            }
        }, 25);
    }

    onCommentChange(value: string, index: number) {
        this.PSForm.value.PSRows[index].comment = value;
    }
}
