import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'wave-selector',
    template: `Wave:<br />
                    <ng-select  style="border: 1px white solid" class="top-main-select wave-selector"
                        (change)="onWaveChange($event)"
                        [items]="waveList"
                        bindLabel="Name"
                         bindValue="WaveId"
                         [closeOnSelect]="true"
                       [searchable]="true"
                       [(ngModel)]="selectedWave">
                 </ng-select>`,
    styleUrls: ['../../style/mat-table.component.less'],

})
export class WaveSelectorComponent implements OnInit {

    protected _onDestroy = new Subject<void>();
    waveList: Wave[] = []
    selectedWave = -1
    constructor(
        private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private defaults: DefaultValuesService           
    ) {
    }

    ngOnInit() {  
        this.defaults.managementIdMessageChanges$.subscribe(() => this.getWavesByFieldBranchId(this.defaults.managementid));
    }

    ngOnChanges() {  
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
    ngAfterViewInit() {
    }

    onWaveChange(value: any) {
        this.defaults.updateWave(value.WaveId);
    }

    getWavesByFieldBranchId(fieldBranchId: string) {

        Utils.httpGetAllWavesByFieldBranchId(
            this.http
            , this.baseUrl
            , fieldBranchId
            , this
            , function (tthis: WaveSelectorComponent, response: Wave[]) {
                tthis.waveList = response;
                tthis.selectedWave = tthis.waveList[0]?.WaveId ?? -1;
                tthis.defaults.updateWave(tthis.selectedWave);
            }
        )
    }
}