import { Component, OnInit, Inject, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { WaitingService } from './services/waiting.service';
import { DefaultValuesService } from './services/defaultvalues.service';
import { ComponentTool } from './interfaces/componenttool';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DialogService } from './services/dialog.service';
import { Utils } from './utils/utils';
import { HttpClient } from '@angular/common/http';
import { AppModuleComponent } from './interfaces/appmodules';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css', 'style/tke-theme.less', 'style/new-generic-styles.component.less', 'style/menu.component.less']
})
export class AppComponent implements OnInit, OnDestroy {

    @Output() onTenantChanged = new EventEmitter<string>();
    @Output() onControllerTypeChanged = new EventEmitter<ControllerType>();
    @Output() onLanguageChanged = new EventEmitter<Language>();
    @Output() onStageChanged = new EventEmitter<string>();
    @Output() onManagementIdChanged = new EventEmitter<string>();
    componentToolEnum: typeof ComponentTool = ComponentTool;

    private readonly _destroying$ = new Subject<void>();
    hideControllerTypeMenuItem = true;
    hideLanguageMenuItem = true;
    hideContractMenuItem = true;
    hideTenantMenuItem = true;
    hideGroupFunctionMenuItem = true;
    hideManagementIdMenuItem = true;
    hideFiscalYearMenuItem = true;
    hidePeriodMenuItem = true;
    hideOwnerMenuItem = true;
    hideOplVersionMenuItem = true;
    hideInlineDoc = true;
    hideCountryMenuItem = true;
    hidePopulationMenuItem = true;
    hideRegulatoryAndDialerItem = true;
    hideWaveItem = true;


    isWikiBtnDisabled = true;
    currentUser: string = '';
    isControllerOwner: any;
    isDigitalServiceView: any = false;
    reload = 0;
    selectedItem = 1;

    showWikiLoadBar = false;

    currentLocation = ''
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        public authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        public waiting: WaitingService,
        public dialog: DialogService,
        public defaults: DefaultValuesService,
        public router: Router,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string
    ) {
        if (window.location.href.toLowerCase().includes('rulebook')) {
            this.currentLocation = 'Rulebook'
        }
        else if (window.location.href.toLowerCase().includes('mersy')) {
            this.currentLocation = 'Mersy'
        }
        else {
        }
    }

    async ngOnInit(): Promise<void> {
        //this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

        /**
         * You can subscribe to MSAL events as shown below. For more info,
         * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
         */

        //this.msalBroadcastService.msalSubject$
        //    .pipe(
        //        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        //    )
        //    .subscribe((result: EventMessage) => {
        //        if (this.authService.instance.getAllAccounts().length === 0) {
        //            window.location.pathname = "/";
        //        } else {
        //        }
        //    });

        //this.msalBroadcastService.inProgress$
        //    .pipe(
        //        filter((status: InteractionStatus) => status === InteractionStatus.None),
        //        takeUntil(this._destroying$)
        //    )
        //    .subscribe(() => {
        //        this.checkAndSetActiveAccount();
        //    })

        // TO-DO - Review how to manage it properly 
        await setTimeout(() => {
            if (this.authService.instance.getAllAccounts().length === 0) return;
            const url = this.baseUrl + "api/generic/default/" + Utils.getDomainByWindowLocation();
            const tthis = this;

            Utils.getClusterComponent(this.http, this.baseUrl, this,
                function (tthis: AppComponent, res: AppModuleComponent) {
                    tthis.defaults.isActualReportingEnabled = res.IsActualReportingEnabled
                    tthis.defaults.isMonthlyOPLEnabled = res.IsMonthlyOplEnabled
                    tthis.defaults.isYearlyOPLEnabled = res.IsYearlyOplEnabled
                    tthis.defaults.isActualBranchReportingEnabled = res.IsActualBranchReportingEnabled
                    tthis.defaults.isMonthlyOPLBranchReportingEnabled = res.IsMonthlyOplBranchReportingEnabled
                    tthis.defaults.isYearlyOPLBranchReportingEnabled = res.IsYearlyOplBranchReportingEnabled

                    tthis.defaults.componentTool = res.ComponentTool
                    tthis.defaults.menuClusters = res.Clusters
                    tthis.defaults.components = [];
                    let counter = 0;
                    res.Clusters.forEach(x => {
                        x.ClusterId = counter;
                        if (x.ClusterComponents) {
                            x.ClusterComponents.forEach(y => {
                                tthis.defaults.components.push({ ...y, ClusterName: x.DisplayName, ClusterId: counter })
                            }
                            )
                        }
                        else {
                            tthis.defaults.components.push({ ...x, ClusterId: counter })
                        }
                        counter++;
                    })
                    tthis.http.get<Defaults>(url)
                        .subscribe({
                            next : res => {
                                tthis.initializeUI(res);
                            },
                            error: error => Utils.writeLog(tthis.http, tthis.baseUrl, error)
                        });
                }
            );
        }, 2000)

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {

                this.reload = Math.random() * 100
                if (event.url === '/contract' || event.url === '/customer' || event.url === '/assets' || event.url === '/chairlift'
                    || event.url === '/service-ticket' || event.url === '/file/2' || event.url === '/file/3'
                    || event.url === '/file/4' || event.url === '/file/5' || event.url === '/file/7' || event.url === '/file/8' || event.url === '/file/9'
                    || event.url === '/backend-setup' || event.url === '/file/10' || event.url === '/file/11'
                    || event.url === '/kpi' || event.url === '/file/12' || event.url === '/servicesdatacheck' || event.url === '/nonrecoverableerrors'
                    || event.url === '/virtualprovisioning' || event.url === '/file/14' || event.url === '/file/14') {
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideControllerTypeMenuItem = this.hideLanguageMenuItem = this.hideOwnerMenuItem =
                        this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true;
                    this.hideContractMenuItem = this.hideTenantMenuItem = this.isDigitalServiceView = false
                }
                else if (event.url === '/' || event.url === '/home' || event.url === '/maintenancecontrolplan' || event.url.includes('/maintenancecontrolplangroup/')) {
                    if (event.url === '/')
                        this.selectedItem = -1;
                    if (event.url === '/home')
                        this.router.navigate(['/']); // navigate to home page.
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideTenantMenuItem = this.hideControllerTypeMenuItem = this.hideLanguageMenuItem = this.hideContractMenuItem = this.hideOwnerMenuItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true;
                    this.isDigitalServiceView = false
                }
                else if (event.url === '/harmonizedopmodes' || event.url === '/businesskey') {
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideControllerTypeMenuItem = this.hideContractMenuItem = this.hideOwnerMenuItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true;
                    this.hideTenantMenuItem = this.hideLanguageMenuItem = this.isDigitalServiceView = false
                }
                else if (event.url === '/deploymentsdevops') {
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideControllerTypeMenuItem = this.hideTenantMenuItem = this.hideContractMenuItem = this.hideOwnerMenuItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true;
                    this.hideLanguageMenuItem = false;
                }
                else if (event.url === '/digitalservices' || event.url === '/file/13') {
                    this.hideControllerTypeMenuItem = this.hideLanguageMenuItem = this.hideTenantMenuItem = this.hideOwnerMenuItem = false;
                    this.hideWaveItem = this.hideCountryMenuItem = this.hideContractMenuItem = this.isDigitalServiceView = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = this.hidePopulationMenuItem = true;
                }
                else if (event.url === '/file/0' || event.url === '/file/1' || event.url === '/fingerprint-rules') {
                    this.isDigitalServiceView = undefined
                    this.hideControllerTypeMenuItem = this.hideLanguageMenuItem = this.hideTenantMenuItem = this.hideOwnerMenuItem = false;
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideContractMenuItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                }
                else if (event.url === '/testcases') {
                    this.isDigitalServiceView = undefined
                    this.hideTenantMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem = false
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideLanguageMenuItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                }
                else if (event.url === '/controllertypes-deploymentstatus' || event.url === '/remoteactions' || event.url === '/healthscore') {
                    event.url === '/healthscore' ? this.hidePopulationMenuItem = false : this.hidePopulationMenuItem = true;
                    this.hideTenantMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = false
                    this.hideWaveItem = this.hideCountryMenuItem = this.hideLanguageMenuItem = this.hideContractMenuItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                }
                else if (event.url === '/ControllerTypeHarmonization' || event.url === '/backendharmonization' || event.url === '/branch-hierarchy' || event.url === '/file/6') {
                    this.hideTenantMenuItem = false
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem =
                        this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                }

                else if (event.url === '/changelog') {
                    this.hideControllerTypeMenuItem = false
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem =
                        this.hideLanguageMenuItem = this.hideTenantMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                }
                else if (event.url.includes('/actualreporting') || event.url.includes('/actualbranchreporting') || event.url === '/file/15' || event.url === '/file/18') {
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                    this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = false;
                }
                else if (event.url === '/file/16' || event.url === '/file/17' || event.url === '/file/19' || event.url === '/file/20' || event.url.includes('/oplkpi')) {
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem = this.hidePeriodMenuItem = this.hideRegulatoryAndDialerItem = true
                    this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = false;
                }
                else if (event.url === '/market-sheet' || event.url === '/segment-sheet') {
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem = this.hidePeriodMenuItem = this.hideRegulatoryAndDialerItem = this.hideOplVersionMenuItem = true
                    this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hideFiscalYearMenuItem = false;
                }
                else if (event.url === '/monitoringdevops' || event.url.includes('/sectionscheduler') || event.url.includes('/adminsection')) {
                    this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem =
                        this.hideWaveItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                }
                else if (event.url === '/branch-excellence-admin') {
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem =
                        this.hideGroupFunctionMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = true
                    this.hideManagementIdMenuItem = false
                }
                else if (event.url === '/assessment' || event.url === '/focus-plan') {
                    this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem =
                        this.hideGroupFunctionMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = true
                    this.hideWaveItem = this.hideManagementIdMenuItem = false
                }
                else if (event.url.includes('/hierarchysetup')) {
                    this.hideWaveItem = this.hideRegulatoryAndDialerItem = this.hidePopulationMenuItem = this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem = this.hideContractMenuItem =
                        this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                    this.hideCountryMenuItem = false;
                }
                else if (event.url === '/UmpHome' || event.url.includes('/EditUser') || event.url.includes('/UMPAnalytics') || event.url.includes('/Group')) {
                    this.hideWaveItem = this.hideRegulatoryAndDialerItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem =
                        this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                    this.hideContractMenuItem = false;
                }
                else if (event.url === '/tenantdialerconfig') {
                    this.hideContractMenuItem = this.hideRegulatoryAndDialerItem = this.hideTenantMenuItem = false
                    this.hideWaveItem = this.hideCountryMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideOwnerMenuItem =
                        this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hidePopulationMenuItem = true
                }
                else if(event.url === '/equipment-activation'){
                    this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideCountryMenuItem =
                    this.hidePopulationMenuItem = this.hideFiscalYearMenuItem = this.hideGroupFunctionMenuItem =
                        this.hideOplVersionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideRegulatoryAndDialerItem = true;
                    this.hideContractMenuItem = false;
                }
                else if (['/emtcontrollersoftware', '/jcobrules', '/tdccomponents'].includes(event.url)) {
                    this.hideContractMenuItem = false;
                    this.hideTenantMenuItem = this.hideLanguageMenuItem = this.hideControllerTypeMenuItem = this.hideCountryMenuItem =
                        this.hidePopulationMenuItem = this.hideFiscalYearMenuItem = this.hideGroupFunctionMenuItem =
                        this.hideOplVersionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideRegulatoryAndDialerItem = true;
                }
                else {
                    this.hideControllerTypeMenuItem = this.hideLanguageMenuItem = this.hideTenantMenuItem = this.hideOwnerMenuItem = this.isDigitalServiceView = false;
                    this.hideWaveItem = this.hidePopulationMenuItem = this.hideCountryMenuItem = this.hideContractMenuItem = this.hideGroupFunctionMenuItem = this.hideManagementIdMenuItem = this.hidePeriodMenuItem = this.hideFiscalYearMenuItem = this.hideOplVersionMenuItem = this.hideRegulatoryAndDialerItem = true
                }
            }
        }
        )
    }
    initializeUI(defaultsData: Defaults): void {
        this.currentUser = defaultsData.Session.UserUPN;
        this.isControllerOwner = defaultsData.IsControllerOwner;
        this.defaults.isControllerOwner = this.isControllerOwner;
        let tempTenants: Tenant[] = [];
        if (this.defaults.componentTool === this.componentToolEnum.Rulebook) {
            if (defaultsData.Claims === null) {
                // This is for the AD account scenario
                tempTenants.push({ Tenant: defaultsData.Session.Tenant, CountryName: 'Administrator', AllBranches: 0, Environment: 'AdministratorEnvironment' });
            }
            else {
                tempTenants.push(defaultsData.Claims.PrimaryTenants);
                for (const tenant of defaultsData.Claims.SecondaryTenants) {
                    tempTenants.push(tenant);
                }

            }
            tempTenants = tempTenants.sort(function (a, b) {
                if (a.Tenant < b.Tenant) { return -1; }
                if (a.Tenant > b.Tenant) { return 1; }
                return 0;
            })

            tempTenants.forEach(x => x.Label = `${x.CountryName} - (${x.Tenant})`)

            this.defaults.environments = defaultsData.Claims.Environments;
            this.defaults.tenants = tempTenants;
            this.defaults.tenant = defaultsData.Session.Tenant;
            this.defaults.controllertypes = defaultsData.ControllerTypesByTenant;
            this.defaults.controllertypesdigitalservices = defaultsData.ControllerTypesByTenantDigitalServices;
            this.defaults.controllertype = defaultsData.SelectedControllerType;
            this.defaults.languages = defaultsData.Languages;

            this.defaults.language = defaultsData.SelectedLanguage;
            this.defaults.isCountryBackendProdUser = defaultsData.IsCountryBackendProdUser;
            this.defaults.isDomainExpertUser = defaultsData.IsDomainExpertUser;
            this.defaults.isCountryBackendStableUser = defaultsData.IsCountryBackendStableUser;
            this.defaults.isReadonlyUser = defaultsData.IsReadonlyUser;
            this.defaults.isHomeUser = defaultsData.IsHomeUser;
            this.defaults.stage = defaultsData.Session.Stage;
            this.defaults.stageAfterRedirect = defaultsData.Session.Stage;
            this.defaults.isExternalUser = defaultsData.IsExternalUser;
            this.defaults.isDevUserManagementPortalUser = defaultsData.IsDevUserManagementPortalUser;
            this.defaults.isProdUserManagementPortalUser = defaultsData.IsProdUserManagementPortalUser;

            this.defaults.isDevMersyUMPUser = defaultsData.IsDevMersyUMPUser;
            this.defaults.isProdMersyUMPUser = defaultsData.IsProdMersyUMPUser;
            this.defaults.isDevADOGroupUMPUser = defaultsData.IsDevADOGroupUMPUser;
            this.defaults.isProdADOGroupUMPUser = defaultsData.IsProdADOGroupUMPUser;

            this.defaults.isPBIWorkspaceCreatorUser = defaultsData.IsPBIWorkspaceCreatorUser;

            this.defaults.IsLicenseManagerUser = defaultsData.IsLicenseManagerUser;
            this.defaults.isEMTManagerUser = defaultsData.IsEMTManagerUser;
            this.defaults.isMfgManagerUser = defaultsData.IsMfgManagerUser;
            this.defaults.isUMPDataAnalyticsUser = defaultsData.IsUMPDataAnalyticsUser;
            this.defaults.isHomManagerUser = defaultsData.IsHomManagerUser;
            this.defaults.isGlobalSystemAdmin = defaultsData.IsGlobalSystemAdmin;
            this.defaults.isUserMgmtFldBranchesUser = defaultsData.IsUserMgmtFldBranchesUser;

            this.defaults.isMFGAdminUser = defaultsData.IsMFGAdminUser;
            this.defaults.isMFGEquipmentManagerUser = defaultsData.IsMFGEquipmentManagerUser;
            this.defaults.isMFGAdminPDCSUser = defaultsData.IsMFGAdminPDCSUser;
            this.defaults.isMFGAdminCenterUser = defaultsData.IsMFGAdminCenterUser;
            this.defaults.isMFGContractEngineerUser = defaultsData.IsMFGContractEngineerUser;
            this.defaults.isDESConfAdmUser = defaultsData.IsDESConfAdmUser;

        }
        else {
            this.defaults.managementids = defaultsData.ManagementIds;
            this.defaults.managementid = defaultsData.Session.ManagementId.toString();

            this.defaults.fiscalYear = defaultsData.Session.FiscalYear
            this.defaults.period = defaultsData.Session.Period

            this.defaults.groupFunctions = defaultsData.GroupFunctions
            this.defaults.groupFunction = defaultsData.Session.GroupFunction
            this.defaults.IsMersyUser = defaultsData.IsMersyUser;
            this.defaults.isMersyAdminUser = defaultsData.IsMersyAdminUser;
            this.defaults.isMersyAdminBranchExcellenceUser = defaultsData.IsMersyAdminBranchExcellenceUser;
            this.defaults.isMersyReporterBranchExcellenceUser = defaultsData.IsMersyReporterBranchExcellenceUser;
            this.defaults.isMersyViewerBranchExcellenceUser = defaultsData.IsMersyViewerBranchExcellenceUser;
            this.defaults.isMersyAdminMarketQueryUser = defaultsData.IsMersyAdminMarketQueryUser;
            this.defaults.isMersyOwnerMarketQueryUser = defaultsData.IsMersyOwnerMarketQueryUser;
            this.defaults.isMersyRevokerMarketQueryUser = defaultsData.IsMersyRevokerMarketQueryUser;
            this.defaults.isMersyReporterMarketQueryUser = defaultsData.IsMersyReporterMarketQueryUser;
            this.defaults.isMersyDelegateMarketQueryUser = defaultsData.IsMersyDelegateMarketQueryUser;
            this.defaults.isMersyViewerMarketQueryUser = defaultsData.IsMersyViewerMarketQueryUser;
            this.defaults.isMersyDelegatedUser = defaultsData.IsMersyDelegatedUser;
            this.defaults.isMersyOwnerUser = defaultsData.IsMersyOwnerUser;
            this.defaults.isMersyReporterUser = defaultsData.IsMersyReporterUser;
            this.defaults.isMersyViewerUser = defaultsData.IsMersyViewerUser;

            this.defaults.isMersyDelegatedBranchReportingUser = defaultsData.IsMersyDelegatedBranchReportingUser;
            this.defaults.isMersyReporterBranchReportingUser = defaultsData.IsMersyReporterBranchReportingUser;
            this.defaults.isMersyViewerBranchReportingUser = defaultsData.IsMersyViewerBranchReportingUser;
            this.defaults.isMersyRevokerUser = defaultsData.IsMersyRevokerUser;
            this.defaults.isMersyRevokerBranchReportingUser = defaultsData.IsMersyRevokerBranchReportingUser;
        }

        this.defaults.isadminuser = defaultsData.IsAdminUser;
        this.defaults.session = defaultsData.Session;
        this.defaults.branches = defaultsData.Branches;

        this.defaults.notifyStageChange(this.defaults.stage.toString())
        this.defaults.notifyLanguageChange(this.defaults.language)
        this.defaults.notifyManagementIdChange()
        this.defaults.loadInitialData = true;
        this.reload = Math.random() * 100
    }


    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            // add your code for handling multiple accounts here
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    async login() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.authService.loginPopup()
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                await this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
            } else {
                await this.authService.loginRedirect();
            }
        }
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    onTenantSelectedChanged(evt: any) {
        this.onTenantChanged.emit(evt);
        if (this.onTenantChanged.observers.length === 0)
            this.waiting.toWait(false);
    }
    onInlineDocClick() {
        //this.wikiChangeService.getIsWikiAvailable()
        this.isWikiBtnDisabled ? null : this.hideInlineDoc = !this.hideInlineDoc;
    }
    receiveWikiAvBool(value: boolean) {
        this.isWikiBtnDisabled = value;
    }
    onControllerTypeSelectedChanged(evt: any) {
        this.onControllerTypeChanged.emit(evt);
        if (this.onControllerTypeChanged.observers.length === 0)
            this.waiting.toWait(false);
    }
    onLanguageSelectedChanged(evt: any) {
        this.onLanguageChanged.emit(evt);
        if (this.onLanguageChanged.observers.length === 0)
            this.waiting.toWait(false);
    }

    onStageSelectedChanged(evt: any) {
        this.onStageChanged.emit(evt);
        if (this.onStageChanged.observers.length === 0)
            this.waiting.toWait(false);
    }
    onManagementIdSelectedChanged(evt: any) {
        this.onManagementIdChanged.emit(evt);
        if (this.onManagementIdChanged.observers.length === 0)
            this.waiting.toWait(false);
    }   

    receiveWikiLdBarBool(value: boolean) {
        this.showWikiLoadBar = value;
    }
}
