import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayService } from '../../../../services/spinner-overlay.service';
import { UMPCategory, Utils } from '../../../../utils/utils';
import { DefaultValuesService } from '../../../../services/defaultvalues.service';
import { AddGroupDialogue } from '../../home/edituser/addgroupdialogue/addgroupdialogue.component';

@Component({
  selector: 'commonselection-list',
  templateUrl: './commonselection-list.component.html',
    styleUrls: ['./commonselection-list.component.less'],
    encapsulation: ViewEncapsulation.None

})
export class CommonselectionListComponent implements OnInit {

    @Input() RoleType: string='';
    @Input() Umpcategory: UMPCategory = {} as UMPCategory;
    dataCopy: any;

    public userName: String = '';
    userId = ''


    searchString: string = '';
    filteredRoleTypeRoles: any[] = [];

    RoleTypeRoles = [];
    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    errorResponse: any;
    isCallSucceed: number = 0;

    dialogRef: MatDialogRef<AddGroupDialogue, any> | undefined

    AllAzDevProj = [];
    UserAzDevProj = [];
    RemainAzDevProj = [];
    componentType: string='';



    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastrService: ToastrService,
        private dialogForm: MatDialog,
        private spinnerOverlayService: SpinnerOverlayService,
        private defaults: DefaultValuesService,


        @Inject('BASE_URL') private baseUrl: string
    ) {

        this.updateRolesResults();

        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        defaults.resetBulkGroupTrigger$.subscribe(() => this.resetData())
    }

    ngOnInit() {
        this.componentType = this.RoleType.replace(/\s/g, "").toLowerCase()

        this.funcGetRoles();
    }
    async updateRolesResults() {
        this.filteredRoleTypeRoles = this.searchByValue(this.RoleTypeRoles, "persona", this.searchString);
    }

    searchByValue(items: any, filterProperty: string, query: string) {

        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })
    }

    funcGetRoles() {
        this.Response_State = -2;
        Utils.httpGetAllRelatedRoles(
            this.http
            , this.baseUrl
            , this.componentType
            , this
            , function (tthis: CommonselectionListComponent, data: any) {
                tthis.dataCopy = structuredClone(data)
                tthis.assignValues(data)
            }
        )
    }
    assignValues(data) {
        this.RoleTypeRoles = data.personae
        this.filteredRoleTypeRoles = this.RoleTypeRoles

        if (this.RoleTypeRoles?.length) {
            this.Response_State = 1;
        }
        else {
            this.Response_State = 0;

        }
    }
    resetData() {
        this.assignValues(structuredClone(this.dataCopy))
    }
    itemSelected(event) {
        if (event.options[0]._selected) {
            this.onSelect(event.options[0]._value);
        } else {
            this.onDeselect(event.options[0]._value);
        }
    }

    onSelect(selectedItem) {
        this.defaults.addGroupListItem({ Personae: selectedItem.persona, Id: selectedItem.id, UmpCategory: this.Umpcategory });

    }

    onDeselect(selectedItem) {
        this.defaults.removeGroupListItem(selectedItem.id);

    }

}
