import { Component, ElementRef, Inject, NgModule, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { MatSort, Sort } from '@angular/material/sort';


@Component({
    selector: 'history-lever-dialog',
    templateUrl: './history-lever-dialog.component.html',
    styleUrls: ['./history-lever-dialog.component.less']
})
export class HistoryLeverDialog implements OnInit {

    public dataSource = new MatTableDataSource<any>();
    @ViewChild('tableBody') tableBody!: ElementRef;
    label: string = "";
    isOpenTextAreaOverlay = false;
    constructor(
        private http: HttpClient,
        private dialogRef: MatDialogRef<HistoryLeverDialog>,
        @Inject('BASE_URL') private baseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public datePipe: DatePipe
    ) {
    }

    columnsToDisplay = ["action", "notes", "bestpractices", "user", "date"];

    ngOnInit(): void {
        const lever = this.data.lever as AssessmentExcellenceLever
        this.label = `${lever.ExcellenceLever}`
        Utils.httpGetAssessmentLeverHistory(
            this.http,
            this.baseUrl,
            lever.WaveExcellenceLeverConfigId,
            this,
            function (tthis: HistoryLeverDialog, result: any) {
                tthis.dataSource.data = result.map((item: any, index: number) => ({
                    ...item,
                    index: index
                }));
            }
        )
    }

    ngAfterViewInit() {
    }

    close() {
        this.dialogRef.close();
    }
}
