import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Utils } from '../../utils/utils';
import { SaveService } from '../../services/save.service';
import { DialogService } from '../../services/dialog.service';
import { CustomDialogService } from '../../services/customdialog.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'operationmode-menu',
    templateUrl: './operationmode-menu.component.html',
    styleUrls: ['../../style/menu.component.less']
})

export class OperationModeMenuComponent {

    @Output() onNew = new EventEmitter<{}>();
    @Output() onReload = new EventEmitter<{}>();
    DOC_URL = "https://tkebbxdev.visualstudio.com/E2E%20MAX/_wiki/wikis/E2E-MAX.wiki/269/Operation-Modes";

    constructor(
        private router: Router
        , @Inject('BASE_URL') private baseUrl: string
        , public saveService: SaveService
        , private dialog: DialogService
        , private customDialog: CustomDialogService
        , private http: HttpClient
        , public defaults: DefaultValuesService
    ) {
    }

    onNewLanguage() {
        if (this.saveService.isSaveButtonShown())
            this.dialog.showErrorStringDialog("You must save the data before continue!");
        else
            this.router.navigate(["language/new"]);
    }

    onUpload() {
        this.router.navigate(["file/" + Utils.getFileUploadOpModeValue()]);
    }

    onCopyRulesToProd() {
        this.customDialog.openConfirm({
            title: "Copy all rules to prod",
            message: `All Stable alert rules will be copied to Prod. Are you sure?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe((res) => {
            if (res.confirmed) {
                const url = this.baseUrl + Utils.getOperationModeAPI() + "alertrulestoprod";
                this.http
                    .put<boolean>(url, {})
                    .subscribe(() => {
                        this.onReload.emit({});
                    }, error => {
                        this.dialog.showErrorDialog(error);
                    });
            }
        })
    }

    openDocumentationModal() {
        window.open(this.DOC_URL, "_blank");
    }

    downloadOperationModes() {
        Utils.httpDownloadOperationModes(
            this.http
            , this.baseUrl
            , this.defaults.controllertype.BK
            ,this
        )
    }
    async onError(error) {
        var errortxt = await Utils.readBlobError(error);
        this.dialog.showErrorDialog({
            'error':
            {
                'message': errortxt 
            }
        })
    }
   

}
