import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DefaultValuesService } from 'src/app/services/defaultvalues.service';
import { EquipmentManagenementService } from 'src/app/services/equipmentmanagement.service';

@Component({
  selector: 'addlevel',
  templateUrl: './addlevel.component.html',
  styleUrls: ['../../../style/custom-mat-dialog.component.less']
})
export class AddLevelComponent implements OnInit {
  @Output() onLevelInitializedTrigger = new EventEmitter<EquipmentConfigurationLevel>();

  environments: SelectList[] = [];
  tenants: SelectList[] = [];
  branches: SelectList[] = [];

  public formGroup: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<AddLevelComponent>,
    private defaults: DefaultValuesService,
    private fb: FormBuilder,
    private emtConfigurationService: EquipmentManagenementService,
  private toastService: ToastrService) 
  {
      this.formGroup = this.fb.group({
          environment: [null, Validators.required],
          tenant: [null],
          branch: [null],
          deviceId: [""]
    });
  }

  ngOnInit() {
      //this.tenants = this.defaults.tenants.map(x => {
      //    return {
      //        value: x.Tenant,
      //        text: x.Tenant + ' (' + x.CountryName + ')'
      //    };
      //});
      this.environments = this.defaults.environments.map(x => {
          return {
              value: x,
              text: x
          };
      });
  }

  onDeviceIdChange(event: any){
    const deviIdValue = event.target.value;
    const branchControl = this.formGroup.get('branch');
    if(deviIdValue && deviIdValue.length > 0){    
      branchControl?.setValidators(Validators.required);
    }
    else{
      branchControl?.clearValidators();
    }
    branchControl?.updateValueAndValidity();
  }

  closeDialog(event: any) {
    event.preventDefault();
    this.dialogRef.close();
  }

    onEnvironmentChanged($event: any) {
        this.tenants = this.defaults.tenants
            .filter(x => x.Environment === $event.value)
            .map(x => {
                return {
                    value: x.Tenant,
                    text: x.Tenant + ' (' + x.CountryName + ')'
                };
            });
    }

  onTenantChanged($event:any){
    this.branches = this.defaults.branches
    .filter(x => x.Country === $event.value)
    .map(x => {
      return {
        value: x.Number,
        text: x.Branch + ' - '+ x.Number
      }
    });
  }

  onSubmit(){
    if (this.formGroup.valid) {
      console.log('Form Submitted', this.formGroup.value);
      this.saveLevel();
    } else {
      console.log('Form is invalid');
    }
  }

  private saveLevel(){
    let newLevel = this.createFormRequest();
    this.emtConfigurationService.initEquipmentConfiguration(
      newLevel,
      result => {
        this.onLevelInitializedTrigger.emit(newLevel);
        this.dialogRef.close();
      },
      error => {
        if(error.status == 409){
          this.toastService.warning(error.error)
        }
        else{
          this.toastService.error("Unable to save new level.");
        }       
      }
    );
  }

  private createFormRequest():EquipmentConfigurationLevel{
      const newLevel: EquipmentConfigurationLevel = {
      environment: this.formGroup.get('environment')!.value,
      tenantId: this.formGroup.get('tenant')?.value,
      branchNumber: this.formGroup.get('branch')?.value,
      deviceId: this.formGroup.get('deviceId')?.value
    }
    return newLevel;
  }

}
