<div style="display:flow-root">
    <div style="float:left">
        <h5>This is the EMT Controller Software Releases management screen. </h5>
    </div>
</div>
<div class="flex-center">
    <div class="contentWidth">
        <div style="height: calc(90vh - 100px); overflow-y: auto;">
            <form [formGroup]="CSRForm" autocomplete="off">
                <ng-container formArrayName="CSRRows">
                    <table #objTable
                           mat-table
                           matSort
                           [dataSource]="dataSource"
                           class="table-add-butt-header-orange mat-elevation-z8 mat-table-custom">

                        <ng-container matColumnDef="headertitle">
                            <th class="title-col" mat-header-cell *matHeaderCellDef colspan="8">
                                <div class="pull-left">
                                    <h4>Controller Software Releases</h4>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="headeraddbutton">
                            <th class="butt-col" mat-header-cell *matHeaderCellDef>
                                <div class="OrangeButton">
                                    <button mat-raised-button (click)="addNewRow()"
                                            matTooltip="Add Release" class="button-color-fix pull-right">
                                        <mat-icon class="icon-color-fix icon-padding-fix">add_box</mat-icon>
                                    </button>
                                </div>
                            </th>
                        </ng-container>

                        <!-- Software Version Column -->
                        <ng-container matColumnDef="softwareversion">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">Software Version</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:15%">
                                <div [innerHTML]="CSRForm?.get('CSRRows')?.value[i].softwareVersion" *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isNewRow"></div>
                                <mat-form-field style="width: 100%" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isNewRow">
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="CSRForm?.get('CSRRows')?.value[i].softwareVersion"
                                           maxlength="50"
                                           matTooltip="Max 50 characters"
                                           (keydown)="checkInput($event)" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Controller Software Version Column -->
                        <ng-container matColumnDef="controllersoftwareversion">
                            <th mat-header-cell *matHeaderCellDef style="width:10%">Controller Software Version</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:10%">
                                <div [innerHTML]="CSRForm?.get('CSRRows')?.value[i].controllerSoftwareVersion" *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isNewRow"></div>
                                <mat-form-field style="width: 100%" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isNewRow">
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="CSRForm?.get('CSRRows')?.value[i].controllerSoftwareVersion"
                                           maxlength="10"
                                           matTooltip="Max 10 characters" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- TDC Agent Version Column -->
                        <ng-container matColumnDef="agentversion">
                            <th mat-header-cell *matHeaderCellDef style="width:7%">Agent Version</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:7%">
                                <div [innerHTML]="CSRForm?.get('CSRRows')?.value[i].tdcAgentVersion" *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isNewRow"></div>
                                <mat-form-field style="width: 100%" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isNewRow">
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="CSRForm?.get('CSRRows')?.value[i].tdcAgentVersion"
                                           maxlength="10"
                                           matTooltip="Max 10 characters" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Family Code Column -->
                        <ng-container matColumnDef="familycode">
                            <th mat-header-cell *matHeaderCellDef style="width:18%">Family Code</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:18%">
                                <div [innerHTML]="getFamilyCodeText(CSRForm?.get('CSRRows')?.value[i].familyCodeId)" *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isNewRow"></div>
                                <mat-form-field floatLabel="never" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isNewRow">
                                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="CSRForm?.get('CSRRows')?.value[i].familyCodeId"
                                                [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="-1" [disabled]="true">Select</mat-option>
                                        <mat-option *ngFor="let fc of availableFamilyCodes" [value]="fc.familyCodeId">
                                            {{getFamilyCodeText(fc.familyCodeId)}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Product usage Column -->
                        <ng-container matColumnDef="productusage">
                            <th mat-header-cell *matHeaderCellDef style="width:10%">Product usage</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:10%">
                                <div [innerHTML]="getProductUsageText(CSRForm?.get('CSRRows')?.value[i].productUsageId)" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isEditable"></div>
                                <mat-form-field floatLabel="never" *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isEditable">
                                    <mat-select style="min-width: 200px; color:red" [(ngModel)]="CSRForm?.get('CSRRows')?.value[i].productUsageId"
                                                [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="-1" [disabled]="false">Select</mat-option>
                                        <mat-option *ngFor="let fc of availableProductUsages" [value]="fc.productUsageID">
                                            {{getProductUsageText(fc.productUsageID)}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Software file Column -->
                        <ng-container matColumnDef="file">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">Software File</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:15%">
                                <div [innerHTML]="CSRForm?.get('CSRRows')?.value[i].softwareFileName" *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isNewRow"></div>
                                <input id={{getFileUploadId(i)}} type="file" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isNewRow"
                                       class="form-control"
                                       (change)="onFileChange($event, i)" />
                            </td>
                        </ng-container>

                        <!-- Release Date Column -->
                        <ng-container matColumnDef="releasedate">
                            <th mat-header-cell *matHeaderCellDef style="width:10%">Release UTC Date</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:10%">
                                <div [innerHTML]="datePipe.transform(CSRForm?.get('CSRRows')?.value[i]?.releaseDate,'yyyy-MM-dd')" *ngIf="CSRForm?.get('CSRRows')?.value[i]?.isEditable"></div>
                                <mat-form-field *ngIf="!CSRForm?.get('CSRRows')?.value[i]?.isEditable">
                                    <input matInput [matDatepicker]="picker"
                                           [ngModelOptions]="{standalone: true}"
                                           [min]="minDate"
                                           [(ngModel)]="CSRForm.get('CSRRows')?.value[i].releaseDate"
                                           (dateChange)="getDateUTCChange($event, i)" />
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <!-- Comment Column -->
                        <ng-container matColumnDef="comment">
                            <th mat-header-cell *matHeaderCellDef style="width:10%">Comment</th>
                            <td mat-cell *matCellDef="let element; let i = index" style="width:10%">
                                <textareaoverlay [inputControls]="{
                                                     isOpenOverlay: CSRForm?.get('CSRRows')?.value[i].isOpenOverlay,
                                                     textAreaId: CSRForm?.get('CSRRows')?.value[i].textAreaId,
                                                     isEditable: !CSRForm?.get('CSRRows')?.value[i].isEditable,
                                                     maxCommentLength: CSRForm?.get('CSRRows')?.value[i].maxCommentLength,
                                                     comment: CSRForm?.get('CSRRows')?.value[i].comment
                                                     }"
                                                 [customSpanClass]="{'alternative2': !CSRForm?.get('CSRRows')?.value[i]?.isEditable}"
                                                 (commentChange)="onCommentChange($event.comment, i)">
                                </textareaoverlay>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:5%"></th>
                            <td mat-cell
                                *matCellDef="let element; let i = index" style="width:5%">

                                <button mat-icon-button
                                        *ngIf="!CSRForm.get('CSRRows')?.value[i]?.isEditable"
                                        matTooltip="Cancel Changes"
                                        color="warn"
                                        (click)="resetRow(i)">
                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="CSRForm.get('CSRRows')?.value[i]?.isEditable"
                                        matTooltip="Edit"
                                        color="primary"
                                        (click)="editRow(i)">
                                    <mat-icon class="actionButtons">edit</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="false"
                                        matTooltip="Delete"
                                        color="warn"
                                        (click)="deleteRow(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="savefooter">
                            <th mat-footer-cell *matFooterCellDef colspan="9">
                                <div class="OrangeButton">
                                    <button mat-raised-button type="submit" (click)="onSave()"
                                            [disabled]="!anyChange()"
                                            [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                            class="button-color-fix pull-right">
                                        Save
                                        <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                    </button>
                                </div>
                            </th>

                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="['headertitle', 'headeraddbutton']; sticky: true" class="stickyTopz100"></tr>
                        <tr class="col-headers" mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; let i = index; columns: columnsToDisplay;" [class.alternative2]="!CSRForm.get('CSRRows')?.value[i]?.isEditable"></tr>
                        <tr mat-footer-row *matFooterRowDef="['savefooter']; sticky: true"></tr>
                    </table>
                </ng-container>
            </form>
        </div>
    </div>
</div>