import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Utils } from '../../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { ToastrService } from 'ngx-toastr';
import { AssessmentAssignmentPopupComponent } from './assessmentassignmentpopup/assessmentassignmentpopup.component';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';

@Component({
    selector: 'assessment-assignment',
    templateUrl: './assessment-assignment.component.html',
    styleUrls: ['./assessment-assignment.component.less',
        '../branchexcellenceadmin.component.less',
        '../../../../../style/new-generic-styles.component.less']
})
export class AssessmentAssignmentComponent implements OnInit, OnChanges {
    public dataSource = new MatTableDataSource<Wave>();

    @Input() selectedWave: Wave | undefined;
    columnsToDisplay = ['Checkbox', 'MgmtId', 'MgmtName', 'AssessmentDate', 'ChangedAtUtc', 'ChangedBy'];

    isRemoveAssignmentEnabled = false;
    removeBranchesList: string[] = []

    list: any
    constructor(
        private http: HttpClient
        , private dialogForm: MatDialog
        , @Inject('BASE_URL') private baseUrl: string
        , private customDialog: CustomDialogService
        , private toastrService: ToastrService
        , public defaults: DefaultValuesService) { }

    ngOnInit(): void {

    }
    ngOnChanges(): void {
        this.reload()
    }

    reload() {
        this.defaults.notifyManagementIdChange() // to force to load newest waves
        if (this.selectedWave && this.selectedWave?.WaveId !== -1)
            Utils.httpGetAssessments(
                this.http,
                this.baseUrl,
                this.selectedWave!.WaveId,
                this,
                function (tthis: AssessmentAssignmentComponent, list: any) {
                    tthis.list = list;
                    tthis.dataSource.data = list
                    tthis.isRemoveAssignmentEnabled = false;
                    tthis.removeBranchesList = [];
                },
                null

            )
    }

    openAssignBranchPopup() {
        const dialog = this.dialogForm.open(AssessmentAssignmentPopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            minWidth: "60vw",
            data: this.selectedWave
        })

        dialog.afterClosed().subscribe(() => {
            this.reload()
        })
    }

    enableRemoveAssignmentMode() {
        this.isRemoveAssignmentEnabled = !this.isRemoveAssignmentEnabled;
        if (this.isRemoveAssignmentEnabled)
            this.removeBranchesList = [];
    }

    controlAssignedItem(ischecked, row: any | undefined) {
        if (row) {
            if (ischecked) {
                this.removeBranchesList.push(row.MgmtId)
            } else {
                const index = this.removeBranchesList.indexOf(row.MgmtId);
                this.removeBranchesList.splice(index, 1);
            }
        }
        else {
            if (ischecked) {
                this.list.forEach((x: any) => {
                    this.removeBranchesList.push(x.MgmtId)
                })
            } else {
                this.removeBranchesList = [];
            }
        }
    }

    confirmRemoveAssignedBranches() {
        Utils.httpUnassignBranchesToWave(
            this.http,
            this.baseUrl,
            this.selectedWave!.WaveId,
            this.removeBranchesList,
            this,
            function (tthis: AssessmentAssignmentComponent, result: any) {
                if (result)
                    tthis.toastrService.success('Branches were unassigned successfully!');
                else
                    tthis.toastrService.error('Branches could not be unassigned. Please, contact with administrator');
                tthis.reload();
            },
            null

        )
    }
}
