<div class="
    example-container-kpimanagement
    black-checkbox
    new-table-style"
     style="height:70vh !important">

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="ActionPriorizationList">

        <ng-container matColumnDef="ExcellenceLever">
            <th mat-header-cell *matHeaderCellDef> Excellence Lever </th>
            <td mat-cell *matCellDef="let lever">
                <p [innerHTML]="lever.ExcellenceLever  | bold: dataSource.filter"></p>
            </td>
        </ng-container>


        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let lever">
                <textareaoverlay [inputControls]="{
                                 isOpenOverlay: isOpenTextAreaOverlay,
                                 textAreaId: 'Action_ta_' + lever.index,
                                 isEditable: false,
                                 maxCommentLength: 4000,
                                 comment: lever.Action,
                                 maxSubstringSize: 250
                                 }"
                                 [customSpanClass]="{'boldText': !isEditModeEnabled}"
                                 (commentChange)="onTextFieldChange($event.comment, $event.textAreaId, lever)">
                </textareaoverlay>
            </td>
        </ng-container>

        <ng-container matColumnDef="Stage">
            <th mat-header-cell *matHeaderCellDef> Stage </th>
            <td mat-cell *matCellDef="let lever">
                <p [innerHTML]="lever.ExcellenceStageId"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="Priority">
            <th mat-header-cell *matHeaderCellDef> Priority </th>
            <td mat-cell *matCellDef="let lever">
                <p [innerHTML]="lever.Priority" [style.display]="isEditModeEnabled === false ? 'block' : 'none'"></p>
                <ng-select [ngClass]="{'hidden': isEditModeEnabled === false }"
                           class="integratedNgSelect"
                           [items]="priorities"
                           bindLabel="Description"
                           bindValue="PriorityId"
                           [clearable]="false"
                           [searchable]="false"
                           [(ngModel)]="lever.PriorityId"
                           [clearOnBackspace]="false"
                           (ngModelChange)="onChange(lever.WaveExcellenceLeverConfigId)">
                </ng-select>
            </td>
        </ng-container>

        <ng-container matColumnDef="Comments">
            <th mat-header-cell *matHeaderCellDef> Comments </th>
            <td mat-cell *matCellDef="let lever">
                <textareaoverlay [inputControls]="{
                                 isOpenOverlay: isOpenTextAreaOverlay,
                                 textAreaId: 'DiscussedComments_ta_' + lever.index,
                                 isEditable: isEditModeEnabled,
                                 maxCommentLength: 4000,
                                 comment: lever.DiscussedComments,
                                 maxSubstringSize: 250
                                 }"
                                 [customSpanClass]="{'boldText': !isEditModeEnabled}"
                                 (commentChange)="onTextFieldChange($event.comment, $event.textAreaId, lever)">
                </textareaoverlay>
            </td>
        </ng-container>


        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let factor; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditModeEnabled === true">
        </tr>
    </table>
</div>