import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Utils } from "../../../utils/utils";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { DialogService } from "../../../services/dialog.service";
import { WaitingService } from "../../../services/waiting.service";
import { Subscription } from 'rxjs';
import { SpinnerOverlayService } from '../../../services/spinner-overlay.service';

@Component({
    selector: "deploymentsdevops",
    templateUrl: './deploymentsdevops.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less', 'deploy-form.less']
})

export class DeploymentsDevOpsComponent implements OnInit {

    title = "Deployment all *.sql files in all languages";

    deployment = {} as Deployment;

    constructor(
        private http: HttpClient
        , private waiting: WaitingService
        , private confimDialog: DialogService
        , private defaults: DefaultValuesService
        , private dialog: DialogService
        , @Inject('BASE_URL') private baseUrl: string
        , private readonly spinnerOverlayService: SpinnerOverlayService
    ) {
        this.deployment.BusinessKeys = false;
        this.deployment.ControllerConfig = false;
        this.deployment.ControllerTypesByTenant = false;
        this.deployment.ControllerTypeToMasterDataRequired = false;
        this.deployment.ControllerTypes = this.defaults.controllertypes.concat(this.defaults.controllertypesdigitalservices);
        this.deployment.CustomerPortal = false;
        this.deployment.BranchHierarchy = false;
        this.deployment.RemoteActions = false;
        this.deployment.EMTMFGConfiguration = false;
    }

    ngOnInit() {
        this.dialog.tthis = this;
        Utils.httpGetAllControllerTypesAvailable(
            this.http
            , this.baseUrl
            , this
            , function (tthis: any, list: ControllerType[]) {
                tthis.deployment.ControllerTypes = list;
            }
        );
    }

    onDeploy() {
        window.scroll(0, 0);
        if (this.deployment.CustomerPortal)
            this.confimDialog.showConfirmDialog("Do you want to deploy all translation files for Customer Portal, and all the selected data?", "Cloud data & Customer Portal Translations", this);
        else
            this.onYes();
    }

    /**
     * called by the dialog service, to close the dialog
     * */
    onBack() { }

    /**
     * called by the confirm dialog service, to deploy
     * */
    onYes() {
        const url = this.baseUrl + Utils.getGenericAPI() + "deploy";
        this.http
            .post<Deployment>(url, this.deployment)
            .subscribe(
                res => {
                    const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
                    const intervalPolling = setInterval(() => {
                        const url = this.baseUrl + Utils.getGenericAPI() + "get-deploy-status";
                        this.http
                            .get<string>(url).subscribe(
                                (res: any) => {
                                    var result = res.result
                                    if (result) {
                                        if (result.indexOf('OK -') >= 0) {
                                            this.confimDialog.showSuccessDialog("The deployment was successfully initiated!");
                                            this.waiting.toWait(false);
                                            clearInterval(intervalPolling)
                                            spinnerSubscription.unsubscribe();
                                        }
                                        else {
                                            if (result.indexOf('ERROR -') >= 0) {
                                                this.waiting.toWait(false);
                                                this.confimDialog.showErrorDialog({ 'error': { 'message': result } })
                                                clearInterval(intervalPolling)
                                                spinnerSubscription.unsubscribe();
                                            }
                                            else {
                                                if (result.includes("|/|")) {
                                                    const textPart = result?.split("|/|")[0].trim();
                                                    const fractionPart = result?.split("|/|")[1].trim();
                                                    const [numerator, denominator] = fractionPart.split('/').map(Number);
                                                    this.confimDialog.showInfoBackgroundDialog(textPart, numerator / denominator * 100)
                                                }

                                            }
                                        }
                                    }
                                },
                                error => {
                                    this.confimDialog.showErrorDialog(error)
                                }
                            )
                    }, 10000)

                }, error => this.confimDialog.showErrorDialog(error)
            );
    }
}