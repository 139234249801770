import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SaveService } from '../../../../../services/save.service';
import { CreateMeasurePopupComponent } from '../create-measure/create-measure-popup.component';
import { CreateActionItemPopupComponent } from '../create-actionitem/create-action-item-popup.component';
import { DialogService } from '../../../../../services/dialog.service';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'focusplanstatus-list',
    templateUrl: './focusplanstatus-list.component.html',
    styleUrls: ['./focusplanstatus-list.component.less',
        '../../../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]
})
export class FocusPlanListStatusComponent implements OnInit {

    @Input('measuresInfo') measuresInfo!: MeasureInfo[];
    @Input('waveId') waveId!: number;
    @Input('isEditModeEnabled') isEditModeEnabled!: boolean;
    @Output() refresh = new EventEmitter<string>();
    updatedItems: any[] = []
    editedStatusComments: any[] = [];

    columnsToDisplay = ['arrow', 'Name', 'Description', 'Kpi'];
    subcolumnsToDisplay = ['ItemNo', 'Description', 'Owner', 'Comments', 'Deadline', 'StatusQ1', 'StatusQ2', 'StatusQ3', 'StatusQ4', 'StatusComments'];

    public dataSource = new MatTableDataSource<MeasureInfo>();

    expandedElements = new Set();

    dialogRef: any;

    onRefresh: any;

    statuses!: any[]
    constructor(
        private saveService: SaveService
        , private dialogForm: MatDialog
        , private dialog: DialogService
        , private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , private toastrService: ToastrService
    ) { }

    ngOnInit(): void {
        Utils.httpGetAvailableStatuses(
            this.http
            , this.baseUrl
            , this
            , function (tthis: FocusPlanListStatusComponent, result: any[]) {
                tthis.statuses = result
            },
            null
        );
    }

    ngOnChanges(changes: any) {
        if (this.statuses) {
            this.measuresInfo.forEach(x => {
                x.ActionItems.forEach(y => {
                    y.StatusDescQ1 = this.statuses?.find(z => y.StatusQ1 === z.StatusId)?.Description
                    y.StatusDescQ2 = this.statuses?.find(z => y.StatusQ2 === z.StatusId)?.Description
                    y.StatusDescQ3 = this.statuses?.find(z => y.StatusQ3 === z.StatusId)?.Description
                    y.StatusDescQ4 = this.statuses?.find(z => y.StatusQ4 === z.StatusId)?.Description
                })
            })
            this.dataSource.data = this.measuresInfo
        }
    }

    pushPopElement(measureId: number) {
        if (this.expandedElements.has(measureId)) {
            this.expandedElements.delete(measureId);
        }
        else {
            this.expandedElements.add(measureId);
        }
    }
    checkExpanded(measureId: number): boolean {
        return this.expandedElements.has(measureId);
    }
    
    onChange(actionItemId: number, statusId: number, quarter: number, actionItemStatusId: number) {
        const index = this.updatedItems.findIndex(
            (obj) =>
                obj.actionItemId === actionItemId &&
                obj.quarter === quarter)

        if (index > 0) {
            this.updatedItems.splice(index, 1)
        }

        this.updatedItems.push({
            actionItemId: actionItemId,
            quarter: quarter,
            statusId: statusId,
            actionItemStatusId: actionItemStatusId
        })

    }
    

    onStatusCommentsChange(actionItem: any, updatedComment) {
        actionItem.StatusComments = updatedComment;
        if (!this.editedStatusComments.includes(actionItem)) {
            this.editedStatusComments.push(actionItem);
        }
    }
  
}