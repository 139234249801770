import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from
    "@angular/forms"
import { ActivatedRoute, Params } from '@angular/router';
import { UMPAction, UMPCategory, Utils } from '../../../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { AddGroupDialogue } from '../addgroupdialogue/addgroupdialogue.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerOverlayService } from '../../../../../services/spinner-overlay.service';





@Component({
    selector: "mersyroles",
    templateUrl: './mersyroles.component.html',
    styleUrls: ['./mersyroles.component.less', '../../../../../style/new-generic-styles.component.less']

})

export class MersyRoles implements OnInit {
    @Input() public groupmgmtcallback: Function | undefined;
    @Input() public isMQRequest: boolean | undefined;
    @Input() public isBERequest: boolean | undefined;
    public userName: String = '';
    userId = ''


    searchString: string = '';
    filteredMersyRoles: any[] = [];

    MersyRoles: any[] = [];
    Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
    errorResponse: any;
    isCallSucceed: number = 0;

    dialogRef: MatDialogRef<AddGroupDialogue, any> | undefined

    AllAzDevProj = [];
    UserAzDevProj = [];
    RemainAzDevProj = [];




    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private toastrService: ToastrService,
        private dialogForm: MatDialog,
        private spinnerOverlayService: SpinnerOverlayService,


        @Inject('BASE_URL') private baseUrl: string
    ) {
        this.route.params.subscribe(
            (params: Params) => {
                this.resetAll();
            });


    }

    ngOnInit() {

    }
    async updateRolesResults() {
        this.filteredMersyRoles = this.searchByValue(this.MersyRoles, "persona", this.searchString);
    }
    resetAll() {
        this.userName = '';
        this.userId = ''


        this.searchString = '';
        this.filteredMersyRoles = [];

        this.MersyRoles = [];
        this.Response_State = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API
        this.isCallSucceed = 0;


        this.AllAzDevProj = [];
        this.UserAzDevProj = [];
        this.RemainAzDevProj = [];
        this.updateRolesResults();

        this.userId = this.route.snapshot.paramMap.get('userId') || '';
        this.funcGetUserMersyRoles();
    }
    searchByValue(items: any, filterProperty: string, query: string) {

        return items.filter(item => {
            if (query.trim() === '') {
                return true;
            } else {
                return item[filterProperty].toLowerCase().includes(query.trim().toLocaleLowerCase())
            }
        })
    }

    handleDeleteButton(selected: any) {

        this.isCallSucceed = 0;
        Utils.httpRevokeGroupPersonae(
            this.http
            , this.baseUrl
            , {
                "Personae": [selected.id],
                "UmpAction": UMPAction.Unassign,
                "UmpCategory": UMPCategory.MersyRoles,
                "Type": 2
            }
            , this.userId
            , this
            , function (tthis: MersyRoles) {
                tthis.funcGetUserMersyRoles();
                tthis.funcNotify(selected.persona);
            }
        )




    }
    funcGetUserMersyRoles() {
        this.Response_State = -2;
        Utils.httpGetUserMersyRoles(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , function (tthis: MersyRoles, data: any) {
                tthis.MersyRoles = data.personae.filter(obj =>
                    tthis.isMQRequest ? obj.persona.toLowerCase().includes("marketquery")
                        : tthis.isBERequest ? obj.persona.toLowerCase().includes("branchexcellence")
                            : !obj.persona.toLowerCase().includes("marketquery") && !obj.persona.toLowerCase().includes("branchexcellence"))

                tthis.UserAzDevProj = data.personae.filter(obj =>
                    tthis.isMQRequest ? obj.persona.toLowerCase().includes("marketquery")
                        : tthis.isBERequest ? obj.persona.toLowerCase().includes("branchexcellence")
                            : !obj.persona.toLowerCase().includes("marketquery") && !obj.persona.toLowerCase().includes("branchexcellence"));


                tthis.filteredMersyRoles = tthis.MersyRoles

                if (tthis.MersyRoles?.length) {
                    tthis.Response_State = 1;
                }
                else {
                    tthis.Response_State = 0;

                }
            }
        )
    }
    funcNotify(persona) {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Delete "' + persona + '"  request sent successfully !', `${this.isMQRequest === true ? 'Market Query' : 'Mersy'} Role Delete Request!`);
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Delete "' + persona + '"  request not sent successfully !', `${this.isMQRequest === true ? 'Market Query' : 'Mersy'} Mersy Role Delete Request!`);
        }
    }

    //DialogueForm generalised  
    funcGetMersyRoles() {
        Utils.httpGetMersyRoles(
            this.http
            , this.baseUrl
            , this.userId
            , this
            , function (tthis: MersyRoles, data: any) {
                tthis.AllAzDevProj = data.personae.filter(obj =>
                    tthis.isMQRequest ? obj.persona.toLowerCase().includes("marketquery")
                        : tthis.isBERequest ? obj.persona.toLowerCase().includes("branchexcellence")
                            : !obj.persona.toLowerCase().includes("marketquery") && !obj.persona.toLowerCase().includes("branchexcellence"))
                //tthis.funcGetUserMersyRoles_seq();
                tthis.getUnselectedItems(tthis.AllAzDevProj, tthis.UserAzDevProj);

            }
        )
    }



    //funcGetUserMersyRoles_seq() {
    //    Utils.httpGetUserMersyRoles(
    //        this.http
    //        , this.baseUrl
    //        , this.userId
    //        , this
    //        , function (tthis: MersyRoles, data: any) {
    //            tthis.UserAzDevProj = data.personae;
    //            tthis.getUnselectedItems(tthis.AllAzDevProj, tthis.UserAzDevProj);
    //        }
    //    )
    //}



    getUnselectedItems(allList: any, selectedList: any) {

        this.RemainAzDevProj = allList.filter(item => !selectedList.some(selectedItem => selectedItem.id === item.id));
        this.opnedialoguebox()
    }

    addUserTrigger() {


        this.funcGetMersyRoles();
    };

    opnedialoguebox() {
        this.dialogRef = this.dialogForm.open(AddGroupDialogue, {
            height: '540px',
            width: '600px',
            data: { title: this.getMersyOrMarketLabel(), umpCategory: UMPCategory.MersyRoles, userId: this.userId, AllGroups: this.AllAzDevProj, UserAssignedGroups: this.UserAzDevProj, UserUnAssignedGroups: this.RemainAzDevProj, type: 2, callback: this.groupmgmtcallback }
        });
        this.dialogRef.afterClosed().subscribe(() => {

            this.funcGetUserMersyRoles();
        });
    }

    getMersyOrMarketLabel() {
        return this.isMQRequest ? 'Market Query' :
            this.isBERequest ? 'Branch Excellence' : 'MERSY'
    }
}