<div class="
    example-container-kpimanagement
    black-checkbox
    new-table-style"
     style="height:70vh !important">

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="Assessment">

        <ng-container matColumnDef="Factors">
            <th mat-header-cell *matHeaderCellDef>Factors</th>
            <td mat-cell *matCellDef="let element" (click)="pushPopElement(element, false)">
                <span class="material-icons" *ngIf="checkExpanded(element, false); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="KPIs">
            <th mat-header-cell *matHeaderCellDef>KPIs</th>
            <td *matCellDef="let element" (click)="pushPopElement(element, true)">
                <span class="material-icons" *ngIf="checkExpanded(element, true); else show_kpis_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="Function">
            <th mat-header-cell *matHeaderCellDef> Function </th>
            <td mat-cell *matCellDef="let lever">
                <p [innerHTML]="lever.Function | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="LineOfBusiness">
            <th mat-header-cell *matHeaderCellDef> Line Of Business </th>
            <td mat-cell *matCellDef="let lever">
                <p [innerHTML]="lever.LineOfBusiness | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ExcellenceLever">
            <th mat-header-cell *matHeaderCellDef> Excellence Lever </th>
            <td mat-cell *matCellDef="let lever">
                <p [innerHTML]="lever.ExcellenceLever  | bold: dataSource.filter"></p>
            </td>
        </ng-container>


        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let lever">
                <textareaoverlay [inputControls]="{
                                 isOpenOverlay: isOpenTextAreaOverlay,
                                 textAreaId: 'Action_ta_' + lever.index,
                                 isEditable: isEditModeEnabled,
                                 maxCommentLength: 4000,
                                 comment: lever.Action
                                 }"
                                 [customSpanClass]="{'boldText': !isEditModeEnabled}"
                                 (commentChange)="onTextFieldChange($event.comment, $event.textAreaId, lever)">
                </textareaoverlay>
            </td>
        </ng-container>

        <!--<ng-container matColumnDef="Stage">
            <th mat-header-cell *matHeaderCellDef> Stage </th>
            <td mat-cell *matCellDef="let lever">
                <p [innerHTML]="lever.ExcellenceStageId" [style.display]="isEditModeEnabled === false ? 'block' : 'none'"></p>
                <ng-select [ngClass]="{'hidden': isEditModeEnabled === false }"
                           class="integratedNgSelect"
                           [items]="stages"
                           bindLabel="Id"
                           bindValue="Id"
                           [clearable]="false"
                           [searchable]="false"
                           [(ngModel)]="lever.ExcellenceStageId"
                           [clearOnBackspace]="false"
                           (ngModelChange)="onChange(lever.WaveExcellenceLeverConfigId)">
                </ng-select>
            </td>
        </ng-container>-->

        <ng-container matColumnDef="Contact">
            <th mat-header-cell *matHeaderCellDef> Contact </th>
            <td mat-cell [ngClass]="{'editcolor': isEditModeEnabled === false }" *matCellDef="let lever">
                <p [innerHTML]="lever.Contact" [style.display]="isEditModeEnabled === false ? 'block' : 'none'"></p>
                <ng-select [ngClass]="{'hidden': isEditModeEnabled === false } "
                           class="integratedNgSelect"
                           [items]="contacts"
                           bindLabel="Label"
                           bindValue="BranchContactId"
                           [clearable]="false"
                           [searchable]="false"
                           [(ngModel)]="lever.ContactId"
                           [clearOnBackspace]="false"
                           (ngModelChange)="onChange(lever.WaveExcellenceLeverConfigId)">
                </ng-select>
            </td>
        </ng-container>

        <ng-container matColumnDef="Notes">
            <th mat-header-cell *matHeaderCellDef> Notes </th>
            <td mat-cell *matCellDef="let lever">
                <textareaoverlay [inputControls]="{
                                 isOpenOverlay: isOpenTextAreaOverlay,
                                 textAreaId: 'Notes_ta_' + lever.index,
                                 isEditable: isEditModeEnabled,
                                 maxCommentLength: 4000,
                                 comment: lever.Notes
                                 }"
                                 [customSpanClass]="{'boldText': !isEditModeEnabled}"
                                 (commentChange)="onTextFieldChange($event.comment, $event.textAreaId, lever)">
                </textareaoverlay>
            </td>
        </ng-container>

        <ng-container matColumnDef="BestPractices">
            <th mat-header-cell *matHeaderCellDef> Best Practices </th>
            <td mat-cell *matCellDef="let lever">
                <textareaoverlay [inputControls]="{
                                 isOpenOverlay: isOpenTextAreaOverlay,
                                 textAreaId: 'BestPractices_ta_' + lever.index,
                                 isEditable: isEditModeEnabled,
                                 maxCommentLength: 4000,
                                 comment: lever.BestPractices
                                 }"
                                 [customSpanClass]="{'boldText': !isEditModeEnabled}"
                                 (commentChange)="onTextFieldChange($event.comment, $event.textAreaId, lever)">
                </textareaoverlay>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let lever">
                <button mat-icon-button matTooltip="Get History" (click)="onDetail($event,lever)">
                    <mat-icon>history</mat-icon> <span>Get History</span>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="factors">
            <td mat-cell *matCellDef="let lever" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(lever, false) ? 'expanded' : 'collapsed'"
                     id="AssessmentFactors">
                    <table mat-table
                           [dataSource]="lever.Factors"
                           class="mat-subtable-custom">

                        <ng-container matColumnDef="Factor">
                            <th mat-header-cell *matHeaderCellDef> Excellence Factor </th>
                            <td mat-cell *matCellDef="let factor">
                                <p [innerHTML]="factor.Name | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Stage">
                            <th mat-header-cell *matHeaderCellDef> Stage </th>
                            <td mat-cell *matCellDef="let factor" [style.background]="isEditModeEnabled === true ? '#ffbe7a' : ''">
                                <p [innerHTML]="factor.ExcellenceStage" [style.display]="isEditModeEnabled === false ? 'block' : 'none'"></p>
                                <ng-select [ngClass]="{'hidden': isEditModeEnabled === false }"
                                           class="integratedNgSelect"
                                           [items]="stages"
                                           bindLabel="ExcellenceStage"
                                           bindValue="ExcellenceStageId"
                                           [clearable]="false"
                                           [searchable]="false"
                                           [(ngModel)]="factor.ExcellenceStageId"
                                           [clearOnBackspace]="false"
                                           (ngModelChange)="onChange(lever.WaveExcellenceLeverConfigId)">
                                </ng-select>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Description">
                            <th mat-header-cell *matHeaderCellDef> Description </th>
                            <td mat-cell *matCellDef="let factor">
                                <p [innerHTML]="factor.Description"></p>
                            </td>
                        </ng-container>
                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: subcolumnsToDisplay;" class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="kpis">
            <td mat-cell *matCellDef="let lever" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(lever, true) ? 'expanded' : 'collapsed'"
                     id="AssessmentKpis">
                    <table mat-table
                           [dataSource]="lever.KPIs"
                           class="mat-subtable-custom">


                        <ng-container matColumnDef="AccountId">
                            <th mat-header-cell *matHeaderCellDef> KPI name </th>
                            <td mat-cell *matCellDef="let kpi">
                                <p [innerHTML]="kpi.Name | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Description">
                            <th mat-header-cell *matHeaderCellDef> Description </th>
                            <td mat-cell *matCellDef="let kpi">
                                <p [innerHTML]="kpi.Description"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="GF">
                            <th mat-header-cell *matHeaderCellDef> Group Function </th>
                            <td mat-cell *matCellDef="let kpi">
                                <p [innerHTML]="kpi.GF"></p>
                            </td>
                        </ng-container>
                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsKpisToDisplay"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: subcolumnsKpisToDisplay;" class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>


        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let factor; let i = dataIndex; columns: columnsToDisplay;"
            class="item"
            [class.alternative2]="isEditModeEnabled === true">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['factors']" class="row-subitem" ></tr>
        <tr mat-row *matRowDef="let row; columns: ['kpis']" class="row-subitem"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons" matTooltip="Related Excellence Factors">
        expand_more
    </span>
</ng-template>


<ng-template #show_kpis_expand_more>
    <span class="material-icons" matTooltip="Related KPIs">
        expand_more
    </span>
</ng-template>