import { Component, Output, Inject, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { DialogService } from '../../services/dialog.service';
import { SaveService } from '../../services/save.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Injectable, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SpinnerOverlayService } from '../../services/spinner-overlay.service';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ViewChild } from '@angular/core';
import { GroupFunctionComponent } from '../groupfunction/groupfunction.component';


interface MGMTBranchNode {
    Value: string;
    GroupName: string;
    subGroupsAllowed: boolean,
    Selected?: boolean;
    Disabled?: boolean;
    Indeterminate?: boolean;
    Parent?: MGMTBranchNode;
    Groups: MGMTBranchNode[];
    GroupFunction: string;
    BusinessLevel: string;
}

@Component({
    selector: 'managementid',
    templateUrl: './managementid.component.html',
    styleUrls: ['./managementid.component.less'],
    encapsulation: ViewEncapsulation.None

})
export class ManagementIdComponent implements AfterViewInit {

    @Output() managementidChanged = new EventEmitter<string>();

    @ViewChild(MatAutocompleteTrigger) mgmtAutocomplete: MatAutocompleteTrigger = {} as MatAutocompleteTrigger;
    @ViewChild(GroupFunctionComponent) GroupFuncComp: GroupFunctionComponent = {} as GroupFunctionComponent;

    protected _onDestroy = new Subject<void>();

    groupfunction = "";
    selGrpFuncFrmAll = "";
    selectorValue: string = 'All';
    selectedManagementId: string = '';
    allToggelButtonOption = [];
    value = '';
    lastSelectedNode: MGMTBranchNode = {} as MGMTBranchNode;

    userId = '';
    public TREE_DATA: MGMTBranchNode[] = [];/*** */
    public arrayFiltered: MGMTBranchNode[] = [];/*** */

    public selectedNodes: string[] = [];

    public treeControl: NestedTreeControl<MGMTBranchNode>;
    public dataSource = new MatTreeNestedDataSource<MGMTBranchNode>();

    public searchString = '';
    public showOnlySelected = false;
    Tree_Response_State: number = -2; //0 -> empty , 1 -> correct and not empty ,-1 -> error, -2 -> Calling API

    mode: ManagementMode = ManagementMode.BranchReporting
    isCallSucceed: number = 0;
    placeHolder = "";
    previousPlaceHolder = "";

    //data: TreeManagement = {
    //    SelectedBranches: [],
    //    UnselectedBranches: []
    //}
    modeEnum: typeof ManagementMode = ManagementMode;


    constructor(
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        public defaults: DefaultValuesService,
        private dialog: DialogService,
        private saveService: SaveService,

        private router: Router) {

        this.treeControl = new NestedTreeControl<MGMTBranchNode>((node) => node.Groups)


    }
    ngOnInit() {

    }
    ngAfterViewInit() {

        if (this.router.url === '/actualreporting' || this.router.url === '/oplkpimanagement'
            || this.router.url === '/file/15' || this.router.url === '/file/16' || this.router.url === '/file/17'
            || this.router.url === '/market-sheet' || this.router.url === '/segment-sheet')
            this.mode = ManagementMode.Mersy

        else if (this.router.url === '/branch-excellence-admin' || this.router.url === '/assessment')
            this.mode = ManagementMode.BranchExcellence
        else
            this.mode = ManagementMode.BranchReporting
        this.funcGetUserMgmtBranches();


        this.defaults.languageMessageChanges$.subscribe(() => {
            this.funcGetUserMgmtBranches();
        });

        this.router.events.subscribe((event: any) => {

            if (event instanceof NavigationEnd) {
                if (event.url === '/actualreporting' || event.url === '/oplkpimanagement'
                    || event.url === '/file/15' || event.url === '/file/16' || event.url === '/file/17'
                    || event.url === '/market-sheet' || this.router.url === '/segment-sheet')
                    this.mode = ManagementMode.Mersy
                else if (this.router.url === '/branch-excellence-admin' || this.router.url === '/assessment')
                    this.mode = ManagementMode.BranchExcellence
                else
                    this.mode = ManagementMode.BranchReporting

                this.funcGetUserMgmtBranches();
            }
        }
        )

    }
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    changeManagementId() {
        if (this.value !== '-1') {
            const url = this.baseUrl + Utils.getManagementIdAPI();
            this.defaults.managementid = this.value;
            this.http
                .post<string>(url, { 'value': this.defaults.managementid })
                .subscribe(() => {
                    this.defaults.notifyManagementIdChange();
                    this.placeHolder = this.selectedManagementId;
                }, error => Utils.writeLog(this.http, this.baseUrl, error));
        }
    }

    onNo() {
        this.saveService.fireCancelAll();
        this.changeManagementId();
    }

    onSelect(managementid: any) {
        if (!managementid || !managementid.Value || !this.defaults.managementids)
            return;
        this.value = managementid.Value;
        if (this.saveService.isSaveButtonShown())
            this.dialog.showConfirmDialog("Do you want to save the changes? If you select No, all changes will be lost!", "Save", this);
        else
            this.onNo();
    }

    onYes() {
        this.saveService.fireSave();
        this.changeManagementId();
    }

    public hasChild = (_: number, node: MGMTBranchNode) =>
        !!node.Groups && node.Groups.length > 0;

    private setParent(node: MGMTBranchNode, Parent: MGMTBranchNode | undefined) {
        if (node)
            node.Parent = Parent;
        if (node.Groups) {
            node.Groups.forEach((childNode) => {
                this.setParent(childNode, node);
            });
        }
    }

    private checkAllParents(node: MGMTBranchNode) {
        if (node.Parent) {
            const descendants = this.treeControl.getDescendants(node.Parent);
            node.Parent.Indeterminate = descendants.some((child) => child.Selected);
            this.checkAllParents(node.Parent);
        }
    }

    private GetParentsString(node: MGMTBranchNode) {
        if (node.Parent) {
            this.treeControl.expand(node.Parent);
            this.selectedManagementId = node.Parent.GroupName + ' / ' + this.selectedManagementId;
            this.GetParentsString(node.Parent);
        }
    }

    public async itemToggle(checked: boolean, node: MGMTBranchNode, isUserInteraction: boolean = true) {
        this.selectedManagementId = ""
        this.unselectAll();
        this.treeControl.collapseAll();
        node.Selected = checked;

        //Update the values only if they are different/chnaged
        if (this.defaults.managementName != node.GroupName) {
            this.defaults.managementName = node.GroupName;
            this.value = node.Value;
        }
        this.selectedManagementId = node.GroupName

        this.checkAllParents(node);
        //this.selectedManagementId = ''
        this.GetParentsString(node);

        if (isUserInteraction) {
            this.searchString = "";
            this.groupfunction == 'All' && node.GroupFunction.includes('/') ?
                (this.selGrpFuncFrmAll == '' ?
                    this.defaults.groupFunction = node.GroupFunction.split('/')[0] : this.defaults.groupFunction = this.selGrpFuncFrmAll) : null;
            await this.GroupFuncComp.onNo();
            this.onSelect(node)
            this.closeAutocomplete();
        }
        this.selGrpFuncFrmAll = '';

    }

    public selectFirstMgmtId(groupfunctionselected = this.defaults.groupFunction) {
        this.groupfunction = groupfunctionselected;
        this.arrayFiltered = [];
        this.treeControl.dataNodes.forEach((branchNode) => {
            if (branchNode.GroupFunction && this.groupFuncExist(this.groupfunction, branchNode)) {
                if (this.filterNode(branchNode))
                    this.arrayFiltered.push(branchNode);
            }
            if (branchNode.Groups?.length > 0) {
                const alldecendents = this.treeControl.getDescendants(branchNode)
                alldecendents.forEach((node) => {
                    if (node.GroupFunction && this.groupFuncExist(this.groupfunction, node)) {
                        if (this.filterNode(node))
                            this.arrayFiltered.push(node);
                    }
                })
            }
        });
        var matchNode = this.arrayFiltered.length > 0 ? this.arrayFiltered.find(node => node?.Value === this.defaults.managementid) : undefined;
        if (this.arrayFiltered.length > 0) {
            if (matchNode !== undefined) {
                if (this.mode === ManagementMode.BranchExcellence && matchNode.BusinessLevel === 'MANAGEMENT') {
                    const newNode = matchNode.Groups[0]
                    this.itemToggle(true, newNode, true);
                    this.defaults.managementName = matchNode.GroupName
                }
                else {
                    this.itemToggle(true, matchNode, false);
                    this.defaults.managementName = matchNode.GroupName
                }
            }
            else {
                this.unselectAll()
                if (this.mode === ManagementMode.Mersy) { // Is case of being selected one FieldBranch and open any MANAGEMENT section
                    const temp = this.defaults.managementids[0] as any
                    this.setParent(temp, undefined)
                    let node = this.getParentByChild(temp, this.defaults.managementid)
                    if (node && node.Parent) {
                        this.itemToggle(true, node.Parent, false)
                        this.onSelect(node.Parent)
                    }
                }
                else if (this.mode === ManagementMode.BranchReporting) {
                    this.itemToggle(true, this.arrayFiltered[0], true)
                }   
            }
        }
    }

    private unselectAll() {
        this.treeControl.dataNodes.forEach((branchNode) => {
            branchNode.Selected = false;
            branchNode.Indeterminate = false;
            if (branchNode.Groups) {
                const alldecendents = this.treeControl.getDescendants(branchNode)
                alldecendents.forEach((node) => (node.Selected = false, node.Indeterminate = false))
            }
        });
    }

    private itemUnselect(node: MGMTBranchNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => { node.Indeterminate = false; node.Selected = false });
    }

    private itemDisable(checked: boolean, node: MGMTBranchNode) {
        const descendants = this.treeControl.getDescendants(node);
        descendants.forEach(node => node.Disabled = checked);
    }


    public hideLeafNode(node: MGMTBranchNode): boolean {
        return (this.searchString !== '' && new RegExp(this.searchString, 'i').test(node.GroupName) === false)
            || ((this.mode === ManagementMode.BranchReporting || this.mode === ManagementMode.BranchExcellence) && node.BusinessLevel !== 'FIELDBRANCH')
            || (this.mode === ManagementMode.Mersy && node.BusinessLevel !== 'MANAGEMENT');
    }
    public hideLeafNodeselector(selVal, node: MGMTBranchNode): boolean { 
        return this.groupFuncExist(selVal, node) === false
            || ((this.mode === ManagementMode.BranchReporting || this.mode === ManagementMode.BranchExcellence) && node.BusinessLevel !== 'FIELDBRANCH')
            || (this.mode === ManagementMode.Mersy && node.BusinessLevel !== 'MANAGEMENT');
    }
    public groupFuncExist(selVal, node: MGMTBranchNode) {
        if (node.GroupFunction) {
            var GroupFunction = node.GroupFunction;
            if (GroupFunction.includes('/')) {
                const namesArray = GroupFunction.split('/').map(name => name.trim().toLowerCase());

                // Check if the targetName is present in the array
                return namesArray.includes(selVal.toLowerCase());
            } else {
                // If there are no slashes, compare the namesString directly with targetName
                return GroupFunction.trim().toLowerCase() === selVal.toLowerCase();
            }
        }
        return false

    }


    public hideParentNode(node: MGMTBranchNode): boolean {
        if (this.searchString !== '' && node.GroupName.toLowerCase().indexOf(this.searchString.toLowerCase()) !==
            -1
        ) {
            return false
        }
        const descendants = this.treeControl.getDescendants(node)

        if (descendants.some(
            (descendantNode) =>
                descendantNode.GroupName
                    .toLowerCase()
                    .indexOf(this.searchString.toLowerCase()) !== -1 &&
                (((this.mode === ManagementMode.Mersy || this.mode === ManagementMode.BranchExcellence) && descendantNode.BusinessLevel !== 'FIELDBRANCH') || this.mode === ManagementMode.BranchReporting)
        )
        ) {
            return false
        }

        return true
    }
    public hideParentNodeselector(selVal, node: MGMTBranchNode): boolean {

        if (!selVal || (node.GroupFunction && node.GroupFunction.toLowerCase().indexOf(selVal.toLowerCase()) !== -1)) {
            return false;
        }

        const descendants = this.treeControl.getDescendants(node);

        if (descendants.some((descendantNode) => descendantNode.GroupFunction && this.groupFuncExist(selVal, descendantNode))) {
            return false;
        }

        return true;
    }

    public hideParentManagement(node: MGMTBranchNode): boolean {
        const descendants = this.treeControl.getDescendants(node);

        if (descendants.some((descendantNode) =>
            this.mode === ManagementMode.Mersy
                || ((this.mode === ManagementMode.BranchReporting || this.mode === ManagementMode.BranchExcellence) && descendantNode.BusinessLevel === 'FIELDBRANCH'))) {
            return false;
        }

        return true;
    }

    private funcGetUserMgmtBranches() {

        this.Tree_Response_State = 1;
        var temp: any;
        temp = this.defaults.managementids;
        this.TREE_DATA = temp; ///******** */
        this.treeControl.dataNodes = this.TREE_DATA;
        this.dataSource.data = this.TREE_DATA;
        Object.keys(this.dataSource.data).forEach((key) => {
            this.setParent(this.dataSource.data[key], undefined);

        })
        this.groupfunction = this.defaults.groupFunction;

        this.selectFirstMgmtId()
        this.placeHolder = this.selectedManagementId;

    }

    private getSelectedItem() {
        return this.selectedManagementId;
    }
    treeControlFunc() {
        this.searchString === "" ? "" : this.treeControl.expandAll();
    }

    closeAutocomplete() {
        if (this.mgmtAutocomplete) {
            this.mgmtAutocomplete?.closePanel();

        }
    }

    onAutocompleteClosed() {
        this.GroupFuncComp.resetGrpFunctionDefault()
        this.searchString = "";
        this.placeHolder = this.previousPlaceHolder
        this.selectFirstMgmtId()
    }

    onAutocompleteOpened() {
        this.previousPlaceHolder = this.placeHolder
        this.placeHolder = "Search"
    }

    getParentByChild(object: MGMTBranchNode, branchId: string): MGMTBranchNode | undefined {
        if (object.Value === branchId) {
            return object;
        }
        if (object?.Groups) {
            for (const item of object?.Groups) {
                const result = this.getParentByChild(item, branchId);
                if (result !== null && result?.Parent) {
                    return result;
                }
            }
        }
        return undefined
    }

    showExpandIcon(node: MGMTBranchNode) {
        return this.mode === ManagementMode.BranchReporting
            || this.mode === ManagementMode.BranchExcellence
            || (this.mode === ManagementMode.Mersy && !node.Selected && node.Groups.filter(x => x.BusinessLevel !== 'FIELDBRANCH').length > 0)
    }

    filterNode(node: MGMTBranchNode) {
        return (this.mode === ManagementMode.Mersy && node.BusinessLevel !== 'FIELDBRANCH')
            || ((this.mode === ManagementMode.BranchReporting || this.mode === ManagementMode.BranchExcellence)
                    && (node.Groups?.filter(x => x.BusinessLevel === 'FIELDBRANCH') || node.BusinessLevel === 'FIELDBRANCH'))
    }

    async updateGrpFunfrmDrpDwn(node, grpFunc) {
        this.selGrpFuncFrmAll = grpFunc;
        this.itemToggle(true, node)
    }

}
export enum ManagementMode {
    Mersy = 0,
    BranchExcellence = 1,
    BranchReporting = 2
}
