import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { Component } from '@angular/core';

@Component({
    selector: "marketquery-powerbi-report",
    templateUrl: './powerbi-report.component.html',
    styleUrls: ['../../../../style/container-tab-list.component.less']
})

export class MarketQueryPowerBiReportComponent {

    ReportType: string = "";

    constructor(
    ) {

    }

}
