<div class="
    black-checkbox
    new-table-style">

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="MeasuresInfo">

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="pushPopElement(element)">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Measure </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.Name | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="Owner">
            <th mat-header-cell *matHeaderCellDef> Owner </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.Owner | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.Description | bold: dataSource.filter"></p>
            </td>
        </ng-container>


        <ng-container matColumnDef="Kpi">
            <th mat-header-cell *matHeaderCellDef> Kpi </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.KpiDescription ?? measure.KpiName "></p>
                <!-- <ng-select [ngClass]="{'hidden': isEditModeEnabled === false }"
                       class="integratedNgSelect"
                       [items]="stages"
                       bindLabel="Name"
                       bindValue="Id"
                       [clearable]="false"
                       [searchable]="false"
                       [(ngModel)]="lever.ExcellenceStageId"
                       [clearOnBackspace]="false"
                       (ngModelChange)="onChange(lever.WaveExcellenceLeverConfigId)">
            </ng-select> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let measure">

                <button mat-icon-button matTooltip="{{isButtonDisabled(measure.ActionItems) ? 'Max Action Items per measure reached': 'Add Action Item'}}"
                        (click)="onUpsertActionItem(measure, undefined)"
                        disabled="{{isButtonDisabled(measure.ActionItems)}}">
                    <mat-icon>library_add</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Edit Row" (click)="onEditMeasure(measure)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Delete" (click)="onDeleteMeasure(measure)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="actionitems">
            <td mat-cell *matCellDef="let measure" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(measure) ? 'expanded' : 'collapsed'"
                     id="MeasureActionItems">
                    <table mat-table
                           [dataSource]="measure.ActionItems"
                           class="mat-subtable-custom">

                        <ng-container matColumnDef="ItemNo">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let actionItem;index as i;">
                                <p [innerHTML]="(i+1)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.Name | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Description">
                            <th mat-header-cell *matHeaderCellDef> Description </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.Description | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Owner">
                            <th mat-header-cell *matHeaderCellDef> Owner </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.Owner | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Comments">
                            <th mat-header-cell *matHeaderCellDef> Comments </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.Comments | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Deadline">
                            <th mat-header-cell *matHeaderCellDef> Deadline </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.Deadline | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let actionItem">

                                <button mat-icon-button matTooltip="Edit Action Item" (click)="onUpsertActionItem(measure, actionItem)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Delete" (click)="onDeleteActionItem(actionItem)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: subcolumnsToDisplay;" class="subitem"></tr>
                    </table>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let measure; let i = dataIndex; columns: columnsToDisplay;"
            class="item">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['actionitems']" class="row-subitem"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons" matTooltip="Show Action Items">
        expand_more
    </span>
</ng-template>