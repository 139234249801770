import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { DialogService } from '../../services/dialog.service';
import { DatePipe } from '@angular/common';
import { DestroyService } from '../../services/destroyservice';
import { takeUntil } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { UrlSupportPopupComponent } from './urlsupport-popup/urlsupport-popup.component';

@Component({
    selector: 'global-task-list',
    templateUrl: './global-task-list.component.html',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({
                height: '*',
                minHeight: '',
                display: ''
            })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ],
    styleUrls: ['../../style/new-generic-styles.component.less',
        '../../style/tables/table-add-butt-header-orange.less',
        './global-task-list.component.less'],
    providers: [DestroyService]
})
export class GlobalTaskListComponent implements OnInit {
    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatTable) objTable!: MatTable<any>;
    CSRForm!: FormGroup;
    originalPatternDefList: PatternDefinition[] = [];
    modifiedPatternDefList: PatternDefinition[] = [];
    newPatternDefList: PatternDefinition[] = [];
    originalPatternTaskList: PatternTask[] = [];
    modifiedPatternTaskList: PatternTask[] = [];
    newPatternTaskList: PatternTask[] = [];
    isCallSucceed: number = 0;
    subscriptionLanguage: any;
    forbiddenchars = ['/', '\\', ':', '*', '?', '<', '>', '|', '"'];
    minDate: Date = new Date()

    expandedElements = new Set<number>();
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private _formBuilder: FormBuilder,
        private toastrService: ToastrService,
        public defaults: DefaultValuesService,
        private confimDialog: DialogService,
        private dialogForm: MatDialog,
        public datePipe: DatePipe,
        @Self() private readonly destroy$: DestroyService,
        private cdr: ChangeDetectorRef) {
        this.confimDialog.tthis = this;
    }

    columnsToDisplay = ["arrow", "description", "description_ref", "symptomdescription", "symptomdescription_ref", "isglobal", "activehours", "action"];
    subcolumnsToDisplay = ["taskDescription", "taskDescription_ref", "taskhours", "priority", "context", "tags", "actions"];

    ngOnInit(): void {
        this.subscriptionLanguage = this.defaults.languageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.loadHeaders();
            this.getPatternDefinition()
        });
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array([])
        });
        this.loadHeaders();
        this.getPatternDefinition();
    }

    onCommentChange(value: string, index: number) {
        this.CSRForm.value.CSRRows[index].comment = value;
    }

    loadHeaders() {
        if (this.defaults.language.LanguageId === Utils.getDefaultLaguangeID()) {
            this.columnsToDisplay = ["arrow", "description", "symptomdescription", "isglobal", "activehours", "action"];
            this.subcolumnsToDisplay = ["taskDescription", "taskhours", "priority", "context", "tags", "actions"];
        }
        else {
            this.columnsToDisplay = ["arrow", "description", "description_ref", "symptomdescription", "symptomdescription_ref", "isglobal", "activehours", "action"];
            this.subcolumnsToDisplay = ["taskDescription", "taskDescription_ref", "taskhours", "priority", "context", "tags", "actions"];
        }
    }

    getPatternDefinition() {
        Utils.httpGetPatternDefinition(
            this.http,
            this.baseUrl,
            this,
            function (tthis: GlobalTaskListComponent, result: PatternDefinition[]) {
                tthis.originalPatternDefList = result;
                tthis.expandedElements.clear();
                tthis.mapDataToForm(tthis.originalPatternDefList);
            }
            , function (tthis: GlobalTaskListComponent, error: string) {
                tthis.toastrService.error('Cannot Global Task List information. Please, contact with administrator');
            }
        );
    }

    mapDataToForm(data) {
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array([])
        });
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array(data.map((val, index) => this._formBuilder.group({
                patternDefinitionId: new FormControl(val.patternDefinitionId),
                isGlobal: new FormControl(val.isGlobal),
                activeHours: new FormControl(val.activeHours),
                description: new FormControl(val.description),
                symptomDescription: new FormControl(val.symptomDescription),
                description_Ref: new FormControl(val.description_Ref),
                symptomDescription_Ref: new FormControl(val.symptomDescription_Ref),
                isOpenOverlay: new FormControl(false),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
                textAreaId: new FormControl(this.getTextAreaId(index)),
                maxCommentLength: new FormControl(2000),
                patternTasks: this._formBuilder.group({
                    TaskRows: this._formBuilder.array([])
                })
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.CSRForm.get('CSRRows') as FormArray).value;
    }

    mapTasksDataToForm(data, index) {
        const taskRowsControl = (this.CSRForm.get('CSRRows') as FormArray).at(index) as FormGroup;
        taskRowsControl.setControl('patternTasks', this._formBuilder.group({
            TaskRows: this._formBuilder.array(data.map((val, index) => this._formBuilder.group({
                patternTaskId: new FormControl(val.patternTaskId),
                patternId: new FormControl(val.patternId),
                priority: new FormControl(val.priority),
                taskHours: new FormControl(val.taskHours),
                context: new FormControl(val.context),
                tags: new FormControl(val.tags),
                taskDescription: new FormControl(val.taskDescription),
                taskDescription_ref: new FormControl(val.taskDescription_Ref),
                isOpenOverlay: new FormControl(false),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
                textAreaId: new FormControl(this.getTextAreaId(index)),
                maxCommentLength: new FormControl(2000)
            })
            ))
        }));

    }

    initCSRForm(index: number): FormGroup {
        return this._formBuilder.group({
            patternDefinitionId: new FormControl(-1),
            isGlobal: new FormControl(false),
            activeHours: new FormControl(0),
            description: new FormControl(''),
            symptomDescription: new FormControl(''),
            description_Ref: new FormControl(''),
            symptomDescription_Ref: new FormControl(''),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
            isOpenOverlay: new FormControl(false),
            textAreaId: new FormControl(this.getTextAreaId(index)),
            maxCommentLength: new FormControl(2000),
            patternTasks: this._formBuilder.group({
                TaskRows: this._formBuilder.array([])
            })
        });
    }

    initTaskForm(index: number, patternDefinitionId): FormGroup {
        return this._formBuilder.group({
            patternTaskId: new FormControl(-1),
            patternId: new FormControl(patternDefinitionId),
            priority: new FormControl(0),
            taskHours: new FormControl(0),
            context: new FormControl(''),
            tags: new FormControl(''),
            taskDescription: new FormControl(''),
            taskDescription_Ref: new FormControl(''),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
            isOpenOverlay: new FormControl(false),
            textAreaId: new FormControl(this.getTextAreaId(index)),
            maxCommentLength: new FormControl(2000),
        });
    }

    addNewRow() {
        const control = this.CSRForm.get('CSRRows') as FormArray;
        control.insert(0, this.initCSRForm(control.length));
        this.dataSource = new MatTableDataSource(control.value);
        this.expandedElements = new Set(Array.from(this.expandedElements).map(x => x + 1));
        this.refreshTable();
    }

    addNewTaskRow(element: PatternDefinition, index: number) {
        if (!this.expandedElements.has(index)) {
            this.expandedElements.add(index);
            this.onExpand(index)
        }
        const control = (this.CSRForm.get('CSRRows') as FormArray).at(index).get('patternTasks.TaskRows') as FormArray;
        control.insert(0, this.initTaskForm(control.length, element.patternDefinitionId));
        this.dataSource = new MatTableDataSource((this.CSRForm.get('CSRRows') as FormArray).value);
        this.refreshTable();
    }

    resetRow(i) {
        if (this.CSRForm.value.CSRRows[i]?.isNewRow) {
            this.expandedElements = new Set(Array.from(this.expandedElements).map(x => x - 1));
            this.deleteRow(i);
        }
        else {
            this.updateFormPattern(i);
            this.CSRForm.value.CSRRows[i].isEditable = true;
        }
        this.dataSource = new MatTableDataSource((this.CSRForm.get('CSRRows') as FormArray).value);
        this.refreshTable();
    }

    resetTaskRow(index, subindex) {
        if (this.CSRForm.value.CSRRows[index].patternTasks.TaskRows[subindex]?.isNewRow)
            this.deleteTaskRow(index, subindex);
        else {
            this.updateFormPatternTask(index, subindex);
            this.CSRForm.value.CSRRows[index].patternTasks.TaskRows[subindex].isEditable = true;
        }
        this.dataSource = new MatTableDataSource((this.CSRForm.get('CSRRows') as FormArray).value);
        this.refreshTable();
    }

    deleteRow(index) {
        const control = this.CSRForm.get('CSRRows') as FormArray;
        control.removeAt(index);
        this.dataSource = new MatTableDataSource((this.CSRForm.get('CSRRows') as FormArray).value);
        this.refreshTable();
    }

    deleteTaskRow(index, subindex) {
        const control = (this.CSRForm.get('CSRRows') as FormArray).at(index).get('patternTasks.TaskRows') as FormArray;
        control.removeAt(subindex);
        this.dataSource = new MatTableDataSource((this.CSRForm.get('CSRRows') as FormArray).value);
        this.refreshTable();
    }

    updateFormPattern(index) {
        const formArray = this.CSRForm.get('CSRRows') as FormArray;
        const formGroup = formArray.at(index) as FormGroup;
        var updated = this.originalPatternDefList.find(s => s.patternDefinitionId == this.CSRForm.value.CSRRows[index]?.patternDefinitionId);
        formGroup.patchValue({ description: updated?.description });
        formGroup.patchValue({ symptomDescription: updated?.symptomDescription });
        formGroup.patchValue({ isGlobal: updated?.isGlobal });
        formGroup.patchValue({ activeHours: updated?.activeHours ?? 0 });
    }

    updateFormPatternTask(index, subindex) {
        const control = ((this.CSRForm.get('CSRRows') as FormArray).at(index).get('patternTasks.TaskRows')) as FormArray;
        const formGroup = control.at(subindex)
        var updated = this.originalPatternTaskList.find(s => s.patternTaskId == this.CSRForm.value.CSRRows[index].patternTasks.TaskRows[subindex]?.patternTaskId);
        formGroup.patchValue({ taskDescription: updated?.taskDescription });
        formGroup.patchValue({ taskHours: updated?.taskHours });
        formGroup.patchValue({ priority: updated?.priority });
        formGroup.patchValue({ tags: updated?.tags });
    }

    editRow(index) {
        this.CSRForm.value.CSRRows[index].isEditable = false;
    }

    editTaskRow(index, subindex) {
        this.CSRForm.value.CSRRows[index].patternTasks.TaskRows[subindex].isEditable = false;
    }

    refreshTable() {
        this.objTable.renderRows();
    }

    anyChange() {
        return this.mapFormToData();
    }

    mapFormToData() {
        this.modifiedPatternDefList = this.CSRForm.get('CSRRows')?.value.filter(row => !row.isNewRow
            && !row.isEditable
            && row.patternDefinitionId !== -1
            && row.description !== ""
            && row.symptomDescription !== ""
        );

        this.newPatternDefList = this.CSRForm.get('CSRRows')?.value.filter(row => row.isNewRow
            && row.patternDefinitionId === -1
            && row.description !== ""
            && row.symptomDescription !== ""
        );

        const csrRows = this.CSRForm.get('CSRRows') as FormArray;
        let allTaskRows: any[] = [];

        csrRows.controls.forEach((row, index) => {
            const taskRows = (row.get('patternTasks.TaskRows') as FormArray).value;
            allTaskRows = allTaskRows.concat(taskRows);
        });

        this.newPatternTaskList = allTaskRows.filter(row => row.isNewRow
            && row.patternDefinitionId !== -1
            && row.patternTaskId === -1
            && row.taskDescription !== ""
            && row.taskHours >= 1 && row.taskHours <= 8
            && row.priority >= 1 && row.priority <= 3
        );

        this.modifiedPatternTaskList = allTaskRows.filter(row => !row.isNewRow
            && !row.isEditable
            && row.patternDefinitionId !== -1
            && row.patternTaskId !== -1
            && row.taskDescription !== ""
            && row.taskHours >= 1 && row.taskHours <= 8
            && row.priority >= 1 && row.priority <= 3

        );
        return this.modifiedPatternDefList?.length > 0 || this.newPatternDefList?.length > 0
            || this.newPatternTaskList?.length > 0 || this.modifiedPatternTaskList?.length > 0
    }

    changeIsGlobal(index, value: boolean) {
        this.CSRForm.value.CSRRows[index].isGlobal = value
        this.refreshTable();
    }

    getTextAreaId(index) {
        return `tArea_${index}`;
    }

    onOverlayChange(value: string, field: string, index: number, subindex = -1) {
        if (subindex !== -1)
            this.CSRForm.value.CSRRows[index].patternTasks.TaskRows[subindex][field] = value
        else
            this.CSRForm.value.CSRRows[index][field] = value;
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }

    // Collapse / Expand methods
    checkExpanded(i: number) {
        return this.expandedElements.has(i);
    }

    pushPopElement(i: number) {
        if (this.expandedElements.has(i)) {
            this.expandedElements.delete(i);
        }
        else {
            this.expandedElements.add(i);
            this.onExpand(i)
        }
    }

    onExpand(i: number) {
        const patternDefinitionId = this.CSRForm.value.CSRRows[i]?.patternDefinitionId;
        Utils.httpGetPatternTasks(
            this.http
            , this.baseUrl
            , patternDefinitionId
            , this
            , function (tthis: GlobalTaskListComponent, list: PatternTask[]) {
                tthis.originalPatternTaskList = list;
                tthis.mapTasksDataToForm(list, i);
            }, function (tthis: GlobalTaskListComponent, error: string) {
                tthis.toastrService.error('Cannot get Task List for selected Pattern. Please, contact with administrator');
            }
        );
    }

    openUrlSupportPopup(patternTaskId: number) {
        this.dialogForm.open(UrlSupportPopupComponent, {
            data: { "patternTaskId": patternTaskId }

        })
    }

    // Http Requests
    onSave() {
        if (this.anyChange()) {
            if (this.modifiedPatternDefList?.length > 0 || this.newPatternDefList?.length > 0)
                Utils.httpMergePatternDefinition(
                    this.http
                    , this.baseUrl
                    , [...this.newPatternDefList, ...this.modifiedPatternDefList]
                    , this
                    , function (tthis: GlobalTaskListComponent, result: any) {
                        tthis.toastrService.success('Pattern information was updated successfully');
                        tthis.getPatternDefinition();
                        tthis.funcNotify();
                    }, function (tthis: GlobalTaskListComponent, error: string) {
                        tthis.toastrService.error('Cannot save Pattern information. Please, contact with administrator');
                    }
                )

            if (this.newPatternTaskList?.length > 0 || this.modifiedPatternTaskList?.length > 0)
                Utils.httpMergePatternTask(
                    this.http
                    , this.baseUrl
                    , [...this.newPatternTaskList, ...this.modifiedPatternTaskList]
                    , this
                    , function (tthis: GlobalTaskListComponent, result: any) {
                        tthis.toastrService.success('Task Information was updated successfully');
                        tthis.getPatternDefinition();
                        tthis.funcNotify();
                    }, function (tthis: GlobalTaskListComponent, error: string) {
                        tthis.toastrService.error('Cannot save Pattern Task information. Please, contact with administrator');
                    }
                )
        }

    }

    onDelete(patternDefinitionId: number, index: number) {
        Utils.httpDeletePatternDefinition(
            this.http
            , this.baseUrl
            , patternDefinitionId
            , this
            , function (tthis: GlobalTaskListComponent, result: any) {
                tthis.deleteRow(index)
                tthis.toastrService.success('Pattern was deleted successfully');

            }, function (tthis: GlobalTaskListComponent, error: string) {
                tthis.toastrService.error('Cannot delete selected pattern. Please, contact with administrator');
            }
        )

    }

    onDeletePatternTask(patternTaskId: number, index: number, subindex: number) {
        Utils.httpDeletePatternTask(
            this.http
            , this.baseUrl
            , patternTaskId
            , this
            , function (tthis: GlobalTaskListComponent, result: any) {
                tthis.deleteTaskRow(index, subindex)
                tthis.toastrService.success('Pattern Task was deleted successfully');

            }, function (tthis: GlobalTaskListComponent, error: string) {
                tthis.toastrService.error('Cannot delete selected pattern task. Please, contact with administrator');
            }
        )

    }
}
