 <!--The Heading sections (Heading + Serach )-->
<div class="row header-section mt-5">

    <div class="col-lg-6 col-md-9 col-sm-9 col-xs-12">
        <h4>
            Branch Contact
        </h4>
    </div>

    <div class="col-lg-6 col-md-3 col-sm-3 col-xs-12 flex-right" *ngIf="defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser">
        <div class="OrangeButton" style="margin-right: 50px;">
            <button mat-raised-button (click)="openCreateContactPopup()" title="Create new Contact" class="btn btn-sm button-color-fix">
                Create Contact <mat-icon class="add-button icon-color-fix">add_box</mat-icon>
            </button>
        </div>
    </div>

</div>

<div class="">
    <table mat-table #itemsList
           [dataSource]="dataSource"
           multiTemplateDataRows
           class="mat-elevation-z8 mat-table-custom" id="branchcontact">

        <ng-container matColumnDef="ContactName">
            <th mat-header-cell *matHeaderCellDef> Contact Name </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ContactName"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="ContactEmail">
            <th mat-header-cell *matHeaderCellDef> Contact Email </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ContactEmail"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="ContactCategory">
            <th mat-header-cell *matHeaderCellDef> Contact Category </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ContactCategory"></p>
            </td>
        </ng-container>
        <ng-container matColumnDef="ChangedBy">
            <th mat-header-cell *matHeaderCellDef> Changed By </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedBy"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="ChangedAtUtc">
            <th mat-header-cell *matHeaderCellDef> Changed At Utc </th>
            <td mat-cell *matCellDef="let element">
                <p [innerHTML]="element.ChangedAtUtc | date:'yyyy-MM-dd HH:mm'"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element; let i = dataIndex">
                <button mat-icon-button matTooltip="Remove Contact" (click)="onRemoveContact(element.ContactId)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
            class="item">
        </tr>
    </table>
</div>



<div class="OrangeButton" *ngIf="defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser">
    <button (click)="openAddContactPopup()" type="submit" class="btn button-color-fix pull-right">
        Add Contact
        <mat-icon class="icon-color-fix">add</mat-icon>
    </button>
</div>
