import { Component, Inject, Input, OnInit, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Utils } from '../../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CustomDialogService } from '../../../../../services/customdialog.service';
import { ToastrService } from 'ngx-toastr';
import { AssignWaveExcellenceLeverPopupComponent } from './assignwaveexcellencelever-popup/assignwaveexcellencelever-popup.component';
import { ImportExcellenceLeversPopup } from './importexcellencelevers-popup/importexcellencelevers-popup.component';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';

@Component({
    selector: 'waveexcellenceleverconfig',
    templateUrl: './waveexcellenceleverconfig.component.html',
    styleUrls: ['./waveexcellenceleverconfig.component.less',
        '../branchexcellenceadmin.component.less',
        '../../../../../style/new-generic-styles.component.less']
})
export class WaveExcellenceLeverConfigComponent implements OnInit, OnChanges {

    public dataSource = new MatTableDataSource<Wave>();

    @Input() selectedWave: Wave | undefined;
    columnsToDisplay = ['Checkbox', 'ExcellenceLever', 'ExcellenceLever', 'ChangedAtUtc', 'ChangedBy'];

    isRemoveLeverAssignmentEnabled = false;
    removeExcellenceLeverList: number[] = []
    list: any;
    dialog: any;
    constructor(
        private http: HttpClient
        , private dialogForm: MatDialog
        , @Inject('BASE_URL') private baseUrl: string
        , private customDialog: CustomDialogService
        , public defaults: DefaultValuesService
        , private toastrService: ToastrService) { }

    ngOnInit(): void {

    }
    ngOnChanges(): void {
        this.reload()
    }

    reload() {
        if (this.selectedWave && this.selectedWave?.WaveId !== -1)
            Utils.httpGetWaveExcellenceLeverConfigurations(
                this.http,
                this.baseUrl,
                this.selectedWave!.WaveId,
                this,
                function (tthis: WaveExcellenceLeverConfigComponent, list: any) {
                    tthis.dataSource.data = list
                    tthis.removeExcellenceLeverList = [];
                    tthis.isRemoveLeverAssignmentEnabled = false;
                },
                null

            )
    }

    controlAssignedItem(ischecked, row: any | undefined) {
        console.log(row)
        if (row) {
            if (ischecked) {
                this.removeExcellenceLeverList.push(row.ExcellenceLeverId)
            } else {
                const index = this.removeExcellenceLeverList.indexOf(row.ExcellenceLeverId);
                this.removeExcellenceLeverList.splice(index, 1);
            }
        }
        else {
            if (ischecked) {
                this.list.forEach((x: any) => {
                    this.removeExcellenceLeverList.push(x.ExcellenceLeverId)
                })
            } else {
                this.removeExcellenceLeverList = [];
            }
        }
    }

    openAssignExcellenceLeverWave() {
        this.dialog = this.dialogForm.open(AssignWaveExcellenceLeverPopupComponent, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: this.selectedWave
        })
        this.afterAssigningExcellenceLevers();
    }

    openImportExcellenceLevers() {
        this.dialog = this.dialogForm.open(ImportExcellenceLeversPopup, {
            panelClass: ['custom-mat-dialog_v2'],
            disableClose: true,
            data: this.selectedWave
        })

        this.afterAssigningExcellenceLevers();
    }

    private afterAssigningExcellenceLevers() {
        this.dialog.afterClosed().subscribe(() => {
            if (this.selectedWave?.WaveId !== -1)
                Utils.httpGetWaveExcellenceLeverConfigurations(
                    this.http,
                    this.baseUrl,
                    this.selectedWave!.WaveId,
                    this,
                    function (tthis: WaveExcellenceLeverConfigComponent, list: any) {
                        tthis.dataSource.data = list
                    },
                    null

                )
        })
    }
    confirmRemoveExcellenceLevers() {    
        this.customDialog.openConfirm({
            title: "Unassign Excellence Levers",
            message: `Do you want to unassign all selected levers?`,
            caller: this
        });
        this.customDialog.confirmed().subscribe(res => {
            if (res.confirmed) {
                Utils.httpUnassignWaveExcellenceLeverConfigurations(
                    this.http
                    , this.baseUrl
                    , this.selectedWave?.WaveId
                    , this.removeExcellenceLeverList
                    , this
                    , function (tthis: WaveExcellenceLeverConfigComponent, result: any) {
                        if (result) {
                            tthis.reload();
                            tthis.toastrService.success(`Excellence Levers were unassigned successfully!`);
                        }
                        else
                            tthis.toastrService.error(`Excellence Levers could not be unassigned. Please, contact with administrator`);
                    },
                    null
                );
            }
        });
    }


    enableRemoveAssignmentMode() {
        this.isRemoveLeverAssignmentEnabled = !this.isRemoveLeverAssignmentEnabled;
        if (this.isRemoveLeverAssignmentEnabled)
            this.removeExcellenceLeverList = [];
    }     
}
