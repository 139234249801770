import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DefaultValuesService } from '../../../../../services/defaultvalues.service';
import { Utils } from '../../../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { BranchconfigdatesPopup } from './branchconfigdates-popup/branchconfigdates-popup.component';

@Component({
    selector: 'branchconfig',
    templateUrl: './branchconfig.component.html',
    styleUrls: ['./branchconfig.component.less',
        '../branchexcellenceadmin.component.less',
        '../../../../../style/new-generic-styles.component.less']
})
export class BranchConfigComponent implements OnInit {

    public editBranchConfigForm: FormGroup;
    subscriptionManagementId: any;
    subscriptions: any[] = [];
    AllArchetypes: any[] = [];

    data: FieldBranchDetails

    constructor(
        private fb: FormBuilder,
        public defaults: DefaultValuesService,
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private toastrService: ToastrService,
        private matDialog: MatDialog
    ) {
        this.data = {
            MgmtId: '',
            MgmtName: '',
            Currency: '',
            CurrencyShort: '',
            CurrencySymbol: '',
            DecimalDigits: '',
            Dates: undefined
        }
        this.editBranchConfigForm = this.fb.group(
            {
                responsiblePerson: ['', Validators.required],
                archetype: ['', Validators.required],
                dates: undefined
            });
    }

    ngOnInit(): void {
        this.subscriptionManagementId = this.defaults.managementIdMessageChanges$.subscribe(() => {
            this.getBranchDetails()
        });
        if (this.data.MgmtId === '')
            this.getBranchDetails();
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
        this.subscriptionManagementId.unsubscribe();
    }

    getBranchDetails() {
        Utils.httpGetfieldBranchDetails(
            this.http,
            this.baseUrl,
            this,
            function (tthis: BranchConfigComponent, result: any) {
                tthis.data = result;
                tthis.data.Dates = {
                    ActualAssessmentDate: result?.ActualAssessmentDate,
                    ActualCheckInCall_1_Date: result?.ActualCheckInCall_1_Date,
                    ActualCheckInCall_2_Date: result?.ActualCheckInCall_2_Date,
                    ActualCheckInCall_3_Date: result?.ActualCheckInCall_3_Date,
                    ActualCheckInCall_4_Date: result?.ActualCheckInCall_4_Date,
                    ActualValidationCallDate: result?.ActualValidationCallDate,
                    PlannedAssessmentDate: result?.PlannedAssessmentDate,
                    PlannedValidationCallDate: result?.PlannedValidationCallDate
                }
                tthis.AllArchetypes = result.AllArchetypes;
                tthis.editBranchConfigForm.controls['responsiblePerson'].setValue(result.ResponsiblePerson);
                tthis.editBranchConfigForm.controls['archetype'].setValue(result.Archetype);
                tthis.editBranchConfigForm.controls['dates'].setValue(tthis.data.Dates);
            },
            null
        )
    }

    onSave(onlyDates: boolean = false) {
        var formvalues = this.editBranchConfigForm.getRawValue();

        Utils.httpUpdateFieldBranchDetails(
            this.http
            , this.baseUrl
            , formvalues
            , this
            , onlyDates
            , function (tthis: BranchConfigComponent, result: boolean) {
                let objDetail = onlyDates ? 'Dates' : 'Details';
                if (result)
                    tthis.toastrService.success(`Branch ${objDetail} were updated successfully!`);
                else
                    tthis.toastrService.error(`${objDetail} could not be updated. Please, contact with administrator`);
            },
            null
        )
    }

    openSetDatesPopup() {
        const dialogRef = this.matDialog.open(BranchconfigdatesPopup, {
            panelClass: ['custom-mat-dialog_v2', 'min-width-600'],
            data: { ...this.data.Dates },
            disableClose: true,
        });

        dialogRef.componentInstance.datesChange.subscribe((configuredDates: FieldBranchDates) => {
            this.data.Dates = configuredDates;
            this.editBranchConfigForm.controls['dates'].setValue(configuredDates);
            this.onSave(true);
        });
    }
}
