<div style="width:550px">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="form-group col-lg-6">
                <label>Planned Assessment Date</label>
                <div class="col-lg-11" style="position:relative">
                        <input [matDatepicker]="plannedAssessmentDatePicker"
                               [(ngModel)]="data.PlannedAssessmentDate"
                               [min]="minDate"
                               class="form-control"
                               formControlName="plannedAssessmentDateForm"
                               (dateChange)="getDateUTCChange($event, 'plannedAssessmentDateForm')"
                               readonly />
                        <button mat-icon-button *ngIf="form.get('plannedAssessmentDateForm')?.value" matSuffix (click)="clearDate('plannedAssessmentDateForm')" class="clear-icon">
                            <mat-icon>clear</mat-icon>
                        </button>
                </div>
                <div class="col-lg-1" style="padding-left:0">                    
                    <mat-datepicker-toggle matSuffix [for]="plannedAssessmentDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #plannedAssessmentDatePicker></mat-datepicker>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <label>Actual Assessment Date</label>
                <div class="col-lg-11" style="position:relative">
                    <input [matDatepicker]="actualAssessmentDatePicker"
                           [(ngModel)]="data.ActualAssessmentDate"
                           [min]="minDate"
                           class="form-control"
                           formControlName="actualAssessmentDateForm"
                           (dateChange)="getDateUTCChange($event, 'actualAssessmentDateForm')"
                           readonly />
                    <button mat-icon-button *ngIf="form.get('actualAssessmentDateForm')?.value" matSuffix (click)="clearDate('actualAssessmentDateForm')" class="clear-icon">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div class="col-lg-1" style="padding-left:0">
                    <mat-datepicker-toggle matSuffix [for]="actualAssessmentDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #actualAssessmentDatePicker></mat-datepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-6">
                <label>Planned Validation Call Date</label>
                <div class="col-lg-11" style="position:relative">
                    <input [matDatepicker]="plannedValidationCallDatePicker"
                           class="form-control"
                           [(ngModel)]="data.PlannedValidationCallDate"
                           [min]="minDate"
                           formControlName="plannedValidationCallDateForm"
                           (dateChange)="getDateUTCChange($event, 'plannedValidationCallDateForm')"
                           readonly />
                    <button mat-icon-button *ngIf="form.get('plannedValidationCallDateForm')?.value" matSuffix (click)="clearDate('plannedValidationCallDateForm')" class="clear-icon">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div class="col-lg-1" style="padding-left:0">
                    <mat-datepicker-toggle matSuffix [for]="plannedValidationCallDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #plannedValidationCallDatePicker></mat-datepicker>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <label>Actual Validation Call Date</label>
                <div class="col-lg-11" style="position:relative">
                    <input [matDatepicker]="actualValidationCallDatePicker"
                           class="form-control"
                           [(ngModel)]="data.ActualValidationCallDate"
                           [min]="minDate"
                           formControlName="actualValidationCallDateForm"
                           (dateChange)="getDateUTCChange($event, 'actualValidationCallDateForm')"
                           readonly />
                    <button mat-icon-button *ngIf="form.get('actualValidationCallDateForm')?.value" matSuffix (click)="clearDate('actualValidationCallDateForm')" class="clear-icon">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div class="col-lg-1" style="padding-left:0">
                    <mat-datepicker-toggle matSuffix [for]="actualValidationCallDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #actualValidationCallDatePicker></mat-datepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-6">
                <label>Actual Check-in Call 1 Date</label>
                <div class="col-lg-11" style="position:relative">
                    <input [matDatepicker]="actualCheckInCall_1_DatePicker"
                           class="form-control"
                           [(ngModel)]="data.ActualCheckInCall_1_Date"
                           [min]="minDate"
                           formControlName="actualCheckInCall_1_DateForm"
                           (dateChange)="getDateUTCChange($event, 'actualCheckInCall_1_DateForm')"
                           readonly />
                    <button mat-icon-button *ngIf="form.get('actualCheckInCall_1_DateForm')?.value" matSuffix (click)="clearDate('actualCheckInCall_1_DateForm')" class="clear-icon">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div class="col-lg-1" style="padding-left:0">
                    <mat-datepicker-toggle matSuffix [for]="actualCheckInCall_1_DatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #actualCheckInCall_1_DatePicker></mat-datepicker>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <label>Actual Check-in Call 2 Date</label>
                <div class="col-lg-11" style="position:relative">
                    <input [matDatepicker]="actualCheckInCall_2_DatePicker"
                           class="form-control"
                           [(ngModel)]="data.ActualCheckInCall_2_Date"
                           [min]="minDate"
                           formControlName="actualCheckInCall_2_DateForm"
                           (dateChange)="getDateUTCChange($event, 'actualCheckInCall_2_DateForm')"
                           readonly />
                    <button mat-icon-button *ngIf="form.get('actualCheckInCall_2_DateForm')?.value" matSuffix (click)="clearDate('actualCheckInCall_2_DateForm')" class="clear-icon">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div class="col-lg-1" style="padding-left:0">
                    <mat-datepicker-toggle matSuffix [for]="actualCheckInCall_2_DatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #actualCheckInCall_2_DatePicker></mat-datepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-lg-6">
                <label>Actual Check-in Call 3 Date</label>
                <div class="col-lg-11" style="position:relative">
                    <input [matDatepicker]="actualCheckInCall_3_DatePicker"
                           class="form-control"
                           [(ngModel)]="data.ActualCheckInCall_3_Date"
                           [min]="minDate"
                           formControlName="actualCheckInCall_3_DateForm"
                           (dateChange)="getDateUTCChange($event, 'actualCheckInCall_3_DateForm')"
                           readonly />    
                    <button mat-icon-button *ngIf="form.get('actualCheckInCall_3_DateForm')?.value" matSuffix (click)="clearDate('actualCheckInCall_3_DateForm')" class="clear-icon">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div class="col-lg-1" style="padding-left:0">
                    <mat-datepicker-toggle matSuffix [for]="actualCheckInCall_3_DatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #actualCheckInCall_3_DatePicker></mat-datepicker>
                </div>
            </div>
            <div class="form-group col-lg-6">
                <label>Actual Check-in Call 4 Date</label>
                <div class="col-lg-11" style="position:relative">
                    <input [matDatepicker]="actualCheckInCall_4_DatePicker"
                           class="form-control"
                           [(ngModel)]="data.ActualCheckInCall_4_Date"
                           [min]="minDate"
                           formControlName="actualCheckInCall_4_DateForm"
                           (dateChange)="getDateUTCChange($event, 'actualCheckInCall_4_DateForm')"
                           readonly />
                    <button mat-icon-button *ngIf="form.get('actualCheckInCall_4_DateForm')?.value" matSuffix (click)="clearDate('actualCheckInCall_4_DateForm')" class="clear-icon">
                        <mat-icon>clear</mat-icon>
                    </button>
                </div>
                <div class="col-lg-1" style="padding-left:0">
                    <mat-datepicker-toggle matSuffix [for]="actualCheckInCall_4_DatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #actualCheckInCall_4_DatePicker></mat-datepicker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="panel-body">
                <button (click)="backButtonHandler($event)" mat-raised-button class="btn  button-color-fix_red ">
                    <mat-icon class=" icon-color-fix ">arrow_back_ios</mat-icon>
                    Back
                </button>
                <button mat-raised-button type="submit" class="btn button-color-fix pull-right">
                    Save
                    <mat-icon class="icon-color-fix">save</mat-icon>
                </button>

            </div>
        </div>
    </form>
</div>