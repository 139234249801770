<div class="black-checkbox container-tab-lists">
    <h4>{{title}}</h4>
    <div class="inner-tcontainer-tab">

        <div class="tab-body">
            <div class="tab-content">

                <div class="container-expansions">

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                BusinessKeys
                            </mat-panel-title>
                            <mat-panel-description>
                                Deployment of BusinessKeys and DebriefingItems if selected
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <deploybusinesskeys [deployment]="deployment"></deploybusinesskeys>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                ControllerTypes Options
                            </mat-panel-title>
                            <mat-panel-description>
                                Deployment of ControllerConfig, ControllerTypeByTenant, ControllerTypeToMasterdataRequired, Default Transmission Modes and Alert Window Configuration if selected
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <deploycontrollertypes [deployment]="deployment"></deploycontrollertypes>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Remote Actions
                            </mat-panel-title>
                            <mat-panel-description>
                                Deployment of all Remote Actions configuration
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-checkbox [(ngModel)]="deployment.RemoteActions">Set to deploy Remote Actions to repo?</mat-checkbox>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Error Codes &amp; Operation Modes
                            </mat-panel-title>
                            <mat-panel-description>
                                Deployment of the selected Error Codes and Operation Modes
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <deployerrorcode-opmode [deployment]="deployment"></deployerrorcode-opmode>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Branch Hierarchy
                            </mat-panel-title>
                            <mat-panel-description>
                                Deploy all branch hierarchy configuration
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-checkbox [(ngModel)]="deployment.BranchHierarchy">Set to deploy Branch Hierarchy to repo?</mat-checkbox>

                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                EMT - MFG Configuration
                            </mat-panel-title>
                            <mat-panel-description>
                                Deploy of EMT / MFG Configuration
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-checkbox [(ngModel)]="deployment.EMTMFGConfiguration">Set to Deploy to repo?</mat-checkbox>

                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Customer Portal
                            </mat-panel-title>
                            <mat-panel-description>
                                Deploy all the selected data in all languages to the Customer Portal
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <mat-checkbox [(ngModel)]="deployment.CustomerPortal">Set to Deploy to CustomerPortal?</mat-checkbox>

                    </mat-expansion-panel>

                </div>

                <div class="mt-2">
                    <button mat-icon-button (click)="onDeploy()" matTooltip="Deploy the selected data">
                        <mat-icon>cloud_upload</mat-icon>
                        Deploy
                    </button>
                </div>

            </div> <!--tab-content-->
        </div> <!--tab-body-->

    </div> <!--inner-tcontainer-tab-->
</div> <!--container-tab-lists-->
