import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DefaultValuesService } from "../../../services/defaultvalues.service";
import { DialogService } from "../../../services/dialog.service";
import { BuildReleaseType, Utils } from "../../../utils/utils";
import { CustomDialogService } from "../../../services/customdialog.service";

@Component({
    selector: "controllertypes-deploymentstatus-popup",
    templateUrl: './controllertypes-deploymentstatus-popup.component.html',
    styleUrls: ['../../../style/container-tab-list.component.less', '../../../style/custom-mat-dialog.component.less']
})

export class ControllerTypesDeploymentStatusPopup {

    columnsToDisplay = ['environment', 'status', 'infoupdateddate'];
    public dataSource = new MatTableDataSource<EventArchiveStatus>();

    public deploymentStatusInfo: EventArchiveStatus[];
    buildRelease: BuildRelease | undefined
    environments: EnvironmentObject[] | undefined

    prodEnvironment!: EventArchiveStatus

    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , public defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<ControllerTypesDeploymentStatusPopup>
        , @Inject(MAT_DIALOG_DATA) public data: any
        , private dialog: MatDialog
        , private customDialog: CustomDialogService
        ,
    ) {
        this.deploymentStatusInfo = []
    }

    ngOnInit() {
        this.refreshInformation()
    }

    onBack() { }


    onNoClick(): void {
        this.dialogRef.close();
    }

    public sinceDays(date: Date) {
        let currentDate = new Date();

        let dateDeployment = new Date(date);
        return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
            - Date.UTC(dateDeployment.getFullYear(), dateDeployment.getMonth(), dateDeployment.getDate())) / (1000 * 60 * 60 * 24));
    }

    onClickDeploy() {
        Utils.httpCreateBuildByControllerTypeAndInformationType(
            this.http
            , this.baseUrl
            , this
            , this.data.component === 'opmodes' ? BuildReleaseType.OperationModes : BuildReleaseType.ErrorCodes
            , function (tthis: ControllerTypesDeploymentStatusPopup, data: boolean) {
                if (data === true) {
                    tthis.customDialog.openDisplayInfoDialog({ title: 'Build started', message: 'Please wait until build finishes for releasing it into desired environment. Use refresh button to check whether build is generated or not.' });
                    tthis.refreshInformation();
                }
                else
                    tthis.customDialog.openDisplayInfoDialog({ title: 'Error', message: 'Error executing deployment. Please, contact DevOps team.' });
            }
        );
    }

    refreshInformation() {
        Utils.httpGetAllDeploymentStatusByControllerType(     // Getting all deployment statuses by environment
            this.http
            , this.baseUrl
            , this
            , function (tthis: ControllerTypesDeploymentStatusPopup, values: EventArchiveStatus[]) {
                tthis.deploymentStatusInfo = values
                Utils.httpGetLastBuildIdByInformationType(   // Get last build by information Type 
                    tthis.http
                    , tthis.baseUrl
                    , tthis
                    , tthis.data.component === 'opmodes' ? BuildReleaseType.OperationModes : BuildReleaseType.ErrorCodes
                    , function (tthis: ControllerTypesDeploymentStatusPopup, buildRelease: BuildRelease) {

                        tthis.buildRelease = buildRelease
                        if (tthis.buildRelease && tthis.buildRelease.BuildId !== -1)
                            Utils.httpGetReleaseByControllerTypeAndInformationType(                // Get release in case of being generated by build id
                                tthis.http
                                , tthis.baseUrl
                                , tthis
                                , tthis.data.component === 'opmodes' ? BuildReleaseType.OperationModes : BuildReleaseType.ErrorCodes
                                , function (tthis: ControllerTypesDeploymentStatusPopup, data: ReleaseInformation) {

                                    tthis.environments = data.Environments
                                    if (tthis.buildRelease) tthis.buildRelease.ReleaseId = data.ReleaseId
                                    tthis.columnsToDisplay = ['environment', 'status', 'infoupdateddate', 'environmentdeploymentStatus'];
                                    tthis.deploymentStatusInfo?.forEach(x => {
                                        tthis.environments?.forEach(y => {
                                            if (y.name.toUpperCase() === x.Environment.toUpperCase()) {
                                                x.EnvironmentDeploymentStatus = y.status
                                                x.ReleaseEnvironmentId = y.id
                                                // In case of currently being deployed dev environment
                                                if (y.name.toUpperCase() === 'STABLE' && y.status.toUpperCase() === 'NOTSTARTED')
                                                    x.EnvironmentDeploymentStatus = 'inProgress'
                                            }
                                        })
                                    })

                                    tthis.prodEnvironment = tthis.deploymentStatusInfo.filter(x => x.Environment.toUpperCase() == 'EA')[0]
                                    tthis.dataSource.data = tthis.deploymentStatusInfo
                                }
                            );
                        else {
                            tthis.columnsToDisplay = ['environment', 'status', 'infoupdateddate'];
                            tthis.dataSource.data = tthis.deploymentStatusInfo
                        }
                    }
                );
            }
        );
    }

    releaseEnvironment(envId: number) {
        Utils.httpReleaseEnvironmentByControllerTypeAndInformationType(
            this.http
            , this.baseUrl
            , this
            , this.data.component === 'opmodes' ? BuildReleaseType.OperationModes : BuildReleaseType.ErrorCodes
            , envId
            , function (tthis: ControllerTypesDeploymentStatusPopup, data: boolean) {
                if (data === true) {
                    tthis.customDialog.openDisplayInfoDialog({ title: 'Release started', message: 'Please wait until deployment finishes. Use refresh button to update the information.' });
                    tthis.refreshInformation();
                }
                else
                    tthis.customDialog.openDisplayInfoDialog({ title: 'Error', message: 'Error executing deployment. Please, contact DevOps team.' });

            }
        );
    }
}