import { Component, EventEmitter, Input, OnInit, Output, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { EquipmentManagenementService } from 'src/app/services/equipmentmanagement.service';
import { SaveService } from 'src/app/services/save.service';
import { ConfigurationKeyEnumType, Utils } from '../../../utils/utils';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { takeUntil } from 'rxjs';
import { DestroyService } from 'src/app/services/destroyservice';

@Component({
    selector: 'emtconfiguration-list',
    templateUrl: './emtconfiguration-list.component.html',
    styleUrls: ['./emtconfiguration-list.component.less', '../../../style/new-generic-styles.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [DestroyService]
})
export class EmtconfigurationListComponent implements OnInit {
    @Input() isRowEditMode = false;

    selectedLevel?: EquipmentConfigurationLevel;
    configurations: LevelEquipmentConfiguration[] = [];
    clonedList: { [s: string]: LevelEquipmentConfiguration; } = {};

    defaultColumnsToDisplay: Map<string, string[]>;
    columnsToDisplay: string[] = [];
    editableColumn: string;
    ENVIRONMENT = "environment";
    TENANT = "tenant";
    BRANCH = "branch";
    DEVICE = "device";

    configForm!: FormGroup;

    @Output() onRefresh = new EventEmitter<{}>();
    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

    enumSelectors: SelectList[] = [];
    subscriptionStage: any;
    constructor(
        private emtConfigurationService: EquipmentManagenementService,
        private toastrService: ToastrService,
        private saveService: SaveService,
        private formBuilder: FormBuilder,
        public defaults: DefaultValuesService,
        @Self() private readonly destroy$: DestroyService
    ) {
        this.editableColumn = this.TENANT;
        this.defaultColumnsToDisplay = new Map<string, string[]>();

        this.defaultColumnsToDisplay.set(
            this.ENVIRONMENT,
            ['warning_icon', 'key', 'type', 'environment', 'actions']
        );
        this.defaultColumnsToDisplay.set(
            this.TENANT,
            ['warning_icon', 'key', 'type', 'tenant', 'environment', 'actions']
        );

        this.defaultColumnsToDisplay.set(
            this.BRANCH,
            ['warning_icon', 'key', 'type', 'branch', 'tenant', 'environment', 'actions']
        );

        this.defaultColumnsToDisplay.set(
            this.DEVICE,
            ['warning_icon', 'key', 'type', 'device', 'branch', 'tenant', 'environment', 'actions']
        );

        this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.DEVICE)!;

        this.configForm = this.formBuilder.group({
            configRows: this.formBuilder.array([])
        });
    }

    ngOnInit() {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (this.selectedLevel) {
                this.loadEquipmentManagementConfiguration();
            }
        });
        this.resetData();

        this.enumSelectors = Object.keys(ConfigurationKeyEnumType)
            .filter(key => !isNaN(Number(ConfigurationKeyEnumType[key])))
            .map(key => ({
                value: `${ConfigurationKeyEnumType[key]}`,
                text: Utils.ConfigurationKeyEnumTypeDescriptions[ConfigurationKeyEnumType[key]]
            }));

        if (this.selectedLevel) {
            this.loadEquipmentManagementConfiguration();
        }

        this.getHeader();
    }
    ngAfterViewInit() {
    }

    getEnumText(value: string) {
        return this.enumSelectors.find(x => x.value === value)?.text;
    }

    resetData() {
        this.configForm = this.formBuilder.group({
            configRows: this.formBuilder.array([])
        });
        this.configForm.reset();
        this.dataSource.data = [];
    }

    loadEquipmentManagementConfiguration() {
        this.emtConfigurationService.searchEquipmentConfiguration(
            this,
            this.selectedLevel!,
            result => {
                this.configurations = result;
                this.mapConfigurationToForm();
                this.dataSource = new MatTableDataSource((this.configForm.get('configRows') as FormArray).controls);
            },
            error => {
                this.toastrService.error("Unable to load equipment configuration");
            }
        );
    }

    getHeader() {
        if (!this.selectedLevel) {
            this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.ENVIRONMENT)!;
            this.editableColumn = this.ENVIRONMENT;
            return;
        }

        if (this.emtConfigurationService.isEnvironment(this.selectedLevel)) {
            this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.ENVIRONMENT)!;
            this.editableColumn = this.ENVIRONMENT;
        }
        else if (this.emtConfigurationService.isTenant(this.selectedLevel)) {
            this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.TENANT)!;
            this.editableColumn = this.TENANT;
        }
        else if (this.emtConfigurationService.isBranch(this.selectedLevel)) {
            this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.BRANCH)!;
            this.editableColumn = this.BRANCH;
        }
        else if (this.emtConfigurationService.isDevice(this.selectedLevel)) {
            this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.DEVICE)!;
            this.editableColumn = this.DEVICE;
        }
        else {
            this.columnsToDisplay = this.defaultColumnsToDisplay.get(this.ENVIRONMENT)!;
            this.editableColumn = this.ENVIRONMENT;
        }
    }

    changeSelectedLevel(newLevel: EquipmentConfigurationLevel) {
        this.resetData();
        this.selectedLevel = newLevel;
        this.getHeader();
        this.loadEquipmentManagementConfiguration();
    }

    onRowEditInit(index: number) {
        var configRows = this.configForm.get('configRows') as FormArray;
        var row = configRows.at(index);
        row.get('OnEdit')?.patchValue(true);
    }

    onRowEditCancel(index: number) {
        var previousRow = this.configurations.find(x => x.configurationKey.id == this.configForm.value.configRows[index].Id);
        this.updateFormFromElement(index, previousRow!);
        this.updateFormElement(index, 'OnEdit', false);
    }

    updateFormFromElement(index: number, element: LevelEquipmentConfiguration) {
        switch (this.editableColumn) {
            case this.DEVICE:
                this.updateFormElement(index, 'DeviceValue', element.deviceValue);
                break;
            case this.BRANCH:
                this.updateFormElement(index, 'BranchValue', element.branchValue);
                break;
            case this.TENANT:
                this.updateFormElement(index, 'TenantValue', element.tenantValue);
                break;
            case this.ENVIRONMENT:
                this.updateFormElement(index, 'EnvironmentValue', element.environmentValue);
                break;
        }
    }
    updateFormElement(index: number, fieldName: string, newValue: any) {
        var configRows = this.configForm.get('configRows') as FormArray;
        var row = configRows.at(index);
        const isBoolType = row.get('Type')?.value === 'bool';
        row.get(fieldName)?.setValue(isBoolType && typeof newValue === 'string' ? this.stringToBoolean(newValue) : newValue);
    }

    updateElementsByForm(modifiedFormRows: any[]) {
        modifiedFormRows.forEach(row => {
            var relatedElement = this.configurations.filter(config => config.configurationKey.id === row.Id)[0];
            switch (this.editableColumn) {
                case this.DEVICE:
                    relatedElement.deviceValue = row.DeviceValue;
                    break;
                case this.BRANCH:
                    relatedElement.branchValue = row.BranchValue
                    break;
                case this.TENANT:
                    relatedElement.tenantValue = row.TenantValue
                    break;
                case this.ENVIRONMENT:
                    relatedElement.environmentValue = row.EnvironmentValue;
                    break;
            }
        });
    }

    onShowSavebutton() {
        this.saveService.showSaveButton();
    }
    onSave() {
        let updateRequest: UpdateEquipmentConfigurationValueRequest[] = [];
        var modifiedRows = this.configForm.value.configRows.filter(x => x.OnEdit);
        const requestLevel: EquipmentConfigurationLevel = {
            environment: this.selectedLevel!.environment,
            tenantId: this.selectedLevel?.tenantId,
            branchNumber: this.selectedLevel?.branchNumber,
            deviceId: this.selectedLevel?.deviceId
        };
        modifiedRows.forEach(row => {
            var newValue;
            switch (this.editableColumn) {
                case this.DEVICE:
                    newValue = row.DeviceValue;
                    break;
                case this.BRANCH:
                    newValue = row.BranchValue
                    break;
                case this.TENANT:
                    newValue = row.TenantValue
                    break;
                case this.ENVIRONMENT:
                    newValue = row.EnvironmentValue
                    break;
            }
            const newRequest: UpdateEquipmentConfigurationValueRequest = {
                level: requestLevel,
                componentFunctionType: row.Id,
                configurationValue: newValue
            }
            updateRequest.push(newRequest);
        });

        if (updateRequest.length > 0) {
            this.emtConfigurationService.updateEquipmentConfiguration(
                updateRequest,
                response => {
                    this.updateElementsByForm(modifiedRows);
                    modifiedRows.forEach(row => row.OnEdit = false);
                    this.toastrService.success('Configuration changes saved.');
                },
                error => {
                    this.toastrService.error(error.error);
                });
        }
    }

    anyChange() {
        return this.configForm.value.configRows.filter(x => x.OnEdit).length > 0;
    }

    mapConfigurationToForm() {
        this.configForm = this.formBuilder.group({
            configRows: this.formBuilder.array(this.configurations.map(configKeyValue => {
                const isBoolType = configKeyValue.configurationKey.type === 'bool';
                return this.formBuilder.group({
                    Id: new FormControl(configKeyValue.configurationKey.id),
                    IsStatic: new FormControl(configKeyValue.configurationKey.isStatic),
                    Key: new FormControl(configKeyValue.configurationKey.key),
                    Type: new FormControl(configKeyValue.configurationKey.type),                   
                    EnvironmentValue: new FormControl(isBoolType && typeof configKeyValue.environmentValue === 'string' ? this.stringToBoolean(configKeyValue.environmentValue) : configKeyValue.environmentValue, this.editableColumn == this.ENVIRONMENT ? this.getConfigurationKeyValidators(configKeyValue) : []),
                    DeviceValue: new FormControl(isBoolType && typeof configKeyValue.deviceValue === 'string' ? this.stringToBoolean(configKeyValue.deviceValue) : configKeyValue.deviceValue, this.editableColumn == this.DEVICE ? this.getConfigurationKeyValidators(configKeyValue) : []),
                    BranchValue: new FormControl(isBoolType && typeof configKeyValue.branchValue === 'string' ? this.stringToBoolean(configKeyValue.branchValue) : configKeyValue.branchValue, this.editableColumn == this.BRANCH ? this.getConfigurationKeyValidators(configKeyValue) : []),
                    TenantValue: new FormControl(isBoolType && typeof configKeyValue.tenantValue === 'string' ? this.stringToBoolean(configKeyValue.tenantValue) : configKeyValue.tenantValue, this.editableColumn == this.TENANT ? this.getConfigurationKeyValidators(configKeyValue) : []),
                    OnEdit: new FormControl(false)
                });
            }))
        });
    }

    getConfigurationKeyValidators(configurationValue: LevelEquipmentConfiguration) {
        return (control: AbstractControl): ValidationErrors | null => {

            if (configurationValue.configurationKey.key === 'int') {
                return minValidator(0)(control);
            }
            return null;
        };
    }

    getFormValue(i: number) {
        return this.configForm?.get('configRows')?.value[i];
    }

    stringToBoolean(value: string): boolean {
        return value?.toLowerCase() === 'true';
    }

    showWarning(i: number): boolean {
        let row = this.configForm.get('configRows')?.value[i];

        if (row.IsStatic)
            return false;

        let active = false;
        switch (this.editableColumn) {
            case this.ENVIRONMENT:
                return !row.EnvironmentValue;
            case this.TENANT:
                return !row.TenantValue;
            case this.BRANCH:
                return !row.BranchValue;
            case this.DEVICE:
                return !row.DeviceValue;
            default:
                return false;
        }
    }
}

export const minValidator = function (min: number) {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (value !== null && (isNaN(value) || value < min)) {
            return {
                minError: `Value must be greater than ${min}`
            };
        }
        return null;
    }
}

