 <!--User Details-->
<div class="panel-body row">
    <h4>Update Branch Details</h4>
</div>

<form [formGroup]="editBranchConfigForm" autocomplete="off" (ngSubmit)="onSave()">
    <div class="row">
        <div class="form-group col-lg-6">
            <label class="col-lg-4 control-label ">Branch Id:</label>
            <div class="col-lg-8 ">
                <p>{{data.MgmtId}}</p>
                <p>
            </div>
        </div>
        <div class="form-group col-lg-6">
            <label class="col-lg-4 control-label ">Branch Name:</label>
            <div class="col-lg-8 ">
                <p>{{data.MgmtName}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-lg-3">
            <label class="col-lg-4 control-label">Currency:</label>
            <div class="col-lg-8 ">
                <p>{{data.Currency}}</p>
            </div>
        </div>
        <div class="form-group col-lg-3">
            <label class="col-lg-4 control-label">Currency Short:</label>
            <div class="col-lg-8 ">
                <p>{{data.CurrencyShort}}</p>
            </div>
        </div>
        <div class="form-group col-lg-3">
            <label class="col-lg-4 control-label">Currency Symbol:</label>
            <div class="col-lg-8 ">
                <p>{{data.CurrencySymbol}}</p>
            </div>
        </div>
        <div class="form-group col-lg-3">
            <label class="col-lg-4 control-label">Decimals:</label>
            <div class="col-lg-8 ">
                <p>{{data.DecimalDigits}}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-lg-6 zoomIn">
            <label class="col-lg-4 control-label">Responsible<span class="orange-asterisk"> *</span></label>
            <div class="col-lg-8">
                <input class="form-control" formControlName="responsiblePerson" required>
                <span class="field-validation-valid text-danger" data-valmsg-for="responsible" data-valmsg-replace="true"></span>
                <p *ngIf="
                      editBranchConfigForm.get('responsiblePerson')?.invalid &&
                        (editBranchConfigForm.get('responsiblePerson')?.dirty ||
                          editBranchConfigForm.get('responsiblePerson')?.touched)">
                    <mat-error *ngIf="editBranchConfigForm.get('responsiblePerson')?.hasError('required')">Responsible is required</mat-error>
                </p>
                <p *ngIf="!(
                      editBranchConfigForm.get('responsiblePerson')?.invalid &&
                        (editBranchConfigForm.get('responsiblePerson')?.dirty ||
                          editBranchConfigForm.get('responsiblePerson')?.touched))"><br> </p>
            </div>
        </div>
        <div class="form-group col-lg-6 zoomIn">
            <label class="col-lg-4 control-label">Archetype<span class="orange-asterisk"> *</span></label>
            <div class="col-lg-8 ngSelectGrayOrange">
                <ng-select 
                           [items]="AllArchetypes"
                           bindLabel="Description"
                           bindValue="Id"
                           formControlName="archetype"
                           [clearable]="false"
                           [clearOnBackspace]="false">
                </ng-select>
                <span class="field-validation-valid text-danger" data-valmsg-for="archetype" data-valmsg-replace="true"></span>
                <p *ngIf="
                      editBranchConfigForm.get('archetype')?.invalid &&
                        (editBranchConfigForm.get('archetype')?.dirty ||
                          editBranchConfigForm.get('archetype')?.touched)">
                    <mat-error *ngIf="editBranchConfigForm.get('archetype')?.hasError('required')">Archetype is required</mat-error>
                </p>
                <p *ngIf="!(
                              editBranchConfigForm.get('archetype')?.invalid &&
                                (editBranchConfigForm.get('archetype')?.dirty ||
                                  editBranchConfigForm.get('archetype')?.touched))"><br> </p>
            </div>
        </div>
    </div>
    <div class="panel-body row button-container zoomIn" *ngIf="defaults.isMersyAdminBranchExcellenceUser || defaults.isMersyReporterBranchExcellenceUser">
        <div class="OrangeButton">
            <button mat-raised-button type="submit" class="btn right-buttons button-color-fix pull-right " [disabled]="!editBranchConfigForm.valid">
                Save
                <mat-icon class="icon-color-fix">save</mat-icon>
            </button>
        </div>
        <div>
            <button type="button" mat-raised-button (click)="openSetDatesPopup()" title="Configure Dates" class="btn btn-purple pull-right">
                Configure Dates <mat-icon class="icon-fix">date_range</mat-icon>
            </button>
        </div>
    </div>

</form>

