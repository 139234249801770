import { Component, Inject, Input, OnInit, OnChanges, EventEmitter, Output, AfterViewInit, ViewChildren, ElementRef, QueryList, AfterViewChecked } from "@angular/core";
import { DialogService } from "../../../../services/dialog.service";
import { HttpClient } from "@angular/common/http";
import { DefaultValuesService } from "../../../../services/defaultvalues.service";
import { MatDialog } from "@angular/material/dialog";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { APICallStatus, Utils } from "../../../../utils/utils";
import { KpiDetailFormComponent } from "../../kpimanagement/kpidetail-form.component";



@Component({
    selector: "segment-sheet-list",
    templateUrl: './segment-sheet-list.component.html',
    styleUrls: ['./segment-sheet-list.component.less',
    '../style/market-query.less',
        '../../../../style/new-generic-styles.component.less'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', })),
            transition('expanded => collapsed', [animate('0ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
        ]),
    ]

})

export class SegmentSheetListComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {
    @Input('list') list: KpiManagement[] = [];
    @Input('auxFilterPredicate') auxFilterPredicate!: (filters: string, data: any, parentLevel: boolean, showAllEnabled) => boolean;
    @Input('isEditModeEnabled') isEditModeEnabled: boolean = false;
    @Input('isShowAllEnabled') isShowAllEnabled: boolean = false;
    @Input('inclusiveMode') inclusiveMode: boolean = false;
    @Input('selectorFilter') selectorFilter = '0';
    @Input('selectorKpiUnitFilter') selectorKpiUnitFilter = '0';
    @Input('filter') filter = '';

    @Input('isRevokeActive') isRevokeActive: boolean = false;
    @Input('isReleaseActive') isReleaseActive: boolean = false;
    @Output('onUpdateSelectorKpiUnit') onUpdateSelectorKpiUnit = new EventEmitter<{}>();

    @Output() onClickReleaseMode: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onClickRevokeMode: EventEmitter<boolean> = new EventEmitter<boolean>();
    columnsToDisplay = ['arrow', 'Account', 'KpiName', 'KpiUnit', 'LocalCurrency'];

    @ViewChildren('stickyColumn') stickyColumns?: QueryList<ElementRef>;
    @ViewChildren('stickyColumnData') stickyColumnsData?: QueryList<ElementRef>;

    public dataSource = new MatTableDataSource<KpiManagement>();

    expandedElements = new Set();
    isAllCollapsed = false;
    Filter = 0;

    updatedItems: KpiManagementUpdate[] = []
    releaseList: selectedReleaseRow[] = []

    selection = new SelectionModel<UserSummary>(true, []);

    currentFY: string | undefined;
    FY1: string | undefined;
    FY2: string | undefined;
    FY3: string | undefined;

    tableResponseState: APICallStatus = APICallStatus.NotRequested;

    constructor(
        private http: HttpClient
        , private dialogService: DialogService
        , private defaults: DefaultValuesService
        , @Inject('BASE_URL') private baseUrl: string
        , private dialogForm: MatDialog

    ) {
    }

    ngOnChanges(changes: any) {
        this.getFiscalYearHeaders()
        if (changes.list) {
            this.dataSource.data = this.list;
        }

        this.updatedItems = [];

        if (changes.filter || changes.inclusiveFilter || changes.selectorFilter) {
            this.dataSource.filter = this.filter;
        }

        if (this.selectorKpiUnitFilter === '1')
            this.columnsToDisplay = ['arrow', 'Account', 'KpiName', 'KpiUnit'];
        else
            this.columnsToDisplay = ['arrow', 'Account', 'KpiName', 'KpiUnit', 'LocalCurrency'];
    }

    ngOnInit() {
        this.dialogService.tthis = this;
        this.dataSource.filterPredicate = (data: KpiManagement, filters: string) => {
            if (this.selectorFilter === '0') {  // Just applying filter on first level
                if (data.subtable)
                    data.subtable.filter = this.filter
                return this.auxFilterPredicate(filters, data, true, this.isShowAllEnabled)
            }
            else {
                if (!data.subtable)
                    this.createMatSubtable(data)
                if (this.selectorFilter === '1')
                    data.subtable.filter = this.filter
                if (data.subtable.filteredData.length > 0 && this.filter[0] !== '+')
                    this.expandElement(data)
                return this.auxFilterPredicate(filters, data, true, this.isShowAllEnabled) || data.subtable.filteredData.length > 0
            }
        };
    }   

    ngAfterViewInit() {
        this.setStickyColumnPositions();
    }

    ngAfterViewChecked() {
        this.setStickyColumnPositions();
    }
    
    setStickyColumnPositions() {
        if (this.list?.length > 0) {
            this.list.forEach((account, accountIndex) => {
                const accountContainerId = `table-account-${account.AccountID}`;
                const headerElements = this.stickyColumns?.filter((element) => {
                    const parentContainer = element.nativeElement.closest(`#${accountContainerId}`);
                    return parentContainer !== null;
                });
                if (headerElements != undefined && headerElements.length > 0) {
                    const rowCount = this.list[accountIndex].KpiDetail.length;
                    let leftOffset = -1;
                    const dataElements = this.stickyColumnsData?.filter((element) => {
                        const parentContainer = element.nativeElement.closest(`#${accountContainerId}`);
                        return parentContainer !== null;
                    });

                    headerElements.forEach((column, index) => {
                        if (index > 0) {
                            const previousColumn = headerElements[index - 1].nativeElement;
                            leftOffset += previousColumn.clientWidth - 1;
                        }
                        else
                            column.nativeElement.style.minWidth = '30px';
                        column.nativeElement.style.position = 'sticky';
                        column.nativeElement.style.left = `${leftOffset}px`;
                        column.nativeElement.style.zIndex = '100';
                    });

                    if (dataElements != undefined && dataElements.length > 0) {
                        dataElements.forEach((elem, index) => {
                            const headerIndex = Math.floor(index / rowCount);
                            elem.nativeElement.style.position = 'sticky';
                            elem.nativeElement.style.left = headerElements[headerIndex].nativeElement.style.left;
                        });
                    }
                }
            });
        }
    }

    checkExpanded(element) {
        return this.expandedElements.has(element);
    }

    pushPopElement(element) {
        if (this.expandedElements.has(element)) {
            this.expandedElements.delete(element);
        }
        else {
            this.expandElement(element)
            if (!element.subtable) {
                this.createMatSubtable(element)
            }
        }
    }

    collapseOrExpandAll() {
        if (this.isAllCollapsed === false) {
            this.isAllCollapsed = true;
            this.dataSource.data.forEach((el) => {
                if (!this.expandedElements.has(el)) {
                    if (!el.subtable)
                        this.createMatSubtable(el)
                    this.expandElement(el)
                }
            })
        }
        else {
            this.isAllCollapsed = false;
            this.expandedElements.clear()
        }
    }

    private createMatSubtable(el: any) {
        let dataSource = new MatTableDataSource<any>();
        dataSource.data = el.KpiDetail
        dataSource.filterPredicate = (data2: any, filters: string) => {
            return this.auxFilterPredicate(filters, data2, false, this.isShowAllEnabled)
        }
        el.subtable = dataSource
    }

    private expandElement(el: any) {
        this.expandedElements.add(el);
    }

    formatCurrency(value, decimalDigits): string {
        if (value == undefined)
            return '';
        else
            return parseFloat(value).toLocaleString('en-US', { maximumFractionDigits: decimalDigits });
    }


    onChange(breakdown: any, account: any, field: string, $e: any, type: string) {
        let pM1 = 0

        let tempObject: KpiManagementUpdate = {
            'KpiId': breakdown.KpiId,
            'KpiDefinitionId': breakdown.KpiDefinitionId,
            'KpiVersion': 'SEGMENT',
            'KpiBreakdown1Id': breakdown.KpiBreakdown1Id,
            'KpiBreakdown2Id': breakdown.KpiBreakdown2Id,
            'KpiMovementTypeId': breakdown.KpiMovementTypeId,
            'KpiProductHierarchyId': breakdown.KpiProductHierarchyId,
            'KpiValue': $e,
            KpiUnit: account.KpiUnit,
            AccountId: account.AccountID,
            ApprovalStatus: "",
            DecimalDigits: 0,
            auxFYFC: "",
            IsAccumulative: false,
            Field: field,
            Type: type
        }
        this.addUpdatedItem(tempObject);
    }

    private addUpdatedItem(newObject: KpiManagementUpdate) {
        const index = this.updatedItems.findIndex(
            (obj) =>
                obj.KpiDefinitionId === newObject.KpiDefinitionId &&
                obj.KpiBreakdown1Id === newObject.KpiBreakdown1Id &&
                obj.KpiBreakdown2Id === newObject.KpiBreakdown2Id &&
                obj.KpiId === newObject.KpiId &&
                obj.KpiVersion === newObject.KpiVersion &&
                obj.KpiMovementTypeId === newObject.KpiMovementTypeId &&
                obj.KpiProductHierarchyId === newObject.KpiProductHierarchyId &&
                obj.Field === newObject.Field &&
                obj.Type === newObject.Type
        );

        if (index !== -1) {
            if (newObject.KpiValue === 'NaN')
                this.updatedItems.splice(index, 1)
            else
                this.updatedItems[index] = newObject;
        } else {
            this.updatedItems.push(newObject);
        }
    }

    disableArrowKeys(event: KeyboardEvent) {
        if (
            event.key === 'ArrowUp' ||
            event.key === 'ArrowDown'
        ) {
            event.preventDefault();
        }
    }

    private getFiscalYearHeaders() {
        this.FY3 = (this.defaults.fiscalYear + 2).toString() + '/' + (this.defaults.fiscalYear + 3).toString();
        this.FY2 = (this.defaults.fiscalYear + 1).toString() + '/' + (this.defaults.fiscalYear + 2).toString();
        this.FY1 = (this.defaults.fiscalYear).toString() + '/' + (this.defaults.fiscalYear + 1).toString();
        this.currentFY = (this.defaults.fiscalYear - 1).toString() + '/' + (this.defaults.fiscalYear).toString();
    }

    public getShareValue(tkeValue: number, marketValue: number): string | undefined {
        if (tkeValue != undefined && marketValue != undefined) {
            if (marketValue === 0)
                return undefined;
            else
                return `${(tkeValue / marketValue * 100).toLocaleString('en-US', { maximumFractionDigits: 2 })}%`;
        }
        else
            return undefined;
    }

    public getYoYValue(previousYearValue: number, currentYearValue: number): string | undefined {
        if (previousYearValue != undefined && currentYearValue != undefined) {
            if (previousYearValue === 0)
                return undefined;
            else
                return `${((currentYearValue - previousYearValue) * 100 / previousYearValue).toLocaleString('en-US', { maximumFractionDigits: 2 })}%`;
        }
        else
            return undefined;
    }

    public filterKpiUnit(value: any) {
        this.onUpdateSelectorKpiUnit.emit(value.value)
    }
    public cellTolltipValue(accountUnit) {
        return accountUnit === 'Values' ? 'Value in thousands' : ''
    }

    canBeReleased(breakdown: any) {
        return Utils.IsValidNumber(breakdown.MarketPYBUD)
            && Utils.IsValidNumber(breakdown.MarketFY1BUD)
            && Utils.IsValidNumber(breakdown.MarketFY2BUD)
            && Utils.IsValidNumber(breakdown.MarketFY3BUD)
    }

    mapReleaseRow(row) {
        var selectedReleaseRow: selectedReleaseRow = {
            KpiDefinitionId: row.KpiDefinitionId,
            KpiMovementTypeId: row.KpiMovementTypeId,
            KpiProductHierarchyId: row.KpiProductHierarchyId,
            KpiBreakdown1Id: row.KpiBreakdown1Id,
            KpiBreakdown2Id: row.KpiBreakdown2Id

        };
        return selectedReleaseRow;
    }

    accountCheckboxCheck(row) {
        var Checked = row.KpiDetail.every((row) => {
            var mappedRow = this.mapReleaseRow(row);
            return this.releaseList.some((object) => this.objectsEqual(mappedRow, object));
        })
        return Checked
    }
    accountItemsIndetermine(row) {
        var Indeterminate = this.releaseList.some((selected) => (selected.KpiDefinitionId === row.KpiDefinitionId))
        return Indeterminate;
    }
    accountItemsDisable(row) {
        //console.log(row)
        var Disable = !row.KpiDetail.some((item) => {
            return (this.isReleaseActive ? item.ApprovalStatus == "NEW" && this.canBeReleased(item) :
                (item.ApprovalStatus == "RELEASED"))
        }
        )
        return Disable;
    }

    accountItemsSelected(ischecked, row) {
        row.KpiDetail.forEach((item) => {
            this.isReleaseActive ? ((item.ApprovalStatus == "NEW" && this.canBeReleased(item)) ? this.expandedItemSelected(ischecked, item) : null) :
                (item.ApprovalStatus == "RELEASED" ? this.expandedItemSelected(ischecked, item) : null)
        });

    }
    expandedItemSelected(ischecked, row) {
        if ((this.isReleaseActive || this.isRevokeActive) && (this.isReleaseActive ? (row.ApprovalStatus == 'NEW') : (row.ApprovalStatus == 'RELEASED'))) {
            var selectedReleaseRow = this.mapReleaseRow(row);
            if (ischecked) {
                this.selection.select(row)
                this.onSelect(selectedReleaseRow);
            } else {
                this.selection.deselect(row)
                this.onDeselect(selectedReleaseRow);
            }
        }

    }
    onSelect(selectedReleaseRow) {
        //this.releaseList.push(selectedReleaseRow)

        if (!this.releaseList.some((object) => this.objectsEqual(object, selectedReleaseRow))) {
            this.releaseList.push(selectedReleaseRow)
        }

    }
    onDeselect(selectedReleaseRow) {
        var mappedRow = this.mapReleaseRow(selectedReleaseRow)
        const index = this.releaseList.findIndex((object) => this.objectsEqual(mappedRow, object));
        if (index !== -1) {
            this.releaseList.splice(index, 1);
        }
    }

    objectsEqual(object1, object2) {
        if (object1 === object2) {
            return true;
        }
        if (Object.keys(object1).length !== Object.keys(object2).length) {
            return false;
        }
        for (const property in object1) {
            if (object1[property] !== object2[property]) {
                return false;
            }
        }
        return true;
    }
    getSelectionList() {
        return this.releaseList;
    }
    clearSelectionList() {
        this.releaseList = [];
        this.selection?.clear();
    }
    onClickReleaseMode_emitter() {
        this.onClickReleaseMode.emit();
    }
    onClickRevokeMode_emitter() {
        this.onClickRevokeMode.emit();
    }
    anyActionableItemExist() {
        //console.log(this.list);
        return this.selection.hasValue() && (this.isReleaseActive ?
            this.list.some((kpi) => kpi.KpiDetail.some((kpidef) => (kpidef.ApprovalStatus == "NEW"))) :
            this.list.some((kpi) => kpi.KpiDetail.some((kpidef) => (kpidef.ApprovalStatus == "RELEASED"))))
    }
    textRelRevbuttonState() {
        return this.anyActionableItemExist() ?
            (((this.isReleaseActive || this.isRevokeActive && this.isReleaseActive) ? 'Release ' : 'Revoke ') + (!this.selection.hasValue() ? 'All' : 'Selected')) :
            (this.isReleaseActive ? 'Nothing to Release' : 'Nothing to Revoke')
    }

    onHistoryDetail(element: any) {

        var KpiId = "null";

        if (element.KpiId != undefined)
            KpiId = element.KpiId;

        const Dialog = this.dialogForm.open(KpiDetailFormComponent, {
            panelClass: 'custom-mat-dialog',
            disableClose: false,
            data: {
                "KpiDefinitionId": element.KpiDefinitionId,
                "KpiId": KpiId,
                "DataType": 'SEGMENT',
                "kpiHistoryLogDialogTags": element.kpiHistoryLogDialogTags
            }
        });
        Dialog.afterClosed().subscribe(() => {
        });

    }

    showIndividualHistory(element: any) {
        return element.KpiId != undefined
    }
}
export interface SearchItem {
    name: string;
}
