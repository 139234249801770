<div class="container-tab-lists">
    <mat-tab-group orientation="vertical" #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
        <mat-tab label="Excellence Factors">
            <div class="inner-tcontainer-tab">
                <excellence-lever></excellence-lever>
                <excellence-lever-kpi></excellence-lever-kpi>
                <excellence-factor></excellence-factor>
            </div>
        </mat-tab>

        <mat-tab label="Waves">
            <div class="inner-tcontainer-tab">
                <div class="panel-body row">
                    <p>In order to configure a specific wave (configuring levers or assigning branches), please select a wave clicking on respective arrow.</p>
                </div>

                <wave (selectedWaveEmit)="onSelectedWave($event)"></wave>
                <waveexcellenceleverconfig *ngIf="selectedWave.WaveId !== -1" [selectedWave]="selectedWave"></waveexcellenceleverconfig>
                <assessment-assignment *ngIf="selectedWave.WaveId !== -1" [selectedWave]="selectedWave"></assessment-assignment>
            </div>
        </mat-tab>

    </mat-tab-group>
</div>