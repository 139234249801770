import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { HttpClient } from "@angular/common/http";
import { MatDialog } from '@angular/material/dialog';
import { EmtTdcDeploymentStatusPopup } from '../emttdcdeployment/emttdc-deploymentstatus-popup.component';

@Component({
  selector: 'tdccomponents',
  templateUrl: './tdccomponents.component.html',
    styleUrls: ['./tdccomponents.component.less', '../../style/new-generic-styles.component.less'],
})
export class TDCComponents implements OnInit {
    @Input() availableComponentFunctions: ComponentFunction[] = [];
    @Input() availableFamilyCodes: FamilyCode[] = [];
    constructor(private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , private matDialog: MatDialog    ) { }

  ngOnInit(): void {
  }

    loadCompFunctions(compFunctions: ComponentFunction[]) {
        this.availableComponentFunctions = compFunctions;
    }

    loadFamilyCodes(familyCodes: FamilyCode[]) {
        this.availableFamilyCodes = familyCodes;
    }

    openDeploymentInformationPopup() {
        this.matDialog.open(EmtTdcDeploymentStatusPopup, {
            panelClass: 'custom-mat-dialog',
        });
    }
}
