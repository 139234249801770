<div class="flex-center">
    <div class="contentWidth">
        <div style="height: calc(90vh - 100px); overflow-y: auto;">
            <form [formGroup]="CSRForm" autocomplete="off">
                <ng-container formArrayName="CSRRows">
                    <table #objTable
                           mat-table
                           multiTemplateDataRows
                           matSort
                           [dataSource]="dataSource.data"
                           class="table-add-butt-header-orange mat-elevation-z8 mat-table-custom">

                        <ng-container matColumnDef="headertitle">
                            <th class="title-col" mat-header-cell *matHeaderCellDef [attr.colspan]="columnsToDisplay.length - 1">
                                <div class="pull-left">
                                    <h4>Global Task List</h4>
                                </div>
                            </th>
                        </ng-container>
                        <ng-container matColumnDef="headeraddbutton">
                            <th class="butt-col" mat-header-cell *matHeaderCellDef>
                                <div class="OrangeButton">
                                    <button mat-raised-button
                                            (click)="addNewRow()"
                                            matTooltip="Add Release" class="button-color-fix pull-right">
                                        <mat-icon class="icon-color-fix icon-padding-fix">add_box</mat-icon>
                                    </button>
                                </div>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="arrow">
                            <th mat-header-cell *matHeaderCellDef style="width:5%"></th>
                            <td mat-cell style="width:5%" *matCellDef="let element, let i = dataIndex"
                                (click)="pushPopElement(i)">
                                <ng-container *ngIf=" !CSRForm.get('CSRRows')?.value[i]?.isNewRow ">
                                    <span class="material-icons"
                                          *ngIf="checkExpanded(i);
                                                else show_expand_more">
                                        expand_less
                                    </span>
                                </ng-container>
                            </td>

                        </ng-container>
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">Description</th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex" style="width:15%">
                                <textareaoverlay [inputControls]="{
                                                     isOpenOverlay: CSRForm?.get('CSRRows')?.value[i].isOpenOverlay,
                                                     textAreaId: CSRForm?.get('CSRRows')?.value[i].textAreaId,
                                                     isEditable: !CSRForm?.get('CSRRows')?.value[i].isEditable,
                                                     maxCommentLength: CSRForm?.get('CSRRows')?.value[i].maxCommentLength,
                                                     comment: CSRForm?.get('CSRRows')?.value[i].description
                                                     }"
                                                 [customSpanClass]="{'alternative2': !CSRForm?.get('CSRRows')?.value[i]?.isEditable}"
                                                 (commentChange)="onOverlayChange($event.comment, 'description', i)">
                                </textareaoverlay>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="description_ref">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">Description Ref</th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex" style="width:15%">
                                <textareaoverlay [inputControls]="{
                                                     isOpenOverlay: CSRForm?.get('CSRRows')?.value[i].isOpenOverlay,
                                                     textAreaId: CSRForm?.get('CSRRows')?.value[i].textAreaId,
                                                     isEditable: false,
                                                     maxCommentLength: CSRForm?.get('CSRRows')?.value[i].maxCommentLength,
                                                     comment: CSRForm?.get('CSRRows')?.value[i].description_Ref
                                                     }"
                                                 [customSpanClass]="{'alternative2': !CSRForm?.get('CSRRows')?.value[i]?.isEditable}"
                                                 (commentChange)="onOverlayChange($event.comment, 'description_Ref', i)">
                                </textareaoverlay>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="symptomdescription">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">Symptom Description</th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex" style="width:15%">
                                <textareaoverlay [inputControls]="{
                                                     isOpenOverlay: CSRForm?.get('CSRRows')?.value[i].isOpenOverlay,
                                                     textAreaId: CSRForm?.get('CSRRows')?.value[i].textAreaId,
                                                     isEditable: !CSRForm?.get('CSRRows')?.value[i].isEditable,
                                                     maxCommentLength: CSRForm?.get('CSRRows')?.value[i].maxCommentLength,
                                                     comment: CSRForm?.get('CSRRows')?.value[i].symptomDescription
                                                     }"
                                                 [customSpanClass]="{'alternative2': !CSRForm?.get('CSRRows')?.value[i]?.isEditable}"
                                                 (commentChange)="onOverlayChange($event.comment, 'symptomDescription', i)">
                                </textareaoverlay>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="symptomdescription_ref">
                            <th mat-header-cell *matHeaderCellDef style="width:15%">Symptom Description Ref</th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex" style="width:15%">
                                <textareaoverlay [inputControls]="{
                                                     isOpenOverlay: CSRForm?.get('CSRRows')?.value[i].isOpenOverlay,
                                                     textAreaId: CSRForm?.get('CSRRows')?.value[i].textAreaId,
                                                     isEditable: false,
                                                     maxCommentLength: CSRForm?.get('CSRRows')?.value[i].maxCommentLength,
                                                     comment: CSRForm?.get('CSRRows')?.value[i].symptomDescription_Ref
                                                     }"
                                                 [customSpanClass]="{'alternative2': !CSRForm?.get('CSRRows')?.value[i]?.isEditable}"
                                                 (commentChange)="onOverlayChange($event.comment, 'symptomDescription_Ref', i)">
                                </textareaoverlay>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="isglobal">
                            <th mat-header-cell *matHeaderCellDef style="width:10%">Is Global?</th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex" style="width:10%">
                                <mat-checkbox [checked]="CSRForm.get('CSRRows')?.value[i].isGlobal"
                                              (change)="changeIsGlobal(i, $event.checked)"
                                              [ngClass]="!CSRForm.get('CSRRows')?.value[i]?.isEditable ? 'black' :''"
                                              [disabled]="!CSRForm.get('CSRRows')?.value[i]?.isNewRow"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="activehours">
                            <th mat-header-cell *matHeaderCellDef style="width:10%">Active Hours</th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex" style="width:10%">
                                <div [innerHTML]="CSRForm.get('CSRRows')?.value[i].activeHours" *ngIf="CSRForm.get('CSRRows')?.value[i]?.isEditable"></div>
                                <mat-form-field floatLabel="never" *ngIf="!CSRForm.get('CSRRows')?.value[i]?.isEditable">
                                    <input matInput type="number"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="CSRForm.get('CSRRows')?.value[i].activeHours"
                                           pattern="[0-9]*"
                                           inputmode="numeric"
                                           matTooltip="Only numbers allowed" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:15%"></th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex" style="width:15%">
                                <button mat-icon-button
                                        *ngIf="!CSRForm.get('CSRRows')?.value[i]?.isNewRow"
                                        matTooltip="Add Task"
                                        color="warn"
                                        (click)="addNewTaskRow(CSRForm.get('CSRRows')?.value[i],i)">
                                    <mat-icon class="actionButtons">add</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="!CSRForm.get('CSRRows')?.value[i]?.isEditable"
                                        matTooltip="Cancel Changes"
                                        color="warn"
                                        (click)="resetRow(i)">
                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="CSRForm.get('CSRRows')?.value[i]?.isEditable"
                                        matTooltip="Edit"
                                        color="primary"
                                        (click)="editRow(i)">
                                    <mat-icon class="actionButtons">edit</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="!CSRForm.get('CSRRows')?.value[i]?.isNewRow"
                                        matTooltip="Delete"
                                        color="warn"
                                        (click)="onDelete(CSRForm.get('CSRRows')?.value[i]?.patternDefinitionId, i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="columnsToDisplay.length">
                                <div [@detailExpand]="checkExpanded(i) ? 'expanded' : 'collapsed'">
                                    <table mat-table [dataSource]="CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows"
                                           class="table-add-butt-header-orange mat-subtable-custom" id="patterntask">

                                        <ng-container matColumnDef="taskDescription">
                                            <th mat-header-cell *matHeaderCellDef> Task Description </th>
                                            <td mat-cell *matCellDef="let element2, let j = index">
                                                <textareaoverlay [inputControls]="{
                                                                     isOpenOverlay: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].isOpenOverlay,
                                                                     textAreaId: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].textAreaId,
                                                                     isEditable: !CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].isEditable,
                                                                     maxCommentLength: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].maxCommentLength,
                                                                     comment: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].taskDescription
                                                                 }"
                                                                 [customSpanClass]="{'alternative2': !CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isEditable}"
                                                                 (commentChange)="onOverlayChange($event.comment, 'taskDescription', i, j)">
                                                </textareaoverlay>
                                            </td>

                                        </ng-container>
                                        <ng-container matColumnDef="taskDescription_ref">
                                            <th mat-header-cell *matHeaderCellDef style="width:20%"> Task Description Ref</th>
                                            <td mat-cell *matCellDef="let element2, let j = index" style="width:20%">
                                                <textareaoverlay [inputControls]="{
                                                                     isOpenOverlay: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].isOpenOverlay,
                                                                     textAreaId: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].textAreaId,
                                                                     isEditable: false,
                                                                     maxCommentLength: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].maxCommentLength,
                                                                     comment: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].taskDescription_Ref
                                                                 }"
                                                                 [customSpanClass]="{'alternative2': !CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isEditable}"
                                                                 (commentChange)="onOverlayChange($event.comment, 'taskDescription_ref', i, j)">
                                                </textareaoverlay>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="taskhours">
                                            <th mat-header-cell *matHeaderCellDef style="width:10%"> Hours </th>
                                            <td mat-cell *matCellDef="let element; let j = index" style="width:10%">
                                                <div [innerHTML]="CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.taskHours" *ngIf="CSRForm.get('CSRRows')?.value[i]?.patternTasks.TaskRows[j].isEditable"></div>
                                                <mat-form-field style="width: 100%" *ngIf="!CSRForm.get('CSRRows')?.value[i]?.patternTasks.TaskRows[j].isEditable">
                                                    <input matInput type="number"
                                                           [ngModelOptions]="{standalone: true}"
                                                           [(ngModel)]="CSRForm.get('CSRRows')?.value[i]?.patternTasks.TaskRows[j].taskHours"
                                                           pattern="[0-9]*"
                                                           min="0"
                                                           max="8"
                                                           inputmode="numeric"
                                                           matTooltip="Only numbers allowed" />
                                                </mat-form-field>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="priority">
                                            <th mat-header-cell *matHeaderCellDef style="width:10%"> Priority </th>
                                            <td mat-cell *matCellDef="let element; let j = index" style="width:10%">
                                                <div [innerHTML]="CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.priority" *ngIf="CSRForm.get('CSRRows')?.value[i]?.patternTasks.TaskRows[j].isEditable"></div>
                                                <mat-form-field floatLabel="never" *ngIf="!CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isEditable">
                                                    <mat-select style="min-width: 200px; display:flex; justify-content:center; color:red"
                                                                [(ngModel)]="CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].priority"
                                                                [ngModelOptions]="{standalone: true}">
                                                        <mat-option [value]="-1" [disabled]="false">Select</mat-option>
                                                        <mat-option [value]="1">1</mat-option>
                                                        <mat-option [value]="2">2</mat-option>
                                                        <mat-option [value]="3">3</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="controllerType">
                                            <th mat-header-cell *matHeaderCellDef style="width:10%"> Controller Type </th>
                                            <td mat-cell *matCellDef="let element; let j = index" style="width:10%">
                                                <div [innerHTML]="CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.controllerType"
                                                     *ngIf="!CSRForm.get('CSRRows')?.value[i]?.isGlobal &&
                                                                CSRForm.get('CSRRows')?.value[i]?.patternTasks.TaskRows[j].isEditable"></div>
                                                <mat-form-field style="width: 100%" *ngIf="!CSRForm.get('CSRRows')?.value[i]?.isGlobal &&
                                                                !CSRForm.get('CSRRows')?.value[i]?.patternTasks.TaskRows[j].isEditable">
                                                    <input matInput type="number"
                                                           [ngModelOptions]="{standalone: true}"
                                                           [(ngModel)]="CSRForm.get('CSRRows')?.value[i]?.patternTasks.TaskRows[j].controllerType"
                                                           pattern="[0-9]*"
                                                           inputmode="numeric"
                                                           matTooltip="Only numbers allowed" />
                                                </mat-form-field>
                                            </td>

                                        </ng-container>
                                        <ng-container matColumnDef="context">
                                            <th mat-header-cell *matHeaderCellDef style="width:20%"> Context </th>
                                            <td mat-cell *matCellDef="let element2, let j = index" style="width:20%">
                                                <textareaoverlay [inputControls]="{
                                                                     isOpenOverlay: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].isOpenOverlay,
                                                                     textAreaId: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].textAreaId,
                                                                     isEditable: !CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].isEditable,
                                                                     maxCommentLength: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].maxCommentLength,
                                                                     comment: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].context
                                                                 }"
                                                                 [customSpanClass]="{'alternative2': !CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isEditable}"
                                                                 (commentChange)="onOverlayChange($event.comment, 'context', i, j)">
                                                </textareaoverlay>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="tags">
                                            <th mat-header-cell *matHeaderCellDef style="width:20%"> Tags </th>
                                            <td mat-cell *matCellDef="let element2, let j = index" style="width:20%">
                                                <textareaoverlay [inputControls]="{
                                                                     isOpenOverlay: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].isOpenOverlay,
                                                                     textAreaId: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].textAreaId,
                                                                     isEditable: !CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].isEditable,
                                                                     maxCommentLength: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].maxCommentLength,
                                                                     comment: CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j].tags
                                                                 }"
                                                                 [customSpanClass]="{'alternative2': !CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isEditable}"
                                                                 (commentChange)="onOverlayChange($event.comment, 'tags', i, j)">
                                                </textareaoverlay>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef style="width:15%"></th>
                                            <td mat-cell *matCellDef="let element2; let j = index" style="width:15%">
                                                <button mat-icon-button
                                                        *ngIf="!CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isEditable"
                                                        matTooltip="Cancel Changes"
                                                        color="warn"
                                                        (click)="resetTaskRow(i,j)">
                                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                                </button>

                                                <button mat-icon-button
                                                        *ngIf="CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isEditable"
                                                        matTooltip="Edit"
                                                        color="primary"
                                                        (click)="editTaskRow(i,j)">
                                                    <mat-icon class="actionButtons">edit</mat-icon>
                                                </button>

                                                <button mat-icon-button
                                                        *ngIf="!CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isNewRow"
                                                        matTooltip="Delete"
                                                        color="warn"
                                                        (click)="onDeletePatternTask(CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.patternTaskId, i, j)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>

                                                <button mat-icon-button
                                                        *ngIf="!CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isNewRow"
                                                        matTooltip="Delete"
                                                        color="warn"
                                                        (click)="openUrlSupportPopup(CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.patternTaskId)">
                                                    <mat-icon>share</mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                                        <tr mat-row *matRowDef="let element2; let j = index; columns: subcolumnsToDisplay;"
                                            [class.alternative2]="!CSRForm.get('CSRRows')?.value[i].patternTasks.TaskRows[j]?.isEditable"
                                            class="subitem"></tr>
                                    </table>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="savefooter">
                            <th mat-footer-cell *matFooterCellDef [attr.colspan]="columnsToDisplay.length">
                                <div class="OrangeButton">
                                    <button mat-raised-button type="submit" (click)="onSave()"
                                            [disabled]="!anyChange()"
                                            [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                            class="button-color-fix pull-right">
                                        Save
                                        <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                    </button>
                                </div>
                            </th>

                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="['headertitle', 'headeraddbutton'];"></tr>
                        <tr class="col-headers" mat-header-row *matHeaderRowDef="columnsToDisplay;"></tr>
                        <tr mat-row *matRowDef="let row; let i = dataIndex; columns: columnsToDisplay;"
                            [class.alternative2]="!CSRForm.get('CSRRows')?.value[i]?.isEditable"></tr>

                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="row-subitem"></tr>

                        <tr mat-footer-row *matFooterRowDef="['savefooter'];" style="width:100%"></tr>
                    </table>
                </ng-container>
            </form>
        </div>
    </div>
</div>

<ng-template #show_expand_more>
    <span class="material-icons">
        expand_more
    </span>
</ng-template>
