import { Component, Inject, OnInit, Self } from '@angular/core';
import { DefaultValuesService } from '../../services/defaultvalues.service';
import { HttpClient } from '@angular/common/http';
import { Utils } from '../../utils/utils';
import { Subject, takeUntil } from 'rxjs';
import { DestroyService } from '../../services/destroyservice';
import { MatDialog } from '@angular/material/dialog';
import { EmtTdcDeploymentStatusPopup } from '../emttdcdeployment/emttdc-deploymentstatus-popup.component';

@Component({
    selector: 'jcobrules',
    templateUrl: './jcobrules.component.html',
    styleUrls: ['./jcobrules.component.less', '../../style/new-generic-styles.component.less'],
    providers: [DestroyService]
})
export class JCOBRulesComponent implements OnInit {

    originalMfgCenterProduct: MFGCenterProductItem[] = [];
    originalSoftwareReleases: EMTControllerSoftwareReleaseItem[] = [];

    constructor(private http: HttpClient
        , @Inject('BASE_URL') private baseUrl: string
        , public defaults: DefaultValuesService
        , @Self() private readonly destroy$: DestroyService
        , private matDialog: MatDialog) { }

    ngOnInit(): void {
        this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.execAllGets() });
        this.execAllGets();
    }
    resetItems() {
        this.originalMfgCenterProduct = [];
        this.originalSoftwareReleases = [];
    }
    execAllGets() {
        this.resetItems()
        this.getManufacturingCentersProducts();
        this.getSoftwareReleases();
    }


    getManufacturingCentersProducts() {
        Utils.httpGetMfgCenterProducts(
            this.http,
            this.baseUrl,
            this,
            function (tthis: JCOBRulesComponent, result: MFGCenterProduct) {
                tthis.originalMfgCenterProduct = result.centerProductList;
            }
        );
    }


    getSoftwareReleases() {
        Utils.httpGetEMTControllerSoftwareReleases(
            this.http,
            this.baseUrl,
            this,
            function (tthis: JCOBRulesComponent, result: EMTControllerSoftwareReleases) {
                tthis.originalSoftwareReleases = result.releasesList;
            }
        );
    }

    openDeploymentInformationPopup() {
        this.matDialog.open(EmtTdcDeploymentStatusPopup, {
            panelClass: 'custom-mat-dialog',
        });
    }
}
