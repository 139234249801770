import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, takeUntil } from "rxjs";
import { AppModuleComponent } from "../interfaces/appmodules";
import { ComponentTool } from "../interfaces/componenttool";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";

const API_BUSINESS_KEY = "api/businesskey/";
const API_LOG = "api/log/";
const API_ELEVATOR = "api/elevator/";
const API_ESCALATOR = "api/escalator/";
const API_OTHERASSET = "api/otherassets/";
const API_CONTROLLER_TYPE = "api/controllertype/";
const API_ERROR_CODE = "api/errorcode/";
const API_GENERIC = "api/generic/";
const API_HOME = "api/home/";
const API_MAINTENANCE_CONTROL_PLAN = "api/maintenancecontrolplan/";
const API_MAINTENANCE_WARNING = "api/maintenancewarning/";
const API_FINGERPRINT = "api/fingerprintrule/";
const API_BRANCHHIERARCHY = "api/branchhierarchy";
const API_LANGUAGE = "api/language/";
const API_OPERATION_MODE = "api/operationmode/";
const API_TEST_CASE = "api/testcase/";
const API_TRANSLATION_AREA = "api/translationarea/";
const API_COUNTRY = "api/country/";
const API_ORGHIERARCHY = "api/orghierarchy";
const API_ALERTRULE = "api/alertrule";
const API_EXPORTIMPORT = "api/ImportExportModel/";
const API_AZUREDATAFACTORY = "api/AzureDataFactory/";
const API_BUILDRELEASE = "api/buildrelease/";
const API_HEALTHSCORE = "api/healthscore";
const API_TENSPECIFICCONF = "api/tenantspecificconf";



//CDP SEPE 06/2022
const API_CDP_CONTROLLER_TYPE_MAPPING = "api/controllertypemapping/";

//Default Transmision Mode SEPE 06/2022
const API_TRANSMISSION_MODE_CONFIGURATION = "api/transmissionmodeconfiguration";
const API_MASTER_ALERT_WINDOW = "api/AlertWindow";

// Allen
const API_CLAIMS = "api/generic/Claims/";
const API_TENANT = "api/tenant/";


// UMP
const API_UMPUSER = "api/user"
const API_Wiki = "api/wiki"

const EXCEL_FILEUPLOAD_ERRORCODES = 0;
const EXCEL_FILEUPLOAD_OPMODES = 1;
const EXCEL_FILEUPLOAD_CONTRACTS = 2;
const EXCEL_FILEUPLOAD_CUSTOMERS = 3;
const EXCEL_FILEUPLOAD_ELEVATORS = 4;
const EXCEL_FILEUPLOAD_ESCALATORS = 5;
const EXCEL_FILEUPLOAD_BRANCHHIERARCHY = 6;
const EXCEL_FILEUPLOAD_SERVICETICKETS = 7;
const EXCEL_FILEUPLOAD_CHAIRLIFTS = 8;
const EXCEL_FILEUPLOAD_OTHERASSETS = 9;
const EXCEL_FILEUPLOAD_CONTRACTFACILITYMANAGER = 10;
const EXCEL_FILEUPLOAD_CUSTOMERFACILITYMANAGER = 11;
const EXCEL_FILEUPLOAD_KPI = 12;
const EXCEL_FILEUPLOAD_ERRORCODESDIGITALSERVICES = 13;
const EXCEL_FILEUPLOAD_CONFIGIT = 14;
const EXCEL_FILEUPLOAD_ACTUALREPORTING = 15;
const EXCEL_FILEUPLOAD_OPLYEARLY = 16;
const EXCEL_FILEUPLOAD_OPLMONTHLY = 17;
const EXCEL_FILEUPLOAD_ACTUALBRANCHREPORTING = 18;
const EXCEL_FILEUPLOAD_OPLYEARLYBRANCHREPORTING = 19;
const EXCEL_FILEUPLOAD_OPLMONTHLYBRANCHREPORTING = 20;
const EXCEL_FILEUPLOAD_MARKET_SHEET = 21;
const EXCEL_FILEUPLOAD_SEGMENT_SHEET = 22;
const EXCEL_FILEUPLOAD_EXCELLENCE_LEVER = 23;
const EXCEL_FILEUPLOAD_EXCELLENCE_LEVER_KPI = 24;
const EXCEL_FILEUPLOAD_EXCELLENCE_Factor = 25;

const API_INFOHUB = "api/countrybackend/infohub/"
const API_EVENTARCHIVE = "api/countrybackend/eventarchive/"
const API_CONFIGURATION = "api/countrybackend/configuration/"
const API_CDPWIKI = "api/countrybackend/wiki/"
const API_SETUP = "api/countrybackend/setup/"
const API_CONTRACTBUNDLE = "api/countrybackend/contractbundle/"

const API_REMOTEACTIONS = "api/remoteaction/";
const API_VIRTUALPROVISIONING = "api/virtualprovisioning"

const API_GROUPFUNCTION = 'api/groupfunction'
const API_MANAGEMENTID = 'api/managementid'
const API_FISCALYEAR = 'api/fiscalyear'
const API_PERIOD = 'api/period'
const API_KPIMANAGEMENT = 'api/kpimanagement'
const API_MARKETQUERY = 'api/marketquery'
const API_BRANCHEXCELLENCE = 'api/branchexcellence'
const API_EMT = 'api/equipmentmanagement'
const API_JCOB = 'api/JCOB'
const API_GLOBALTASKLIST = 'api/globaltasklist'

const NOT_TRANSLATED_CLASSNAME = "not-translated";

/************************************************
 *      constant for default language
 * */
const DEFAULT_LANGUAGE_ID = 50;

/************************************************
 *      constant for editing classname
 * */
const EDITING_INPUT_CLASSNAME = "editing";

/**
 * id for status server error code exception
 * */
const STATUS_SERVER_ERROR_CODE = 509;
const UNAUTHORIZED_SERVER_ERROR = 401;

/************************************************
 *      constant for default controller type
 * */
const DEFAULT_CONTROLLERTYPE_ID = 32;

/************************************************
 *      constants for alert confirm div
 * */
const SUCCESS = 0;
const ERROR = 1;
const WARNING = 2;
const CONFIRM = 3;
const INFO_BACKGROUND = 4;

/************************************************
 *      Message types
 * */
const BLUEBOX_MESSAGE_TYPE = 4;
const DOOR_MOVEMENT_MESSAGE_TYPE = 40;
const ERROR_CODE_MESSAGE_TYPE = 51;
const OPERATION_MODE_MESSAGE_TYPE = 52;
const TRIP_MESSAGE_TYPE = 69;
const CONTROLLER_MESSAGE_TYPE = 60;

/************************************************
 *      Schema Keys
 * */
const START_FLOOR_SCHEMA_KEY = 13;
const END_FLOOR_SCHEMA__KEY = 14;
const DOOR_START_STATE_SCHEMA_KEY = 15;
const DOOR_END_STATE_SCHEMA_KEY = 16;
const ID_SCHEMA_KEY = 22;
const DOOR_FLOOR_SCHEMA_KEY = 29;

const STABLE_STAGE = '0'
const PRODUCTION_STAGE = '1';
const DEV_STAGE = '2';

export const API_ROUTES = {
    API_EMT,
    API_BRANCHHIERARCHY,
    API_BUILDRELEASE
};


export const Session = {
    ControllerType: DEFAULT_CONTROLLERTYPE_ID
    , LanguageID: DEFAULT_LANGUAGE_ID
}

export enum BusinessKeyTypes {
    BrakeType = 2,
    BuildingType = 3,
    ControllerType = 4,
    DoorType = 5,
    DriveType = 6,
    ElevatorType = 7,
    MachineRoomLocation = 8,
    MotorType = 9,
    ReevingRatioType = 10,
    ValveType = 11,
    WrappingType = 12,
    Manufacturer = 25,
    LoadSpectrum = 26,
    Series = 27,
    BalustradeType = 28,
    AngleInclination = 29,
    Width = 30,
    ClimatClass = 31,
    Embarquement = 32,
    Code = 33,
    BlueBox = 51,
    DoorState = 55
}

export enum UMPCategory {
    None = -1,
    UserDetails = 0,
    SpecialPermissions = 1,
    AssignedLicenses = 2,
    DevOpsProjectAreas = 3,
    MaxRoles = 4,
    EmtRoles = 5,
    HomeRoles = 6,
    MersyRoles = 7,
    MersyGroupFunctions = 8,
    DAnalyticsPowerBI = 9,
    BranchHierarchy = 10,
    MgmtBranchHierarchy = 11,
    UserManagement = 12,
    MfgRoles = 13,
    ExternalUserCreate = 14,
    MarketLineOfBusiness = 15,
    MarketProductType = 16,
}

export enum UMPAction {
    Create = 0,
    Delete = 1,
    Update = 2,
    Assign = 3,
    Unassign = 4,
    Reset = 5,
    Clear = 6
}

export enum EmailSearchfor {
    SearchOnly = 0,
    UserCreation = 1,
}

export enum APICallStatus {
    NotRequested = -3,
    Waiting4Response = -2,
    ResponseError = -1,
    ResponseEmptyOk = 0,
    ResponseDataOk = 1
}

export enum ConfigurationKeyEnumType {
    NoConsequence = 0,
    Shutdown = 1,
}

export enum BuildReleaseType {
    OperationModes = 0,
    ErrorCodes = 1,
    TdcEmt = 2
}

export class Utils {

    public static getBlueBoxMessageTypeValue(): number { return BLUEBOX_MESSAGE_TYPE; }

    public static getBusinessKeyAPI(): string { return API_BUSINESS_KEY; }

    public static getConfirmValue(): number { return CONFIRM; }

    public static getControllerTypeAPI(): string { return API_CONTROLLER_TYPE; }

    public static getControllerTypeMessageTypeValue(): number { return CONTROLLER_MESSAGE_TYPE; }

    public static getDefaultControllerTypeID(): number { return DEFAULT_CONTROLLERTYPE_ID; }

    public static getDefaultLaguangeID(): number { return DEFAULT_LANGUAGE_ID; }

    public static getDialogHideClassName(): string { return "hide"; }

    public static getDialogShowClassName(): string { return "dialog"; }

    public static getDoorEndStateSchemaKey(): number { return DOOR_END_STATE_SCHEMA_KEY; }

    public static getDoorFloorSchemaKey(): number { return DOOR_FLOOR_SCHEMA_KEY; }

    public static getDoorMovementMessageTypeValue(): number { return DOOR_MOVEMENT_MESSAGE_TYPE; }

    public static getDoorStartStateSchemaKey(): number { return DOOR_START_STATE_SCHEMA_KEY; }

    public static getEditingInputClassName(): string { return EDITING_INPUT_CLASSNAME; }

    public static getEndFloorSchemaKey(): number { return END_FLOOR_SCHEMA__KEY; }

    public static getErrorCodeAPI(): string { return API_ERROR_CODE; }

    public static getErrorMessageTypeValue(): number { return ERROR_CODE_MESSAGE_TYPE; }

    public static getErrorValue(): number { return ERROR; }

    public static getInfoBackgroundValue(): number { return INFO_BACKGROUND; }

    public static getFileUploadErrorCodeValue(): number { return EXCEL_FILEUPLOAD_ERRORCODES; }

    public static getFileUploadOpModeValue(): number { return EXCEL_FILEUPLOAD_OPMODES; }

    public static getFileUploadContracts(): number { return EXCEL_FILEUPLOAD_CONTRACTS; }

    public static getFileUploadCustomers(): number { return EXCEL_FILEUPLOAD_CUSTOMERS; }

    public static getFileUploadElevators(): number { return EXCEL_FILEUPLOAD_ELEVATORS; }

    public static getFileUploadEscalators(): number { return EXCEL_FILEUPLOAD_ESCALATORS; }

    public static getFileUploadBranchHierarchy(): number { return EXCEL_FILEUPLOAD_BRANCHHIERARCHY; }

    public static getFileUploadServiceTickets(): number { return EXCEL_FILEUPLOAD_SERVICETICKETS; }

    public static getFileUploadChairlifts(): number { return EXCEL_FILEUPLOAD_CHAIRLIFTS; }

    public static getFileUploadOtherAssets(): number { return EXCEL_FILEUPLOAD_OTHERASSETS; }

    public static getFileUploadContractFacilitiesManager(): number { return EXCEL_FILEUPLOAD_CONTRACTFACILITYMANAGER; }

    public static getFileUploadCustomerFacilitiesManager(): number { return EXCEL_FILEUPLOAD_CUSTOMERFACILITYMANAGER; }

    public static getFileKpi(): number { return EXCEL_FILEUPLOAD_KPI; }

    public static getFileUploadErrorCodesDigitalServices(): number { return EXCEL_FILEUPLOAD_ERRORCODESDIGITALSERVICES; }

    public static getFileUploadConfigIt(): number { return EXCEL_FILEUPLOAD_CONFIGIT; }

    public static getFileUploadActualReportingInformation(): number { return EXCEL_FILEUPLOAD_ACTUALREPORTING; }

    public static getFileUploadOplYearlyInformation(): number { return EXCEL_FILEUPLOAD_OPLYEARLY; }

    public static getFileUploadOplMonthlyInformation(): number { return EXCEL_FILEUPLOAD_OPLMONTHLY; }

    public static getFileUploadActualBranchReportingInformation(): number { return EXCEL_FILEUPLOAD_ACTUALBRANCHREPORTING; }

    public static getFileUploadOplYearlyBranchReportingInformation(): number { return EXCEL_FILEUPLOAD_OPLYEARLYBRANCHREPORTING; }

    public static getFileUploadOplMonthlyBranchReportingInformation(): number { return EXCEL_FILEUPLOAD_OPLMONTHLYBRANCHREPORTING; }

    public static getFileUploadMarketSheet(): number { return EXCEL_FILEUPLOAD_MARKET_SHEET; }

    public static getFileUploadSegmentSheet(): number { return EXCEL_FILEUPLOAD_SEGMENT_SHEET; }
    public static getFileUploadExcellenceLever(): number { return EXCEL_FILEUPLOAD_EXCELLENCE_LEVER; }
    public static getFileUploadExcellenceLeverKPI(): number { return EXCEL_FILEUPLOAD_EXCELLENCE_LEVER_KPI; }
    public static getFileUploadExcellenceFactor(): number { return EXCEL_FILEUPLOAD_EXCELLENCE_Factor; }

    public static getGenericAPI(): string { return API_GENERIC; }

    public static getHomeAPI(): string { return API_HOME; }

    public static getIdSchemaKey(): number { return ID_SCHEMA_KEY; }

    public static getLanguageAPI(): string { return API_LANGUAGE; }

    public static getMaintenanceControlPlanAPI(): string { return API_MAINTENANCE_CONTROL_PLAN; }

    public static getMaintenanceWarningAPI(): string { return API_MAINTENANCE_WARNING; }

    public static getNotTranslatedClassName(): string { return NOT_TRANSLATED_CLASSNAME; }

    public static getOpacityClassName(): string { return "opacity"; }

    public static getOperationModeMessageTypeValue(): number { return OPERATION_MODE_MESSAGE_TYPE; }

    public static getOperationModeAPI(): string { return API_OPERATION_MODE; }

    //public static getSelectedControllerType(): ControllerType { return this.controllertype; }

    //public static getSelectedLanguage(): Language { return this.language; }

    public static getStartFloorSchemaKey(): number { return START_FLOOR_SCHEMA_KEY; }

    public static getStatusServerError(): number { return STATUS_SERVER_ERROR_CODE; }

    public static getSuccessValue(): number { return SUCCESS; }

    public static getTestCaseAPI(): string { return API_TEST_CASE; }

    public static getTripMessageTypeValue(): number { return TRIP_MESSAGE_TYPE; }

    public static getWaitingClassName(): string { return "waiting"; }

    public static getWarningValue(): number { return WARNING; }

    public static getAzureDataFactory(): string { return API_AZUREDATAFACTORY; }

    public static getBackendSetup(): string { return API_SETUP; }

    public static getElevatorAPI(): string { return API_ELEVATOR; }

    public static getEscalatorAPI(): string { return API_ESCALATOR; }

    public static getCDPControllerTypeMappingAPI(): string { return API_CDP_CONTROLLER_TYPE_MAPPING; }

    public static getRemoteActionAPI(): string { return API_REMOTEACTIONS; }
    // Allen
    public static getClaimsAPI(): string { return API_CLAIMS; }

    public static getTenantAPI(): string { return API_TENANT; }

    public static getFingerprintAPI(): string { return API_FINGERPRINT; }

    public static getBranchHierarchyAPI(): string { return API_BRANCHHIERARCHY; }

    public static getVirtualProvisioningAPI(): string { return API_VIRTUALPROVISIONING; }

    // External databases

    public static getInfoHubAPI(): string { return API_INFOHUB; }

    public static getEventArchiveAPI(): string { return API_EVENTARCHIVE; }

    // SEPE 06/2022
    public static getTransmissionModeConfigurationAPI(): string { return API_TRANSMISSION_MODE_CONFIGURATION; }

    public static getMasterAlertWindowAPI(): string { return API_MASTER_ALERT_WINDOW; }

    public static getStableEnvironment(): string { return STABLE_STAGE; }

    public static getProductionEnvironment(): string { return PRODUCTION_STAGE; }

    public static getDevEnvironment(): string { return DEV_STAGE; }

    //SEPE 11/2022
    public static getAPIFingerPrint(): string { return API_FINGERPRINT; }

    public static getGroupFunctionAPI(): string { return API_GROUPFUNCTION; }

    public static getManagementIdAPI(): string { return API_MANAGEMENTID; }

    public static getFiscalYearAPI(): string { return API_FISCALYEAR; }

    public static getPeriodAPI(): string { return API_PERIOD; }

    public static getKpiManagementAPI(): string { return API_KPIMANAGEMENT; }

    public static getMarketQueryAPI(): string { return API_MARKETQUERY; }

    public static getBranchExcellenceAPI(): string { return API_BRANCHEXCELLENCE; }

    public static getGlobalTaskListAPI(): string { return API_GLOBALTASKLIST; }

    // UMP ENDPOINT
    public static getUMPUser(): string { return API_UMPUSER; }
    public static getWiki(): string { return API_Wiki; }

    // EMT ENDPOINT
    public static getEMTAPI(): string { return API_EMT; }

    //JCOB ENDPOINT
    public static getJCOBAPI(): string { return API_JCOB; }


    ///**
    // * hide the dialog
    // * 
    // * @param tthis
    // * @deprecated
    // */
    //public static hideDialog(tthis: any) {
    //    tthis.classdialog = this.getDialogHideClassName();
    //    tthis.defaults.classname = tthis.classname = "";
    //}

    public static getClusterComponent(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + API_GENERIC + "appmodules/" + Utils.getDomainByWindowLocation();;
        http.get<AppModuleComponent>(url).toPromise().then(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });

    }

    public static getRegions(http: HttpClient, baseUrl: string): Observable<OrgHierarchy[]> {
        const url = baseUrl + API_ORGHIERARCHY + "/regions";
        return http.get<OrgHierarchy[]>(url);

    }

    public static getAllBranches(http: HttpClient, baseUrl: string): Observable<OrgHierarchy[]> {
        const url = baseUrl + API_ORGHIERARCHY + "/branches";
        return http.get<OrgHierarchy[]>(url);

    }

    public static getBranches(http: HttpClient, baseUrl: string, region: string): Observable<OrgHierarchy[]> {
        const url = baseUrl + API_ORGHIERARCHY + "/branches/" + region;
        return http.get<OrgHierarchy[]>(url);

    }

    public static getBusinessKeyLookups(http: HttpClient, baseUrl: string, businessKeyType: BusinessKeyTypes): Observable<BusinessKey[]> {
        const url = baseUrl + API_BUSINESS_KEY + "all/" + businessKeyType;
        return http.get<BusinessKey[]>(url);

    }

    public static httpGetAllAssetTypes(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) { //: Observable<Country[]> {
        const url = baseUrl + API_CONTROLLER_TYPE + "assettype";
        return http.get<AssetType[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }

    public static httpGetAllBusinessKeys(http: HttpClient, baseUrl: string, TranslationAreaID: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BUSINESS_KEY + "all/" + TranslationAreaID;
        http.get<BusinessKey[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpDownloadOperationModes(http: HttpClient, baseUrl: string, controllerType: string, tthis: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + 'download/operationmodes/operation-modes';
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, "OperationModes_" + controllerType, "xlsx")
        }, (error: any) => {
            tthis.onError(error);
        })
    }

    public static httpDownloadErrorCodes(http: HttpClient, baseUrl: string, controllerType: string, tthis: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + 'download/errorcodes/error-codes';
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, "ErrorCodes_" + controllerType, "xlsx")
        }, (error: any) => {
            tthis.onError(error);
        })
    }

    public static httpDownloadBusinessKeys(http: HttpClient, baseUrl: string, TranslationAreaID: number, translationAreaName: string) {
        const url = baseUrl + API_EXPORTIMPORT + 'download/businesskeys/' + TranslationAreaID;
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, "BusinessKeys_" + translationAreaName, "xlsx")
        })
    }

    public static httpDownloadMarketQuery(http: HttpClient, baseUrl: string, file_name: string, maketQueryType: 'MARKET' | 'SEGMENT') {
        const url = `${baseUrl}${API_EXPORTIMPORT}download/marketquery/${maketQueryType}`;
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, file_name, "xlsx"),
                (error: any) => {
                    Utils.writeLog(http, baseUrl, error);
                }
        });
    }
    public static httpDownloadExcellenceFactors(http: HttpClient, baseUrl: string, file_name: string, ItemType: string) {
        const url = `${baseUrl}${API_EXPORTIMPORT}download/excellenceFactor/${ItemType}`;
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, file_name, "xlsx"),
                (error: any) => {
                    Utils.writeLog(http, baseUrl, error);
                }
        });
    }

    // CDP SEPE 06/2022
    public static httpGetAllControllerTypeLocal(http: HttpClient, baseUrl: string, filter: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CDP_CONTROLLER_TYPE_MAPPING + "all/" + filter.toString();
        http.get<ControllerTypeMapping[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }


    public static httpGetAllCauses(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BUSINESS_KEY + "cause";
        http.get<BusinessKeySon[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllChangeLog(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_LOG + "changes";
        tthis.subscriptions.push(http.get<ChangeLog[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        })
        );
    }

    public static httpGetAllControllerDrives(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) { //: Observable<Country[]> {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllerdrive";
        return http.get<ControllerDrive[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }

    public static httpGetAllControllerOwner(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) { //: Observable<Country[]> {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllerowner";
        return http.get<Country[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });

    }
    public static httpGetAllPopulationforController(http: HttpClient, baseUrl: string, controllerBK: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_HEALTHSCORE + "/allPopulation/" + controllerBK;
        return http.get<Population[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }
    public static httpGetDialerNameAndRegulatoryCodes(http: HttpClient, baseUrl: string, tenant: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TENSPECIFICCONF + "/dialerCodes/" + tenant;
        return http.get<any>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            //tthis.getRegCodeForDialer(tthis.selectedDialer);
            Utils.writeLog(http, baseUrl, error);
        });
    }
    public static httpGetDialerProperties(http: HttpClient, baseUrl: string, queryString: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TENSPECIFICCONF + "/dialerproperties/" + queryString;
        return http.get<any>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            tthis.Response_State = -1;
            Utils.writeLog(http, baseUrl, error);
        });
    }

    public static httpPostDialerProperties(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + API_TENSPECIFICCONF + "/updatedialerproperties";
        //console.log("before sending request ", body);
        http
            .post<string>(url, body).subscribe((result: string) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis, result);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceed = -1;
                callback(tthis);
            });
    }
    public static httpGetAllScoreFactorforPopulation(http: HttpClient, baseUrl: string, populationId: number | undefined, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_HEALTHSCORE + "/scoreFactor/" + populationId;
        return http.get<ScoreFactor[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }
    public static httpGetAllScoreFactorFeatures(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_HEALTHSCORE + "/scoreFactor/" + "allfeatures";
        return http.get<Feature[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }
    public static httpDeleteScoreFactor(http: HttpClient, baseUrl: string, scoreFactorId: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_HEALTHSCORE + "/scoreFactor/" + scoreFactorId;
        return http.delete<Boolean>(url, {}).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }
    public static httpUpdateScoreFactor(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + API_HEALTHSCORE + "/updateSF";
        //console.log("before sending request ", body);
        http
            .put<string>(url, body).subscribe((result: string) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceed = -1;
                callback(tthis);
            });
    }
    public static httpGetAllCountries(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) { //: Observable<Country[]> {
        const url = baseUrl + API_COUNTRY + "all";
        return http.get<Country[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });

    }

    public static httpPostTriggerAzdf(http: HttpClient, baseUrl: string, body: any, type: string, item: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_AZUREDATAFACTORY + type + "/" + item;
        http.post<any>(url, body).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result, type, item);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetLastPipelineRunsAzdf(http: HttpClient, baseUrl: string, type: string, item: string, startDate: Date, endDate: Date, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + API_AZUREDATAFACTORY + type + "/" + item + "/" + startDate.toISOString() + "/" + endDate.toISOString();
        http.get<any>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetActivitiesByRunId(http: HttpClient, baseUrl: string, type: string, runId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_AZUREDATAFACTORY + "activities/" + type + "/" + runId;
        http.get<string[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetTargetSystemByTenant(http: HttpClient, baseUrl: string, stage: number, tenant: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONFIGURATION + "webservice/" + stage;
        http.get<any>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetWikiByEntity(http: HttpClient, baseUrl: string, stage: number, entity: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CDPWIKI + stage + "/" + entity;
        http.get<any>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpDownloadWsdlByEntity(http: HttpClient, baseUrl: string, stage: number, entity: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + 'cdp/wiki/wsdl/' + stage + "/" + entity;
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, entity, "wsdl")
        })
    }

    public static httpDownloadDocByEntity(http: HttpClient, baseUrl: string, stage: number, entity: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + 'cdp/wiki/doc/' + stage + "/" + entity;
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, entity, "xlsx")
        })
    }

    public static httpGetTechnicalUsersSetup(http: HttpClient, baseUrl: string, stage: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_SETUP + 'technicaluser';
        http.get<any>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetWebServiceConfigurationList(http: HttpClient, baseUrl: string, stage: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_SETUP + 'webserviceconfigration/' + stage;
        http.get<any>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetCertificates(
        http: HttpClient, baseUrl: string, isSSL: boolean,
        callback: any | undefined = undefined,
        errcallback: any | undefined = undefined
    ) {
        const url = baseUrl + API_SETUP + 'certificate/' + isSSL;
        http.get(url, { responseType: 'blob' })
            .subscribe({
                next: (response: any) => {
                    this.downloadFile(response, "certificates", "zip")
                    this.httpGetZipPassword(http, baseUrl, callback)
                },
                error: () => {
                    if (errcallback)
                        errcallback();
                }
            });
    }

    public static httpGetZipPassword(http: HttpClient, baseUrl: string, callback: any | undefined = undefined) {
        const url = baseUrl + API_SETUP + 'zip-password/';
        http.get<any>(url).subscribe({
            next: result => {
                if (callback)
                    callback(result);
            }, error: error => {
                Utils.writeLog(http, baseUrl, error);
            }
        });
    }

    public static httpGetAllElevators(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ELEVATOR + "all";
        http.get<Elevator[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllEscalators(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ESCALATOR + "all";
        http.get<Escalator[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllOtherAssets(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_OTHERASSET + "all";
        http.get<OtherAsset[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllComponents(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, componentSubSystemID: number, callback: any | undefined = undefined) {
        const url = baseUrl + API_BUSINESS_KEY + "component/" + componentSubSystemID;
        http.get<ComponentItem[]>(url).subscribe(result => {
            if (callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllControllerConfig(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllerconfig";
        http.get<ControllerConfig[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllControllerConfigDataType(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllerconfigdatatype";
        http.get<ControllerConfigDataType[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllControllerConfigDescription(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllerconfigdescription";
        http.get<ControllerConfigDescription[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllControllerConfigPredefinedItemsDescription(http: HttpClient, baseUrl: string, controllerConfigBK: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllerconfigpredefineditemsdescriptions/" + controllerConfigBK;
        http.get<any[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllControllerTypeByTenant(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllertypebytenant";
        http.get<ControllerTypeByTenant[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    /**
     * return all the controllertype by tenant for countries
     *
     * @param http
     * @param baseUrl
     * @param tthis
     * @param callback
     */
    public static httpGetAllControllerTypeByTenantCountries(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllertypebytenant/" + id;
        http.get<ControllerTypeByTenant[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    /**
     * return all the controllertype to connection type
     *
     * @param http
     * @param baseUrl
     * @param tthis
     * @param callback
     */
    public static httpGetAllControllerTypeToConnectionType(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllertypetoconnectiontype";
        http.get<ControllerTypeToConnectionType[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    /**
     * return all the controllertype to masterdata required
     *
     * @param http
     * @param baseUrl
     * @param tthis
     * @param callback
     */
    public static httpGetAllControllerTypeToMasterdataRequired(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllertypetomasterdatarequired";
        http.get<ControllerTypeToMasterdataRequired[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    /**
     * return all deployment status by controller type
     *
     * @param http
     * @param baseUrl
     * @param tthis
     * @param callback
     */
    public static httpGetAllDeploymentStatusByControllerType(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "controllertypedeploymentstatus";
        http.get<EventArchiveStatus[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetLastBuildIdByInformationType(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, informationType: number, callback: any | undefined = undefined, errorCallback: any | undefined = undefined) {
        const url = baseUrl + API_BUILDRELEASE + "build/" + informationType;
        http.get<BuildRelease>(url).subscribe({
            next: result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error: error => {
                Utils.writeLog(http, baseUrl, error);
                if (tthis && errorCallback)
                    errorCallback(tthis, error);
            }
        });
    }

    public static httpGetReleaseByControllerTypeAndInformationType(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, informationType: number, callback: any | undefined = undefined, errorCallback: any | undefined = undefined) {
        const url = baseUrl + API_BUILDRELEASE + "release/" + informationType;
        http.get<any>(url).subscribe({
            next: result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error: error => {
                Utils.writeLog(http, baseUrl, error);
                if (tthis && errorCallback)
                    errorCallback(tthis, error);
            }
        });
    }

    public static httpCreateBuildByControllerTypeAndInformationType(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, informationType: number, callback: any | undefined = undefined, errorCallback: any | undefined = undefined) {
        const url = baseUrl + API_BUILDRELEASE + informationType;
        http.put<any>(url, {}).subscribe({
            next: result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error: error => {
                Utils.writeLog(http, baseUrl, error); 
                if (tthis && errorCallback)
                    errorCallback(tthis, error);
            }
        }); 
    }

    public static httpReleaseEnvironmentByControllerTypeAndInformationType(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, informationType: number, env: number, callback: any | undefined = undefined, errorCallback: any | undefined = undefined) {
        const url = baseUrl + API_BUILDRELEASE + 'release/' + env + '/' + informationType;
        http.put<any>(url, {}).subscribe({
            next: result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error: error => {
                Utils.writeLog(http, baseUrl, error); 
                if (tthis && errorCallback)
                    errorCallback(tthis, error);
            }
        });
    }


    /**
     * return all the controllertypes available to the user's tenant
     * 
     * @param http
     * @param baseUrl
     * @param tthis
     * @param callback
     */
    public static httpGetAllControllerTypes(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "all";
        http.get<ControllerTypeByTenant[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    /**
     * return all the controllertypes available to the user's tenant - Allen
     * 
     * @param http
     * @param baseUrl
     * @param tthis
     * @param tenantid
     * @param callback
     */
    public static httpGetAllControllerTypesByTenant(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, tenantid: string, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "all/" + tenantid;
        http.get<ControllerTypeByTenant[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    /**
     * return all the controllertypes digitalservices available to the user's tenant - Allen
     * 
     * @param http
     * @param baseUrl
     * @param tthis
     * @param tenantid
     * @param callback
     */
    public static httpGetAllControllerTypesByTenantDigitalServices(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, tenantid: string, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "alldigitalservices/" + tenantid;
        http.get<ControllerTypeByTenant[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    /**
     * return all controllertypes available
     * 
     * @param http
     * @param baseUrl
     * @param tthis
     * @param callback
     */
    public static httpGetAllControllerTypesAvailable(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE + "all-controllertype";
        http.get<ControllerType[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    /**
     * return if the current selected tenant and controller type is the controller owner rule.
     * 
     * @param http
     * @param baseUrl
     * @param tthis
     * @param callback
     */
    public static async httpGetIsControllerOwner(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, tenant: string, controllerTypeId: number, callback: any | undefined = undefined) {
        const url = baseUrl + API_GENERIC + "controllerowner/" + `${tenant}/${controllerTypeId}`;
        http.get<boolean[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static async httpGetAllErrorCodes(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "all";
        await http.get<ErrorCode[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllTroubleShootingKeywords(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "keywords";
        http.get<string[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpPutErrorCodesAlertRulesToProd(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "alertrulestoprod";
        http
            .put<boolean>(url, {})
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    // Allen: get all error codes ml
    public static async httpGetErrorCodesML(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "errorcodeml";
        await http.get<ErrorCodeML[]>(url).subscribe(result => {
            if (tthis && callback) {
                callback(tthis, result)
            }
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }


    // Allen: get all possible failure ml undo histories
    public static async httpGetPossibleFailureMLUndoHistories(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "possiblefailuremlundohistory";
        await http.get<PossibleFailureMLUndoHistory[]>(url).subscribe(result => {
            if (tthis && callback) {
                callback(tthis, result)
            }
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }

    //public static httpGetAllErrorCodes(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
    //    const url = baseUrl + API_ERROR_CODE + "all";
    //    http.get<ErrorCode[]>(url).subscribe(result => {
    //        if (tthis && callback)
    //            callback(tthis, result);
    //    }, error => {
    //        Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
    //    }); //console.error(error));
    //}

    public static httpGetAllErrorCodesNotMapped(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "notmapped";
        http.get<ErrorCodeNotMapped[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllErrorCodesForControllerType(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "allerrorcodescontrollertype/" + id;
        http.get<ErrorCode[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }


    public static httpGetAllErrorCodeResolutions(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "resolution/" + id;
        http.get<ErrorCodeResolution[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllFPErrorCodeResolutions(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        let url = baseUrl + API_FINGERPRINT + "resolution/" + id;
        http.get<ErrorCodeResolution[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }


    public static httpGetDESDeviceRelationship(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "desdevices/" + id;
        http.get<DESDeviceRelationship[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    // Allen: get PossibleFailureML by ErrorCodeId
    public static httpGetPossbileFailureMLByErrorCodeId(http: HttpClient, baseUrl: string, errorcodeid: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "possiblefailureml/" + errorcodeid;
        http.get<PossibleFailureML[]>(url).subscribe(result => {
            if (tthis && callback) {
                callback(tthis, result)
            }
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }

    public static getPossibleFailureMLAPIUrl(baseUrl: string, errorcodeid: number) {
        return baseUrl + API_ERROR_CODE + "possiblefailureml/" + errorcodeid;
    }

    /**
     *
     * @param http
     * @param baseUrl
     * @param tthis     - [optional] passing this object to use in the callback function
     * @param callback
     */
    public static httpGetAllExceptionLog(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_LOG + "Exceptions";
        http.get<ExceptionLog[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    /**
     * 
     * @param http
     * @param baseUrl
     * @param tthis     - [optional] passing this object to use in the callback function
     * @param callback
     */
    public static httpGetAllLanguages(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_LANGUAGE;

        http.get<Language[]>(url + "all").subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error)); 
    }

    public static httpGetAllLocations(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BUSINESS_KEY + "location";
        http.get<ComponentSubSystem[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllMaintenanceControlPlan(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + "all";
        http.get<MaintenanceControlPlan[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllMaintenanceControlPlanGroupActions(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + "allgroupactions/" + id;
        http.get<MaintenanceControlPlanGroupAction[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpAllGetMaintenanceControlPlanGroupActionComponents(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + "allgroupactioncomponents/" + id;
        http.get<MaintenanceControlPlanGroupActionComponent[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpAllGetMaintenanceControlPlanGroupActionComponentErrors(http: HttpClient, baseUrl: string, id: number, component: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + "allgroupactioncomponenterrors/" + id + "/" + component;
        http.get<MaintenanceControlPlanGroupActionComponentError[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpAllGetMaintenanceControlPlanGroupActionComponentProposedErrors(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + "allgroupactioncomponentproposederrors/" + id;
        http.get<MaintenanceControlPlanGroupActionComponentProposedErrors[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllMaintenanceControlPlanGroups(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + "allgroups/" + id;
        http.get<MaintenanceControlPlanGroup[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllWarnings(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_WARNING + "all";
        http.get<MaintenanceWarning[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllWarningResolutions(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_WARNING + "resolutions/" + id;
        http.get<MaintenanceWarningResolution[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpDownloadMaintenanceWarnings(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/maintenancewarnings/maintenancewarnings";
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, 'MaintenanceWarnings', "xlsx")
        });
    }

    public static httpGetAllFingerprintRulesByTenant(http: HttpClient, baseUrl: string, ids: string[], tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        let httpParams: HttpParams = new HttpParams();
        ids.forEach(id => httpParams = httpParams.append('tenants', id));

        const url = baseUrl + API_FINGERPRINT + "all/";

        http.get<TenantControllerTypeFingerprintRule[]>(url, { params: httpParams }).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

        }); //console.error(error));
    }

    public static httpFPControllerConfiguration(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        let httpParams: HttpParams = new HttpParams();
        //        ids.forEach(id => httpParams = httpParams.append('tenants', id));

        let url = baseUrl + API_FINGERPRINT + "fp-controllerconfiguration/";

        http.get<TenantControllerTypeFingerprintRule[]>(url, { params: httpParams }).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

        }); //console.error(error));
    }

    public static httpFPModesAvailable(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        let httpParams: HttpParams = new HttpParams();
        //        ids.forEach(id => httpParams = httpParams.append('tenants', id));

        let url = baseUrl + API_FINGERPRINT + "fp-modesavailable/";

        http.get<any[]>(url, { params: httpParams }).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

        }); //console.error(error));
    }


    public static httpFPTriggerConditions(http: HttpClient, baseUrl: string, PopulationSetupId: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        let httpParams: HttpParams = new HttpParams();
        //        ids.forEach(id => httpParams = httpParams.append('tenants', id));

        let url = baseUrl + API_FINGERPRINT + "fp-triggerconditions/" + PopulationSetupId;

        http.get<any[]>(url, { params: httpParams }).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

        }); //console.error(error));
    }




    public static httpGetBranchHierarchy(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BRANCHHIERARCHY;

        http.get<BranchHierarchyView[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

        }); //console.error(error));
    }

    public static httpGetBranchHierarchyRef(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BRANCHHIERARCHY + '/ref';

        http.get<BranchHierarchyRef[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

        }); //console.error(error));
    }

    public static httpGetBranchHierarchyKey(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BRANCHHIERARCHY + '/key';

        http.get<BranchHierarchyKey[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

        }); //console.error(error));
    }

    public static httpDownloadBranchHierarchy(http: HttpClient, baseUrl: string, stage: number, tenant: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/branchhierarchy";
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenant + '_BranchHierarchy', "xlsx")
        });
    }

    public static httpGetBranchHierarchyKeyByCountry(http: HttpClient, baseUrl: string, country: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BRANCHHIERARCHY + '/keys/' + country;

        http.get<BranchHierarchyKey[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

        }); //console.error(error));
    }

    public static httpGetAllOperationModes(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_OPERATION_MODE + "all";
        http.get<OperationMode[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllOperationModesHarmonized(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_OPERATION_MODE + "harmonized";
        http.get<HarmonizedOperationMode[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpPutOpModesAlertRulesToProd(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_OPERATION_MODE + "alertrulestoprod";
        http
            .put<boolean>(url, {})
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetAllAlertRules(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ALERTRULE
        http.get<AlertRule[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllResolutions(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BUSINESS_KEY + "resolution";
        http.get<BusinessKeySon[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllTestAreas(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + "areas";
        http.get<TestArea[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllTestCases(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + "all/" + false;
        http.get<TestCase[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllTestCasesFilteredByITRelevant(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + "all/" + true;
        http.get<TestCase[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllTestCasesDatabricksTrigger(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + "databrickstriggers";
        http.get<TestCaseDatabricksTrigger[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllDevices(http: HttpClient, baseUrl: string, filter: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + "devices/" + filter;
        http.get<Elevator[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetAllTranslationAreas(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TRANSLATION_AREA + "all";
        http.get<TranslationArea[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetBusinessKey(http: HttpClient, baseUrl: string, translationCodeID: number, TranslationAreaID: number, LanguageID: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BUSINESS_KEY + translationCodeID + "/" + TranslationAreaID + "/" + LanguageID;
        http.get<BusinessKey[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetErrorCode(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + id;
        http.get<ErrorCode>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetFileDeploy(http: HttpClient, baseUrl: string, title: string) {
        let api = API_OPERATION_MODE;
        if (title === "Error") api = API_ERROR_CODE;

        const url = baseUrl + api;
        http.get(url);
    }

    //public static httpGetIsUserAdmin(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
    //    const url = baseUrl + API_GENERIC;

    //    http.get<boolean>(url + "isadmin").subscribe(result => {
    //        if (tthis && callback)
    //            callback(tthis, result);
    //    }, error => {
    //        Utils.writeLog(http, baseUrl, error);
    //    });
    //}


    public static httpGetLanguageControllerTypeOperationMode(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_LANGUAGE;

        http.get<Language[]>(url + "operationmode").subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetLanguageControllerTypeErrorCode(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_LANGUAGE;

        http.get<Language[]>(url + "errorcode").subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetMaintenanceControlPlan(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + id;
        http.get<MaintenanceControlPlan>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetMaintenanceControlPlanGroupAction(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + "groupaction/" + id;
        http.get<MaintenanceControlPlanGroupAction>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetMaintenanceControlPlanGroupActionComponent(http: HttpClient, baseUrl: string, id: number, component: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + "groupactioncomponent/" + id + "/" + component;
        http.get<MaintenanceControlPlanGroupActionComponent>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }


    public static httpGetMaintenanceControlPlanGroup(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_MAINTENANCE_CONTROL_PLAN + "group/" + id;
        http.get<MaintenanceControlPlanGroup>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetPossibleFailure(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "possiblefailure/" + id;
        http.get<ErrorCodeResolution>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpTranslatePossibleFailure(http: HttpClient, baseUrl: string, data: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_ERROR_CODE + "translatepossiblefailure";
        http.post<any>(url, data).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetOperationMode(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_OPERATION_MODE + id;

        http.get<OperationMode>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetSelectedControllerType(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTROLLER_TYPE;
        http.get<ControllerType>(url + "selected").subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
            //this.controllertype = result;
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error)
        }); //console.error(error));
    }

    // Allen for Claims
    public static httpGetClaims(http: HttpClient, baseUrl: string, tthis: any, callback: Function) {
        const url = baseUrl + API_CLAIMS;
        http.get<Claims>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }

    public static httpGetSelectedTenant(http: HttpClient, baseUrl: string, tthis: any, callback: Function) {
        const url = baseUrl + API_TENANT;
        http.get<string>(url + "selected").subscribe(result => {
            if (tthis && callback) {
                callback(tthis, result);
            }
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }

    /**
     * 
     * @param http
     * @param baseUrl
     * @param tthis     - [optional] passing this object to use in the callback function
     * @param callback
     */
    public static httpGetSelectedLanguage(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_LANGUAGE;
        http.get<Language>(url + "selected").subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
            //this.language = result;
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetTestCase(http: HttpClient, baseUrl: string, id: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + id;
        http.get<TestCase>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetTestCaseBatched(http: HttpClient, baseUrl: string, testcaseid: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + "batched/" + testcaseid;
        http.get<TestCaseBatched[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetTestCaseMessageTypeSchemaKeyDescription(http: HttpClient, baseUrl: string, testcaseid: number, messagetypeid: number, index: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + "message/" + testcaseid + "/" + messagetypeid + "/" + index;
        http.get<TestCaseMessageTypesSchemaKeysDescription>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetTestCaseMessageTypeSchemaKeyDescriptions(http: HttpClient, baseUrl: string, testcaseid: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + "message/" + testcaseid;
        http.get<TestCaseMessageTypesSchemaKeysDescription[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetTestCaseMessageTypeSchemaKeyValues(http: HttpClient, baseUrl: string, testcaseid: number, messagetypeid: number, indexintestcase: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_TEST_CASE + "messagevalues/" + testcaseid + "/" + messagetypeid + "/" + indexintestcase;
        http.get<TestCaseMessageTypesSchemaKeys[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetUserTenant(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_COUNTRY + "usertenant";
        http.get<Country>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }

    public static httpGetContracts(http: HttpClient, baseUrl: string, stage: number, body: ContractFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "contracts/" + stage;
        tthis.subscriptions.push(http
            .post<Contract[]>(url, body)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })); //console.error(error));
    }

    public static httpDownloadContracts(http: HttpClient, baseUrl: string, stage: number, tenantId: string, body: ContractFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/contracts?stage=" + stage + "&filename=contracts";
        http.post(url, body, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenantId + '_Contracts', "xlsx")
        });
    }

    public static httpGetDetailedContract(http: HttpClient, baseUrl: string, stage: number, contract: Contract, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB +
            "contract-info" + "/"
            + stage + "/"
            + encodeURIComponent(this.encodeSlashes(contract.ContractNumber)) + "/"
            + encodeURIComponent(this.encodeSlashes(contract.UnitId))
            + "/" + encodeURIComponent(contract.ContractLine || 'null');
        http
            .get<Contract>(url).subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateContracts(http: HttpClient, baseUrl: string, stage: number, contracts: Contract[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "contracts" + "/" + stage;
        http
            .put<Contract>(url, contracts)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis, error)

            }); //console.error(error));
    }

    public static httpGetContractFacilityManagers(http: HttpClient, baseUrl: string, stage: number, body: FacilityManagerFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "contractsfacilitymanager/" + stage;
        tthis.subscriptions.push(
            http
                .post<FacilityManager[]>(url, body)
                .subscribe(res => {
                    if (tthis && callback)
                        callback(tthis, res);
                }, error => {
                    if (callbackerror)
                        callbackerror(tthis, error)

                })); //console.error(error));
    }

    public static httpDownloadContractFacilityManager(http: HttpClient, baseUrl: string, stage: number, tenantId: string, body: FacilityManagerFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/contractsfacilitymanager?stage=" + stage + "&filename=contractsfacilitymanager";
        http.post(url, body, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenantId + '_ContractFacilityManagers', "xlsx")
        });
    }

    public static httpGetDetailedContractFacilityManager(http: HttpClient, baseUrl: string, stage: number, facilityManager: FacilityManager, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "contractfacilitymanager-info" + "/" + stage + "/" + facilityManager.FacilityManagerId + "/" + facilityManager.ContractNumber;
        http
            .get<FacilityManagerDetails>(url).subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateContractFacilityManagers(http: HttpClient, baseUrl: string, stage: number, facilityManagers: FacilityManager[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "contractsfacilitymanager" + "/" + stage;
        http
            .put<FacilityManager>(url, facilityManagers)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis, error)

            }); //console.error(error));
    }

    public static httpGetContractBundleInformation(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTRACTBUNDLE + "configuration";
        tthis.subscriptions.push(
            http
                .get<ContractBundle[]>(url)
                .subscribe(res => {
                    if (tthis && callback)
                        callback(tthis, res);
                }, error => {
                    Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

                })); //console.error(error));
    }

    public static httpGetContractBundleOptions(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTRACTBUNDLE + "options";
        tthis.subscriptions.push(
            http
                .get<ContractBundle[]>(url)
                .subscribe(res => {
                    if (tthis && callback)
                        callback(tthis, res);
                }, error => {
                    Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

                })); //console.error(error));
    }

    public static httpAddContractBundleByTenant(http: HttpClient, baseUrl: string, bundleId: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTRACTBUNDLE + bundleId;
        http
            .post<any>(url, {})
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpAddAllContractBundleByTenant(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTRACTBUNDLE + 'all';
        http
            .post<any>(url, {})
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpDeleteContractBundleByTenant(http: HttpClient, baseUrl: string, bundleId: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_CONTRACTBUNDLE + bundleId;
        http
            .delete<any>(url, {})
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetCustomerFacilityManagers(http: HttpClient, baseUrl: string, stage: number, body: CustomerFacilityManagerFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "customersfacilitymanager/" + stage;
        tthis.subscriptions.push(http
            .post<CustomerFacilityManager[]>(url, body)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })); //console.error(error));
    }

    public static httpDownloadCustomerFacilityManager(http: HttpClient, baseUrl: string, stage: number, tenantId: string, body: CustomerFacilityManagerFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/customersfacilitymanager?stage=" + stage + "&filename=customersfacilitymanager";
        http.post(url, body, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenantId + '_CustomerFacilityManagers', "xlsx")
        });
    }

    public static httpUpdateCustomerFacilityManagers(http: HttpClient, baseUrl: string, stage: number, facilityManagers: CustomerFacilityManager[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "customersfacilitymanager" + "/" + stage;
        http
            .put<FacilityManager>(url, facilityManagers)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis, error)

            }); //console.error(error));
    }

    public static httpGetCustomers(http: HttpClient, baseUrl: string, stage: number, body: CustomerFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {

        const url = baseUrl + Utils.getInfoHubAPI() + "customers" + "/" + stage;
        tthis.subscriptions.push(http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis, error)

            })); //console.error(error));
    }

    public static httpGetDetailedCustomer(http: HttpClient, baseUrl: string, stage: number, customer: Customer, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "customer-info" + "/" + stage + "/" + customer.CustomerId + "/" + encodeURIComponent(customer.CustomerName);
        http
            .get<Customer>(url).subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateCustomers(http: HttpClient, baseUrl: string, stage: number, customers: any[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "customers" + "/" + stage;
        http
            .put<Customer>(url, customers)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis, error)

            }); //console.error(error));
    }

    public static httpDownloadCustomers(http: HttpClient, baseUrl: string, stage: number, tenantId: string, body: CustomerFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/customers?stage=" + stage + "&filename=customers";
        http.post(url, body, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenantId + '_Customers', "xlsx")
        });
    }

    public static httpGetDetailedServiceTicket(http: HttpClient, baseUrl: string, stage: number, serviceTicket: ServiceTicket, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "servicetickets" + "/" + stage + "/" + encodeURIComponent(this.encodeSlashes(serviceTicket.UnitId)) + "?ticketId=" + serviceTicket.TicketId + "&serviceRecordId=" + serviceTicket.ServiceRecordId
        http
            .get<ServiceTicket>(url).subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetServiceTickets(http: HttpClient, baseUrl: string, stage: number, body: ServiceTicketFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "servicetickets" + "/" + stage;
        tthis.subscriptions.push(http
            .post<ServiceTicket[]>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })); //console.error(error));
    }

    public static httpUpdateServiceTickets(http: HttpClient, baseUrl: string, stage: number, servicetickets: any[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "servicetickets" + "/" + stage;
        http
            .put<ServiceTicket>(url, servicetickets)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis, error)

            }); //console.error(error));
    }

    public static httpDownloadServiceTickets(http: HttpClient, baseUrl: string, stage: number, tenantId: string, body: ServiceTicketFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/servicetickets?stage=" + stage + "&filename=servicetickets";
        http.post(url, body, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenantId + '_ServiceTickets', "xlsx")
        });
    }

    public static httpGetElevators(http: HttpClient, baseUrl: string, stage: number, body: ElevatorFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getEventArchiveAPI() + "elevators" + "/" + stage;
        tthis.subscriptions.push(http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })); //console.error(error));
    }

    public static httpGetDetailedElevator(http: HttpClient, baseUrl: string, stage: number, elevator: Elevator, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEventArchiveAPI() + "elevator-info" + "/" + stage + "/" + encodeURIComponent(this.encodeSlashes(elevator.UnitID));
        http
            .get<ElevatorDetails>(url).subscribe((res: ElevatorDetails) => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateElevators(http: HttpClient, baseUrl: string, elevators: any[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_EVENTARCHIVE + "elevators";
        http
            .put<Elevator>(url, elevators)
            .subscribe(() => {
                if (tthis && callback)
                    callback(tthis);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis)

            }); //console.error(error));
    }

    public static httpGetEscalators(http: HttpClient, baseUrl: string, stage: number, body: EscalatorFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getEventArchiveAPI() + "escalators" + "/" + stage;
        tthis.subscriptions.push(http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })); //console.error(error));
    }

    public static httpGetDetailedEscalator(http: HttpClient, baseUrl: string, stage: number, escalator: Escalator, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEventArchiveAPI() + "escalator-info" + "/" + stage + "/" + encodeURIComponent(this.encodeSlashes(escalator.UnitID));
        http
            .get<any>(url).subscribe((res: any) => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateEscalators(http: HttpClient, baseUrl: string, stage: number, escalators: any[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_EVENTARCHIVE + "escalators";
        http
            .put<Escalator>(url, escalators)
            .subscribe(() => {
                if (tthis && callback)
                    callback(tthis);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis)

            }); //console.error(error));
    }

    public static httpDownloadEscalators(http: HttpClient, baseUrl: string, tenantId: string, body: EscalatorFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/escalators?filename=escalators";
        http.post(url, body, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenantId + '_Escalators', "xlsx")
        });
    }

    public static httpGetOtherAssets(http: HttpClient, baseUrl: string, stage: number, body: OtherAssetFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getEventArchiveAPI() + "otherassets" + "/" + stage;
        tthis.subscriptions.push(http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })); //console.error(error));
    }

    public static httpGetDetailedOtherAsset(http: HttpClient, baseUrl: string, stage: number, otherasset: OtherAsset, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEventArchiveAPI() + "otherasset-info" + "/" + stage + "/" + encodeURIComponent(this.encodeSlashes(otherasset.UnitID));
        http
            .get<any>(url).subscribe((res: any) => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetOtherAssetAttributes(http: HttpClient, baseUrl: string, stage: number, otherasset: OtherAsset, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEventArchiveAPI() + "otherasset-attr" + "/" + stage + "/" + encodeURIComponent(this.encodeSlashes(otherasset.UnitID));
        http
            .get<any>(url).subscribe((res: any) => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateOtherAssets(http: HttpClient, baseUrl: string, stage: number, otherassets: any[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_EVENTARCHIVE + "otherasset" + "/" + stage;
        http
            .put<OtherAsset>(url, otherassets)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis)
            }); //console.error(error));
    }

    public static httpUpdateOtherAssetAttrs(http: HttpClient, baseUrl: string, stage: number, otherassetsattrs: any[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_EVENTARCHIVE + "otherassetattrs" + "/" + stage;
        http
            .put<OtherAsset>(url, otherassetsattrs)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis, error)

            }); //console.error(error));
    }

    public static httpDownloadOtherAssets(http: HttpClient, baseUrl: string, tenantId: string, body: OtherAssetFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/otherassets?filename=otherassets";
        http.post(url, body, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenantId + '_OtherAssets', "xlsx")
        });
    }

    public static httpGetKpiUnits(http: HttpClient, baseUrl: string, stage: number, body: KpiFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getInfoHubAPI() + "kpiunits" + "/" + stage;
        http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetKpis(http: HttpClient, baseUrl: string, stage: number, body: KpiUnit, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "kpis" + "/" + stage + "/" + body.UnitId + "/" + body.ContractNumber + "/" + body.CustomerId;
        http
            .get<any>(url).subscribe((res: any) => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateKpis(http: HttpClient, baseUrl: string, stage: number, items: any[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_INFOHUB + "kpis" + "/" + stage;
        http
            .put<OtherAsset>(url, items)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis, error)

            }); //console.error(error));
    }

    public static httpDownloadKpis(http: HttpClient, baseUrl: string, stage: number, tenantId: string, body: KpiFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/kpis?stage=" + stage + "&filename=kpis";
        http.post(url, body, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenantId + '_Kpis', "xlsx")
        });
    }

    public static httpGetChairlifts(http: HttpClient, baseUrl: string, stage: number, body: ChairliftFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getEventArchiveAPI() + "chairlifts" + "/" + stage;
        tthis.subscriptions.push(http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })); //console.error(error));
    }

    public static httpGetDetailedChairlift(http: HttpClient, baseUrl: string, stage: number, chairlift: Chairlift, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEventArchiveAPI() + "chairlift-info" + "/" + stage + "/" + encodeURIComponent(this.encodeSlashes(chairlift.UnitId));
        http
            .get<ChairliftDetails>(url).subscribe((res: ChairliftDetails) => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateChairlifts(http: HttpClient, baseUrl: string, stage: number, chairlifts: any[], tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_EVENTARCHIVE + "chairlifts" + "/" + stage;
        http
            .put<Chairlift>(url, chairlifts)
            .subscribe(res => {
                if (tthis && callback)
                    callback(tthis, res);
            }, error => {
                if (callbackerror)
                    callbackerror(tthis, error)

            }); //console.error(error));
    }

    public static httpDownloadChairlifts(http: HttpClient, baseUrl: string, stage: number, tenantId: string, body: ChairliftFilter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/chairlifts?stage=" + stage + "&filename=chairlifts";
        http.post(url, body, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, tenantId + '_chairlifts', "xlsx")
        });
    }

    public static httpGetCommands(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "commands";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetAllowedRemoteActions(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "allowedactions";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetCommandConfigControllerType(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "commandconfigcontrollertype";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetCommandConfigTemplate(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "commandconfigtemplate";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetAssetParameters(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "assetparameter";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetParameterKeywords(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "parameterkeywords";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetParameterConfigController(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "parametersconfigcontroller";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateParameterConfigController(http: HttpClient, baseUrl: string, body: ParameterConfigController, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "parametersconfig";
        http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetFunctionConfig(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "functionconfig";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetFunctionConfigParameters(http: HttpClient, baseUrl: string, functionKey: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "functionconfigparams/" + functionKey;
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpCreateParameterConfig(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "parameter";
        http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateFunctionConfigParameter(http: HttpClient, baseUrl: string, body: FunctionConfigParameter, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "functionconfigparams";
        http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpCreateFunctionConfig(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "functionconfig";
        http
            .post<any>(url, body).subscribe(result => {
                if (tthis && callback)
                    callback(tthis);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetRemoteResetCommandConfig(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "remoteresetcommandconfig";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetRemoteResetSupported(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getRemoteActionAPI() + "isremoteresetsupported";
        http
            .get<any>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetAvailableMacAddress(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getVirtualProvisioningAPI() + "/registry";
        tthis.subscriptions.push(http
            .get<string>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })
        )
    }

    public static httpGetControllerTypes(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getVirtualProvisioningAPI() + "/controllertypes";
        tthis.subscriptions.push(http
            .get<string>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }))
    }

    public static httpGetUnitIdsToDeviceMapping(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getVirtualProvisioningAPI() + "/unittodevicemapping";
        tthis.subscriptions.push(http
            .get<UnitIdToDeviceMapping>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })
        )
    }

    public static httpGetUnprovisionedAssetByUnitId(http: HttpClient, baseUrl: string, unitId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getVirtualProvisioningAPI() + "/unprovisionedassetbyunitid/" + unitId;
        http
            .get(url).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetIsManyToOneProvisioning(http: HttpClient, baseUrl: string, macAddress: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getVirtualProvisioningAPI() + "/isManyToOneProvisioning/" + macAddress;
        tthis.subscriptions.push(http
            .get<boolean>(url).subscribe(result => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            })
        )
    }

    public static httpCreateProvisioningRequest(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getVirtualProvisioningAPI() + "/provision";
        http
            .post<any>(url, body).subscribe(() => {
                if (tthis && callback)
                    callback(tthis);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpPreRegisterBluebox(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getVirtualProvisioningAPI() + "/PreRegisterBlueBox";
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetGroupFunctionByCurrentManagementId(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getGroupFunctionAPI();
        http
            .get<string>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpGetSelectedGroupFunction(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getGroupFunctionAPI();
        http.get<string>(url + "/selected").subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
            //this.language = result;
        }, error => {
            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);
        }); //console.error(error));
    }

    public static httpGetActualReportingInformation(http: HttpClient, baseUrl: string, isBranchReporting: boolean, tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getKpiManagementAPI() + '/kpi_all/' + isBranchReporting;
        tthis.subscriptions.push(http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })); //console.error(error));
    }

    public static httpGetOPLYearlyReportingInformation(http: HttpClient, baseUrl: string, isBranchReporting: boolean, tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getKpiManagementAPI() + '/kpi_opl_yearly/' + isBranchReporting;
        tthis.subscriptions.push(http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })); //console.error(error));
    }

    //public static httpUpdateKpiApprovalStatus(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

    //    const url = baseUrl + Utils.getKpiManagementAPI() + "/approvalStatus";
    //    http
    //        .put<any>(url, body).subscribe((result) => {
    //            if (tthis && callback)
    //                callback(tthis, result);
    //        }, error => {
    //            Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

    //        }); //console.error(error));
    //}

    public static httpReleaseKpiDefinitions(http: HttpClient, baseUrl: string, body: any, isActual: boolean, oplyearly: boolean, isBranchReporting: boolean, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getKpiManagementAPI() + '/release/' + isActual + '/' + oplyearly + '/' + isBranchReporting;
        http
            .put<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpRevokeKpiValues(http: HttpClient, baseUrl: string, body: any, isActual: boolean, oplyearly: boolean, isBranchReporting: boolean, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getKpiManagementAPI() + '/revoke/' + isActual + '/' + oplyearly + '/' + isBranchReporting;
        http
            .put<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpDownloadActualReportingInformation(http: HttpClient, baseUrl: string, file_name: string, showAll: boolean, isBranchReporting: boolean, tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/actualreportinginformation/" + showAll + "/" + isBranchReporting;
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, file_name, "xlsx"),
                (error: any) => {
                    Utils.writeLog(http, baseUrl, error);
                    callbackError(tthis, error);
                }
        });
    }

    public static httpDownloadYearlyOPLInformation(http: HttpClient, baseUrl: string, file_name: string, showAll: boolean, isBranchReporting: boolean, tthis: any | undefined = undefined, callbackError: any | undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/yearlyoplinformation/" + showAll + "/" + isBranchReporting;
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, file_name, "xlsx"),
                (error: any) => {
                    Utils.writeLog(http, baseUrl, error);
                    callbackError(tthis, error);
                }
        });
    }

    public static httpDownloadMonthlyOPLInformation(http: HttpClient, baseUrl: string, file_name: string, showAll: boolean, isBranchReporting: boolean, isActualReportingCall: boolean, tthis: any | undefined = undefined, callbackError: any | undefined) {
        const url = baseUrl + API_EXPORTIMPORT + "download/monthlyoplinformation/" + showAll + "/" + isBranchReporting + "/" + isActualReportingCall;
        http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
            this.downloadFile(response, file_name, "xlsx"),
                (error: any) => {
                    Utils.writeLog(http, baseUrl, error);
                    callbackError(tthis, error);
                }
        });
    }

    public static httpGetSectionSchedulerInformation(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getKpiManagementAPI() + "/schedulersection";
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpUpdateSectionSchedulerInformation(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getKpiManagementAPI() + "/schedulersection";
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }




    ////////////////
    // UMP requests
    public static httpGetUsersByFilter(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined, isDataAnalytStage = false) {

        const url = baseUrl + Utils.getUMPUser() + "/filter/" + Utils.getDomainByWindowLocation() + "/" + isDataAnalytStage;
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback) {
                    callback(tthis, result);
                }

            }, error => {
                console.log("that is the error", error)
                Utils.writeLog(http, baseUrl, error);

            });
    }
    public static httpGetUsersGroupListByFilter(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined, isDataAnalytStage: boolean = false) {

        const url = baseUrl + Utils.getUMPUser() + "/groupfilterlist/" + Utils.getDomainByWindowLocation() + "/" + isDataAnalytStage;
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback) {
                    callback(tthis, result);
                }

            }, error => {
                Utils.writeLog(http, baseUrl, error);

            });
    }
    public static httpPostGroupModifyUser(http: HttpClient, baseUrl: string, isAdding: boolean, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/groupModifyUser/" + isAdding;
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback) {
                    callback(tthis, result);

                }

            }, error => {
                console.log("that is the error", error)
                Utils.writeLog(http, baseUrl, error);

            });
    }

    public static httpGetInitialFilters(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/initialFilters";
        http.get(url).subscribe((result) => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);

        });
    }

    public static httpGetCreateUserPopupInformation(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/createUserPopupInformation";
        http.get(url).subscribe((result) => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);

        });
    }

    public static httpCreateUser(http: HttpClient, baseUrl: string, body: any, isInternal: boolean, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/create/" + Utils.getDomainByWindowLocation() + '/' + isInternal;
        http
            .post<string>(url, body).subscribe((result: string) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callback(tthis, '');


            });
    }

    public static httpUpdateUser(http: HttpClient, baseUrl: string, body: any, userId: string, umpCategory: UMPCategory, umpAction: UMPAction, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        body.umpaction = umpAction;
        body.umpcategory = umpCategory;
        const url = baseUrl + Utils.getUMPUser() + "/user/" + userId;
        http
            .put<string>(url, body).subscribe((result: string) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceed = -1;
                callback(tthis);
            });
    }
    public static httpDeleteUser(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/user/" + userId + "/delete/";
        http

            .delete<string>(url).subscribe({
                next: data => {
                    tthis.isCallSucceed = 1;
                    callback(tthis);

                },
                error: error => {
                    tthis.isCallSucceed = -1;
                    const errorMessage = error.message;
                    console.error('There was an error!', error);
                    callback(tthis);

                }
            });

    }


    public static httpCreateBulkExternalUsers(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/createBulkExternalUsers/" + Utils.getDomainByWindowLocation();
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callback(tthis, false);

            });
    }

    public static httpGetBulkUserExcelTemplate(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/bulkexceltemplate";
        http
            .get(url, { responseType: 'blob' }).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callback(tthis, false);
            });
    }

    public static httpGetUserDetails(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined, isDataAnalytStage: boolean = false) {
        const url = baseUrl + Utils.getUMPUser() + "/details/" + userId + '/' + isDataAnalytStage;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);

            });
    }

    public static httpGetUserLicenses(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/licenses/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.License_Response_State = 1;
                    callback(tthis, result);
                }
            }, error => {
                tthis.License_Response_State = -1;
                Utils.writeLog(http, baseUrl, error);

            });
    }

    public static httpGetDirIndirUserRoles(http: HttpClient, baseUrl: string, componentName: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/dirIndirUserRoles" + "/" + componentName + "/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;
            });
    }
    public static httpGetAllRelatedRoles(http: HttpClient, baseUrl: string, componentName: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getUMPUser() + "/allrelatedroles/" + componentName;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;
            });
    }

    public static httpGetAllMaxRoles(http: HttpClient, baseUrl: string, componentName: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/" + componentName;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;
            });
    }

    public static httpGetDirIndirRemainingRoles(http: HttpClient, baseUrl: string, componentName: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/remainingroles" + "/" + componentName + "/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;
            });
    }

    public static httpGetUserMersyRoles(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/mersypersonae/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;

            });
    }
    public static httpGetSpecialPermissions(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/specialPermissions/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;

            });
    }
    public static httpGetLicenseMgmt(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/licenseMgmt/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;

            });
    }
    public static httpGetMersyRoles(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/mersypersonae";
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;

            });
    }

    public static httpGetUserMersyGroups(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/usermersygroupfunction/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;

            });
    }
    public static httpGetMersyGroups(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/mersygroupfunction";
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;

            });
    }

    public static httpGetUserAzDevProj(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/adoGroups/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;


            });
    }
    public static httpGetAzDevProj(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/adoGroups";
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
                tthis.Response_State = -1;


            });
    }

    public static httpPostGroupsByType(http: HttpClient, baseUrl: string, body: any, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/groups-nottree/" + userId;
        //console.log("before sending request ", body);
        http
            .post<string>(url, body).subscribe((result: string) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis, result);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceed = -1;
                callback(tthis);
            });
    }

    public static httpRevokeGroupPersonae(http: HttpClient, baseUrl: string, body: any, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/user/" + userId + "/RevokeGroupPersonae";
        http

            .post<string>(url, body).subscribe({
                next: data => {
                    tthis.isCallSucceed = 1;
                    callback(tthis);

                },
                error: error => {
                    tthis.isCallSucceed = -1;
                    const errorMessage = error.message;
                    console.error('There was an error!', error);
                    callback(tthis);

                }
            });

    }
    public static httpGetInlineWiki(http: HttpClient, baseUrl: string, clusterComponentLink: string, title: string | null = null, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getWiki() + "/getInlineWiki/" + clusterComponentLink + '/' + title;
        tthis.subscriptions.push(http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                tthis.spinnerOverlayService.setFalseskipInterceptor();
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
            }));

    }
    public static httpIsWikiAvailable(http: HttpClient, baseUrl: string, clusterComponentLink: string, title: string | null = null, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getWiki() + "/isAvailable/" + clusterComponentLink + '/' + title;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                tthis.spinnerOverlayService.setFalseskipInterceptor();
                Utils.writeLog(http, baseUrl, error);
                tthis.errorResponse = error.message;
            });

    }

    public static httpPostTreeGroupsByType(http: HttpClient, baseUrl: string, body: TreeManagement<UMPCategory>, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/groups/" + userId;
        //console.log("before sending request ", body);
        http
            .post<string>(url, body).subscribe((result: string) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis, result);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceed = -1;
                callback(tthis);
            });
    }
    public static httpPostChangePassword(http: HttpClient, baseUrl: string, body: any, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/resetPassword/" + userId;
        http
            .post<string>(url, body).subscribe((result: string) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceed = -1;
                callback(tthis);
            });
    }
    public static httpResentInvitation(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {


        const url = baseUrl + Utils.getUMPUser() + "/SendUserInvitation/" + Utils.getDomainByWindowLocation();
        http
            .post<string>(url, body).subscribe((result: string) => {
                if (tthis && callback) {
                    tthis.isCallSucceedResInv = 1;
                    callback(tthis);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceedResInv = -1;
                callback(tthis);
            });
    }



    public static httpGetUserDataAnlytics(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/dataAnalytics/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.Tree_Response_State = 1;
                    callback(tthis, result);
                }
            }, error => {
                tthis.Tree_Response_State = -1;
                Utils.writeLog(http, baseUrl, error);


            });
    }
    public static httpGetDataAnlytics(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/DataAnalyticsPersonaeByUpn";
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.Tree_Response_State = 1;
                    callback(tthis, result);
                }
            }, error => {
                tthis.Tree_Response_State = -1;
                Utils.writeLog(http, baseUrl, error);


            });
    }

    public static httpGetUserBranches(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/branches/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.Tree_Response_State = 1;
                    callback(tthis, result);
                }
            }, error => {
                tthis.Tree_Response_State = - 1;
                Utils.writeLog(http, baseUrl, error);

            });
    }
    public static httpGetBranches(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/branches";
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.Tree_Response_State = 1;
                    callback(tthis, result);
                }
            }, error => {
                tthis.Tree_Response_State = - 1;
                Utils.writeLog(http, baseUrl, error);

            });
    }

    public static httpGetUserMgmtBranches(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/enrichmgmtBranches/" + userId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.Tree_Response_State = 1;
                    callback(tthis, result);
                }
            }, error => {
                tthis.Tree_Response_State = - 1;
                Utils.writeLog(http, baseUrl, error);

            });
    }
    public static httpGetFieldManagementIds(http: HttpClient, baseUrl: string, businessLevel: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getBranchHierarchyAPI() + "/fieldmanagementid/" + businessLevel;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    callback(tthis, result);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);

            });
    }
    public static httpGetIsAvailabelHK(http: HttpClient, baseUrl: string, HK: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getBranchHierarchyAPI() + "/isAvailableHK/" + HK;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    callback(tthis, result);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);

            });
    }
    public static GetBranchHierarchyKeyHistory(http: HttpClient, baseUrl: string, HK: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getBranchHierarchyAPI() + "/brachHierarchyHistory/" + HK;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    callback(tthis, result);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);

            });
    }
    public static httpUpdateFieldManagementIds(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getBranchHierarchyAPI() + "/updatemgmnt";
        http
            .put(url, body).subscribe((result) => {
                if (tthis && callback) {
                    callback(tthis, result);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);

            });
    }
    public static httpClearCacheByUser(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined, isDataAnalytStage = false) {

        const url = baseUrl + Utils.getUMPUser() + "/user/" + userId + '/clearcache' + '/' + isDataAnalytStage;
        http
            .delete(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis);
                }
            }, error => {
                tthis.isCallSucceed = - 1;
                Utils.writeLog(http, baseUrl, error);
            });
    }

    public static httpEnableDisablePowerBiUser(http: HttpClient, baseUrl: string, userId: string, isEnabled: boolean, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/groupMersyReportPowerBi/" + userId + '/' + isEnabled;
        http
            .post(url, {}).subscribe((result) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis);
                }
            }, error => {
                tthis.isCallSucceed = - 1;
                Utils.writeLog(http, baseUrl, error);
            });
    }

    public static httpGetUserCache(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/user/" + userId + '/cache';
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis, result);
                }
            }, error => {
                tthis.isCallSucceed = - 1;
                Utils.writeLog(http, baseUrl, error);
            });
    }

    public static httpPostPowerbiWorkspace(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackerror: any | undefined = undefined) {
        const url = baseUrl + API_UMPUSER + "/powerbi/workspace";
        http
            .post<any>(url, body)
            .subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackerror(tthis)

            }); //console.error(error));
    }

    public static httpGetPowerbiCapacities(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_UMPUSER + "/powerbi/capacities";
        http
            .get<any>(url)
            .subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }); //console.error(error));
    }

    public static httpGetPowerbiReferenceData(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_UMPUSER + "/powerbi/referenceData";
        http
            .get<any>(url)
            .subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }); //console.error(error));
    }

    public static httpGetfieldBranchDetails(http: HttpClient, baseUrl: string,
        tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/field-branch-details';
        tthis.subscriptions.push(http
            .get<any>(url).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                }, error: error => {
                    Utils.writeLog(http, baseUrl, error);
                    if (tthis && callbackError)
                        callbackError(tthis, error);
                }
            })
        ); 
    }
    public static httpGetAllContactCategories(http: HttpClient, baseUrl: string,
        tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/all-contact-categories';
        tthis.subscriptions.push(http
            .get<any>(url).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                }, error: error => {
                    Utils.writeLog(http, baseUrl, error);
                    if (tthis && callbackError)
                        callbackError(tthis, error);
                }
            })
        );
    }
    public static httpUpdateFieldBranchDetails(http: HttpClient,
        baseUrl: string, body: any,
        tthis: any | undefined = undefined,
        onlyDates: boolean = false,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/field-branch-details' + (onlyDates ? '?onlyDates=true' : '');
        tthis.subscriptions.push(http
            .post<any>(url, body).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                }, error: error => {
                    Utils.writeLog(http, baseUrl, error);
                    if (tthis && callbackError)
                    callbackError(tthis, error);
                }
            })
        );
    }

    public static httpGetAdminBranchContacts(http: HttpClient, baseUrl: string,
        tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/field-branch-contact';
        tthis.subscriptions.push(http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })); //console.error(error));
    }

    public static httpGetFieldBranchContacts(http: HttpClient, baseUrl: string,
        tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/branch-contact-assessment';
        tthis.subscriptions.push(http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })); //console.error(error));
    }

    public static httpCreateContact(http: HttpClient,
        baseUrl: string,
        body: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/create-contact';
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpGetAvailableContact(http: HttpClient,
        baseUrl: string,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/available-contacts';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpAssignContact(http: HttpClient,
        baseUrl: string,
        body: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/assign-contact/' + body;
        http
            .post<any>(url, {}).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpUnassignContact(http: HttpClient,
        baseUrl: string,
        id: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/unassign-contact/' + id;
        http
            .delete<any>(url, {}).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpGetExcellenceLevers(http: HttpClient, baseUrl: string,
        tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/excellence-levers';
        tthis.subscriptions.push(http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })); //console.error(error));
    }

    public static httpGetExcellenceLeversKpis(http: HttpClient, baseUrl: string,
        tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/excellence-levers-kpis';
        tthis.subscriptions.push(http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })); //console.error(error));
    }

    public static httpGetExcellenceFactors(http: HttpClient, baseUrl: string,
        tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/excellence-factors';
        tthis.subscriptions.push(http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })); //console.error(error));
    }

    public static httpGetLinesOfBusiness(http: HttpClient,
        baseUrl: string,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/lines-of-business';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpUpsertExcellenceLever(http: HttpClient,
        baseUrl: string,
        body: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/excellence-lever';
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpGetAccounts(http: HttpClient,
        baseUrl: string,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/accounts';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpUpsertExcellenceLeverKpi(http: HttpClient,
        baseUrl: string,
        body: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/excellence-lever-kpi';
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpUpsertExcellenceFactor(http: HttpClient,
        baseUrl: string,
        body: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/excellence-factor';
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }


    public static httpDeleteExcellenceLeverKpi(http: HttpClient,
        baseUrl: string,
        id: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/excellence-lever-kpi/' + id;
        http
            .delete<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpDeleteExcellenceLever(http: HttpClient,
        baseUrl: string,
        id: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/excellence-lever/' + id;
        http
            .delete<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpDeleteExcellenceFactor(http: HttpClient,
        baseUrl: string,
        id: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/excellence-factor/' + id;
        http
            .delete<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpGetWaves(http: HttpClient,
        baseUrl: string,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/waves';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpUpsertWave(http: HttpClient,
        baseUrl: string,
        body: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/waves';
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpGetWaveExcellenceLeverConfigurations(http: HttpClient,
        baseUrl: string,
        id: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/waves-excellence-lever-config/' + id;
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetUnassignedWaveExcellenceLeverConfigurations(http: HttpClient,
        baseUrl: string,
        id: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/unassigned-waves-excellence-lever-config/' + id;
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpAssignWaveExcellenceLeverConfigurations(http: HttpClient,
        baseUrl: string,
        waveId: any,
        ids: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/wave-excellence-levers/' + waveId;
        http
            .post<any>(url, { 'excellenceLeverIds': ids }).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpImportExcellenceLevers(http: HttpClient,
        baseUrl: string,
        sourceWaveId: any,
        targetWaveId: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = `${baseUrl}${Utils.getBranchExcellenceAPI()}/import-wave-excellence-levers/${sourceWaveId}/${targetWaveId}`;
        http
            .post<any>(url, {}).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpUnassignWaveExcellenceLeverConfigurations(http: HttpClient,
        baseUrl: string,
        waveId: any,
        ids: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/unassign-wave-excellence-levers/' + waveId;
        http
            .post<any>(url, { 'excellenceLeverIds': ids }).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetAssessments(http: HttpClient,
        baseUrl: string,
        id: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/assessment/' + id;
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpAssignBranchesToWave(http: HttpClient,
        baseUrl: string,
        waveId: any,
        branchIds: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/assessment/' + waveId;
        http
            .post<any>(url, { 'branchIds': branchIds }).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpUnassignBranchesToWave(http: HttpClient,
        baseUrl: string,
        waveId: any,
        branchIds: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/unassign-assessment/' + waveId;
        http
            .post<any>(url, { 'branchIds': branchIds }).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetAssessmentInformation(http: HttpClient,
        baseUrl: string,
        wave: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/assessment-information/' + wave;
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetFieldManagementIdsFromBranchExcellence(http: HttpClient, baseUrl: string, businessLevel: string, waveId: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + "/fieldmanagementid/" + businessLevel + '/' + waveId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    callback(tthis, result);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);

            });
    }

    public static httpPostAssessmentInformation(http: HttpClient,
        baseUrl: string,
        waveId: number,
        data: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/assessment-information/' + waveId;
        http
            .post<any>(url, data).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetExcellenceStages(http: HttpClient,
        baseUrl: string,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/stages';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetPriorities(http: HttpClient,
        baseUrl: string,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/assessment/priorities';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetAllWavesByFieldBranchId(http: HttpClient, baseUrl: string, fieldBranchId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_BRANCHEXCELLENCE + "/waves/" + fieldBranchId;
        return http.get<Wave[]>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }

    public static httpGetAssessmentLeverHistory(http: HttpClient, baseUrl: string, waveExcellenceLeverConfigId: number, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + API_BRANCHEXCELLENCE + "/assessment-config-history/" + waveExcellenceLeverConfigId;
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis, result);
                }
            }, error => {
                tthis.isCallSucceed = - 1;
                Utils.writeLog(http, baseUrl, error);
            });
    }

    public static httpGetFocusPlanInformation(http: HttpClient,
        baseUrl: string,
        wave: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/' + wave;
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetUnassignedKpisByFieldBranchAndWave(http: HttpClient,
        baseUrl: string,
        wave: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/unassigned-kpis/' + wave;
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpUpsertMeasure(http: HttpClient,
        baseUrl: string,
        wave: number,
        body: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/upsert-measure/' + wave;
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpUpsertActionItem(http: HttpClient,
        baseUrl: string,
        body: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/upsert-actionitem';
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }
    public static httpUpsertActionItemlist(http: HttpClient,
        baseUrl: string,
        body: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/upsert-actionitemlist';
        http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpDeleteMeasure(http: HttpClient,
        baseUrl: string,
        measureId: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/measure/' + measureId;
        http
            .delete<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpDeleteActionItem(http: HttpClient,
        baseUrl: string,
        actionItemId: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/actionitem/' + actionItemId;
        http
            .delete<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpGetAvailableStatuses(http: HttpClient,
        baseUrl: string,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/statuses';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            }); //console.error(error));
    }

    public static httpUpsertActionItemStatuses(http: HttpClient,
        baseUrl: string,
        data: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/upsert-actionitemstatus';
        http
            .post<any>(url, data).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetFocusPlanImpact(http: HttpClient,
        baseUrl: string,
        wave: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/impact/' + wave;
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpUpsertFinancialImpact(http: HttpClient,
        baseUrl: string,
        wave: number,
        data: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getBranchExcellenceAPI() + '/focus-plan/upsert-impact/' + wave;
        http
            .post<any>(url, data).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetPatternDefinition(http: HttpClient,
        baseUrl: string,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getGlobalTaskListAPI() + '/pattern-definitions';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetPatternTasks(http: HttpClient,
        baseUrl: string,
        patternDefinitionId: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getGlobalTaskListAPI() + '/' + patternDefinitionId + '/pattern-tasks';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpGetUrlsSupport(http: HttpClient,
        baseUrl: string,
        patternTaskId: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getGlobalTaskListAPI() + '/' + patternTaskId + '/url-supports';
        http
            .get<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpMergePatternDefinition(http: HttpClient,
        baseUrl: string,
        data: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getGlobalTaskListAPI() + '/pattern-definitions';
        http
            .post<any>(url, data).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpMergePatternTask(http: HttpClient,
        baseUrl: string,
        data: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getGlobalTaskListAPI() + '/pattern-tasks';
        http
            .post<any>(url, data).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpMergeUrlSupport(http: HttpClient,
        baseUrl: string,
        data: any,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getGlobalTaskListAPI() + '/urls-support';
        http
            .post<any>(url, data).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpDeletePatternDefinition(http: HttpClient,
        baseUrl: string,      
        patternDefinitionId: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getGlobalTaskListAPI() + '/pattern-definitions/' + patternDefinitionId;
        http
            .delete<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpDeletePatternTask(http: HttpClient,
        baseUrl: string,      
        patternTaskId: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getGlobalTaskListAPI() + '/pattern-tasks/' + patternTaskId;
        http
            .delete<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    public static httpDeleteUrlSupport(http: HttpClient,
        baseUrl: string,
        urlPatternTaskId: number,
        tthis: any | undefined = undefined,
        callback: any | undefined = undefined,
        callbackError: any | undefined) {

        const url = baseUrl + Utils.getGlobalTaskListAPI() + '/urls-support/' + urlPatternTaskId;
        http
            .delete<any>(url).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })
    }

    /**
     * return a not translated class style, if text is null, undefined or includes 'en-US'
     * @param text - text do verify
     */
    public static notTranslatedClass(text: string): string {
        if (text == null || text == undefined || text.includes("en-US_"))
            return NOT_TRANSLATED_CLASSNAME; //"not-translated";
        return "";
    }



    //public static redirectLogout(http: HttpClient, baseUrl: string) {
    //    const url = baseUrl + API_GENERIC + "logout";
    //    const logout = baseUrl + 'logout';
    //    const logout2 = "https://login.microsoftonline.com/2edb9c97-3a5c-4c4c-9b88-da2b5f3568b8/oauth2/v2.0/logout?post_logout_redirect_uri=https://localhost:44326";

    //    //this.httpGetPostRedirectUrl(http, baseUrl, this, function (tthis: any, value: string) {
    //    //    tthis.postLogoutRedirectUrl = value;
    //    //});
    //    let postLogoutRedirectUrl = '';

    //    http.get(baseUrl + API_GENERIC + 'postlogoutredirecturl').subscribe(data => {
    //        console.log(data);
    //        // postLogoutRedirectUrl = data;
    //    })

    //    http
    //        .post<Elevator>(url, null)
    //        .subscribe(
    //            res => {
    //                // window.location.href = logout2;
    //                http.get(logout2).subscribe(() => {
    //                    console.log("test");
    //                });

    //            }, error => {
    //                window.location.href = logout2;
    //            }
    //        );

    //    http.get(logout).subscribe(result => {
    //        console.log(result);
    //        window.location.href = logout2;
    //    });

    //}

    public static redirectLogout(redirectUrl: string, baseUrl: string, http: HttpClient) {
        const url = baseUrl + API_GENERIC + "logout";
        const logout = baseUrl + 'logout';

        http
            .post<Elevator>(url, null)
            .subscribe(
                res => {
                    // window.location.href = logout2;
                    http.get(redirectUrl).subscribe(() => {
                        console.log("test");
                    });

                }, error => {
                    window.location.href = redirectUrl;
                }
            );

        http.get(logout).subscribe(result => {
            window.location.href = redirectUrl;
        });
    }

    public static httpGetPostRedirectUrl(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + API_GENERIC + "postlogoutredirecturl";
        http.get<string>(url).subscribe(result => {
            if (tthis && callback)
                callback(tthis, result);
        }, error => {
            Utils.writeLog(http, baseUrl, error);
        });
    }

    public static httpGetUserHistory(http: HttpClient, baseUrl: string, userId: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getUMPUser() + "/user/" + userId + '/history';
        http
            .get(url).subscribe((result) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis, result);
                }
            }, error => {
                tthis.isCallSucceed = - 1;
                Utils.writeLog(http, baseUrl, error);
            });
    }

    public static httpGetComponentFunctionTypes(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/functionTypes';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetComponentFamilyCodes(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/familyCodes';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetMfgcenters(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/mfgcenters';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetBomCodes(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/bomcodes';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetEMTControllerSoftwareReleases(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/controllersoftware';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetJCOBReleaseFiles(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getJCOBAPI() + '/jcobrulefiles';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }
    public static httpGetNVStruct(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getJCOBAPI() + '/nvstruct';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }
    public static httpGetJcobCommandFiles(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getJCOBAPI() + '/jcobcommandfiles';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetEMTProductsUsage(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/productUsage';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetEMTPeripheralSoftwareVersions(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/peripheralsoftware';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetMfgCenterProducts(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/mfgcenterproducts';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }

    public static httpGetControllerSoftwareForConfigGeneration(http: HttpClient, baseUrl: string, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/softwareVersionForConfigGeneration';
        http
            .get(url).pipe(takeUntil(tthis.destroy$)).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => { Utils.writeLog(http, baseUrl, error); }
            });
    }


    public static httpUpdateEMTProductsUsage(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getEMTAPI() + "/updateProductUsage";
        http
            .put<string>(url, body).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        tthis.isCallSucceed = 1;
                    callback(tthis, result);
                },
                error: (error) => {
                    tthis.isCallSucceed = -1;
                    Utils.writeLog(http, baseUrl, error);
                    callback(tthis);
                }
            });
    }

    public static httpUpdateControllerSoftwareReleases(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/updateControllerSoftware';
        http
            .put<string>(url, body).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        tthis.isCallSucceed = 1;
                    callback(tthis, result);
                },
                error: (error) => {
                    tthis.isCallSucceed = -1;
                    Utils.writeLog(http, baseUrl, error);
                    callback(tthis);
                }
            });
    }

    public static httpUpdateJCOBRuleFiles(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getJCOBAPI() + '/jcobrulefiles';
        http
            .put<string>(url, body).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        tthis.isCallSucceed = 1;
                    callback(tthis, result);
                },
                error: (error) => {
                    tthis.isCallSucceed = -1;
                    Utils.writeLog(http, baseUrl, error);
                    callback(tthis);
                }
            });
    }
    public static httpUpdateNVStruct(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getJCOBAPI() + '/nvstruct';
        http
            .post<string>(url, body).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        tthis.isCallSucceed = 1;
                    callback(tthis, result);
                },
                error: (error) => {
                    tthis.isCallSucceed = -1;
                    Utils.writeLog(http, baseUrl, error);
                    callback(tthis);
                }
            });
    }
    public static httpUpdateJcobCommandFiles(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getJCOBAPI() + '/jcobcommandfiles';
        http
            .post<string>(url, body).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        tthis.isCallSucceed = 1;
                    callback(tthis, result);
                },
                error: (error) => {
                    tthis.isCallSucceed = -1;
                    Utils.writeLog(http, baseUrl, error);
                    callback(tthis);
                }
            });
    }

    public static httpUpdatePeripheralSoftwareVersions(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/updatePeripheralSoftware';
        http
            .put<string>(url, body).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        tthis.isCallSucceed = 1;
                    callback(tthis, result);
                },
                error: (error) => {
                    tthis.isCallSucceed = -1;
                    Utils.writeLog(http, baseUrl, error);
                    callback(tthis);
                }
            });
    }

    public static httpUpdateCenterProducts(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/updateCenterProduct';
        http
            .put<string>(url, body).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        tthis.isCallSucceed = 1;
                    callback(tthis, result);
                },
                error: (error) => {
                    tthis.isCallSucceed = -1;
                    Utils.writeLog(http, baseUrl, error);
                    callback(tthis);
                }
            });
    }

    public static httpUpdateControllerSoftwareConfigGeneration(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {
        const url = baseUrl + Utils.getEMTAPI() + '/updateControllerSoftwareConfigGeneration';
        http
            .put<string>(url, body).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        tthis.isCallSucceed = 1;
                    callback(tthis, result);
                },
                error: (error) => {
                    tthis.isCallSucceed = -1;
                    Utils.writeLog(http, baseUrl, error);
                    callback(tthis);
                }
            });
    }


    public static httpUpdateComponentFunctionTypes(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getEMTAPI() + "/updateFT";
        http
            .put<string>(url, body).subscribe((result) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceed = -1;
                callback(tthis);
            });
    }

    public static httpUpdateComponentFamilyCodes(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getEMTAPI() + "/updateFC";
        http
            .put<string>(url, body).subscribe((result) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis, result);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceed = -1;
                callback(tthis);
            });
    }

    public static httpUpdateBOMCodes(http: HttpClient, baseUrl: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = baseUrl + Utils.getEMTAPI() + "/updateBOMCodes";
        http
            .put<string>(url, body).subscribe((result) => {
                if (tthis && callback) {
                    tthis.isCallSucceed = 1;
                    callback(tthis);
                }
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                tthis.isCallSucceed = -1;
                callback(tthis);
            });
    }

    public static httpGetMarketQueryInformation(http: HttpClient, baseUrl: string, type: string, tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = baseUrl + Utils.getMarketQueryAPI() + '/' + type;
        tthis.subscriptions.push(http
            .get<any>(url).subscribe({
                next: (result) => {
                    if (tthis && callback)
                        callback(tthis, result);
                },
                error: (error) => {
                    Utils.writeLog(http, baseUrl, error);
                    callbackError(tthis, error);
                }
            })); 
    }

    public static httpUpdateMarketQueryInformation(http: HttpClient, baseUrl: string, marketQueryType: string, body: any, tthis: any | undefined = undefined, callback: any | undefined = undefined, callbackError: any | undefined) {

        const url = `${baseUrl}${Utils.getMarketQueryAPI()}/${marketQueryType}`;
        tthis.subscriptions.push(http
            .post<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis);
            }, error => {
                Utils.writeLog(http, baseUrl, error);
                callbackError(tthis, error);
            })); //console.error(error));
    }

    public static httpReleaseMarketQueryInfo(http: HttpClient, baseUrl: string, body: any, isMarketSheet: boolean, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = `${baseUrl}${Utils.getMarketQueryAPI()}/release/${isMarketSheet}`;
        http
            .put<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }

    public static httpRevokeMarketQueryInfo(http: HttpClient, baseUrl: string, body: any, isMarketSheet: boolean, tthis: any | undefined = undefined, callback: any | undefined = undefined) {

        const url = `${baseUrl}${Utils.getMarketQueryAPI()}/revoke/${isMarketSheet}`;
        http
            .put<any>(url, body).subscribe((result) => {
                if (tthis && callback)
                    callback(tthis, result);
            }, error => {
                Utils.writeLog(http, baseUrl, error); //Utils.showErrorDialog(null, error);

            }); //console.error(error));
    }
    /**
     * stop propagation on an event
     * 
     * @param event
     */
    public static stopPropagation(event: any) {
        event.stopPropagation();
    }


    ///**
    // * 
    // * @param ct
    // * @deprecated
    // */
    //public static setControllerType(ct: ControllerType) { this.controllertype = ct; }

    ///**
    // * 
    // * @param lang
    // * @deprecated
    // */
    //public static setLanguage(lang: Language) { this.language = lang; }

    ///**
    // * show dialogs confirm, the object that uses must have the fields type; message; classdialog and classname
    // * change classname for alert and form
    // * @param tthis
    // * @param question
    // * @deprecated
    // */
    //public static showConfirmDialog(tthis: any, question: string, title: string = "") {
    //    tthis.title = title;
    //    tthis.type = this.getConfirmValue();
    //    tthis.message = question;        
    //    this.showDialog(tthis);
    //}

    ///**
    // * show dialogs confirm, the object that uses must have the fields type; message; classdialog and classname
    // * change classname for alert and form
    // * @param tthis
    // * @param question
    // * @deprecated
    // */
    //public static showConfirmDialog2(tthis: any, question: string, title: string = "") {
    //    //tthis.type = this.getConfirmValue();
    //    //tthis.message = question;
    //    tthis.SetTitle(title);
    //    this.showDialog(tthis);
    //}

    ///**
    // * show dialogs confirm, the object that uses must have the fields type; message; classdialog and classname
    // * change classname for alert and form
    // * @param tthis
    // * @param question
    // */
    //public static showErrorDialog(tthis: any, error: any) {
    //    if ((error.status == 200 && tthis == null) || error.status == STATUS_SERVER_ERROR_CODE) {
    //        Utils.redirectLogout();//window.location.href = "https://login.microsoftonline.com/common/oauth2/logout"; //?post_logout_redirect_uri=http%3A%2F%2Flocalhost%2Fhome%2F"; //error.url + "/logout";

    //        //console.debug("We HAVE an ERROR!!!!!");
    //        //console.error(error);
    //    }
    //    else {
    //        tthis.type = this.getErrorValue();
    //        tthis.title = "Error";
    //        tthis.message = error.error.message;
    //        this.showDialog(tthis);
    //    }
    //}


    /**
     * write to log and redirect if we have a 509 error code
     * change classname for alert and form
     * @param tthis
     * @param question
     */
    public static writeLog(http: HttpClient, baseUrl: string, error: any) {
        console.error(error);
        if (
            error.status == STATUS_SERVER_ERROR_CODE ||
            error.status == UNAUTHORIZED_SERVER_ERROR
        ) {
            Utils.redirectLogout('', baseUrl, http);
        }
    }

    public static downloadBlobFile(Blobdata: any, fileNameDotType: string) {
        const url = window.URL.createObjectURL(Blobdata);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileNameDotType;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    public static isGUIDvalid(guid: string): boolean {
        return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(guid);
    }

    public static downloadFile(response: any, filename: string, extension: string) {
        let dataType = response.type;
        let binaryData: any[] = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', filename + '.' + extension);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }

    public static base64ToBlob(b64Data: any, contentType = '', sliceSize = 512) {
        b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
        let byteCharacters = atob(b64Data);
        let byteArrays: Uint8Array[] = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    private static encodeSlashes(text: string): string {
        return text.replace('/', '$_$')
    }

    public static OmitKeysSpreadOperator = (keys: any[], obj: any) => Object.entries(obj)
        .filter(([key]) => !keys.includes(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    public static RemoveBlankFields = (obj: any) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))

    public static deepCopyArray(array: any) {
        const copiedArray: any[] = [];

        for (let i = 0; i < array.length; i++) {
            if (Array.isArray(array[i])) {
                copiedArray[i] = this.deepCopyArray(array[i]); // Llamada recursiva para copiar subarrays
            } else if (typeof array[i] === 'object' && array[i] !== null) {
                copiedArray[i] = Object.assign({}, array[i]); // Copia el objeto utilizando Object.assign()
            } else {
                copiedArray[i] = array[i]; // Copia el valor primitivo
            }
        }

        return copiedArray;
    }

    public static deepCopy(obj: any): any {
        if (Array.isArray(obj)) {
            const copyArr: any[] = [];
            for (const item of obj) {
                copyArr.push(this.deepCopy(item));
            }
            return copyArr;
        } else if (typeof obj === 'object' && obj !== null) {
            const copyObj: Record<string, any> = {};
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    copyObj[key] = this.deepCopy(obj[key]);
                }
            }
            return copyObj;
        } else {
            return obj;
        }
    }

    public static deepCopyExcludingProperty(obj: any, propertyToExclude: string): any {
        if (obj && propertyToExclude in obj) {
            const { [propertyToExclude]: _, ...restOfObject } = obj;
            return { ...restOfObject, 'Groups': [] };
        } else {
            return { ...obj };
        }
    }

    public static GetManagementChildrenByParent(group: ManagementId, branchId: string): ManagementId | null {
        if (!group.GroupName || !group.Value?.includes(branchId)) {
            if (group.Groups) {
                for (const child of group.Groups) {
                    const result = Utils.GetManagementChildrenByParent(child, branchId);
                    if (result !== null) {
                        return result;
                    }
                }
            }
            return null;
        }

        return group;
    }

    public static hasAdditionalLicenseCost(persona: string): number {
        if (persona.toLowerCase().includes("_test") || persona.toLowerCase().includes("powerbi pro"))
            return 1;
        if (persona.toLowerCase().includes("_basic"))
            return 0;
        return -1;
    }

    public static getDomainByWindowLocation(): string {
        var currentUrl = window.location.href.toLowerCase();
        var urlObject = new URL(currentUrl);
        var domain = urlObject.hostname;
        return domain;
    }

    public static dateUTCchange(event: MatDatepickerInputEvent<Date>): Date {
        const localDate = event.value;
        if (localDate) {
            return this.convertToUTC(localDate);
        }
        else
            return new Date();
    }

    private static convertToUTC(date: Date): Date {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
            date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    public static IsValidNumber(figure: string | number): boolean {
        if (typeof figure === 'number' && !isNaN(figure)) {
            return true;
        }

        if (typeof figure === 'string') {
            const numStr = figure.trim();

            if (numStr === '' || numStr === '.' || numStr === '-' || numStr === '+') {
                return false;
            }

            const num = Number(numStr);
            return !isNaN(num);
        }

        return false;
    }

    public static ConfigurationKeyEnumTypeDescriptions: { [key in ConfigurationKeyEnumType]: string } = {
        [ConfigurationKeyEnumType.NoConsequence]: "No Consequence",
        [ConfigurationKeyEnumType.Shutdown]: "Shutdown",
    };

    public static async readBlobError(error): Promise<string> {
        let errorMessage = '';

        if (error.error instanceof Blob) {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = (e: any) => {
                    errorMessage = `Error: ${e.target.result}`;
                    resolve(errorMessage);
                };
                reader.onerror = (e) => {
                    reject('Error reading blob');
                };
                reader.readAsText(error.error);
            });
        } else {
            return Promise.resolve('Unknown error');
        }
    }
}