<div class="
    black-checkbox
    new-table-style" style="height:70vh !important">

    <table mat-table
           [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           class="mat-elevation-z8 mat-table-custom" id="MeasuresInfoStatus">

        <ng-container matColumnDef="arrow">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="pushPopElement(element)">
                <span class="material-icons" *ngIf="checkExpanded(element); else show_expand_more">
                    expand_less
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Measure </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.Name | bold: dataSource.filter"></p>
            </td>
        </ng-container>

        <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.Description | bold: dataSource.filter"></p>
            </td>
        </ng-container>


        <ng-container matColumnDef="Kpi">
            <th mat-header-cell *matHeaderCellDef> Kpi </th>
            <td mat-cell *matCellDef="let measure">
                <p [innerHTML]="measure.KpiDescription"></p>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="actionitems">
            <td mat-cell *matCellDef="let measure" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="checkExpanded(measure) ? 'expanded' : 'collapsed'"
                     id="MeasureActionItemsStatus">
                    <table mat-table
                           [dataSource]="measure.ActionItems"
                           class="mat-subtable-custom">

                        <ng-container matColumnDef="ItemNo">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let actionItem;index as i;">
                                <p [innerHTML]="(i+1)"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Description">
                            <th mat-header-cell *matHeaderCellDef> Description </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.Description | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Owner">
                            <th mat-header-cell *matHeaderCellDef> Owner </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.Owner | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Comments">
                            <th mat-header-cell *matHeaderCellDef> Comments </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.Comments | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Deadline">
                            <th mat-header-cell *matHeaderCellDef> Deadline </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.Deadline | bold: dataSource.filter"></p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="StatusQ1">
                            <th mat-header-cell *matHeaderCellDef> Status Q1 </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.StatusDescQ1" [style.display]="isEditModeEnabled === false ? 'block' : 'none'"></p>
                                <ng-select [ngClass]="{'hidden': isEditModeEnabled === false }"
                                           class="integratedNgSelect"
                                           [items]="statuses"
                                           bindLabel="Description"
                                           bindValue="StatusId"
                                           [clearable]="false"
                                           [searchable]="false"
                                           [(ngModel)]="actionItem.StatusQ1"
                                           [clearOnBackspace]="false"
                                           (ngModelChange)="onChange(actionItem.ActionItemId, actionItem.StatusQ1,1,actionItem.ActionItemStatusQ1Id)">
                                </ng-select>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="StatusQ2">
                            <th mat-header-cell *matHeaderCellDef> Status Q2 </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.StatusDescQ2" [style.display]="isEditModeEnabled === false ? 'block' : 'none'"></p>
                                <ng-select [ngClass]="{'hidden': isEditModeEnabled === false }"
                                           class="integratedNgSelect"
                                           [items]="statuses"
                                           bindLabel="Description"
                                           bindValue="StatusId"
                                           [clearable]="false"
                                           [searchable]="false"
                                           [(ngModel)]="actionItem.StatusQ2"
                                           [clearOnBackspace]="false"
                                           (ngModelChange)="onChange(actionItem.ActionItemId, actionItem.StatusQ2,2,actionItem.ActionItemStatusQ2Id)">
                                </ng-select>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="StatusQ3">
                            <th mat-header-cell *matHeaderCellDef> Status Q3 </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.StatusDescQ3" [style.display]="isEditModeEnabled === false ? 'block' : 'none'"></p>
                                <ng-select [ngClass]="{'hidden': isEditModeEnabled === false }"
                                           class="integratedNgSelect"
                                           [items]="statuses"
                                           bindLabel="Description"
                                           bindValue="StatusId"
                                           [clearable]="false"
                                           [searchable]="false"
                                           [(ngModel)]="actionItem.StatusQ3"
                                           [clearOnBackspace]="false"
                                           (ngModelChange)="onChange(actionItem.ActionItemId, actionItem.StatusQ3,3,actionItem.ActionItemStatusQ3Id)">
                                </ng-select>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="StatusQ4">
                            <th mat-header-cell *matHeaderCellDef> Status Q4 </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <p [innerHTML]="actionItem.StatusDescQ4" [style.display]="isEditModeEnabled === false ? 'block' : 'none'"></p>
                                <ng-select [ngClass]="{'hidden': isEditModeEnabled === false }"
                                           class="integratedNgSelect"
                                           [items]="statuses"
                                           bindLabel="Description"
                                           bindValue="StatusId"
                                           [clearable]="false"
                                           [searchable]="false"
                                           [(ngModel)]="actionItem.StatusQ4"
                                           [clearOnBackspace]="false"
                                           (ngModelChange)="onChange(actionItem.ActionItemId, actionItem.StatusQ4,4,actionItem.ActionItemStatusQ4Id)">
                                </ng-select>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="StatusComments">
                            <th mat-header-cell *matHeaderCellDef> Status Comments </th>
                            <td mat-cell *matCellDef="let actionItem">
                                <textareaoverlay [inputControls]="{
                                                  isOpenOverlay: false,
                                                  textAreaId: 'Notes_ta_',
                                                  isEditable: isEditModeEnabled,
                                                  maxCommentLength: 2000,
                                                  comment: actionItem.StatusComments
                                                  }"
                                                 [customSpanClass]="{'boldText': !isEditModeEnabled}"
                                                 (commentChange)="onStatusCommentsChange(actionItem,$event.comment)">
                                </textareaoverlay>
                            </td>
                        </ng-container>


                        <tr mat-header-row class="subtableheader" *matHeaderRowDef="subcolumnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let row2; let i2 = index; columns: subcolumnsToDisplay;" class="subitem"
                            [class.alternative2]="isEditModeEnabled === true"></tr>
                    </table>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let measure; let i = dataIndex; columns: columnsToDisplay;"
            class="item">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['actionitems']" class="row-subitem"></tr>
    </table>
</div>

<ng-template #show_expand_more>
    <span class="material-icons" matTooltip="Show Action Items">
        expand_more
    </span>
</ng-template>


