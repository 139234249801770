<div class="flex-center">
    <div class="contentWidth">
        <div style="height: calc(60vh - 100px); width: calc(60vw - 100px); overflow-y: auto;">
            <form [formGroup]="USForm" autocomplete="off">
                <ng-container formArrayName="USRows">
                    <table #objTable
                           mat-table
                           multiTemplateDataRows
                           matSort
                           [dataSource]="dataSource.data"
                           class="table-add-butt-header-orange mat-elevation-z8 mat-table-custom">

                        <ng-container matColumnDef="headertitle">
                            <th class="title-col" mat-header-cell *matHeaderCellDef [attr.colspan]="columnsToDisplay.length - 1">
                                <div class="pull-left">
                                    <h4>Url Support List</h4>
                                </div>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="headeraddbutton">
                            <th class="butt-col" mat-header-cell *matHeaderCellDef>
                                <div class="OrangeButton">
                                    <button mat-raised-button
                                            (click)="addNewRow()"
                                            matTooltip="Add Release" class="button-color-fix pull-right">
                                        <mat-icon class="icon-color-fix icon-padding-fix">add_box</mat-icon>
                                    </button>
                                </div>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="urlsupport">
                            <th mat-header-cell *matHeaderCellDef style="width:80%">Url</th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex" style="width:80%">
                                <div [innerHTML]="USForm.get('USRows')?.value[i].urlSupport" *ngIf="USForm.get('USRows')?.value[i].isEditable"></div>
                                <mat-form-field style="width: 100%" *ngIf="!USForm.get('USRows')?.value[i]?.isEditable">
                                    
                                    <input matInput type="text"
                                           [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="USForm.get('USRows')?.value[i].urlSupport"
                                           maxlength="1000"
                                           matTooltip="Max 1000 characters"
                                           pattern="^(https?|ftp)://[^\s/$.?#].[^\s]*$"
                                           required
                                           title="Please enter a valid URL" />
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:20%"></th>
                            <td mat-cell *matCellDef="let element; let i = dataIndex" style="width:20%">

                                <button mat-icon-button
                                        *ngIf="!USForm.get('USRows')?.value[i]?.isEditable"
                                        matTooltip="Cancel Changes"
                                        color="warn"
                                        (click)="resetRow(i)">
                                    <mat-icon class="actionButtons">rotate_left</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="USForm.get('USRows')?.value[i]?.isEditable"
                                        matTooltip="Edit"
                                        color="primary"
                                        (click)="editRow(i)">
                                    <mat-icon class="actionButtons">edit</mat-icon>
                                </button>

                                <button mat-icon-button
                                        *ngIf="!USForm.get('USRows')?.value[i]?.isNewRow"
                                        matTooltip="Delete"
                                        color="warn"
                                        (click)="onDelete(USForm.get('USRows')?.value[i]?.urlPatternTaskId, i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="savefooter">
                            <th mat-footer-cell *matFooterCellDef [attr.colspan]="columnsToDisplay.length">
                                <div class="OrangeButton">
                                    <button mat-raised-button style="color:white !important"
                                            type="submit" (click)="onSave()"
                                            [disabled]="!anyChange()"
                                            [matTooltip]="anyChange()? 'Save changes' : 'Save disabled'"
                                            class="button-color-fix pull-right white">
                                        Save
                                        <mat-icon style="color:white !important" class="icon-color-fix">save</mat-icon>
                                    </button>
                                </div>
                            </th>

                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="['headertitle','headeraddbutton'];"></tr>
                        <tr class="col-headers" mat-header-row *matHeaderRowDef="columnsToDisplay;"></tr>
                        <tr mat-row *matRowDef="let row; let i = dataIndex; columns: columnsToDisplay;"
                            [class.alternative2]="!USForm.get('USRows')?.value[i]?.isEditable"></tr>
                        <tr mat-footer-row *matFooterRowDef="['savefooter'];" style="width:100%"></tr>
                    </table>
                </ng-container>
            </form>
        </div>
    </div>
</div>

