
<div *ngIf="!isSearchByUnitId">
    <mat-form-field class="search-input">
        <input placeholder="Filter"
               matInput
               (keyup)="applyFilter($event)">
    </mat-form-field>
    <button mat-icon-button (click)="reloadUnitList()" matTooltip="Refresh in case of not seeing any new asset" [disabled]="isLoadingAssets">
        <mat-icon [class.disabled-icon]="isLoadingAssets">refresh</mat-icon>
    </button>
    <button mat-icon-button (click)="openCreateAssetPopup()" matTooltip=" Create new Elevator / Escalator" [disabled]="isLoadingAssets">
        <mat-icon [class.disabled-icon]="isLoadingAssets">add_circle</mat-icon>
    </button>
</div>
<div *ngIf="isSearchByUnitId">
    <mat-form-field class="search-input">
        <input #inputUnitId
               placeholder="UnitId"
               matInput>
    </mat-form-field>
    <button mat-icon-button (click)="searchUnit(inputUnitId.value)" matTooltip="Search for UnitId" [disabled]="inputUnitId.value.length == 0 || isSearchingAssetByUnitId">
        <mat-icon [class.disabled-icon]="inputUnitId.value.length == 0 || isSearchingAssetByUnitId">
            search
        </mat-icon>
    </button>
    <button mat-icon-button (click)="openCreateAssetPopup()" matTooltip=" Create new Elevator / Escalator" [disabled]="isSearchingAssetByUnitId">
        <mat-icon [class.disabled-icon]="isSearchingAssetByUnitId">add_circle</mat-icon>
    </button>
</div>

<div [hidden]="isTableHidden()">
    <div [class.example-container]="!isSearchByUnitId">
        <table mat-table
               [dataSource]="dataSource"
               multiTemplateDataRows
               matSort
               class="mat-elevation-z8 mat-table-custom" id="virtualprovisioninglist">

            <ng-container *ngFor="let item of columnsToDisplay" matColumnDef="{{item}}">
                <th mat-header-cell *matHeaderCellDef>
                    {{item === 'actions' || item === 'index' || item === 'arrow' ? '' : item}}
                </th>
                <ng-container matColumnDef="index" *ngIf="item === 'index'">
                    <td mat-cell *matCellDef="let element; let i = dataIndex">
                        <p>{{i+1}}</p>
                    </td>
                </ng-container>
                <ng-container *ngIf="item==='actions'">
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button matTooltip="Select Unit" (click)="onSelectUnitId(element)" [disableRipple]="true">
                            <mat-icon>done</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container *ngIf="item!=='actions'">
                    <ng-container *matCellDef="let element" [ngSwitch]="getDataType(item)">
                        <td mat-cell *ngSwitchCase="'string'">
                            <p [innerHTML]="getInput(element,item)"></p>
                        </td>
                        <td mat-cell *ngSwitchCase="'boolean'">
                            <mat-checkbox [checked]="getInput(element,item)" [disabled]="true"></mat-checkbox>
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>
            <tr mat-header-row class="tableheader" *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; let i = dataIndex; columns: columnsToDisplay;"
                class="item">
        </table>
    </div>
</div>