import { Component, OnInit, Inject, Input, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import "rxjs/operators";
import { Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DialogService } from '../../../services/dialog.service';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { Utils } from '../../../utils/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'urlsupport-popup',
    templateUrl: './urlsupport-popup.component.html',
    styleUrls: [
        '../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        './urlsupport-popup.component.less']
})

export class UrlSupportPopupComponent implements OnInit {

    @ViewChild(MatTable) objTable!: MatTable<any>;
    columnsToDisplay = ["urlsupport", "action"];
    list: UrlSupport[] = [];
    patternTaskId = -1;
    USForm!: FormGroup;
    originalUrlSupportList: UrlSupport[] = [];
    modifiedUrlSupportList: UrlSupport[] = [];
    newUrlSupportList: UrlSupport[] = [];


    public dataSource = new MatTableDataSource<UrlSupport>();

    constructor(private http: HttpClient
        , private _formBuilder: FormBuilder
        , private confimDialog: DialogService
        , private toastrService: ToastrService
        , private router: Router
        , @Inject(MAT_DIALOG_DATA) public data: any
        , @Inject('BASE_URL') private baseUrl: string
        , public dialogRef: MatDialogRef<UrlSupportPopupComponent>
        , private fb: FormBuilder
        , private defaults: DefaultValuesService) {

        this.confimDialog.tthis = this;
    }

    ngOnInit(): void {
        this.patternTaskId = this.data.patternTaskId;
        this.USForm = this._formBuilder.group({
            USRows: this._formBuilder.array([])
        });
        this.getUrlSupportByTaskId();
    }

    ngOnChanges() {

    }

    getUrlSupportByTaskId() {
        Utils.httpGetUrlsSupport(
            this.http,
            this.baseUrl,
            this.patternTaskId,
            this,
            function (tthis: UrlSupportPopupComponent, result: UrlSupport[]) {
                tthis.originalUrlSupportList = tthis.list = result;
                tthis.mapDataToForm(tthis.list);
            }
            , function (tthis: UrlSupportPopupComponent, error: string) {
                tthis.toastrService.error('Cannot Support urls information. Please, contact with administrator');
            }
        );
    }

    mapDataToForm(data) {
        this.USForm = this._formBuilder.group({
            USRows: this._formBuilder.array(data.map((val, index) => this._formBuilder.group({
                patternTaskId: new FormControl(this.patternTaskId),
                urlPatternTaskId: new FormControl(val.urlPatternTaskId),
                urlSupport: new FormControl(val.urlSupport, [
                    Validators.required
                ]),
                isOpenOverlay: new FormControl(false),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
                textAreaId: new FormControl(this.getTextAreaId(index)),
                maxCommentLength: new FormControl(2000)
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.USForm.get('USRows') as FormArray).value;
    }

    initUSForm(index: number): FormGroup {
        return this._formBuilder.group({
            patternTaskId: new FormControl(this.patternTaskId),
            urlPatternTaskId: new FormControl(-1),
            urlSupport: new FormControl('', [
                Validators.required
            ]),
            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
            isOpenOverlay: new FormControl(false),
            textAreaId: new FormControl(this.getTextAreaId(index)),
            maxCommentLength: new FormControl(2000)
        });
    }

    addNewRow() {
        const control = this.USForm.get('USRows') as FormArray;
        control.insert(0, this.initUSForm(control.length));
        this.dataSource = new MatTableDataSource(control.value);
    }

    resetRow(i) {
        if (this.USForm.value.USRows[i]?.isNewRow) {
            this.deleteRow(i);
        }
        else {
            this.updateFormPattern(i);
            this.USForm.value.USRows[i].isEditable = true;
        }
        this.dataSource = new MatTableDataSource((this.USForm.get('USRows') as FormArray).value);
        this.refreshTable();
    }

    deleteRow(index) {
        const control = this.USForm.get('USRows') as FormArray;
        control.removeAt(index);
        this.dataSource = new MatTableDataSource((this.USForm.get('USRows') as FormArray).value);
        this.refreshTable();
    }

    updateFormPattern(index) {
        const formArray = this.USForm.get('USRows') as FormArray;
        const formGroup = formArray.at(index) as FormGroup;
        var updated = this.originalUrlSupportList.find(s => s.urlPatternTaskId == this.USForm.value.USRows[index]?.urlPatternTaskId);
        console.log(updated)

        formGroup.patchValue({ urlSupport: updated?.urlSupport });
    }

    editRow(index) {
        this.USForm.value.USRows[index].isEditable = false;
    }

    mapFormToData() {
        this.modifiedUrlSupportList = this.USForm.get('USRows')?.value.filter(row => !row.isNewRow
            && !row.isEditable
            && row.urlPatternTaskId !== -1
            && row.urlSupport !== ""
            && row.urlSupport.length <= 1000
            && this.urlValidator(row.urlSupport)
        );

        this.newUrlSupportList = this.USForm.get('USRows')?.value.filter(row => row.isNewRow
            && row.urlPatternTaskId === -1
            && row.urlSupport !== ""
            && row.urlSupport.length <= 1000
            && this.urlValidator(row.urlSupport)
        );
        return (this.modifiedUrlSupportList?.length > 0 || this.newUrlSupportList?.length > 0)
    }

    getTextAreaId(index) {
        return `tArea_${index}`;
    }

    refreshTable() {
        this.objTable.renderRows();
    }

    anyChange() {
        return this.mapFormToData();
    }

    // Http Requests
    onSave() {
        if (this.anyChange()) {
            if (this.modifiedUrlSupportList?.length > 0 || this.newUrlSupportList?.length > 0)
                Utils.httpMergeUrlSupport(
                    this.http
                    , this.baseUrl
                    , [...this.newUrlSupportList, ...this.modifiedUrlSupportList]
                    , this
                    , function (tthis: UrlSupportPopupComponent, result: any) {
                        tthis.toastrService.success('Url Support information was updated successfully');
                        tthis.getUrlSupportByTaskId();
                    }, function (tthis: UrlSupportPopupComponent, error: string) {
                        tthis.toastrService.error('Cannot save Url Support information. Please, contact with administrator');
                    }
                )
        }

    }

    onDelete(urlPatternTaskId: number, index: number) {
        Utils.httpDeleteUrlSupport(
            this.http
            , this.baseUrl
            , urlPatternTaskId
            , this
            , function (tthis: UrlSupportPopupComponent, result: any) {
                tthis.deleteRow(index)
                tthis.toastrService.success('Url Support was deleted successfully');
            }, function (tthis: UrlSupportPopupComponent, error: string) {
                tthis.toastrService.error('Cannot delete selected url support. Please, contact with administrator');
            }
        )
    }

    urlValidator(url: string): boolean {
        const pattern =
            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
        const valid = pattern.test(url);
        return valid;
    }
    onBack() {
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}


