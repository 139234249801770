<div class="container-tab-lists">
    <mat-tab-group orientation="vertical" #tabGroup (selectedTabChange)="defaults.updateComponentTab($event.tab.textLabel)">
        <!--Messages-->
        <mat-tab *ngIf="defaults.isMersyAdminUser || defaults.isMersyOwnerUser" label="Kpi Management">
            <div class="inner-tcontainer-tab">
                <kpiadmin></kpiadmin>
            </div>
        </mat-tab>

        <mat-tab *ngIf="defaults.isMersyAdminUser" label="Schedule Sections">
            <div class="inner-tcontainer-tab">
                <sectionscheduler></sectionscheduler>
            </div>
        </mat-tab>

    </mat-tab-group>
</div>