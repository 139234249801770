<h2>{{title}} for {{defaults.tenant}}</h2>

<div id="virtualprovisioning">
    <div class="container-expansions">
        <mat-expansion-panel [expanded]="state === 0" (opened)="setState(0)" class="mat-expansion-panel-macaddress">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    First step
                </mat-panel-title>
                <mat-panel-description>
                    Select a valid MacAddress {{ selectedMacAddress !== 'Select' ? getMessageMacAddress() : ''}}
                </mat-panel-description>
                <mat-icon *ngIf="selectedMacAddress !== 'Select'">check_circle</mat-icon>
                <mat-icon *ngIf="selectedMacAddress === 'Select'">error</mat-icon>
            </mat-expansion-panel-header>

            <ng-select (change)="onMacAddressSelect($event)" class ="virtual-provisioning-select" style="width:400px; border: 1px white solid; height: 20px;" 
                       [items]="filteredMacAddress"
                       bindLabel="macaddress"
                       bindValue="macaddress"
                       [closeOnSelect]="true"
                       [searchable]="true"
                       [(ngModel)]="selectedMacAddress"
                       [virtualScroll]="true"
                       (search)="loadMacAddresses($event)"
                       >
            </ng-select>

            <button mat-icon-button matTooltip="Preregister new MacAddress" (click)="openPreregisterMacAddressPopup()">
                <mat-icon aria-label="Preregister new MacAddress">add_circle</mat-icon>
            </button>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="state === 1" (opened)="setState(2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Second step
                </mat-panel-title>
                <mat-panel-description>
                    Select an unprovisioned UnitId {{ unitId !== '' ? getMessageUnitId() : ''}}
                </mat-panel-description>

                <mat-icon *ngIf="unitId !== ''">check_circle</mat-icon>
                <mat-icon *ngIf="unitId === ''">error</mat-icon>
            </mat-expansion-panel-header>
            <mat-tab-group>
                <mat-tab>
                    <ng-template mat-tab-label>
                        All units
                        <mat-spinner *ngIf="isLoadingAssets" diameter="15" style="margin-left: 5px;"></mat-spinner>
                    </ng-template>
                    <virtualprovisioning-list [unitlist]="unitlist"
                                              [isLoadingAssets]="isLoadingAssets"
                                              [errorLoadingAssets]="errorLoadingAssets"
                                              [isSearchByUnitId]="false"
                                              (filter)="filter($event)"
                                              (selectedUnitId)="onUnitIdSelect($event)"
                                              (unitListReload)="onUnitIdListReload()">

                    </virtualprovisioning-list>
                    <div *ngIf="errorLoadingAssets && !isLoadingAssets" style="padding-top:15px">
                        <p style="color:white">An error occurred while retrieving all units. Please try again or search by UnitId.</p>
                    </div>
                    <div *ngIf='isLoadingAssets' class="whitebg card__desc" style="width: 100%; margin-left: 0; padding-top:15px">
                        <div *ngFor="let _ of [].constructor(3)" class="card__desc-text"></div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        Search by Unit Id
                        <mat-spinner *ngIf="isSearchingAssetByUnitId" diameter="15" style="margin-left: 5px;"></mat-spinner>
                    </ng-template>
                    <virtualprovisioning-list [unitlist]="uniqueUnitList"
                                              [isSearchingAssetByUnitId]="isSearchingAssetByUnitId"
                                              [errorLoadingAssetByUnitId]="errorLoadingAssetByUnitId"
                                              [(hasSearchedUnit)]="hasSearchedUnit"
                                              (selectedUnitId)="onUnitIdSelect($event)"
                                              (searchUnitId)="onSearchUnitId($event)"
                                              [isSearchByUnitId]="true">
                    </virtualprovisioning-list>
                    <div *ngIf="errorLoadingAssetByUnitId && !isSearchingAssetByUnitId" style="padding-top:15px">
                        <p style="color:white">The unit '{{searchUnitId}}' cannot be found.</p>
                    </div>
                    <div *ngIf="hasSearchedUnit && !isSearchingAssetByUnitId && uniqueUnitList.length == 0 && !errorLoadingAssetByUnitId" style="padding-top:15px">
                        <p style="color:white">The unit '{{searchUnitId}}' is not available for provisioning.</p>
                    </div>
                    <div *ngIf='isSearchingAssetByUnitId' class="whitebg card__desc" style="width: 100%; margin-left: 0; padding-top:15px">
                        <div *ngFor="let _ of [].constructor(3)" class="card__desc-text"></div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="state === 2" (opened)="setState(2)">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Third step
                </mat-panel-title>
                <mat-panel-description>
                    Select a Controller Type {{ controllerType !== 'Select' ? getMessageControllerType() : ''}}
                </mat-panel-description>

                <mat-icon *ngIf="controllerType !== 'Select'">check_circle</mat-icon>
                <mat-icon *ngIf="controllerType === 'Select'">error</mat-icon>

            </mat-expansion-panel-header>

            <ng-select (change)="onControllerTypeSelect($event)"  class ="virtual-provisioning-select" style="width:400px; border: 1px white solid"
                       [items]="controllertypesList"
                       bindLabel="c"
                       bindValue="ct"
                       [closeOnSelect]="true"
                       [searchable]="true"
                       [(ngModel)]="controllerType">
            </ng-select>
        </mat-expansion-panel>
    </div>

    <div class="mt-2">
        <button mat-icon-button [disableRipple]="true" (click)="createProvisioningRequest()">
            <mat-icon>cloud_upload</mat-icon>
            Provision Unit
        </button>
    </div>
</div>