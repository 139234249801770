import { Component, EventEmitter, Inject, Input, OnInit, Output, Self, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../../../utils/utils';
import { HttpClient } from '@angular/common/http';
import { DefaultValuesService } from '../../../services/defaultvalues.service';
import { DialogService } from '../../../services/dialog.service';
import { DestroyService } from '../../../services/destroyservice';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'filemasterdata',
    templateUrl: './filemasterdata.component.html',
    styleUrls: ['../../../style/new-generic-styles.component.less',
        '../../../style/tables/table-add-butt-header-orange.less',
        '../jcobrules.component.less',
        './filemasterdata.component.less'],
    providers: [DestroyService]
})
export class FileMasterdataComponent implements OnInit {
    public dataSource = new MatTableDataSource<any>();
    @ViewChild(MatTable) objTable!: MatTable<any>;
    CSRForm!: FormGroup;
    genType: number = 0;
    originalJCOBRulefiles: JcobRuleFileItem[] = [];
    modifiedJCOBRulefiles: JcobRuleFileItem[] = [];
    newJCOBRulefiles: JcobRuleFileItem[] = [];
    uniqueCondition: string[] = [];
    isCallSucceed: number = 0;
    subscriptionStage: any;
    forbiddenchars = ['/', '\\', ':', '*', '?', '<', '>', '|', '"'];
    @Output() controllerSoftwareEvent = new EventEmitter<EMTControllerSoftwareReleaseItem[]>();


    @Input() originalMfgCenterProduct: MFGCenterProductItem[] = [];
    @Input() originalSoftwareReleases: EMTControllerSoftwareReleaseItem[] = [];


    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private _formBuilder: FormBuilder,
        private fb: FormBuilder,
        private toastrService: ToastrService,
        public defaults: DefaultValuesService,
        private confimDialog: DialogService,
        @Self() private readonly destroy$: DestroyService    ) {
        this.confimDialog.tthis = this;
    }
    @Input() availableFamilyCodes: FamilyCode[] = [];

    columnsToDisplay = ["RuleFileVersion", "ManufacturingCenterProduct", "ControllerSoftwareVersion", "file", "UseforConfigGen", "action"];
    //columnsToDisplay = ["ruleFileVersion", "ManufacturingCenterProduct", "file", "UseforConfigGen","action"];

    ngOnInit(): void {
        this.subscriptionStage = this.defaults.stageMessageChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => { this.getJCOBRuleFiles() });
        this.getJCOBRuleFiles();
    }
    resetItems() {
        this.genType = 0;
        this.CSRForm = this._formBuilder.group({
            CSRRows: this._formBuilder.array([])
        });
        this.originalJCOBRulefiles.splice(0, this.originalJCOBRulefiles.length);
        this.modifiedJCOBRulefiles.splice(0, this.modifiedJCOBRulefiles.length);
        this.newJCOBRulefiles.splice(0, this.newJCOBRulefiles.length);
        this.uniqueCondition = [];
        this.dataSource = new MatTableDataSource<any>();
    }
    getJCOBRuleFiles() {
        this.resetItems();
                Utils.httpGetJCOBReleaseFiles(
            this.http,
            this.baseUrl,
            this,
            function (tthis: FileMasterdataComponent, result: any) {
                tthis.originalJCOBRulefiles = result;
                tthis.originalJCOBRulefiles.length > 0 ? tthis.mapDataToForm(tthis.originalJCOBRulefiles) : null;
            }
        );
    }



    mapDataToForm(data) {
        //isEditable => Can we go to edit phase 
        this.CSRForm = this.fb.group({
            CSRRows: this.fb.array(data.map(val => this.fb.group({
                ruleFileId: new FormControl(val.ruleFileId),
                ruleFileVersion: new FormControl(val.ruleFileVersion),
                manufacturingCenterProductID: new FormControl(val.manufacturingCenterProductID),
                controllerSoftwareVersionID: new FormControl(val.controllerSoftwareVersionID),
                ruleFile: new FormControl(val.ruleFile),
                userForConfigGeneration: new FormControl(val.userForConfigGeneration),
                isEditable: new FormControl(true),
                isNewRow: new FormControl(false),
                action: new FormControl('existingRecord'),
            })
            )) //end of fb array
        }); // end of form group cretation
        this.dataSource.data = (this.CSRForm.get('CSRRows') as FormArray).value;
    }

    initCSRForm(): FormGroup {
        return this.fb.group({
            ruleFileVersion: new FormControl(""),
            manufacturingCenterProductID: new FormControl(-1),
            controllerSoftwareVersionID: new FormControl(-1),
            ruleFile: new FormControl(null),
            userForConfigGeneration: new FormControl(false),


            action: new FormControl('newRecord'),
            isEditable: new FormControl(false),
            isNewRow: new FormControl(true),
        });
    }

    addNewRow() {
        const control = this.CSRForm.get('CSRRows') as FormArray;
        control.insert(0, this.initCSRForm());
        this.dataSource = new MatTableDataSource(control.controls);
        //    this.updateFileUploadControls();
    }



    getMfgCenterProductText(MfgItem: MFGCenterProductItem) {
        return `${MfgItem.productCode} - ${MfgItem.productName}`;
    }

    resetRow(i) {
        if (this.CSRForm.value.CSRRows[i]?.isNewRow)
            this.deleteRow(i);
        else {
            this.updateFormFeature(i);
            this.CSRForm.value.CSRRows[i].isEditable = true;
        }
    }

    deleteRow(i) {
        const control = this.CSRForm.get('CSRRows') as FormArray;
        control.removeAt(i);
        this.dataSource.data = control.value;
        this.refreshTable();
    }

    updateFormFeature(index) {
        var obj = this.CSRForm.value.CSRRows;
        var updated = this.originalJCOBRulefiles.find(s => s.ruleFileId == this.CSRForm.value.CSRRows[index]?.ruleFileId);
        obj[index].ruleFileVersion = updated?.ruleFileVersion;
        obj[index].manufacturingCenterProductID = updated?.manufacturingCenterProductID;
        obj[index].controllerSoftwareVersionID = updated?.controllerSoftwareVersionID;
        obj[index].ruleFile = updated?.ruleFile;
        obj[index].userForConfigGeneration = updated?.userForConfigGeneration;
    }

    editRow(index) {
        this.CSRForm.value.CSRRows[index].isEditable = false;
    }

    refreshTable() {
        this.objTable.renderRows();
        //    this.updateFileUploadControls();
    }

    onSave() {
        if (this.anyChange()) {

            let formData: FormData = new FormData();
            //this.modifiedJCOBRulefiles.forEach((val) => {
            //    formData.append(val.fileUploadId, val.ruleFile);
            //});
            //this.newJCOBRulefiles.forEach((val) => {
            //    formData.append(val.fileUploadId, val.ruleFile);
            //});  
            let releasesChanges = { 'modifiedReleases': this.modifiedJCOBRulefiles, 'newReleases': this.newJCOBRulefiles }
            formData.append('releasesChanges', JSON.stringify(releasesChanges));

            Utils.httpUpdateJCOBRuleFiles(
                this.http
                , this.baseUrl
                , formData
                , this
                , function (tthis: FileMasterdataComponent, result: any) {
                    if (result?.error && result.errorMssg) {
                        tthis.confimDialog.showErrorDialog({
                            'error':
                            {
                                'message': result.errorMssg
                            }
                        })
                    }
                    tthis.getJCOBRuleFiles();
                    tthis.funcNotify();
                }
            )
        }

    }

    anyChange() {
        this.mapFormToData();
        return (this.modifiedJCOBRulefiles?.length > 0 || this.newJCOBRulefiles?.length > 0) && !(this.uniqueCondition?.length > 0);
    }

    mapFormToData() {
        this.modifiedJCOBRulefiles = this.CSRForm.get('CSRRows')?.value.filter(row =>
            !row.isNewRow
            && !row.isEditable
            && row.manufacturingCenterProductID !== -1
            && row.controllerSoftwareVersionID !== -1
            && row.ruleFileVersion !== "" && row.ruleFileVersion.length <= 30
        );
        this.newJCOBRulefiles = this.CSRForm.get('CSRRows')?.value.filter(row =>
            row.isNewRow
            && row.manufacturingCenterProductID !== -1
            && row.controllerSoftwareVersionID !== -1
            && row.ruleFileVersion !== "" && row.ruleFileVersion.length <= 30
            && row.ruleFile != null

        );
        this.uniqueCondition = this.findObjWithMultipleActive(this.CSRForm.get('CSRRows')?.value)

    }
    findObjWithMultipleActive(items): string[] {
        const itemCounts = items.reduce((acc, item) => {
            if (item.userForConfigGeneration ) {
                if (!acc[item.controllerSoftwareVersionID.toString() + item.manufacturingCenterProductID.toString()] ) {
                    acc[item.controllerSoftwareVersionID.toString() + item.manufacturingCenterProductID.toString()]  = 0;
                }
                acc[item.controllerSoftwareVersionID.toString() + item.manufacturingCenterProductID.toString()] ++;
            }
            return acc;
        }, {} as { [key: string]: number });

        // Filter item names that have more than one active entry
        const result = Object.keys(itemCounts).filter(itemName => itemCounts[itemName] > 1);
        return result;
    }

    toggleOvl(index) {
        this.CSRForm.value.CSRRows[index].isOpenOverlay = !this.CSRForm.value.CSRRows[index].isOpenOverlay;
    }


    getFileUploadId(index) {
        return `fu_${index}`;
    }

    onFileChange(event: any, index) {
        const reader = new FileReader();
        const file = event.target.files[0];
        if (file) {
            reader.onload = (e: any) => {
                const xmlContent = e.target.result;
                this.assignToRuleFile(xmlContent, index);
            };
            reader.readAsText(file);
        }
    }

    assignToRuleFile(xmlContent: string, index) {

        this.CSRForm.value.CSRRows[index].ruleFile = xmlContent;
    }

    funcNotify() {
        if (this.isCallSucceed === 1) {
            this.toastrService.success('Save request successfully sent!');
        }
        else if (this.isCallSucceed === -1) {
            this.toastrService.error('Save action could not be performed. Please, contact with administrator');
        }
    }

    checkInput(event) {
        if (this.forbiddenchars.some(x => x === event.key))
            event.preventDefault();
    }

    getManufacturingCenterProductbyId(id) {
        var result = this.originalMfgCenterProduct.find(x => x.manufacturingCenterProductID == id);
        return result ? `${result.mfgCenter} - ${result.productCode} - ${result.productName}` : "";
    }
    getControllerSoftwareVersionbyId(id): string {
        var result = this.originalSoftwareReleases.find(x => x.controllerSoftwareVersionID == id);
        return result ? result.controllerSoftwareVersion : "";
    }
    truncateFile(value: string, limit) {
        var res = value.length > limit ? value.substring(0, limit) : value;
        return res;
    }
    getRuleFile(i) {
        return this.CSRForm?.get('CSRRows')?.value[i]?.ruleFile;
    }

    onCheckboxChange(event, index): void {
        //this.CSRForm?.get('CSRRows')?.value[i].userForConfigGeneration = event.checked;

        if (event.checked) {
            var sameSoftVerProdIds = this.CSRForm.value.CSRRows.filter(
                x => x.manufacturingCenterProductID == this.CSRForm.value.CSRRows[index].manufacturingCenterProductID
                    && x.controllerSoftwareVersionID == this.CSRForm.value.CSRRows[index].controllerSoftwareVersionID)
            sameSoftVerProdIds.forEach(x => x.userForConfigGeneration = false)
        }

        this.CSRForm.value.CSRRows[index].userForConfigGeneration = event.checked;

        //var temp = [1, 2, 3]
        //temp.forEach
    }
}
